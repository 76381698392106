import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { first, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PopulateRowServiceService {

  constructor(private db: FirebaseDataService) {
  }

  async billCostCenter(bill: any): Promise<any> {
    if (bill.costCenter == 'SIN CC' || !!bill.costCenter) {
      return bill.costCenter;
    } else if (!!bill.purchaseOrder) {
      const purchaseOrder = await this.db.docWithId$(bill.purchaseOrder.path).pipe(take(1)).toPromise() as any;
      return `OC ${purchaseOrder.purchaseID.slice(0, -3)}`;
    } else if (!!bill.project) {
      const project = await this.db.docWithId$(bill.project.path).pipe(take(1)).toPromise() as any;
      return project?.name || '-';
    } else {
      return '-';
    }
  }
}
