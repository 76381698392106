import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTableConfig } from "../../../../../../../../shared/interfaces/data-table-config";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit { @Input() ticketsByUser: any = {};
  @Input() entryTicketsByUser: any = {};
  @Input() tickets: any = {};
  @Input() entryTickets: any = {};
  @Input() users;
  @Output() setSelectedUser: EventEmitter<any> = new EventEmitter<any>();

  userTicketsTableConfig: DataTableConfig = {
    title: 'Artículos retirados por usuario',
    notFoundText: 'No se encontraron tickets',
    hasSearch: true,
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 25,
    filtersFields: ['fullname'.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), 'fullname', 'name', 'surnames', 'rut','userType']
  };

  constructor() {}

  ngOnInit(): void {}

  openUserTicketsDetails(user: any) {
    this.handleSelectedUser(user);
  }

  handleSelectedUser(user: any) {
    this.setSelectedUser.emit(user);
  }
}
