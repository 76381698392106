import { Pipe, PipeTransform } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FirebaseDataService } from '../template-services/firebase-data.service';

@Pipe({
  name: 'doc'
})
export class DocPipe implements PipeTransform {

  constructor(private db: FirebaseDataService) {
  }

  transform(reference: DocumentReference): Observable<any> {
    if (!reference?.path) return null;
    return this.db.doc$(reference.path);
  }

}
