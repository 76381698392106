import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProviderService } from '../../../shared/services/provider.service';
import { ProjectService } from '../../../shared/services/project.service';
import { Project } from '../../interfaces/project';
import { map, take } from 'rxjs/operators';
import { Provider } from '../../interfaces/provider';
import { CostCenterType } from '../../enums/cost-center-type.enum';
import { ProjectStatus } from '../../enums/project-status.enum';

@Component({
  selector: 'app-provider-info',
  templateUrl: './provider-info.component.html',
  styleUrls: ['./provider-info.component.css']
})
export class ProviderInfoComponent implements OnInit {
  @Input() providerInfo: any;

  @Output() eventChangeProviderForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() budgetsEmitter: EventEmitter<any> = new EventEmitter<any>();

  submitted: boolean = false;
  providerForm: FormGroup;
  providersSubscription: Subscription;
  providers: Provider[];
  projectSubscription: Subscription;
  projects: Project[];
  projectStatusEnum = ProjectStatus;
  selectArray = [];
  budgets: any[];

  constructor(
    private formBuilder: FormBuilder,
    private _provider: ProviderService,
    private _project: ProjectService
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.getProviders();
    this.getProjects();

    if (this.providerInfo) this.providerForm.patchValue(this.providerInfo);
  }

  private getProviders() {
    this.providersSubscription = this._provider
      .getAll()
      .subscribe(async (data) => {
        this.providers = data;
        this._provider.providers = data;

        if (this.providerInfo.provider) {
          let providerIndex = this.providers.findIndex(
            (provider) => provider.key == this.providerInfo.provider.id
          );

          this.providerForm.patchValue({
            provider: this.providers[providerIndex]
          });
        }
      });
  }

  private getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project.map((project) => ({
            ...project,
            type:
              project.type == CostCenterType.PROJECT
                ? 'Proyectos'
                : 'Centro de Costos'
          }))
        )
      )
      .subscribe(async (data) => {
        if (data.length > 0) {
          data = data.filter((d) => d.status == this.projectStatusEnum.ACTIVE);
        }

        this.selectArray = data;

        if (this.providerInfo.project) {
          let projectIndex = this.selectArray.findIndex(
            (project) => project.key == this.providerInfo.project.id
          );

          this.providerForm.patchValue({
            project: this.selectArray[projectIndex]
          });

          await this.getCategories(true);
          await this.changeProviderForm(null);
        }
      });
  }

  get formControls() {
    return this.providerForm.controls;
  }

  createForm(): void {
    this.providerForm = this.formBuilder.group({
      provider: ['', Validators.required],
      project: ['', Validators.required],
      budget: ['', Validators.required]
    });
  }

  async getCategories(isErased: boolean) {
    if (
      !this.providerForm.value.project ||
      (!this.providerForm.value.project.budgets && !this.providerForm.value.project.budget)
    ) {
      return (this.budgets = []);
    }

    let budgets = null;

    if (this.providerForm.value.project.budgets) budgets = this.providerForm.value.project.budgets;
    if (this.providerForm.value.project.budget) {
      const budgetsData = await this._project.getBudgets(this.providerForm.value.project.budget.path).pipe(take(1)).toPromise();
      budgets = budgetsData.budgets;
    }

    this.budgets = budgets;
    this.budgetsEmitter.emit(budgets);

    for (let i = 0; i < this.budgets.length; i++) {
      let category = await this._project
        .getCategory(this.budgets[i].reference.id)
        .pipe(take(1))
        .toPromise();

      this.budgets[i].name = category.name;
    }

    if (isErased) {
      let index = this.budgets.findIndex(
        (budget) =>
          budget.reference.id == this.providerForm.value.budget.reference.id
      );

      let category = await this._project
        .getCategory(this.budgets[index].reference.id)
        .pipe(take(1))
        .toPromise();

      let budget = {
        name: category.name,
        reference: this.budgets[index].reference,
        totalBudget: this.budgets[index].totalBudget,
        usedBudget: this.budgets[index].usedBudget
      };

      this.providerForm.patchValue({ budget: budget });
    }
  }

  async changeProviderForm(isProject: boolean) {
    if (isProject) {
      await this.getCategories(null);
      this.providerForm.patchValue({ budget: null });
    }

    this.eventChangeProviderForm.emit(this.providerForm.value);
  }
}
