import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProjectService } from '../../../../../shared/services/project.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import moment from 'moment';
import { ContractService } from '../../services/contract.service';
import { Applicant } from '../../../../interfaces/applicant';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicantService } from '../../../../../shared/services/applicant.service';
import { AuthService } from '../../../../../shared/template-services/auth.service';
import { UserService } from '../../../../../shared/services/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.css']
})
export class AddContractComponent implements OnInit, OnDestroy {
  contractForm: FormGroup;
  projects: any[] = [];
  projectsSubscription: Subscription = new Subscription();
  submitted = false;
  applicant: Applicant;
  users: any[] = [];
  contractTypes = ['PLAZO FIJO', 'OBRA O FAENA', 'INDEFINIDO'];

  constructor(private fb: FormBuilder,
              private _project: ProjectService,
              private _contract: ContractService,
              private _applicant: ApplicantService,
              private _auth: AuthService,
              private _user: UserService,
              public modal: BsModalRef) {
    this.contractForm = fb.group({
      contractType: ['', Validators.required],
      project: ['', Validators.required],
      startDate: [moment(Date.now()).format('YYYY-MM-DD'), Validators.required],
      endDate: [moment(Date.now()).format('YYYY-MM-DD'), Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    this.projectsSubscription = this._project.getAll().subscribe(projects => {
      this.projects = projects;

      if (this.applicant.project) {
        this.contractForm.patchValue({
          project: projects.find(project => project.key === this.applicant.project.id)
        });
      }
    });

    this.users = await this._user.getAll().pipe(take(1)).toPromise();
  }

  ngOnDestroy(): void {
    this.projectsSubscription.unsubscribe();
  }

  get formControls() {
    return this.contractForm.controls;
  }

  async submit() {
    this.submitted = true;

    if (this.contractForm.invalid) return;

    if (await AlertService.confirm('¿Está seguro de que desea agregar este contrato?')) {
      const contract = {
        contractType: this.contractForm.value.contractType,
        project: this._project.getReference(this.contractForm.value.project.key),
        startDate: moment(this.contractForm.value.startDate).toDate().getTime(),
        endDate: moment(this.contractForm.value.endDate).toDate().getTime()
      };

      await this._applicant.update(this.applicant.key, {
        initialAccessDate: contract.startDate,
        finalAccessDate: contract.endDate,
        contractDate: Date.now()
      } as any);

      await this._contract.add(this.applicant.key, contract);

      if (!this.users.find(item => item.email === this.applicant.email)) {
        await this.sendAccountModal();
      }

      this.modal.hide();
    }
  }

  async sendAccountModal() {
    this._auth.addToWhitelist(this.applicant.email);

    let user = {
      rut: this.applicant.rut || '',
      name: this.applicant.name || '',
      surnames: this.applicant.surnames || '',
      city: this.applicant.city || '',
      position: this.applicant.position || '',
      status: this.applicant.status || '',
      birthday: this.applicant.birthday || '',
      workExperience: this.applicant.workExperience || '',
      educationLevel: this.applicant.educationLevel || '',
      email: this.applicant.email || '',
      phone: this.applicant.phone || '',
      nationality: this.applicant.nationality || '',
      experience: this.applicant.experience || '',
      expectation: this.applicant.expectation || '',
      quiz: this.applicant.quiz || '',
      curriculum: this.applicant.curriculum || '',
      isApplicant: true,
      firstLogin: true,
      trash: false,
      isDisable: false,
      permissions: []
    };

    const resp: any = await this._user.createAccount(
      this.applicant.email,
      this.applicant.rut
        .replaceAll('.', '')
        .replaceAll('-', '')
        .slice(0, -1)
    );

    if (!resp.ok) {
      return AlertService.error(AuthService.getMessageError(resp.error.code));
    }

    await this._user.addUserByKey(resp.uid, user);
  }
}
