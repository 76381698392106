export const MedicServices= ['FONASA', 'ISAPRES']
export const SizeShoesArray = [
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
];

export const SizeArray = [
  'XXL',
  'XL',
  'L',
  'M',
  'S'
];

export const AccountTypeArray = [
  'Cuenta corriente',
  'Cuenta Vista',
  'Cuenta RUT',
  'Chequera Electrónica',
  'Cuenta de Ahorro'
];

export const BankArray = [
  'Banco Santander',
  'Scotiabank Azul',
  'Banco BICE',
  'Banco Internacional',
  'Banco Itaú',
  'Banco de chile / Edwards-Citi',
  'Corpbanca',
  'Banco Crédito e Inversiones',
  'Banco del Desarrollo',
  'Banco Estado',
  'Banco Falabella',
  'Banco Security',
  'Scotiabank',
  'Rebobank',
  'HSBC Bank',
  'Banco Ripley',
  'Banco Paris',
  'Banco Consorcio',
  'Coopeuch',
  'Prepago los Heroes',
  'Tiempo Prepago S.A'
];
