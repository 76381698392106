<div class="chat-application chat-window-wrapper">
  <div class="card chat-wrapper shadow-none mb-0 bg-transparent h-100">
    <div class="card-content">
      <div class="card-body chat-container" id="test" fxFlex="auto">
        <div class="chat-content">
          <div
            class="chat chat-left message-container" #messages
            *ngFor="let comment of comments"
          >
            <div class="chat-avatar">
              <a [routerLink]="" class="avatar m-0">
                <img [src]="(comment.user | doc | async)?.imageUrl"/>
              </a>
            </div>
            <div class="chat-body ml-2">
              <div class="chat-message">
                <p>
                  <label
                    *ngFor="let user of comment.usersNotification; let last = last"
                    class="user-notification"
                  >
                    @{{ user.surnames ? user.name + ' ' + user.surnames : user.name }}
                    <span *ngIf="!last">{{ ',&nbsp;' }}</span>
                  </label>
                  <br *ngIf="comment?.usersNotification?.length">
                  <app-text-link [text]="comment.comment"></app-text-link>
                </p>
                <span class="chat-time">{{ comment.date | date : 'dd/MM/yyyy HH:mm' }}</span>
                <div class="comment-controls">
                  <div app-tooltip="Eliminar" (click)="deleteComment(comment.key)">
                    <i class="fa fa-times"></i>
                  </div>
                  <div app-tooltip="Editar" (click)="editComment(comment.key,comment.comment)">
                    <i class="fa fa-edit"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex mb-1 justify-content-center">
    <ng-select
      [items]="users"
      [searchable]="true"
      [multiple]="true"
      bindLabel="name"
      appendTo="body"
      [(ngModel)]="usersNotification"
      placeholder="Notificar a usuarios"
    >
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }} {{ item.surnames }}
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label ">{{ item.name }} {{ item.surnames }}</span>
        <span class="ng-value-icon right " (click)="clear(item)">×</span>
      </ng-template>
    </ng-select>
  </div>
  <div class="card-footer chat-footer pb-0">
    <form [formGroup]="commentForm" class="d-flex flex-column justify-content-center align-items-end">
      <textarea
        type="text" formControlName="comment" class="form-control chat-message-send mb-1"
        rows="4"
        id="iconLeft4"
        placeholder="Escriba un comentario aquí...."
      >
      </textarea>
      <button
        type="submit" class="btn btn-primary glow send d-lg-flex"
        (click)="submitComment(applicant.key, $event)"
      >
        <i class="ficon feather ft-send"></i>
        <span class="d-none d-lg-block ml-1">Enviar</span></button>
    </form>
  </div>
</div>
