import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableConfig } from '../../../../../../../shared/interfaces/data-table-config';
import _ from 'lodash';
import { ProviderService } from '../../../../../../../shared/services/provider.service';
import { AlertService } from '../../../../../../../shared/template-services/alert.service';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-search-order-article',
  templateUrl: './search-order-article.component.html',
  styleUrls: ['./search-order-article.component.css']
})
export class SearchOrderArticleComponent implements OnInit {
  @Output() selectedArticle: EventEmitter<any> = new EventEmitter<any>();

  articles$: Observable<any>;
  units$: Observable<any>;
  articles: any[];
  units: any[];

  providers: any[] = [];

  filteredArticles: any[];
  article: any;
  providerFilter;
  unitFilter;
  discountFilter;
  priceFilter = { to: null, from: null };
  dateFilter = { to: null, from: null };
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron artículos',
    hasSearch: true,
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 15,
    filtersFields: ['name'],
  };

  constructor(private _provider: ProviderService,
    private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.articles$.subscribe(async articles => {
      this.articles = articles;
      const providers = _.chain(articles)
        .map('provider.id')
        .uniq()
        .value();
      this.providers = await this._provider.populateArray(providers);
      this.filteredArticles = articles;
    });

    this.units$.subscribe(units => {
      this.units = units;
    });
  }

  filterArticles() {
    let aux = this.articles;

    if (this.dateFilter.from || this.dateFilter.to) {
      const from = this.dateFilter.from ? moment(this.dateFilter.from, 'YYYY-MM-DD').valueOf() : null;
      const to = this.dateFilter.to ? moment(this.dateFilter.to, 'YYYY-MM-DD').valueOf() : null;

      aux = aux.filter(article => {
        if (from && to) {
          return article.createdAt >= from && article.createdAt <= to;
        } else if (from) {
          return article.createdAt >= from;
        } else {
          return article.createdAt <= to;
        }
      });
    }

    if (this.priceFilter.from || this.priceFilter.to) {
      aux = aux.filter(article => {
        if (this.priceFilter.from && this.priceFilter.to) {
          return article.unitPrice >= this.priceFilter.from && article.unitPrice <= this.priceFilter.to;
        } else if (this.priceFilter.from) {
          return article.unitPrice >= this.priceFilter.from;
        } else if (this.priceFilter.to) {
          return article.unitPrice <= this.priceFilter.to;
        }
      });
    }

    if (this.unitFilter) aux = aux.filter(article => article.unitType.id === this.unitFilter);
    if (this.providerFilter) aux = aux.filter(article => article.provider.id === this.providerFilter);
    if (this.discountFilter) aux = aux.filter(article => !!article.discount);

    this.filteredArticles = aux;
  }

  async handleSelectArticle(data) {
    const quantity = await AlertService.input('Ingrese la cantidad', '', 'Agregar', '', 'number');
    this.selectedArticle.emit({
      ...data,
      quantity: +quantity,
      unit: data.unitType,
      unitValue: data.unitPrice
    });
    this.modal.hide(100);
  }
}
