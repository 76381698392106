import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Chart } from 'chart.js';
import { SalaryRangeFormComponent } from '../salary-range-form/salary-range-form.component';
import { first } from 'rxjs/operators';
import _ from 'lodash';
import { JobService } from '../../../../../shared/services/job.service';

@Component({
  selector: 'app-position-salary-graph',
  templateUrl: './position-salary-graph.component.html',
  styleUrls: ['./position-salary-graph.component.css']
})
export class PositionSalaryGraphComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  barChart: any;
  labels: string[] = [];
  data: number[] = [];
  positions: any[] = [];
  applicantsByPosition: {};
  selectedOption: number;
  salaryRanges: any[] = [];
  isComponentMounted: boolean = false;
  defaultSalaryRanges = [
    {
      max: 10000,
      label: '$0 - $10.000'
    },
    {
      min: 10000,
      max: 20000,
      label: '$10.000 - $20.000'
    },
    {
      min: 20000,
      max: 30000,
      label: '$20.000 - $30.000'
    },
    {
      min: 30000,
      max: 40000,
      label: '$30.000 - $40.000'
    },
    {
      min: 40000,
      label: '$40,000 +'
    }
  ];

  constructor(private modal: BsModalService,
    private _position: JobService) {
  }

  ngOnInit(): void {
    this._position.getAll().subscribe(positions => {
      this.positions = positions;
      const currentPosition = _.find(positions, { position: this.selectedOption });
      this.salaryRanges = currentPosition.salaryRanges || this.defaultSalaryRanges;
      const rawSalaryByRange = this.getSalaryByRange(this.applicantsByPosition[this.selectedOption]);

      if (!this.isComponentMounted) {
        this.isComponentMounted = true;
        this.setChart(this.salaryRanges, rawSalaryByRange);
      } else {
        this.updateChart(this.salaryRanges, rawSalaryByRange);
      }
    });
  }

  getSalaryByRange(salaryArray: { min: number, max: number }[]): number[] {
    const rawSalaryByRange = [];
    this.salaryRanges.forEach((range, index) => {
      rawSalaryByRange.push(salaryArray.filter(salary => {
          if (!range.min) {
            return salary <= range.max;
          } else if (!range.max) {
            return salary >= range.min;
          } else {
            return salary >= range.min && salary <= range.max;
          }
        }).length
      );
    });
    return rawSalaryByRange;
  }

  setChart(labels: { label: string }[], data: number[]) {
    this.barChart = new Chart('canvas', {
      type: 'bar',
      data: {
        labels: labels.map(range => range.label),
        datasets: [
          {
            data: data,
            backgroundColor: 'blue',
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Rango salarial',
              fontSize: 16,
              fontStyle: 'bold'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'N° de postulantes',
              fontSize: 16,
              fontStyle: 'bold'
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  updateChart(labels: { label: string }[], data: number[]) {
    this.barChart.data.labels = labels.map(range => range.label);
    this.barChart.data.datasets[0].data = data;
    this.barChart.update();
  }

  selectFilter() {
    this.salaryRanges = _.find(this.positions, { position: this.selectedOption }).salaryRanges || this.defaultSalaryRanges;
    const newSalaryByRange = this.getSalaryByRange(this.applicantsByPosition[this.selectedOption]);
    this.updateChart(this.salaryRanges, newSalaryByRange);
  }

  async openSalaryRangeForm() {
    const currentPosition = _.find(this.positions, { position: this.selectedOption });
    const modalRef = this.modal.show(SalaryRangeFormComponent, {
      initialState: {
        positionKey: currentPosition.key,
        salaryRanges: this.salaryRanges
      },
      class: 'shadow-lg',
      id: 919293
    });

    await modalRef.onHide.pipe(first()).toPromise();
  }

  closeModal() {
    this.modal.hide();
  }
}
