import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectService } from '../../../../shared/services/project.service';
import { Project } from '../../../interfaces/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectsDataService {
  private dataSubject = new BehaviorSubject<Project[]>([]);
  public data$: Observable<Project[]> = this.dataSubject.asObservable();

  constructor(private projectService: ProjectService) {
    this.initData();
  }

  private initData(): void {
    this.projectService.getAll().subscribe(data => {
      this.dataSubject.next(data);
    });
  }
}
