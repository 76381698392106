<fieldset
  *ngIf="warehouse"
  class="border-container w-100"
  cdkDropList
  cdkDropListSortingDisabled
>
  <legend class="border-label"><span>{{ warehouse.name + ' (' + warehouse.code + ')' }}</span></legend>
  <div class="d-flex flex-column">
    <ti-input
      class="mx-2"
      mode="material"
      type="text"
      label="Buscar articulo"
      [(model)]="searchValue"
      (modelChange)="search()"
    ></ti-input>
    <div class="p-2">
      <app-warehouse-article
        *ngFor="let article of  searchedArticles"
        [article]="article"
        [warehouseId]="warehouse.key"
      ></app-warehouse-article>
    </div>
  </div>
</fieldset>
