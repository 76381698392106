<form class="input-container" (submit)="handleNewLimit()">
  <div>
    <label class="">Salario mínimo:</label>
    <input class="form-control p-sm" type="number" [(ngModel)]="lowerLimit" name="min"/>
  </div>
  <button class="btn btn-info" type="submit">Aplicar cambio</button>
</form>
<div class="table-container">
  <div class="w-100">
    <table class="salary-range">
      <thead>
      <tr>
        <th>Cargo</th>
        <th>Mínimo</th>
        <th>Máximo</th>
        <th>Promedio</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let position of positions" (click)="togglePositionSalaryModal(position.position)">
        <td>{{position.name}}</td>
        <td>{{salaryByPosition[[position.position]].min | currencyFormat}}</td>
        <td>{{salaryByPosition[[position.position]].max | currencyFormat}}</td>
        <td>{{salaryByPosition[[position.position]].avg | currencyFormat}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
