export enum PurchaseStatus {
  UNDER_APPROVAL = -3,
  UNDER_REVIEW = -2,
  WAITING_FOR_REVIEW = -1,
  DRAFT,
  GENERATED,//WAITING_FOR_APPROVAL
  AUTHORIZED,
  NOT_AUTHORIZED,
  FINALIZED,
  INCOMPLETE,
  SHIPPED,
  CANCELLED = 99,
}
