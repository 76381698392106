import {Component, OnInit} from '@angular/core';
import {PurchaseOrderService} from '../../../shared/services/purchase-order.service';
import {WorkerSalariesService} from '../../services/worker-salaries.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, of, Subscription} from 'rxjs';
import {PurchaseOrderModalComponent} from '../../pages/purchase-orders/modals/purchase-order-modal/purchase-order-modal.component';
import {PurchaseStatus} from '../../enums/purchase-status.enum';
import {PurchaseStatusLabel} from '../../labels/purchase-status.label';
import {Provider} from '../../interfaces/provider';
import {ProviderService} from '../../../shared/services/provider.service';
import {BillService} from '../../../shared/services/bill.service';
import {PaymentStatusLabel} from '../../labels/payment-status.label';
import {ReportStatus} from '../../enums/report-status.enum';
import {ChangePaydayComponent} from '../change-payday/change-payday.component';
import {take} from 'rxjs/operators';
import {BillModalComponent} from '../bill-modal/bill-modal.component';
import {UserType} from '../../enums/user-type.enum';
import {formatDate} from '@angular/common';
import {Bill} from '../../interfaces/bill';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
  bill: Bill = {key: ''} as Bill;
  bills: any;
  bills$: Observable<Bill[]>;
  payments: any;
  purchases$: any;
  purchases = [];
  salaries = [];
  moves = [];
  filteredPaidBills = [];
  filteredUnpaidBills = [];
  firstDay: number;
  lastDay: number;
  month: number;
  configPurchase: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron facturas',
    title: 'Facturas',
  };
  load: boolean = true;
  purchaseStatusLabel = PurchaseStatusLabel;
  paymentStatusLabel = PaymentStatusLabel;
  providersSubscription: Subscription;
  providers: Provider[];
  typePayment: string;
  thereWasChange: boolean = false;
  permission: UserType;
  permissionEnum = UserType;
  changedDate;

  constructor(
    public modal: BsModalRef,
    private modalService: BsModalService,
    private _provider: ProviderService,
    private _bill: BillService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getProviders();
    await this.getPurchases();
    await this.getBills();

    this.load = false;
  }

  getPurchases() {
    this.purchases = this.payments;
    this.purchases$ = of(this.purchases);
  }

  getBills() {
    this.bills$ = of(this.bills);
  }

  getRut(provider) {
    if (!provider) return '-';
    return provider.substr(0, provider.indexOf(' '));
  }

  getProviders() {
    this.providersSubscription = this._provider.getAll().subscribe((data) => {
      this.providers = data;
    });
  }

  getProvider(providerReference: any): any {
    if (this.providers == undefined) return;
    let providerIndex = this.providers.findIndex(
      (provider) => provider.key == providerReference.id
    );
    if (providerIndex == -1) {
      return '-';
    }
    return this.providers[providerIndex];
  }

  gotoOpenEditPurchaseOrder(purchaseOrder) {
    this.modalService.show(PurchaseOrderModalComponent, {
      initialState: {
        purchaseOrder: {...purchaseOrder},
      },
      class: 'modal-xxl',
      backdrop: 'static',
      id: 31,
    });
  }

  gotoOpenEditBill(bill) {
    const modalRef: BsModalRef = this.modalService.show(BillModalComponent, {
      initialState: {
        bill: {...bill},
        isUpdate: true,
      },
      class: 'modal-xl',
      backdrop: 'static',
      id: 8177,
    });

    modalRef.onHide.pipe(take(1)).subscribe(() => {
      if (modalRef.content.itHasUpdated) {
        let date = modalRef.content.expectedPaymentDate.slice(0, -3);
        let month: number = +date.substring(5);
        let day: number = +modalRef.content.expectedPaymentDate.substring(8);

        if (month == this.month && day > this.firstDay && day <= this.lastDay)
          return;

        let index = this.purchases.findIndex(
          (payment) => payment.key == modalRef.content.bill.key
        );
        this.bills.splice(index, 1);
        this.getPurchases();

        this.thereWasChange = true;
      }
    });
  }

  changePayday(key, date, move) {
    const modalRef: BsModalRef = this.modalService.show(ChangePaydayComponent, {
      initialState: {
        key: key,
        date: date,
        move: move,
      },
      backdrop: 'static',
      id: 33,
    });

    modalRef.onHide.pipe(take(1)).subscribe(() => {
      if (modalRef.content.itHasUpdated) {
        let date = modalRef.content.date.slice(0, -3);
        let month: number = +date.substring(5);
        let day: number = +modalRef.content.date.substring(8);

        if (month == this.month && day > this.firstDay && day <= this.lastDay)
          return;

        if (move == 'purchase') {
          let indexPurchase = this.purchases.findIndex(
            (payment) => payment.key == modalRef.content.key
          );
          this.purchases.splice(indexPurchase, 1);
          this.getPurchases();
        }

        if (move == 'bill') {
          let indexBill = this.bills.findIndex(
            (bill) => bill.key == modalRef.content.key
          );
          let changedBill = this.bills.find(
            (bill) => bill.key == modalRef.content.key
          );
          this._bill.update(changedBill.key, {
            expectedPaymentDate: this.convertToTime(modalRef.content.date),
          } as Bill);
          this.bills.splice(indexBill, 1);
        }

        this.thereWasChange = true;
      }
    });
  }

  getStatusClass(status: number) {
    switch (status) {
      case PurchaseStatus.DRAFT:
        return 'badge-dark';

      case PurchaseStatus.GENERATED:
        return 'badge-primary';

      case PurchaseStatus.AUTHORIZED:
        return 'badge-info';

      case PurchaseStatus.NOT_AUTHORIZED:
        return 'badge-warning';

      case PurchaseStatus.FINALIZED:
        return 'badge-success';

      default:
        return 'badge-success';
    }
  }

  getPaymentClass(status: number) {
    switch (status) {
      case ReportStatus.COMPLETE:
        return 'badge-success';

      case ReportStatus.INCOMPLETE:
        return 'badge-dark';

      case ReportStatus.WITHOUT_PAYING:
        return 'badge-danger';
    }
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  convertToTime(date) {
    if (date == null) {
      return null;
    }
    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }
}
