import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TicketService } from '../../../../../shared/services/ticket.service';
import { WarehouseService } from '../../../../../shared/services/warehouse.service';
import { Ticket } from '../../interfaces/ticket';
import { UserService } from '../../../../../shared/services/user.service';
import { Article } from '../../interfaces/article';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { Warehouse } from '../../interfaces/warehouse';
import { TicketStatus } from '../../enums/ticket-status.enum';
import { debounceTime, first, map, take } from 'rxjs/operators';
import { ProjectService } from '../../../../../shared/services/project.service';
import { User as UserInterface } from '../../../../../shared/interfaces/user';
import { LogsService } from '../../../../services/logs.service';
import { ProjectStatus } from '../../../../enums/project-status.enum';
import { ApplicantService } from '../../../../../shared/services/applicant.service';
import { CostCenterType } from '../../../../enums/cost-center-type.enum';
import { AppConstants } from '../../../../../shared/helpers/app.constants';

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.css']
})
export class AddTicketComponent implements OnInit, OnDestroy {
  user: any;
  users: UserInterface[];
  userSubscription: Subscription;
  ticketForm: FormGroup;
  submitted = false;
  warehouse: Warehouse;
  articleReferences: Article[];
  warehouseKey: string;
  articles = [];
  currentDate = new Date().getTime();
  articleSelected: Article;
  total: number = 0;
  projectStatus: ProjectStatus;
  selectArray: any = [];
  projectSubscription: Subscription = new Subscription();
  priceArticle: number = 0;
  totalArticle: number = 0;
  quantity: number = 0;
  ticket: any;
  categories: any = [];
  warehouseSelected: Warehouse;
  warehouses: Warehouse[];
  isTransfer: boolean = false;
  warehousesSubscription: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private _ticket: TicketService,
    private _warehouse: WarehouseService,
    private _user: UserService,
    public modal: BsModalRef,
    private _project: ProjectService,
    private _log: LogsService,
    private _applicant: ApplicantService
  ) {
    this.ticketForm = this.formBuilder.group({
      articles: [[], Validators.required],
      comments: ['', Validators.required],
      createdDate: [new Date().getTime()],
      endComments: [''],
      endDate: [''],
      status: [TicketStatus.DRAFT],
      isAuthorized: [false],
      supervisor: [null],
      user: ['', Validators.required],
      warehouse: ['', Validators.required],
      total: [0],
      trash: [false],
      projects: [null, Validators.required],
      projectCategory: [null, Validators.required],
      quantity: ['0'],
      article: [''],
      createdBy: [''],
      destinationWarehouse: ['']
    });
  }

  async ngOnInit(): Promise<void> {
    this.getProjects();
    this.setWarehouseReference();
    this.getUsers();
    this.dropArticlesOutOfStock();
    this.mapArticles();
    this.getWarehouses();
  }

  private dropArticlesOutOfStock() {
    for (let article of this.articleReferences) {
      if (article.quantity == 0) {
        const index = this.articleReferences.findIndex((articleReference) => articleReference.name == article.name);
        this.articleReferences.splice(index, 1);
      }
    }
  }

  setWarehouseReference() {
    this.ticketForm.patchValue({ warehouse: this._warehouse.getReference(this.warehouseKey) });
  }

  normalizeRut(rut: string) {
    return (rut || '').replaceAll('.', '').replaceAll('-', '');
  };

  private async getUsers() {
    const applicants = await this._applicant.getAll().pipe(take(1)).toPromise();
    this.userSubscription = this._user.getUsers()
      .subscribe(data => {
        this.users = data.map(user => ({
          ...user,
          fullName: `${user.name} ${user.surnames}`,
          applicant: applicants.find(applicant => this.normalizeRut(applicant.rut) === this.normalizeRut(user.rut))
        })).filter(user => {
          return !user.isApplicant || (user.applicant && user.applicant.finalAccessDate >= Date.now());
        });
      });
  }

  get formControls() {
    return this.ticketForm.controls;
  }

  getWarehouses() {
    this.warehousesSubscription = this._warehouse.getAll().subscribe(warehouses => {
      this.warehouses = warehouses.filter(warehouse => warehouse.key != this.warehouseKey);
    });
  }

  getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        debounceTime(300),
        first(),
        map((projects) =>
          projects
            .filter(project => project.status == ProjectStatus.ACTIVE)
            .map((activeProject) => ({
              name: activeProject.name,
              key: activeProject.key,
              budgets: activeProject.budgets,
              type:
                activeProject.type == CostCenterType.PROJECT
                  ? 'Proyectos'
                  : 'Centro de Costos'
            }))
        )
      )
      .subscribe(async (data) => {
        this.selectArray = data;
      });
  }

  async submit() {
    this.submitted = true;

    if (this.isTransfer && !this.warehouseSelected) {
      return AlertService.toastError('Debe seleccionar una bodega de destino');
    }
    if (!this.ticketForm.valid) {
      return AlertService.toastError('Verifique que haya llenado todos los campos');
    }

    this.ticketForm.removeControl('article');
    this.ticketForm.removeControl('quantity');
    this.ticketForm.removeControl('destinationWarehouse');

    this.ticketForm.patchValue({ createdBy: this._user.getReference(this._user.user.key) });

    if (this.isTransfer) {
      this.ticketForm.patchValue({ isTransfer: true });
      const transferArticle = this.articles.map(article => {
        return {
          ...article,
          destinyWarehouse: this._warehouse.getReference(this.warehouseSelected.key),
          reference: this._warehouse.getReference(article.key)
        };
      });

      this.ticketForm.patchValue({ articles: transferArticle });
    }

    const numberTicket = (await this._ticket.getId()) + 1;
    this._ticket.updateTicketID(numberTicket);
    let milliseconds = (new Date().getMilliseconds()).toString();
    let millisecondsString = ('000' + milliseconds);
    const ticketMilliseconds = millisecondsString.slice(millisecondsString.length - 3, millisecondsString.length);
    const ticketId = `${numberTicket}${ticketMilliseconds}`;

    let ticket: any = await this._ticket.add({
      ...this.ticketForm.value,
      isTransfer: this.isTransfer
    } as Ticket, ticketId);

    this._log.add(this.warehouseKey, {
      description: `Se generó el borrador del ticket ${ticketId.slice(0, -3)}`
    });

    this.ticket = {
      ...this.ticketForm.value,
      key: ticket.id,
      ticketID: ticketId
    };

    this.modal.hide();
  }

  setArticleSelected($event: any) {
    this.articleSelected = $event;
    this.priceArticle = this.articleSelected.price;
    if (this.quantity != 0) {
      this.addArticle(this.quantity);
      this.quantity = 0;
      this.priceArticle = 0;
      this.ticketForm.patchValue({ quantity: 0, article: '' });
    }
  }

  addArticle(quantity) {
    const articleQuantity: number = +quantity.value;
    const index = this.articles.findIndex((article) => article.name == this.articleSelected.name);

    if (index >= 0) {
      return AlertService.toastError('Este artículo ya está en la lista', '');
    }
    if (articleQuantity <= 0) {
      return AlertService.toastError('No ha seleccionado cantidad', '');
    }

    if (this.articleSelected.quantity < articleQuantity) {
      return AlertService.info(`La cantidad disponible para este artículo es: ${this.articleSelected.quantity}`, '');
    }

    this.articles.push({
      name: this.articleSelected.name,
      internalId: this.articleSelected.internalId,
      type: this.articleSelected.type,
      location: !!this.articleSelected.location ? this.articleSelected.location : '',
      quantity: articleQuantity,
      price: this.articleSelected.price,
      total: this.articleSelected.price * articleQuantity,
      key: this.articleSelected.key,
      isComplete: false,
      lost: false,
      message: '',
      imageUrl: !!this.articleSelected.imageUrl ? this.articleSelected.imageUrl : null,
      returned: 0,
      quantityOrdered: articleQuantity
    });

    this.total += this.articleSelected.price * articleQuantity;
    this.ticketForm.patchValue({ articles: this.articles, total: this.total });
  }

  dropArticle(index: number) {
    this.total -= this.articles[index].price * this.articles[index].quantity;
    this.articles.splice(index, 1);
    this.ticketForm.patchValue({ articles: this.articles, total: this.total });
  }

  setUserSelected($event: any) {
    this.ticketForm.patchValue({ user: this._user.getReference($event.key) });
  }

  setProjectSelected($event: any) {
    this.ticketForm.patchValue({ projects: this._project.getReference($event.key) });
    this.categories = $event.budgets;
  }

  changeTotalPriceArticle(articleQuantity, article) {
    const index = this.articles.findIndex((articleItem) => articleItem.name == article.name);
    const articleSelected = this.articleReferences.find((articleReference) => articleReference.name == article.name);
    articleQuantity = +articleQuantity.target.value;

    if (articleQuantity <= 0) {
      return AlertService.toastError('No ha seleccionado cantidad', '');
    }

    if (this.articleSelected.quantity < articleQuantity) {
      setTimeout(() => {
        this.articles[index].quantity = articleSelected.quantity;
        this.articles[index].total = articleSelected.price * articleSelected.quantity;
      }, 100);
      return AlertService.info(`La cantidad disponible para este artículo es: ${articleSelected.quantity}`, '');
    }

    this.articles[index].quantity = articleQuantity;
    this.articles[index].total = articleSelected.price * articleQuantity;
  }

  totalPriceArticle(quantity) {
    this.quantity = quantity;

    if (quantity.value < 0) {
      this.quantity = 0;
      this.ticketForm.patchValue({ quantity: 0 });
      return AlertService.toastError('No se permiten números negativos');
    }

    if (this.articleSelected) {
      this.addArticle(quantity);
      this.quantity = 0;
      this.priceArticle = 0;
      this.ticketForm.patchValue({ quantity: 0, article: '' });
    }
  }

  private mapArticles() {
    this.articleReferences = this.articleReferences.map(article => {
      return {
        ...article,
        searchLabel: article.name + article.internalId
      };
    });
  }

  setCategorytSelected(category) {
    this.ticketForm.patchValue({ projectCategory: category.reference });
  }

  setWarehouseSelected(warehouse) {
    this.warehouseSelected = warehouse;
  }

  toggleTransfer() {
    this.isTransfer = !this.isTransfer;
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.projectSubscription.unsubscribe();
    this.warehousesSubscription.unsubscribe();
  }

  public readonly AppConstants = AppConstants;
}
