export enum TabsPermission {
  SUMMARY = 'summary',
  ARTICLES = 'articles',
  ENTRY_TICKETS = 'entry-tickets',
  TICKETS = 'tickets',
  RETIRED_ARTICLES = 'retired-articles',
  LOGS = 'logs',
  REQUESTS = 'requests',
  MINIMUM_STOCK = 'minimum-stock',
  USER_TICKETS = 'user-tickets',
}
