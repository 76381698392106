import { Routes } from '@angular/router';
import { PrivateLayoutComponent } from '../layout/private-layout/private-layout.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { UsersListComponent } from './pages/users-list/users-list.component';
import { WarehouseDetailsComponent } from './pages/warehouses/components/warehouse-details/warehouse-details.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { ProvidersComponent } from './pages/providers/providers.component';
import { PurchaseOrdersComponent } from './pages/purchase-orders/purchase-orders.component';
import { IdSettingsComponent } from './pages/id-settings/id-settings.component';
import { BillsComponent } from './pages/bills/bills.component';
import { ProvidersArticlesComponent } from './pages/providers-articles/providers-articles.component';
import { UnitsComponent } from './pages/units/units.component';
import { ProofPaymentsComponent } from './pages/proof-payments/proof-payments.component';
import { WorkerSalariesComponent } from './pages/worker-salaries/worker-salaries.component';
import { TotalExpensesComponent } from './pages/total-expenses/total-expenses.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { WarehouseSummaryComponent } from './pages/warehouses/components/warehouse-details/warehouse-summary/warehouse-summary.component';
import { ArticlesComponent } from './pages/warehouses/components/warehouse-details/articles/articles.component';
import { TicketsComponent } from './pages/warehouses/components/warehouse-details/tickets/tickets.component';
import { ArticlesReturnedComponent } from './pages/warehouses/components/warehouse-details/articles-returned/articles-returned.component';
import { LogsComponent } from './pages/warehouses/components/warehouse-details/logs/logs.component';
import {
  TicketAuthorizationComponent
} from './pages/warehouses/components/warehouse-details/ticket-authorization/ticket-authorization.component';
import {
  TicketsArticleEntryComponent
} from './pages/warehouses/components/warehouse-details/tickets-article-entry/tickets-article-entry.component';
import { MinimumStockComponent } from './pages/warehouses/components/warehouse-details/minimum-stock/minimum-stock.component';
import { ProjectInvoiceReportsComponent } from './pages/project-invoice-reports/project-invoice-reports.component';
import { WarehousesModule } from './pages/warehouses/warehouses.module';
import { AdminAccountabilitiesComponent } from './pages/travel-advance/pages/admin-accountabilities/admin-accountabilities.component';
import { PurchaseOrderWrapperComponent } from './pages/purchase-orders/components/purchase-order-wrapper/purchase-order-wrapper.component';
import { EmptyComponent } from './pages/empty/empty.component';
import { PermissionGuard } from '../auth/permission-guard.service';

export const AdminRoutes: Routes = [
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'blog',
        component: EmptyComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'users',
        component: UsersListComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'warehouse',
        canActivate: [PermissionGuard],
        loadChildren: () => WarehousesModule
      },
      {
        path: 'warehouse/:tab',
        canActivate: [PermissionGuard],
        loadChildren: () => WarehousesModule
      },
      {
        path: 'warehouseDetails/:warehouseKey',
        component: WarehouseDetailsComponent,
        canActivate: [PermissionGuard],
        children: [
          {
            path: 'summary',
            component: WarehouseSummaryComponent
          },
          {
            path: 'articles',
            component: ArticlesComponent
          },
          {
            path: 'tickets',
            component: TicketsComponent
          },
          {
            path: 'tickets/:ticketKey',
            component: TicketsComponent
          },
          {
            path: 'tickets-article-entry/:ticketKey',
            component: TicketsArticleEntryComponent
          },
          {
            path: 'articles-returned',
            component: ArticlesReturnedComponent
          },
          {
            path: 'logs',
            component: LogsComponent
          },
          {
            path: 'requests',
            component: TicketAuthorizationComponent
          },
          {
            path: 'minimum-stock',
            component: MinimumStockComponent
          },
          {
            path: 'tickets-article-entry',
            component: TicketsArticleEntryComponent
          },
          {
            path: '**',
            redirectTo: 'summary'
          }
        ]
      },
      {
        path: 'warehouseDetails/:warehouseKey/:ticketKey',
        component: WarehouseDetailsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'user-details/:userKey',
        component: UserDetailsComponent
      },
      {
        path: 'projects',
        component: EmptyComponent,
        canActivate: [PermissionGuard],
        children: [
          {
            path: ':id',
            component: EmptyComponent
          }
        ]
      },
      {
        path: 'projectDetails/:projectKey',
        component: ProjectDetailsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'home/:userKey',
        component: UserDetailsComponent
      },
      {
        path: 'providers',
        component: ProvidersComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'purchase-orders',
        component: PurchaseOrdersComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'purchase-orders/new',
        component: PurchaseOrderWrapperComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'purchase-orders/modal/:purchaseOrderKey',
        component: PurchaseOrdersComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'purchase-orders/details/:purchaseOrderKey',
        component: PurchaseOrderWrapperComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'requisitions',
        component: EmptyComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'id-settings',
        component: IdSettingsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'bills',
        component: BillsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'providerArticles',
        component: EmptyComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'units',
        component: UnitsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'proofPayments',
        component: ProofPaymentsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'workerSalaries',
        component: WorkerSalariesComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'totalExpenses',
        component: TotalExpensesComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'project-invoice-reports',
        component: ProjectInvoiceReportsComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'changeLog',
        component: ChangeLogComponent
      },
      {
        path: 'boards-v2',
        component: EmptyComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'manage-accountabilities',
        component: AdminAccountabilitiesComponent
      },
      {
        path: 'vehicles',
        component: EmptyComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'deviations',
        component: EmptyComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'people-management',
        component: EmptyComponent,
        canActivate: [PermissionGuard],
        children: [
          {
            path: '',
            redirectTo: 'applicants',
            pathMatch: 'full'
          },
          {
            path: 'applicants'
          },
          {
            path: 'applicants/:status'
          },
          {
            path: 'applicant/:id'
          },
          {
            path: 'recruitment'
          },
          {
            path: 'recruitment/:status'
          },
          {
            path: 'recruitment-board'
          },
          {
            path: 'hired'
          },
          {
            path: 'settings'
          },
          {
            path: 'inductions-expired'
          },
          {
            path: 'exams-expired'
          },
          {
            path: 'inactive'
          },
          {
            path: 'dashboard'
          },
          {
            path: 'email-templates'
          },
          {
            path: 'email-templates/:type'
          },
          {
            path: 'schedules'
          },
          {
            path: 'filters'
          },
          {
            path: 'calendar'
          },
          {
            path: 'event-types'
          },
          {
            path: 'exam-types'
          },
          {
            path: 'skills'
          }
        ]
      },
      {
        path: 'sales-v2',
        component: EmptyComponent,
        canActivate: [PermissionGuard],
        children: [
          {
            path: ''
          },
          {
            path: 'inline-modal/sale/:id'
          },
          {
            path: 'list'
          },
          {
            path: 'calendar'
          },
          {
            path: 'contacts'
          },
          {
            path: 'clients'
          },
          {
            path: 'logs'
          },
          {
            path: 'config'
          }
        ]
      },
      {
        path: 'training',
        component: EmptyComponent,
        canActivate: [PermissionGuard]
      },
      {
        path: 'documents',
        component: EmptyComponent,
        canActivate: [PermissionGuard],
        children: [
          {
            path: ''
          },
          {
            path: 'doc/:id'
          }
        ]
      },
      {
        path: '**',
        redirectTo: 'blog'
      }
    ]
  }
];
