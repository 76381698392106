<block-ui>
  <ng-container *ngIf="!!_billData.bill">
    <div class="row p-2">
      <div [class]="detailsSize">
        <app-bill-general></app-bill-general>

        <mat-tab-group>
          <mat-tab label="Detalles">
            <app-bill-details></app-bill-details>
          </mat-tab>

          <mat-tab label="Clasificación">
            <app-bill-classification></app-bill-classification>
          </mat-tab>

          <mat-tab label="Pagos" *ngIf="_billData.bill.isUsed">
            <app-bill-payment></app-bill-payment>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div
        class="col-5"
        *ngIf="_billData.bill.billPdf || _billData.bill.proofOfPayment"
      >
        <mat-tab-group>
          <mat-tab
            label="PDF de la Factura"
            *ngIf="_billData.bill.billPdf"
          >
            <app-bill-pdf></app-bill-pdf>
          </mat-tab>

          <mat-tab
            label="Comprobante de pago"
            *ngIf="_billData.bill.proofOfPayment"
          >
            <app-bill-proof-of-payment></app-bill-proof-of-payment>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <div class="modal-footer border-0 button-position">
      <button
        class="btn btn-success add-btn"
        *ngIf="!isUpdate"
        (click)="submit()"
      >
        Guardar
      </button>
      <button
        type="button"
        class="btn btn-success add-btn"
        *ngIf="isUpdate && !_billData.updating"
        (click)="startUpdating()"
      >
        Editar
      </button>
      <div
        class="btn btn-info cancel-btn cursor-pointer"
        *ngIf="!!_billData.updating"
        (click)="submit()"
      >
        Aplicar
      </div>
      <div
        class="btn btn-danger cancel-btn cursor-pointer"
        *ngIf="!!_billData.updating"
        (click)="cancelUpdating()"
      >
        Cancelar
      </div>
      <div
        class="btn btn-danger cancel-btn cursor-pointer"
        *ngIf="!_billData.updating"
        (click)="modal.hide()"
      >
        Cerrar
      </div>
    </div>
  </ng-container>
</block-ui>
