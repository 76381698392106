<!-- main app container -->
<ngx-loading-bar [includeSpinner]='false' height='3px' color="#FF4961"></ngx-loading-bar>
<div style="display:flex; flex-direction: column; overflow: auto; height:calc(100vh - 5rem);">
  <h1
    *ngIf="
      !window.location.hostname.includes('plataforma.colsan.cl') &&
      !environment.production &&
      environment.firebase.projectId == 'colsan-prod'
    "
    class="position-sticky"
    style="color: blue; font-size: 20px; text-align: center;"
  >
  <span
    class="badge badge-success text-center w-100 "
    *ngIf="!environment.production && environment.firebase.projectId == 'colsan-prod'"
  >
    PRODUCTION
  </span>
  </h1>
  <div style="height: 100%">
    <router-outlet *ngIf="showContent"></router-outlet>
  </div>
</div>
<app-floating-menu></app-floating-menu>
