const app = {
  vehicles: '/admin/vehicles',
  fundToRender: '/admin/fund-to-render',
  peopleManagement: '/admin/people-management',
  blog: '/admin/blog',
  projects: '/admin/projects',
  salesV2: '/admin/sales-v2',
  boardsV2: '/admin/boards-v2'
};

const embedded = {
  vehicles: 'fleet',
  fundToRender: 'funds-to-render',
  peopleManagement: 'people-management',
  blog: 'blog',
  projects: 'projects',
  salesV2: 'sales',
  boardsV2: 'tasks'
};

export const Sections = {
  app,
  embedded
};
