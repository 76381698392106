import { Component, OnInit } from '@angular/core';
import { ExcelHeader } from '../../interfaces/excel-header';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-select-columns-to-print',
  templateUrl: './select-columns-to-print.component.html',
  styleUrls: ['./select-columns-to-print.component.css']
})
export class SelectColumnsToPrintComponent implements OnInit {
  headers: ExcelHeader[] = [];
  currentHeaders: Array<ExcelHeader & { selected?: boolean }> = [];
  willPrint: boolean = false;
  dataToPrint: any;

  constructor(private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.currentHeaders = this.headers.map(header => {
      return {
        ...header,
        selected: true
      };
    });
  }

  handleSelection(activeIndex: number) {
    this.currentHeaders = this.currentHeaders.map((header, index) => ({
      ...header,
      selected: activeIndex == index ? !header.selected : header.selected
    }));
  }

  async drop(event: any) {
    let newArray = [...this.currentHeaders];
    const prevItem = this.currentHeaders[event.previousIndex];
    newArray[event.previousIndex] = newArray[event.currentIndex];
    newArray[event.currentIndex] = prevItem;

    this.currentHeaders = newArray;
  }

  selectAll() {
    this.currentHeaders = this.currentHeaders.map(header => ({
      ...header,
      selected: true
    }));
  }

  unselectAll() {
    this.currentHeaders = this.currentHeaders.map(header => ({
      ...header,
      selected: false
    }));
  }

  submit() {
    if (this.currentHeaders.every(header => !header.selected)) {
      return AlertService.toastError('Debes seleccionar al menos una columna');
    }

    this.dataToPrint = this.currentHeaders
      .filter(header => !!header.selected)
      .map(header => ({
        name: header.name,
        att: header.att,
        type: header.type
      })) as ExcelHeader[];

    this.willPrint = true;
    this.modal.hide();
  }

  cancel() {
    this.modal.hide();
  }
}
