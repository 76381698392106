import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PurchaseOrderModalComponent } from './modals/purchase-order-modal/purchase-order-modal.component';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import { PurchaseStatusLabel } from '../../labels/purchase-status.label';
import { PurchaseStatus } from '../../enums/purchase-status.enum';
import { first, take } from 'rxjs/operators';
import { PurchaseOrder } from './interfaces/purchase-order';
import { Provider } from '../../interfaces/provider';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectService } from '../../../shared/template-services/object.service';
import _ from 'lodash';
import { AlertService } from '../../../shared/template-services/alert.service';
import { CostCenterType } from '../../enums/cost-center-type.enum';
import { UserService } from '../../../shared/services/user.service';
import { UserType } from '../../enums/user-type.enum';
import { PurchaseOrdersDataService } from './services/purchase-orders-data.service';
import { ProjectsDataService } from './services/projects-data.service';
import { ProvidersDataService } from './services/providers-data.service';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.css']
})
export class PurchaseOrdersComponent implements OnInit {
  @Input() projectKey: string;
  purchaseOrders$: Observable<any[]>;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron ordenes de compra',
    title: '',
    filtersFields: ['purchaseID', 'providerName', 'providerRut'],
    exportCallback: this.decoratedPurchaseOrdersToExport.bind(this),
    excelFileName: 'Ordenes de compra',
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 25
  };
  providers: Provider[];
  providersOptions: any[] = [];
  providerSelected: any;
  purchaseStatusLabel = PurchaseStatusLabel;
  purchaseStatus = PurchaseStatus;
  filter: any = { name: 999 };
  myRadio: any = 999;
  startDate = null;
  finalDate = null;
  selectArray = [];
  selectedProject: any = null;
  purchaseOrders: PurchaseOrder[] = [];
  filteredPurchases: PurchaseOrder[] = [];
  filterByDate: boolean = false;
  userPermission: UserType;
  userType = UserType;

  constructor(
    private purchaseData: PurchaseOrdersDataService,
    private _purchase: PurchaseOrderService,
    private modal: BsModalService,
    private providerData: ProvidersDataService,
    private activatedRoute: ActivatedRoute,
    private projectData: ProjectsDataService,
    private _user: UserService,
    private router: Router) {
  }

  ngOnInit(): void {
    const date = new Date(
      `${new Date().getMonth() + 1}/01/${new Date()
        .getFullYear()
        .toString()
        .slice(-2)}`
    );
    this.startDate = this.formatDate(date);
    this.finalDate = this.formatDate(moment(date).add(1, 'month').toDate());
    this.userPermission = this._user.getPermission('COMPRAS');

    this.getSubscriptions();
    this.showInitialPurchaseOrder();
  }

  async showInitialPurchaseOrder() {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params.purchaseOrderKey) {
        const currentPurchaseOrder = await this.purchaseData
          .getPurchaseOrder(params.purchaseOrderKey)
          .pipe(take(1))
          .toPromise();

        if (this.activatedRoute.snapshot) {
          this.openPurchaseOrder(currentPurchaseOrder);
        }
      }
    });
  }

  openPurchaseOrder(purchaseOrder: PurchaseOrder) {
    const modalRef = this.modal.show(PurchaseOrderModalComponent, {
      initialState: {
        purchaseOrder: { ...purchaseOrder }
      },
      class: 'modal-xxl90',
      backdrop: 'static',
      id: 31
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      const newUrl = window.location.href.replace(`/modal/${purchaseOrder.key}`, '');
      window.history.pushState({}, '', newUrl);
    });
  }

  getSubscriptions() {
    combineLatest([
      this.projectData.data$,
      this.providerData.data$,
      this.purchaseData.data$])
      .subscribe(([projects, providers, purchases]) => {
        this.loadProjects(projects);
        this.loadProviders(providers);
        this.getPurchaseOrders(purchases);
      });
  }

  private loadProviders(providers) {
    this.providers = providers;
    this.providersOptions = providers.map((provider) => ({
      value: provider.name,
      label: provider.name
    }));
  }

  private loadProjects(projects) {
    projects.map((project) => ({
      ...project,
      typeName:
        project.type == CostCenterType.PROJECT
          ? 'Proyectos'
          : 'Centro de Costos'
    }));

    this.selectArray = projects.map((project) => ({
      ...project,
      type: project.type === CostCenterType.PROJECT ? 'Proyectos' : 'Centro de Costos'
    }));
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  getPurchaseOrders(purchaseOrders: any[]) {
    this.purchaseOrders = purchaseOrders.map((purchase) => {
      const provider = this.getProvider(purchase.provider);
      return {
        ...purchase,
        providerName: !!provider.name ? provider.name : '',
        providerRut: !!provider.rut ? provider.rut : ''
      };
    });

    if (this.projectKey) {
      this.purchaseOrders = purchaseOrders.filter(purchase => purchase.project.id == this.projectKey);
    }

    this.filteredPurchases = this.purchaseOrders;
    this.changePurchaseStatus();
  }

  gotoOpenEditPurchaseOrder(purchaseOrder, fullScreen = false) {
    if (fullScreen) {
      return this.router.navigate(['/admin/purchase-orders/details/' + purchaseOrder.key]);
    }

    this.router.navigate([`/admin/purchase-orders/modal/${purchaseOrder.key}`]);
  }

  openAddPurchaseOrderModal() {
    this.router.navigate(['/admin/purchase-orders/new']);
  }

  getProvider(providerReference: any): any {
    let providerIndex = this.providers.findIndex(
      (provider) => provider.key == providerReference.id
    );
    if (providerIndex == -1) {
      return '';
    }
    return this.providers[providerIndex];
  }

  getStatusClass(status: number) {
    switch (status) {
      case PurchaseStatus.CANCELLED:
        return 'cancelled-status-color';

      case PurchaseStatus.DRAFT:
        return 'draft-status-color';

      case PurchaseStatus.GENERATED:
        return 'generated-status-color';

      case PurchaseStatus.AUTHORIZED:
        return 'authorized-status-color';

      case PurchaseStatus.NOT_AUTHORIZED:
        return 'not-authorized-status-color';

      case PurchaseStatus.FINALIZED:
        return 'finalized-status-color';

      case PurchaseStatus.WAITING_FOR_REVIEW:
        return 'waiting-for-review-status-color';

      case PurchaseStatus.UNDER_REVIEW:
        return 'under-review-status-color';

      case PurchaseStatus.UNDER_APPROVAL:
        return 'under-approved-status-color';

      default:
        return 'finalized-status-color';
    }
  }

  changePurchaseStatus() {
    switch (this.myRadio) {
      case PurchaseStatus.DRAFT:
        this.filter = { name: 'BORRADORES', status: PurchaseStatus.DRAFT };
        break;

      case PurchaseStatus.WAITING_FOR_REVIEW:
        this.filter = { name: 'EN ESPERA DE REVISIÓN', status: PurchaseStatus.WAITING_FOR_REVIEW };
        break;

      case PurchaseStatus.UNDER_REVIEW:
        this.filter = { name: 'EN REVISIÓN', status: PurchaseStatus.UNDER_REVIEW };
        break;

      case PurchaseStatus.GENERATED:
        this.filter = { name: 'GENERADOS', status: PurchaseStatus.GENERATED };
        break;

      case PurchaseStatus.UNDER_APPROVAL:
        this.filter = { name: 'EN AUTORIZACIÓN', status: PurchaseStatus.UNDER_APPROVAL };
        break;

      case PurchaseStatus.AUTHORIZED:
        this.filter = { name: 'AUTORIZADOS', status: PurchaseStatus.AUTHORIZED };
        break;

      case PurchaseStatus.CANCELLED:
        this.filter = { name: 'CANCELADOS', status: PurchaseStatus.CANCELLED };
        break;

      case PurchaseStatus.NOT_AUTHORIZED:
        this.filter = { name: 'REGRESADOS', status: PurchaseStatus.NOT_AUTHORIZED };
        break;

      case PurchaseStatus.FINALIZED:
        this.filter = { name: 'FINALIZADO', status: PurchaseStatus.FINALIZED };
        break;

      case PurchaseStatus.SHIPPED:
        this.filter = { name: 'EN PROCESO DE ENVÍO' };
        break;

      case 'Unbilled':
        this.filter = { name: 'NO FACTURADO' };
        break;

      default:
        this.filter = { name: 'TODOS' };
        break;
    }

    this.filteredPurchases = this.filterPurchases();
  }

  resetBills() {
    this.filterByDate = false;
    this.filter = { name: 'TODOS' };
    this.selectedProject = null;
    return this.filteredPurchases = this.purchaseOrders;
  }

  handleFilterChange() {
    this.filteredPurchases = this.filterPurchases();
  }

  handleDateFilter() {
    this.filterByDate = true;
    this.filteredPurchases = this.filterPurchases();
  }

  filterPurchases() {
    let filteredPurchases = this.purchaseOrders;

    if (this.filterByDate) {
      filteredPurchases = filteredPurchases.filter(
        (purchase) =>
          purchase.deliveryDate >= moment(this.startDate).valueOf() &&
          purchase.deliveryDate <= moment(this.finalDate).valueOf()
      );
    }

    if (this.selectedProject) {
      filteredPurchases = filteredPurchases.filter(purchase => purchase.project.id === this.selectedProject.key);
    }

    if (this.providerSelected) {
      filteredPurchases = filteredPurchases.filter(purchase => purchase.providerName === this.providerSelected.value);
    }

    if (this.filter.name !== 'TODOS') {
      if (this.filter.name === 'EN PROCESO DE ENVÍO') {
        filteredPurchases = filteredPurchases
          .filter((purchase) => !purchase.received)
          .filter(
            (purchase) =>
              purchase.status === PurchaseStatus.AUTHORIZED ||
              purchase.status === PurchaseStatus.NOT_AUTHORIZED
          );
      } else if (this.filter.name === 'NO FACTURADO') {
        filteredPurchases = filteredPurchases
          .filter((purchase) => !purchase.invoiced)
          .filter(
            (purchase) =>
              purchase.status === PurchaseStatus.AUTHORIZED ||
              purchase.status === PurchaseStatus.NOT_AUTHORIZED
          );
      } else {
        filteredPurchases = filteredPurchases.filter(purchase => purchase.status === this.filter.status);
      }
    }

    return _.uniqBy(filteredPurchases, 'key');
  }

  decoratedPurchaseOrdersToExport(rows: any[]) {
    return ObjectService.replaceUndefined(rows.map((row) => {
      return {
        status: this.purchaseStatusLabel[row.status],
        purchaseID: row.purchaseID.slice(0, -3),
        providerName: row.providerName,
        providerRut: row.providerRut,
        description: row.description,
        total: row.total,
        received: row.received ? 'Entregado' : 'No entregado',
        invoiced: row.invoiced ? 'Facturado' : 'No facturado'
      };
    }));
  }

  async deletePurchase(row: any) {
    if (await AlertService.confirm('¿Está seguro de eliminar esta orden de compra?')) {
      await this._purchase.update(row.key, { trash: true } as any);
      AlertService.toastSuccess('Orden de compra eliminada');
    }
  }
}
