import { Component, OnInit } from '@angular/core';
import { User as UserInterface } from '../../../../../shared/interfaces/user';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Warehouse } from '../../interfaces/warehouse';
import { Article } from '../../interfaces/article';
import { WarehouseService } from '../../../../../shared/services/warehouse.service';
import { UserService } from '../../../../../shared/services/user.service';
import { ArticleService } from '../../../../../shared/services/article.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../../../../shared/services/project.service';
import { NotificationService } from '../../../../services/notification.service';
import { LogsService } from '../../../../services/logs.service';
import { TicketStatus } from '../../enums/ticket-status.enum';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { Ticket } from '../../interfaces/ticket';
import { TicketEntryService } from '../../services/ticket-entry.service';
import { SerialsNumbersComponent } from '../serials-numbers/serials-numbers.component';
import { WarehouseDetailService } from '../../services/warehouse-detail.service';
import { ArticleType } from '../../enums/article-type.enum';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppConstants } from '../../../../../shared/helpers/app.constants';

@Component({
  selector: 'app-add-ticket-entry-articles',
  templateUrl: './add-ticket-entry-articles.component.html',
  styleUrls: ['./add-ticket-entry-articles.component.css']
})
export class AddTicketEntryArticlesComponent implements OnInit {
  user: any;
  users: UserInterface[];
  userSubscription: Subscription;
  ticketForm: FormGroup;
  articleForm: FormGroup;
  submitted = false;
  warehouse: Warehouse;
  articleReferences: Article[] = [];
  warehouseKey: string;
  articles: any = [];
  currentDate = new Date().getTime();
  articleSelected: Article;
  total: number = 0;
  selectArray: any = [];
  priceArticle: number = 0;
  totalArticle: number = 0;
  quantity: number = 0;
  userSelected: UserInterface;
  ticket: any;
  categories: any = [];
  articleTypeEnum = ArticleType;

  constructor(
    private formBuilder: FormBuilder,
    private _ticket: TicketEntryService,
    private _warehouse: WarehouseService,
    private _user: UserService,
    private _article: ArticleService,
    public modal: BsModalService,
    private _project: ProjectService,
    private _notification: NotificationService,
    private _warehouseDetail: WarehouseDetailService,
    private _log: LogsService
  ) {
    this.articleForm = formBuilder.group({
      article: ['', Validators.required],
      quantity: ['0', [Validators.required, Validators.min(1)]],
      reason: ['']
    });
    this.ticketForm = formBuilder.group({
      createdDate: [new Date().getTime()],
      endDate: [''],
      status: [TicketStatus.DRAFT],
      isAuthorized: [false],
      supervisor: [null],
      user: ['', Validators.required],
      warehouse: ['', Validators.required],
      total: [0],
      trash: [false],
      createdBy: ['']
    });
  }

  async ngOnInit(): Promise<void> {
    this.setWarehouseReference();
    this.getUsers();
    this.mapArticles();
  }

  setWarehouseReference() {
    this.ticketForm.patchValue({
      warehouse: this._warehouse.getReference(this.warehouseKey)
    });
  }

  private getUsers() {
    this.userSubscription = this._user.getUsers().subscribe((data) => {
      this.users = data;
    });
  }

  get formControls() {
    return this.ticketForm.controls;
  }

  get articleFormControls() {
    return this.articleForm.controls;
  }

  async submit() {
    if (this.articles?.length == 0) {
      AlertService.toastError('Debe agregar al menos un artículo');
      return;
    }

    this.submitted = true;

    if (!this.ticketForm.valid) {
      return AlertService.toastError(
        'Verifique que haya llenado todos los campos'
      );
    }

    this.ticketForm.patchValue({
      createdBy: await this._user.getReference(this._user.user.key)
    });

    const numberTicket = (await this._ticket.getId()) + 1;
    this._ticket.updateTicketID(numberTicket);
    let milliseconds = new Date().getMilliseconds().toString();
    let millisecondsString = '000' + milliseconds;

    const ticketMilliseconds = millisecondsString.slice(
      millisecondsString.length - 3,
      millisecondsString.length
    );

    const ticketId = `${numberTicket}${ticketMilliseconds}`;


    let ticket: any = await this._ticket.add(
      {
        ...this.ticketForm.value,
        articles: this.articles,
        total: this.total,
        quantity: this.quantity
      },
      ticketId
    );

    this._log.add(this.warehouseKey, {
      description: `Se generó el borrador del ticket ${ticketId.slice(0, -3)}`
    });

    this.ticket = {
      ...this.ticketForm.value,
      key: ticket.id,
      ticketID: ticketId
    };

    AlertService.toastSuccess('Ticket de entrada generado con éxito');
    this.modal.hide();
  }

  setArticleSelected($event: any) {
    this.articleSelected = $event;
    this.priceArticle = this.articleSelected.price;
    if (this.quantity) this.totalPriceArticle(this.quantity);
  }

  handleAddArticle() {
    if (!this.articleForm.valid) {
      this.articleForm.markAllAsTouched();
      return;
    }

    if (this.articleSelected && this.quantity) {
      this.addArticle(this.quantity);
      this.quantity = 0;
      this.priceArticle = 0;
      this.totalArticle = 0;
    }
  }

  addArticle(quantity) {
    const articleQuantity: number = quantity;
    const index = this.articles.findIndex(
      (article) => article.name == this.articleSelected.name
    );

    if (index >= 0) {
      return AlertService.toastError('Este artículo ya está en la lista', '');
    }
    if (articleQuantity <= 0) {
      return AlertService.toastError('No ha seleccionado cantidad', '');
    }

    this.articles.push({
      name: this.articleSelected.name,
      internalId: this.articleSelected.internalId,
      type: this.articleSelected.type,
      location: !!this.articleSelected.location
        ? this.articleSelected.location
        : '',
      quantity: articleQuantity,
      price: this.articleSelected.price,
      total: this.articleSelected.price * articleQuantity,
      key: this.articleSelected.key,
      isComplete: false,
      lost: false,
      message: '',
      imageUrl: !!this.articleSelected.imageUrl
        ? this.articleSelected.imageUrl
        : null,
      returned: 0,
      quantityOrdered: articleQuantity,
      reason: this.articleFormControls.reason.value
    });

    this.total += this.articleSelected.price * articleQuantity;
    this.articleForm.reset({
      quantity: 0,
      article: '',
      reason: ''
    });
  }

  dropArticle(index: number) {
    this.total -= this.articles[index].price * this.articles[index].quantity;
    this.articles.splice(index, 1);
    this.ticketForm.patchValue({ articles: this.articles, total: this.total });
  }

  setUserSelected($event: any) {
    this.ticketForm.patchValue({ user: this._user.getReference($event.key) });
  }

  totalPriceArticle(quantity) {
    quantity = +quantity;
    if (+quantity <= 0) return;
    this.quantity = quantity;

    if (quantity.value < 0) {
      this.quantity = 0;
      return AlertService.toastError('No se permiten números negativos');
    }

    if (this.articleSelected) this.totalArticle = this.articleSelected.price * quantity;
  }


  private mapArticles() {
    this.articleReferences = this.articleReferences.map((article) => {
      return {
        ...article,
        searchLabel: article.name + article.internalId
      };
    });
  }

  showSerialsNumbersArticles(article: any) {
    this.modal.show(SerialsNumbersComponent, {
      initialState: {
        article
      }
    });
  }

  protected readonly event = event;
  public readonly AppConstants = AppConstants;
}
