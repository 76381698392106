import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { Bill } from '../../admin/interfaces/bill';
import { PurchaseOrderService } from '../../shared/services/purchase-order.service';
import { ProjectService } from '../../shared/services/project.service';
import { PopulateRowServiceService } from '../services/populate-row-service.service';

@Pipe({
  name: 'showBillCostCenter'
})
export class ShowBillCostCenterPipe implements PipeTransform {
  constructor(private populateRowService: PopulateRowServiceService) { }

  async transform(bill: any): Promise<string> {
    return await this.populateRowService.billCostCenter(bill);
  }

}
