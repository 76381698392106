import {TabsPermission} from '../enums/tabs-permission.enum';
import {Permission} from '../../../enums/permissions.enum';

export const all = () => [
  [TabsPermission.SUMMARY],
  [TabsPermission.ARTICLES],
  [TabsPermission.ENTRY_TICKETS],
  [TabsPermission.TICKETS],
  [TabsPermission.USER_TICKETS],
  [TabsPermission.RETIRED_ARTICLES],
  [TabsPermission.LOGS],
  [TabsPermission.REQUESTS],
  [TabsPermission.MINIMUM_STOCK]
];

export const TABS_PERMISSIONS = {
  [Permission.USER]: [
    [TabsPermission.ARTICLES],
    [TabsPermission.TICKETS],
  ],
  [Permission.BUYER]: [
    [TabsPermission.SUMMARY],
    [TabsPermission.ARTICLES],
    [TabsPermission.ENTRY_TICKETS],
    [TabsPermission.MINIMUM_STOCK],
  ],
  [Permission.GROCER]: [
    [TabsPermission.SUMMARY],
    [TabsPermission.ARTICLES],
    [TabsPermission.TICKETS],
    [TabsPermission.ENTRY_TICKETS],
    [TabsPermission.RETIRED_ARTICLES],
  ],
  [Permission.SUPERVISOR]: all(),
  [Permission.ADMIN]: all()
};
