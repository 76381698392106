<div class="p-3">
  <form [formGroup]="applicantForm">
    <div>
      <div class="row col-12 justify-content-center mb-1">
        <p class="font-weight-bold ">Información personal</p>
      </div>
      <div class="d-flex justify-content-start align-items-center row">
        <div class="form-group col-12 col-md-4 col-lg-3 offset-lg-1">
          <ti-input
            label="Nombre *"
            mode="material"
            [control]="formControls.name"
          ></ti-input>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-3">
          <ti-input
            label="Apellidos *"
            mode="material"
            [control]="formControls.surnames"
          ></ti-input>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-2">
          <ti-input
            label="R.U.T *"
            mode="material"
            mask="00.000.000-A||0.000.000-A"
            placeholder="12.345.678-9"
            [control]="formControls.rut"
          ></ti-input>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-2">
          <ti-input
            label="Fecha de nacimiento *"
            mode="material"
            type="date"
            [control]="formControls.birthday"
            [max]="maxDateControl"
          ></ti-input>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-3 offset-lg-1">
          <ti-input
            label="Email *"
            mode="material"
            type="email"
            [control]="formControls.email"
          ></ti-input>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-3">
          <ti-select
            [control]="formControls.job"
            label="Cargo *"
            bindLabel="name"
            [options]="selectArrayJobs"
          ></ti-select>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-2">
          <ti-select
            label="Nacionalidad *"
            [control]="formControls.nationality"
            [options]="countries"
          ></ti-select>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-2">
          <ti-input
            label="Teléfono *"
            mode="material"
            mask="00000000"
            prefix="+569 "
            [control]="formControls.phone"
          ></ti-input>
        </div>

        <div class="form-group col-12 col-md-4 col-lg-2 offset-lg-1">
          <ti-select
            [control]="formControls.maritalStatus"
            label="Estado civil *"
            [options]="maritalStatusOptions"
          ></ti-select>
        </div>

        <div class="form-group col-12 col-lg-6">
          <ti-select
            [control]="formControls.skills"
            label="Habiliades *"
            [options]="skillsOptions"
            [multiple]="true"
            bindLabel="name"
            bindValue="reference"
          ></ti-select>
        </div>
      </div>

      <div>
        <div class="row col-12 justify-content-center mb-1 mt-2">
          <p class="font-weight-bold ">Información para la empresa</p>
        </div>

        <div class="d-flex justify-content-start align-items-center row">
          <div class="form-group col-12 col-md-4 col-lg-3 offset-lg-1">
            <ti-select
              label="Región *"
              bindLabel="name"
              [control]="formControls.region"
              [options]="states"
            ></ti-select>
          </div>

          <div class="form-group col-12 col-md-4 col-lg-3">
            <ti-select
              label="Comuna *"
              bindLabel="name"
              [control]="formControls.city"
              [options]="cities"
            ></ti-select>
          </div>

          <div class="form-group col-12 col-md-4 col-lg-2">
            <ti-select
              label="Nivel Educacional *"
              [control]="formControls.educationLevel"
              [options]="educationLevelArray"
            ></ti-select>
          </div>

          <div class="form-group col-12 col-md-4 col-lg-2">
            <ti-input
              label="Experiencia laboral(años) *"
              mode="material"
              type="number"
              [control]="formControls.workExperience"
            ></ti-input>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-12 col-md-4 col-lg-3 offset-lg-1">
            <ti-input
              label="Pretensiones de renta *"
              mode="material"
              prefix="$"
              mask="separator"
              [control]="formControls.expectation"
            ></ti-input>
          </div>

          <div class="form-group col-12 col-md-5">
            <ti-input
              label="Cuéntanos sobre tu experiencia (mínimo 40 caracteres) *"
              [multiline]="true"
              rows="4"
              mode="material"
              [control]="formControls.experience"
            ></ti-input>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
