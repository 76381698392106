<div>
  <div class="p-2">
    <div class="d-flex align-items-end">
      <div class="d-flex flex-column">
        <span><strong>Filtrar por rango de fechas</strong></span>
        <div class="d-flex">
          <div class="d-flex flex-column col-md-6">
            <label>Desde:</label>
            <input
              class="form-control date-input"
              type="date"
              [(ngModel)]="dateFilter.from"
            />
          </div>
          <div class="d-flex flex-column col-md-6">
            <label>Hasta:</label>
            <input
              class="form-control date-input"
              type="date"
              [(ngModel)]="dateFilter.to"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <span><strong>Filtrar por rango de precio</strong></span>
        <div class="d-flex">
          <div class="d-flex flex-column col-6">
            <label>Mínimo:</label>
            <input
              class="form-control date-input"
              type="number"
              [(ngModel)]="priceFilter.from"
            />
          </div>
          <div class="d-flex flex-column col-6">
            <label>Máximo:</label>
            <input
              class="form-control date-input"
              type="number"
              [(ngModel)]="priceFilter.to"
            />
          </div>
        </div>
      </div>
      <button (click)="filterArticles()" class="btn btn-success">Filtrar</button>
    </div>
    <div class="d-flex mt-1">
      <div class="col-md-4">
        <ng-select
          [items]="providers"
          bindLabel="name"
          bindValue="id"
          placeholder="Filtrar por proveedor"
          [(ngModel)]="providerFilter"
          (change)="filterArticles()"
        ></ng-select>
      </div>

      <div class="col-md-4">
        <ng-select
          [items]="units"
          bindLabel="name"
          bindValue="id"
          placeholder="Filtrar por unidad"
          [(ngModel)]="unitFilter"
          (change)="filterArticles()"
        ></ng-select>
      </div>
    </div>
  </div>

  <app-datatable-pagination
    [rows]="filteredArticles"
    [config]="config"
    (rowSelected)="handleSelectArticle($event)"
  >
    <app-datatable-column title="Código">
      <ng-template let-row #column>
        {{ row?.code }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Nombre">
      <ng-template let-row #column>
        {{ row?.name }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Proveedor">
      <ng-template let-row #column>
        {{ (row?.provider | doc | async)?.name }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Unidad">
      <ng-template let-row #column>
        {{ (row?.unitType | doc | async)?.name }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Precio unitario">
      <ng-template let-row #column>
        {{ row?.unitPrice | currencyFormat }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Descuento aplicado">
      <ng-template let-row #column>
        {{ row?.discount | currencyFormat }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha">
      <ng-template let-row #column>
        {{ row?.createdAt | date: 'dd/MM/yyyy' }}
      </ng-template>
    </app-datatable-column>
  </app-datatable-pagination>
</div>
