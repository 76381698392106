import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Provider } from '../../../interfaces/provider';
import { ProviderService } from '../../../../shared/services/provider.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersDataService {
  private dataSubject = new BehaviorSubject<Provider[]>([]);
  public data$: Observable<Provider[]> = this.dataSubject.asObservable();

  constructor(private providerService: ProviderService) {
    this.initData();
  }

  private initData(): void {
    this.providerService.getAll().subscribe(data => {
      this.dataSubject.next(data);
    });
  }
}
