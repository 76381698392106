<div *ngIf="!selectedUser; else userDetails">
  <app-user-list
    [ticketsByUser]="ticketsByUser"
    [entryTicketsByUser]="entryTicketsByUser"
    [tickets]="tickets"
    [entryTickets]="entryTickets"
    [users]="users"
    (setSelectedUser)="handleUserSelected($event)"
  ></app-user-list>
</div>
<ng-template #userDetails>
  <div>
    <app-user-tickets-details
      [tickets]="userTickets"
      [entryTickets]="userEntryTickets"
      [warehouses]="warehouses"
      [user]="selectedUser"
      (resetSelectedUser)="resetSelectedUser($event)"
    >
    </app-user-tickets-details>
  </div>
</ng-template>
