<div>
  <div class="d-flex flex-column text-center my-2">
    <h4>Columnas a imprimir</h4>
    <small class="text-light">* Arrastre el nombre de las columnas para cambiar su orden</small>
    <small class="text-light">* Haga clic sobre el indicador para incluirla o retirarla</small>
  </div>
  <div class="buttons-wrapper">
    <button class="btn btn-outline-success btn-sm" (click)="selectAll()">Seleccionar todas</button>
    <button class="btn btn-outline-dark btn-sm" (click)="unselectAll()">Deseleccionar todas</button>
  </div>
  <div class="">
    <div
      class="items-container"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      <div
        cdkDrag
        class="item-container d-flex align-items-center"
        *ngFor="let header of currentHeaders;let i = index"
        [ngClass]="{'active': header.selected}"
        [cdkDragPreviewClass]="'preview-hidden'"
      >
        <span
          class="active-indicator"
          [ngClass]="{'active': header.selected}"
          (click)="handleSelection(i)"
        ></span>
        {{ header.name }}
        <div cdkDragPreview></div>
      </div>
    </div>
    <div class="d-flex justify-content-center my-2">
      <button class="btn btn-danger mr-2" (click)="cancel()">Cancelar</button>
      <button class="btn btn-info" (click)="submit()">Imprimir</button>
    </div>
  </div>
</div>
