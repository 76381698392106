export enum TicketStatus {
  EXPIRED = -2,
  CANCELLED = -1,
  DRAFT,
  GENERATED,
  AUTHORIZED,
  COLLECTED,
  INCOMPLETE,
  RETURNED
}
