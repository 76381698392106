import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbCarouselConfig, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { SettingsModule } from './layout/settings/settings.module';
import { ThemeSettingsConfig } from './layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './layout/settings/menu-settings.config';
import { HeaderComponent } from './layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './layout/header/full-layout-navbar/full-layout-navbar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './layout/navigation/navigation.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './layout/private-layout/private-layout.component';
import { EmbeddedLayoutComponent } from './layout/embedded-layout/embedded-layout.component';
import { VerticalnavComponent } from './layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './layout/navigation/horizontalnav/horizontalnav.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { CustomizerComponent } from './layout/customizer/customizer.component';
import { ChartsModule } from 'ng2-charts';
import { BreadcrumbModule } from './layout/breadcrumb/breadcrumb.module';
import { HorizontalCustomizerComponent } from './layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { BlockTemplateComponent } from './layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule as intechModule } from '@tintech/shared';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxViewerModule } from 'ngx-viewer';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ExternalLayoutComponent } from './layout/external-layout/external-layout.component';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule,
    RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    LoadingBarRouterModule,
    BlockUIModule.forRoot(),
    SharedModule,
    ModalModule.forRoot(),
    MatFormFieldModule,
    MatSelectModule,
    BsDatepickerModule.forRoot(),
    intechModule,
    ProgressbarModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxViewerModule,
    MatMomentDateModule
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    EmbeddedLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    FooterComponent,
    AppNavigationComponent,
    VerticalnavComponent,
    HorizontalnavComponent,
    CustomizerComponent,
    HorizontalCustomizerComponent,
    BlockTemplateComponent,
    FullLayoutComponent,
    ExternalLayoutComponent
,
    EmbeddedLayoutComponent  ],
  providers: [NgbCarouselConfig, NgbModalConfig, { provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule {}
