import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './template-components/not-found/not-found.component';
import { BlockUIModule } from 'ng-block-ui';
import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DocPipe } from './pipes/doc.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { FastSearchComponent } from './template-components/fast-search/fast-search.component';
import { ConfirmationDialogService } from './template-services/confirmation-dialog.service';
import { NavbarService } from './template-services/navbar.service';
import { AddFloatButtonComponent } from './components/add-float-button/add-float-button.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { FilterPipe } from './pipes/filter.pipe';
import { DataTableColumnComponent } from './components/data-table-column/data-table-column.component';
import { SafePipe } from './pipes/safe.pipe';
import { SearchItemsModalComponent } from './template-components/search-items/search-items-modal.component';
import { UploadFileStorageComponent } from './template-components/upload-file-storage/upload-file-storage.component';
import { JsonPreviewComponent } from './modals/json-preview/json-preview.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DotsCurrencyPipe } from './pipes/dots-currency.pipe';
import { ReportButtonComponent } from './components/report-problem/report-button.component';
import { SortPipe } from './pipes/sort.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CardModule } from './template-components/partials/general/card/card.module';
import { PartialsModule } from './template-components/partials/partials.module';
import { TiInputComponent } from './components/ti-input/ti-input.component';
import { AjScreenRecoderModule } from 'aj-screen-recoder';
import { AjScreenRercoderComponent } from './components/report-problem/lib/aj-screen-recoder.component';
import { SwapCommasAndDotsPipe } from '../admin/pipes/swap-commas-and-dots.pipe';
import { ClearCurrencyPipe } from '../admin/pipes/clear-currency.pipe';
import { TextLinkComponent } from './components/text-link/text-link.component';
import { MatInputModule } from '@angular/material/input';
import { TiSelectComponent } from './components/ti-select/ti-select.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { TiFileComponent } from './components/ti-file/ti-file.component';
import { TiUrlViewerComponent } from './components/ti-url-viewer/ti-url-viewer.component';
import { NgxViewerModule } from 'ngx-viewer';
import { DataTablePaginationComponent } from './components/data-table-pagination/data-table-pagination.component';
import { PaginationPipe } from './pipes/pagination.pipe';
import { PagesComponent } from './components/data-table-pagination/components/pages/pages.component';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { FloatingMenuComponent } from './components/floating-menu/floating-menu.component';
import { AddShortcutComponent } from './components/add-shortcut/add-shortcut.component';
import { IconGroupComponent } from './components/add-shortcut/components/icon-group/icon-group.component';
import { ShortcutsConfigComponent } from './components/shortcuts-config/shortcuts-config.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ShowBillCostCenterPipe } from './pipes/show-bill-cost-center.pipe';
import { GetFullNamePipe } from './pipes/get-full-name.pipe';

@NgModule({
  declarations: [
    NotFoundComponent,
    DocPipe,
    SortPipe,
    FastSearchComponent,
    AddFloatButtonComponent,
    DataTableComponent,
    FilterPipe,
    DataTableColumnComponent,
    SafePipe,
    UploadFileStorageComponent,
    SearchItemsModalComponent,
    JsonPreviewComponent,
    DotsCurrencyPipe,
    ReportButtonComponent,
    TiInputComponent,
    AjScreenRercoderComponent,
    SwapCommasAndDotsPipe,
    ClearCurrencyPipe,
    TextLinkComponent,
    TiSelectComponent,
    TiFileComponent,
    TiUrlViewerComponent,
    DataTablePaginationComponent,
    PaginationPipe,
    PagesComponent,
    CurrencyFormatPipe,
    FloatingMenuComponent,
    AddShortcutComponent,
    IconGroupComponent,
    ShortcutsConfigComponent,
    ShowBillCostCenterPipe,
    GetFullNamePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    BlockUIModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
    ScrollingModule,
    MatTabsModule,
    NgxSpinnerModule,
    CardModule,
    PartialsModule,
    AjScreenRecoderModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    NgxMaskModule,
    NgxViewerModule,
    DragDropModule
  ],
  exports: [
    // COMPONENTS
    NotFoundComponent,
    FastSearchComponent,
    SearchItemsModalComponent,
    AddFloatButtonComponent,
    DataTableComponent,
    DataTableColumnComponent,
    UploadFileStorageComponent,
    ReportButtonComponent,
    DataTablePaginationComponent,

    // PIPES
    DocPipe,
    FilterPipe,
    SortPipe,
    SafePipe,
    SwapCommasAndDotsPipe,
    ClearCurrencyPipe,
    PaginationPipe,
    CurrencyFormatPipe,
    ShowBillCostCenterPipe,

    // MODULES
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgbModule,
    NgSelectModule,
    MatTabsModule,
    NgxSpinnerModule,
    CardModule,
    PartialsModule,
    DotsCurrencyPipe,
    TiInputComponent,
    TextLinkComponent,
    TextLinkComponent,
    TiSelectComponent,
    TiFileComponent,
    FloatingMenuComponent,
    GetFullNamePipe
  ],
  providers: [
    ConfirmationDialogService,
    NavbarService,
    DatePipe,
    SwapCommasAndDotsPipe,
    ClearCurrencyPipe
  ]
})

export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}
