import { Component, Input, OnInit } from '@angular/core';
import { Bill } from '../../interfaces/bill';
import { PaymentCategory } from '../../interfaces/payment-category';
import { ProofOfPayment } from '../../interfaces/proof-of-payment';
import { UserType } from '../../enums/user-type.enum';
import { BillDataService } from './services/bill-data.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';
import { BillService } from '../../../shared/services/bill.service';
import { PurchaseOrder } from '../../pages/purchase-orders/interfaces/purchase-order';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import moment from 'moment';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ProjectService } from '../../../shared/services/project.service';
import { take } from 'rxjs/operators';
import { CostCenterType } from '../../enums/cost-center-type.enum';

@Component({
  selector: 'app-bill-modal-v2',
  templateUrl: './bill-modal-v2.component.html',
  styleUrls: ['./bill-modal-v2.component.scss'],
  providers: [BillDataService]
})
export class BillModalV2Component implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  bill: Bill;
  bills: Bill[] = [];
  providers: any;
  @Input() isUpdate: boolean = false;
  @Input() permission: UserType;
  dataSubscription$: Subscription;

  constructor(public _billData: BillDataService,
    public modal: BsModalRef,
    private _project: ProjectService,
    private _bill: BillService,
    private _purchase: PurchaseOrderService) {
  }

  async ngOnInit(): Promise<void> {
    this._billData.bill = this.bill = await this.populateBill(this.bill);
  }

  async populateBill(bill: Bill) {
    let currentBill = await this._bill.populateObject(bill, ['purchaseOrder', 'project', 'projectCategory']) as Bill;
    const purchaseOrder = currentBill.purchaseOrder ? await this._bill.populateObject(currentBill.purchaseOrder, ['budget', 'project']) : null;
    return {
      ...currentBill,
      purchaseOrder
    };
  }

  get detailsSize() {
    return this._billData.bill.billPdf || this._billData.bill.proofOfPayment ? 'col-7' : 'col-12';
  }

  async submit() {
    this.blockUI.start('Guardando...');

    if (this._billData.billToEdit.project) {
      this._billData.billToEdit.project = this._bill.db.getReference(`projects/${this._billData.billToEdit.project.key}`);
    }

    if (this._billData.billToEdit.projectCategory) {
      if (!!this._billData.billToEdit.projectCategory.reference) {
        this._billData.billToEdit.projectCategory = this._bill.db.getReference(`projectCategories/${this._billData.billToEdit.projectCategory.reference.id}`);
      } else {
        this._billData.billToEdit.projectCategory = this._bill.db.getReference(`projectCategories/${this._billData.billToEdit.projectCategory.id}`);
      }
    }

    if (this._billData.billToEdit.purchaseOrder) {
      const purchaseOrder = this._billData.billToEdit.purchaseOrder;
      const purchaseOrderBillsTotal = await this._purchase.getTotalBills(purchaseOrder.key);
      if (!purchaseOrder.invoice) { purchaseOrder.invoice = []; }
      const isInPurchaseOrder = purchaseOrder.invoice?.some(invoice => invoice.id == this._billData.billToEdit.key);

      if (this.bill?.purchaseOrder?.key && this.bill?.purchaseOrder?.key !== purchaseOrder?.key) {
        await this._purchase.update(this.bill.purchaseOrder.key, {
          invoice: this.bill.purchaseOrder.invoice.filter((invoice) => invoice.id != this._billData.billToEdit.key)
        } as PurchaseOrder);
      }

      if (!isInPurchaseOrder) {
        await this._purchase.update(purchaseOrder.key, {
          invoice: [...purchaseOrder.invoice, this._bill.db.getReference(`bills/${this._billData.billToEdit.key}`)]
        } as PurchaseOrder);
      }

      await this._purchase.update(purchaseOrder.key, {
        ...!isInPurchaseOrder && { invoice: [...purchaseOrder.invoice, this._bill.db.getReference(`bills/${this._billData.billToEdit.key}`)] },
        invoiced: (this._billData.billToEdit.total + purchaseOrderBillsTotal) >= purchaseOrder.total - 10
      } as PurchaseOrder);

      this._billData.billToEdit.purchaseOrder = this._bill.db.getReference(`purchaseOrders/${purchaseOrder.key}`);
    }

    this._billData.billToEdit.expectedPaymentDate = moment(this._billData.billToEdit.expectedPaymentDate).toDate().getTime();

    if (this._billData.billToEdit.realPaymentDate) {
      this._billData.billToEdit.realPaymentDate = moment(this._billData.billToEdit.realPaymentDate).toDate().getTime();
    }

    if (this._billData.billToEdit.proofOfPayment) {
      await this.setProofPayment();
    }

    this._billData.billToEdit.isUsed = (!!this._billData.billToEdit.projectCategory || !!this._billData.billToEdit.purchaseOrder) && !!this._billData.billToEdit.billPdf;

    await this._bill.set(this._billData.billToEdit.key, this._billData.billToEdit);

    AlertService.toastSuccess('Se actualizó la factura correctamente');
    await this.stopUpdating();
  }

  async setProofPayment() {
    let proofOfPayment: ProofOfPayment = {
      documentNumber: +this._billData.billToEdit.documentNumber,
      name: 'Voucher',
      proofOfPayment: this._billData.billToEdit.proofOfPayment,
      reference: this._bill.db.getReference(`bills/${this._billData.billToEdit.key}`),
      trash: false,
      type: 0
    };

    await this._bill.setProofPayment(this.bill.key, proofOfPayment);
  }

  startUpdating() {
    this.blockUI.start('Cargando información...');
    this.dataSubscription$ = combineLatest([
      this._project.getAll(),
      this._project.getAllCategories(),
      this._purchase.getAllAuthorized()
    ]).subscribe(([
      projects,
      projectCategories,
      purchaseOrders
    ]) => {
      this._billData.purchaseOrders = purchaseOrders;
      this._billData.projects = projects.map(project => ({
        ...project,
        type:
          project.type == CostCenterType.PROJECT
            ? 'Proyectos'
            : 'Centro de Costos'
      }));
      this._billData.projectCategories = projectCategories;

      const categoryIndex = _.keyBy(this._billData.projectCategories, 'key');
      this._billData.filteredCategories = this._billData.bill?.project?.budgets?.filter((budget) => categoryIndex[budget.reference.id]) || [];

      this.blockUI.stop();
    });

    this._billData.billToEdit = { ...this._billData.bill };
    this._billData.updating = true;
    this._billData.updatingEmitter.emit(true);
  }

  async stopUpdating() {
    const newBill = this.formatBill(this._billData.billToEdit);
    this._billData.bill = this.bill = await this.populateBill(newBill);

    this._billData.billToEdit = null;
    this._billData.updating = false;
    this.dataSubscription$.unsubscribe();
    this.blockUI.stop();
  }

  cancelUpdating() {
    this._billData.bill = this.formatBill();
    this._billData.billToEdit = null;
    this._billData.updating = false;
  }

  formatBill(bill: Bill = this.bill) {
    return {
      ...bill,
      purchaseOrder: bill.purchaseOrder || null,
      project: bill.project || null,
      projectCategory: bill.projectCategory || null
    };
  }
}
