import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { PurchaseOrder } from '../../../../interfaces/purchase-order';
import { PurchaseStatus } from '../../../../../../enums/purchase-status.enum';
import { PurchaseOrderService } from 'src/app/shared/services/purchase-order.service';
import { LogsService } from '../../../../../../services/logs.service';
import { AlertService } from '../../../../../../../shared/template-services/alert.service';
import { User } from '../../../../../../../shared/interfaces/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../../../../../shared/services/user.service';
import { UserType } from '../../../../../../enums/user-type.enum';
import { FirebaseDataService } from '../../../../../../../shared/template-services/firebase-data.service';
import { Observable, Subscription } from 'rxjs';
import {
  UploadFileStorageComponent
} from '../../../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { ProjectService } from '../../../../../../../shared/services/project.service';
import { Project } from '../../../../../../interfaces/project';
import { ProviderService } from 'src/app/shared/services/provider.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { BillService } from 'src/app/shared/services/bill.service';
import { Category } from '../../../../../../../shared/interfaces/category';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DescriptionFormComponent } from '../description-form/description-form.component';
import moment from 'moment';
import { CostCenterType } from '../../../../../../enums/cost-center-type.enum';

@Component({
  selector: 'app-generated-purchase-order',
  templateUrl: './generated-purchase-order.component.html',
  styleUrls: ['./generated-purchase-order.component.css']
})
export class GeneratedPurchaseOrderComponent implements OnInit, OnDestroy {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  @ViewChild('labelSignature', { static: false }) labelSignature: ElementRef;

  @Input() purchaseOrderId: string;
  @Input() updatedFromDraft: boolean;
  @Input() supervisorUsers: User[];
  @Input() financeUsers: User[];
  @Input() financeThreshold: number;

  @Output() purchaseOrderChanged: EventEmitter<PurchaseOrder> = new EventEmitter<PurchaseOrder>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  purchaseOrder: PurchaseOrder;
  purchaseStatus = PurchaseStatus;
  provider: any;
  articlesPurchase: any;
  permission: any;
  permissionEnum = UserType;
  logs$: Observable<any>;
  isPdf: boolean = true;
  url: any;
  quotation = null;
  spinnerMessage: string = '';
  finalBudget: number;
  description: string = '';
  bussinessConditions: string = '';
  isQuotationLoaded: boolean = false;
  usersSubscription: Subscription = new Subscription();
  users: User[] = [];
  categoryIsExams: boolean = false;
  examCategory: string = 'examenes';
  purchaseOrderSubscription: Subscription = new Subscription();

  constructor(
    private _purchase: PurchaseOrderService,
    private _log: LogsService,
    private SpinnerService: NgxSpinnerService,
    public _user: UserService,
    private db: FirebaseDataService,
    private sanitizer: DomSanitizer,
    private _project: ProjectService,
    private _bills: BillService,
    private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.getPurchaseOrder();
  }

  ngOnDestroy(): void {
    this.usersSubscription.unsubscribe();
    this.purchaseOrderSubscription.unsubscribe();
  }

  async getPurchaseOrder() {
    this.permission = this._user.getPermission('COMPRAS');

    this.usersSubscription = this._user.getUsers().subscribe((users) => {
      users = users.filter((user) => {
        if (!user.permissions) {
          return false;
        }

        let section = user.permissions.find((per) => per.section == 'COMPRAS');

        return (
          !!section &&
          (section.permission == this.permissionEnum.SUPERVISOR ||
            section.permission == this.permissionEnum.ADMIN)
        );
      });

      this.users = users;
    });

    this.purchaseOrderSubscription = this._purchase.get(this.purchaseOrderId).subscribe(async (purchaseOrder: PurchaseOrder) => {
      if (this.permission == this.permissionEnum.FINANCE && purchaseOrder.status == PurchaseStatus.WAITING_FOR_REVIEW) {
        await this._purchase.update(this.purchaseOrderId, { status: PurchaseStatus.UNDER_REVIEW });
        this.purchaseOrderChanged.emit({ ...purchaseOrder, status: PurchaseStatus.UNDER_REVIEW });
      }

      if ((this.permission == this.permissionEnum.ADMIN || this.permission == this.permissionEnum.SUPERVISOR) && purchaseOrder.status == PurchaseStatus.GENERATED) {
        await this._purchase.update(this.purchaseOrderId, { status: PurchaseStatus.UNDER_APPROVAL });
        this.purchaseOrderChanged.emit({ ...purchaseOrder, status: PurchaseStatus.UNDER_APPROVAL });
      }

      this.purchaseOrder = purchaseOrder;
      this.logs$ = this._log.getAllPurchaseOrder(this.purchaseOrderId);
      this.provider = {
        provider: purchaseOrder.provider,
        project: purchaseOrder.project,
        budget: { reference: purchaseOrder.budget }
      };

      if (!!purchaseOrder.description) {
        this.description = purchaseOrder.description;
      }

      if (!!purchaseOrder.bussinessConditions) {
        this.bussinessConditions = purchaseOrder.bussinessConditions;
      }

      this.articlesPurchase = {
        articles: purchaseOrder.articles,
        subtotal: purchaseOrder.subtotal,
        discount: purchaseOrder.discount,
        net: purchaseOrder.net,
        iva: purchaseOrder.iva,
        total: purchaseOrder.total,
        paymentType: purchaseOrder.paymentType,
        currency: purchaseOrder.currency
      };

      this.quotation = purchaseOrder.quotation;
      this.getUrlPdf(this.quotation);

      this.checkForExamCategory(purchaseOrder.budget);
    });
  }

  async checkForExamCategory(budgetRef) {
    this._purchase.getBudget(budgetRef.path).subscribe(async (budget: Category) => {
      const normalizedCategory = budget.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f\u0020]/g, '');

      if (normalizedCategory == this.examCategory) {
        this.categoryIsExams = true;
      }
    });
  }

  getUrlPdf(url: any) {
    this.isPdf = url.includes('pdf');

    if (!this.isPdf) {
      return (this.url = url);
    }

    this.url = this.sanitizer.bypassSecurityTrustHtml(
      `<iframe width="100%" height="915" src="${url}"></iframe>`
    );
  }

  async askForAuthorization() {
    if (!await AlertService.confirm('¿Estás seguro de que deseas solicitar autorización para esta orden de compra?')) {
      return;
    }

    await this._purchase.update(this.purchaseOrder.key, { status: PurchaseStatus.GENERATED });

    this._log.addPurchaseOrder(this.purchaseOrder.key, {
      description: `La orden de compra ${this.purchaseOrder.purchaseID.slice(0, -3)} fue revisada por finanzas`
    });

    AlertService.toastSuccess('La orden de compra está en espera de autorización');
    this.close();
  }

  async changeToDraft() {
    await this._purchase.update(this.purchaseOrder.key, {
      status: PurchaseStatus.DRAFT
    } as PurchaseOrder);

    this.purchaseOrder.status = PurchaseStatus.DRAFT;

    this._log.addPurchaseOrder(this.purchaseOrder.key, {
      description: `La orden de compra ${this.purchaseOrder.purchaseID} pasó a borrador`
    });

    this.purchaseOrderChanged.emit(this.purchaseOrder);
  }

  async getBudgetSpent(categoryKey: string, projectKey: string) {
    const bills: any[] = await this._bills.getByProjectCategory(categoryKey, projectKey);

    return bills.reduce((acc, item) =>
        typeof (item['total']) == 'number'
          ? +(item['total'].toFixed(0).toString().replaceAll('.', '')) + acc
          : +(item['total'].toString().replaceAll('.', '')) + acc
      , 0);
  }

  async checkBudget() {
    let project: any = await this._project.get(this.provider.project.id).pipe(take(1)).toPromise();

    if (project) {
      const expirationDate = project.activeLateEndDate
        ? moment(project.activeLateEndDate)
        : moment(project.endDate, 'YYYY-MM-DD');

      if (expirationDate.isBefore(moment())) {
        return AlertService.toastError('El proyecto ha expirado');
      }
    }

    if (project.budget) {
      const budgetsData = await this._project.getBudgets(project.budget.path).pipe(take(1)).toPromise();
      project.budgets = budgetsData.budgets;
    }

    let index = project.budgets.findIndex(
      (budget) => budget.reference.id == this.provider.budget.reference.id
    );

    const spent = await this.getBudgetSpent(this.provider.budget.reference.id, this.provider.project.id);

    this.finalBudget = project.budgets[index].totalBudget - spent - this.articlesPurchase.total;

    if (this.finalBudget < 0 && this.permission == this.permissionEnum.ADMIN) {
      if (
        (await AlertService.input(
          `Se ha pasado del presupuesto por ${this.currency(Math.abs(this.finalBudget))}
         ¿Quieres aceptar esta orden?`,
          `Para confirmar que deseas aceptar la orden, escribe el OC: ${this.purchaseOrder.purchaseID.slice(0, -3)}`,
          'Aceptar'
        )) == this.purchaseOrder.purchaseID.slice(0, -3)
      ) {
        return this.addPurchase(project, index);
      }
    }

    if (this.finalBudget < 0 && this.permission == this.permissionEnum.SUPERVISOR) {
      return AlertService.info(`Se ha pasado del presupuesto ${this.currency(this.finalBudget)}`);
    }

    if (this.finalBudget >= 0 && this.permission >= this.permissionEnum.SUPERVISOR) {
      if (
        await AlertService.confirm(
          `¿Estás seguro de que deseas autorizar esta orden de compra, el presupuesto que quedara es de ${this.currency(
            this.finalBudget
          )}?`
        )
      ) {
        return this.addPurchase(project, index);
      }
    }
  }

  async addPurchase(project, index) {
    this.spinnerMessage = 'Autorizando orden de compra';
    this.SpinnerService.show();

    project.budgets[index].usedBudget =
      project.budgets[index].usedBudget + this.articlesPurchase.total;

    let signature = this._user.user.signature;

    if (!this._user.user.signature) {
      if (!this.filesStorage.isInputValid) {
        this.SpinnerService.hide();
        return AlertService.toastError('Es necesario agregar una firma');
      }

      signature = await this.filesStorage.uploadDocument(
        'signatures',
        this._user.user.key
      );

      this._user.update(this._user.user.key, { signature } as User);
    }

    const newPurchaseOrder = {
      approvalDate: new Date().getTime(),
      status: this.purchaseStatus.AUTHORIZED,
      supervisor: this.db.getReference(`users/${this._user.user.key}`),
      signature
    };

    let category = await this._project
      .getCategory(project.budgets[index].reference.id)
      .pipe(take(1))
      .toPromise();

    this.purchaseOrderChanged.emit({ ...this.purchaseOrder, ...newPurchaseOrder });

    this._log.updateBudget(this.provider.project.id, {
      description: `Se autorizó la compra ${this.purchaseOrder.purchaseID.slice(0, -3)} con la categoría de ${category.name} y el presupuesto usado fue de ${
        this.articlesPurchase.total
      }`
    });

    await this._purchase.update(this.purchaseOrder.key, newPurchaseOrder);

    if (project.type == CostCenterType.PROJECT) {
      await this._project.update(this.provider.project.id, { budgets: project.budgets } as Project);
    }

    this.createLog();
    this.SpinnerService.hide();
  }

  createLog() {
    this._log.addPurchaseOrder(this.purchaseOrder.key, {
      description: `La orden de compra ${this.purchaseOrder.purchaseID.slice(
        0,
        -3
      )} fue autorizada`
    });
  }

  quotationLoaded() {
    if (this.isQuotationLoaded == false) {
      this.isQuotationLoaded = true;
    }
  }

  async updateQuotation() {
    if (!this.isQuotationLoaded) {
      return AlertService.toastError(
        'Favor de agregar una cotización para actualizar el documento'
      );
    }

    this.SpinnerService.show();
    this.spinnerMessage = 'Actualizando cotización';

    if (this.filesStorage.isInputValid) {
      this.quotation = await this.filesStorage.uploadDocument(
        'purchaseOrders',
        this.purchaseOrder.purchaseID
      );
    } else {
      return;
    }

    this.getUrlPdf(this.quotation);

    this._purchase.set(this.purchaseOrder.key, {
      quotation: this.quotation
    } as PurchaseOrder);

    this.SpinnerService.hide();
  }

  currency(number) {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    return formatter.format(number).split('.')[0].replaceAll(',', '.');
  }

  close() {
    this.closeModal.emit();
  }

  async cancelPurchase() {
    if (await AlertService.confirm('¿Estás seguro que deseas cancelar la orden de compra?', '')) {
      let cancelMessage = await AlertService.input('Motivo de la cancelación', '', 'Confirmar');

      if (!cancelMessage) {
        cancelMessage = 'No se asignó motivo';
      }

      await this._purchase.update(this.purchaseOrder.key, {
        status: PurchaseStatus.CANCELLED,
        cancelMessage
      } as PurchaseOrder);

      this._log.addPurchaseOrder(
        this.purchaseOrder.key,
        { description: `La orden de compra ${this.purchaseOrder.purchaseID.slice(0, -3)} fue cancelada` }
      );

      this.close();
      AlertService.toastSuccess('La orden de compra fue cancelada', '');
    }
  }

  openDescriptionForm() {
    this.modal.show(DescriptionFormComponent, {
      initialState: {
        description: this.description,
        purchaseOrderId: this.purchaseOrder.key
      },
      id: 666
    });
  }

  currencyFormat(value: number | string): string {
    const numberValue = Number(value.toString().replace(/[.$,]/g, '')) || 0;
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(numberValue);
  }
}
