<form class="m-4" (submit)="submit()">
  <div>
    <ti-input
      [multiline]="true"
      [(model)]="comment"
      [label]="'Comment'"
      [placeholder]="'Enter your comment'"
    ></ti-input>
    <div class="d-flex flex-align-items-center justify-content-end mt-2">
      <button (click)="closeModal()" type="button" class="btn btn-info mr-2">Cancelar</button>
      <button type="submit" class="btn btn-primary">Actualizar</button>
    </div>
  </div>
</form>
