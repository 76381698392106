import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Bill } from '../../admin/interfaces/bill';
import { ProofOfPayment } from '../../admin/interfaces/proof-of-payment';
import { first, take } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AlertService } from '../template-services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class BillService {
  constructor(public db: FirebaseDataService,
    private afs: AngularFirestore,
    private http: HttpClient,
    private storage: AngularFireStorage) {
  }

  get(billKey: string): Observable<Bill> {
    return this.db.docWithId$(`bills/${billKey}`);
  }

  getAll(): Observable<Bill[]> {
    return this.db.colWithIds$<Bill>('bills', (ref) =>
      ref.where('trash', '==', false).orderBy('startDate', 'desc')
    );
  }

  getAllByPurchaseOrder(purchaseOrderKey: string): Observable<Bill[]> {
    return this.db.colWithIds$<Bill>('bills', (ref) =>
      ref
        .where('trash', '==', false)
        .where('purchaseOrder', '==', this.db.getReference(`purchaseOrders/${purchaseOrderKey}`))
    );
  }

  getUnused(purchaseOrder): Observable<Bill[]> {
    return this.db.colWithIds$<Bill>('bills', (ref) =>
      ref
        .where('isUsed', '==', false)
        .where('trash', '==', false)
        .orderBy('provider')
        .startAt(purchaseOrder.providerRut)
        .endAt(purchaseOrder.providerRut + '\uf8ff')
    );
  }

  add(bill: Bill): Promise<DocumentReference> {
    return this.afs.collection('bills').add(bill);
  }

  async set(billKey: string, bill: Bill): Promise<void> {
    this.afs.doc(`bills/${billKey}`).set(bill, { merge: true });
  }

  async setNew(billKey: string, bill: Bill): Promise<void> {
    const billDoc = await this.db
      .docWithId$(`bills/${billKey}`)
      .pipe(take(1))
      .toPromise();

    if (!!billDoc) {
      delete bill['purchaseOrder'];
      delete bill['category'];
      delete bill['expectedPaymentDate'];
      delete bill['realPaymentDate'];
      delete bill['isUsed'];
      delete bill['proofOfPayment'];
      delete bill['isPaid'];
    }
    this.afs.doc(`bills/${billKey}`).set(bill, { merge: true });
  }

  delete(billKey: string): Promise<void> {
    return this.afs.doc<Bill>(`bills/${billKey}`).update({ trash: true });
  }

  async update(billKey: string, bill: Bill): Promise<void> {
    try {
      return await this.afs.doc<Bill>(`bills/${billKey}`).update(bill);
    } catch (e) {
      console.log(e);
      AlertService.error('Error al actualizar factura');
    }
  }

  createId(): string {
    return this.afs.createId();
  }

  getAllProofPayments(): Observable<ProofOfPayment[]> {
    return this.db.colWithIds$<ProofOfPayment>('proofPayments', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  setProofPayment(
    proofOfPaymentKey: string,
    proofOfPayment: ProofOfPayment
  ) {
    return this.afs
      .doc(`proofPayments/${proofOfPaymentKey}`)
      .set(proofOfPayment, { merge: true });
  }

  deletePermanentProofPayment(proofOfPaymentKey: string): Promise<void> {
    return this.afs
      .doc(`proofPayments/${proofOfPaymentKey}`)
      .update({ trashPermanent: true });
  }

  async updateProofPayment(
    proofOfPaymentKey: string,
    proofOfPayment: ProofOfPayment
  ): Promise<void> {
    return await this.afs
      .doc<ProofOfPayment>(`proofPayments/${proofOfPaymentKey}`)
      .update(proofOfPayment);
  }

  async uploadBillPdf(file, billKey) {
    const uploadRef = this.getStorageRefFile(billKey);
    await uploadRef.put(file);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(file, billKey);

    return url;
  }

  uploadFileStorage(data, id) {
    return this.storage.upload(`bills/billPdf/${id}/billPdf.pdf`, data);
  }

  getStorageRefFile(id) {
    return this.storage.ref(`bills/billPdf/${id}/billPdf.pdf`);
  }

  getPayments(fromDate) {
    return this.http
      .post(`${environment.apiBaseURL}/getPayments`, {
        fromDate
      })
      .pipe(take(1))
      .toPromise();
  }

  async getByProjectCategory(projectCategory: string, projectKey: string): Promise<Bill[]> {
    // Esto se hizo por la forma en que se guardaron las facturas en la base de datos de firebase en el pasado
    return [
      ...(await (this.db.colWithIds$<Bill>('bills', ref => ref
        .where('trash', '==', false)
        .where('project', '==', this.db.getReference(`projects/${projectKey}`))
        .where('projectCategory', '==', this.db.getReference(`projectCategories/${projectCategory}`)))
        .pipe(first()).toPromise())),

      ...(await (this.db.colWithIds$<Bill>('bills', ref => ref
        .where('trash', '==', false)
        .where('project', '==', this.db.getReference(`projects/${projectKey}`))
        .where('projectCategory', '==', this.db.getReference(`proyectCategories/${projectCategory}`)))
        .pipe(first()).toPromise())),

      ...(await (this.db.colWithIds$<Bill>('bills', ref => ref
        .where('trash', '==', false)
        .where('project', '==', this.db.getReference(`projects/${projectKey}`))
        .where('projectCategory.reference', '==', this.db.getReference(`projectCategories/${projectCategory}`)))
        .pipe(first()).toPromise())),

      ...(await (this.db.colWithIds$<Bill>('bills', ref => ref
        .where('trash', '==', false)
        .where('project.key', '==', projectKey)
        .where('projectCategory', '==', this.db.getReference(`projectCategories/${projectCategory}`)))
        .pipe(first()).toPromise())),

      ...(await (this.db.colWithIds$<Bill>('bills', ref => ref
        .where('trash', '==', false)
        .where('project.key', '==', projectKey)
        .where('projectCategory', '==', this.db.getReference(`proyectCategories/${projectCategory}`)))
        .pipe(first()).toPromise())),

      ...(await (this.db.colWithIds$<Bill>('bills', ref => ref
        .where('trash', '==', false)
        .where('project.key', '==', projectKey)
        .where('projectCategory.reference', '==', this.db.getReference(`projectCategories/${projectCategory}`)))
        .pipe(first()).toPromise()))
    ];
  }

  getProvider(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(provider.indexOf(' ') + 1);
  }

  getRut(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(0, provider.indexOf(' '));
  }

  populateObject(object: { [key: string]: any }, fields: string[]) {
    return this.db.populateObject(object, fields);
  }
}
