import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FirebaseDataService } from "../../../shared/template-services/firebase-data.service";
import { PurchaseOrderService } from "../../../shared/services/purchase-order.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AlertService } from "../../../shared/template-services/alert.service";
import { UserService } from "../../../shared/services/user.service";
import { PurchaseOrderPrintService } from '../../services/purchase-order-print.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-updload-purchase-pdf',
  templateUrl: './updload-purchase-pdf.component.html',
  styleUrls: ['./updload-purchase-pdf.component.css']
})
export class UpdloadPurchasePdfComponent implements OnInit {
  @ViewChild('labelImport', { static: false }) labelImport: ElementRef;
  comment: string = '';
  recipients: any[] = [];
  providerEmail: string = '';
  purchaseID: any;
  purchaseOrderKey: string;

  constructor(private db: FirebaseDataService,
    private _purchase: PurchaseOrderService,
    private modal: BsModalService,
    private _user: UserService,
    private pdfService: PurchaseOrderPrintService,
    private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.recipients.push(this.providerEmail);
    if (this.providerEmail != this._user.user.email) this.recipients.push(this._user.user.email);
  }

  async submit() {
    if (!this.comment) return AlertService.toastError('Debe ingresar un comentario');
    this.SpinnerService.show();

    const pdf = await this.pdfService.getFile(this.purchaseOrderKey) as File;
    const fileUrl = await this.db.uploadFile(pdf, this.db.createID(), 'orden_de_compra.pdf', 'pdf');

    this._purchase.sendPdfToProvider(fileUrl, this.recipients, this.comment, this.purchaseID);

    AlertService.toastSuccess('Orden de compra enviada');
    this.modal.hide(2);
    this.SpinnerService.hide();
  }
}
