import { Component, OnDestroy, OnInit } from '@angular/core';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BsModalService } from 'ngx-bootstrap/modal';
import { KanbanNewItemComponent } from './kanban-new-item/kanban-new-item.component';
import { Observable, of, Subscription } from 'rxjs';
import { KanbanService } from './kanban.service';
import { Task } from './interfaces/task';
import { UserService } from '../../../shared/services/user.service';
import { DocumentReference } from '@angular/fire/firestore';
import { first, map, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { UserType } from '../../enums/user-type.enum';
import { TaskOverhaul } from './enums/task-overhaul';
import { NotificationService } from '../../services/notification.service';
import { Board } from '../../interfaces/board';
import { BoardService } from '../../services/board.service';
import { NotificationType } from '../../enums/notification-type.enum';
import moment from 'moment';
import {
  SearchItemsModalComponent
} from '../../../shared/template-components/search-items/search-items-modal.component';
import { AlertService } from '../../../shared/template-services/alert.service';
import { KanbanStatus } from './enums/kanban-status.enum';
import { ChecklistService } from './services/checklist.service';
import { FirebaseDataService } from '../../../shared/template-services/firebase-data.service';
import { KanbanReportComponent } from '../../kanban/components/kanban-report/kanban-report.component';
import { User } from '../../../shared/interfaces/user';
import { BoardTypeEnum } from '../../enums/board-type.enum';
import { KanbanModalComponent } from './kanban-modal/kanban-modal.component';
import { ExpirationFilterEnum } from './enums/expiration-filter.enum';
import { TitleCasePipe } from '@angular/common';
import { KanbanStatusLabel } from './labels/kanban-status.label';
import { NgxSpinnerService } from 'ngx-spinner';
import { CostCenterType } from '../../enums/cost-center-type.enum';
import { ProjectService } from 'src/app/shared/services/project.service';

declare const $;

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.css']
})
export class KanbanComponent implements OnInit, OnDestroy {
  todo: Task[] = [];
  inProgress: Task[] = [];
  done: Task[] = [];
  taskSubscription: Subscription = new Subscription();
  tagsSubscription: Subscription = new Subscription();
  tags = [];
  status = KanbanStatus;
  boardKey: string;
  userTypeEnum = UserType;
  userPermission: number;
  board: Board;
  team = [];
  users: User[];
  private usersSubscription: Subscription = new Subscription();
  private teamSubscription: Subscription = new Subscription();
  userFilter: any = '';
  tasks: Task[] = [];
  boardType: number;
  boardTypeEnum = BoardTypeEnum;
  startDate: any = moment().startOf('month').format('YYYY-MM-DD');
  finalDate: any = moment().endOf('month').format('YYYY-MM-DD');
  settings;
  monthSelected: any = moment().locale('es').endOf('month').format('YYYY-MM');
  listMode: boolean = false;
  filteredTasks$: Observable<Task[]>;
  zoom: string = localStorage.getItem('tasksZoom');
  expirationFilterEnum = ExpirationFilterEnum;
  validitySelected;
  project: any;
  selectArray: any;
  private projectSubscription: Subscription = new Subscription();
  isAllBoardsExported: boolean = false;

  constructor(private modal: BsModalService,
    private _kanban: KanbanService,
    private _user: UserService,
    private _board: BoardService,
    private _checklist: ChecklistService,
    private _notification: NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private db: FirebaseDataService,
    private titleCasePipe: TitleCasePipe,
    private spinnerService: NgxSpinnerService,
    private _project: ProjectService
  ) {
    this.activatedRoute.params.subscribe(
      (params) => (this.boardKey = params.boardKey)
    );
  }

  async ngOnInit(): Promise<void> {
    this.validitySelected = this.expirationFilterEnum.ALL;
    this.settings = await this._kanban.getSettings();
    this.tagsSubscription = this._kanban
      .getAllTags()
      .subscribe((data) => (this.tags = data));
    this.board = await this._board.get(this.boardKey).pipe(take(1)).toPromise();
    this.boardType = this.board.type;
    await this.loadUsers();
    this.userPermission = this._user.user.permissions.find(
      (user) => user.section == 'TAREAS'
    ).permission;
    if (this.boardType == this.boardTypeEnum.TEAM) {
      if (
        this.board.users.length > 0 &&
        this.board.users.some((user) => user.id == this._user.user.key) &&
        this.userPermission == this.userTypeEnum.SUPERVISOR
      ) {
        this.userPermission = this.userTypeEnum.USER;
      }
      if (
        this.board.admins.length > 0 &&
        this.board.admins.some((admin) => admin.id == this._user.user.key)
      ) {
        this.userPermission = this.userTypeEnum.ADMIN;
      }
      if (
        this.board.admins.length > 0 &&
        this.board.supervisors.some(
          (supervisor) => supervisor.id == this._user.user.key
        )
      ) {
        this.userPermission = this.userTypeEnum.SUPERVISOR;
      }
    }

    this._user.getAllUndeleted().subscribe((users) => {
      this.taskSubscription = this._kanban
        .getAllTasks(this.boardKey)
        .subscribe((data) => {
          data = data.map((task: Task) => ({
            ...task,
            members: (<DocumentReference[]>task.members).map((member) =>
              users.find((user) => user.key == member.id)
            ),
            tags: (<DocumentReference[]>task.tags).map((tag) =>
              this.tags.find((tagItem) => tagItem.key == tag.id)
            ),
            isExpirated:
              task.expiratedDate < new Date().getTime() &&
              task.expiratedDate < task.finishDate
          }));

          this.tasks = _.orderBy(data, 'index', 'asc');

          const params = this.activatedRoute.snapshot.queryParams;
          if (params.task) {
            this.openTask(this.tasks.find((task) => task.key == params.task));
            this.router.navigate([], {
              queryParams: {
                'task': null,
              },
              queryParamsHandling: 'merge'
            })
          }

          this.filterTasks();
        });
    });

    this.getProjects();
  }

  ngOnDestroy() {
    this.taskSubscription.unsubscribe();
    this.tagsSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
    this.teamSubscription.unsubscribe();
    this.projectSubscription.unsubscribe();
  }

  async loadUsers() {
    this.teamSubscription = this._user.getAllUndeleted().subscribe((users) => {
      this.team = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find((per) => per.section == 'TAREAS');
        return (
          !!section && this.board.users.some((data) => data.id == user.key)
        );
      });
    });
    this.usersSubscription = await this._user
      .getAllUndeleted()
      .subscribe((users) => (this.users = users));
  }

  async drop(event: any) {
    if (event.previousContainer != event.container && !(await AlertService.confirm('¿Está seguro de mover la tarea?'))) return;

    if ((event.previousContainer.id == KanbanStatus.IN_PROGRESS || event.previousContainer.id == KanbanStatus.TODO) && event.container.id == KanbanStatus.DONE) {
      let [description, realFinalDate] = await AlertService.withHtml(
        'Finalizar tarea',
        `
        <label>Observaciones:</label>
        <textarea id="swal-input1" rows="10" class="swal2-input"></textarea>
        <label>Fecha de finalización:</label>
        <input id="swal-input2" class="swal2-input" type="date">`,
        function () {
          return new Promise(function (resolve) {
            resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
          });
        }
      );

      if (!description || !realFinalDate) {
        return AlertService.toastError(
          'Necesitas agregar observaciones y fecha final para poder completar la tarea'
        );
      }

      if (this.userPermission == this.userTypeEnum.USER && this.boardType == this.boardTypeEnum.TEAM) {
        this._kanban.updateTask(
          {
            overhaul: TaskOverhaul.ACTIVE,
            finishDate: new Date(realFinalDate).getTime()
          } as Task,
          event.previousContainer.data[event.previousIndex].key,
          this.boardKey
        );

        await this._kanban.setLog(
          this.boardKey,
          event.previousContainer.data[event.previousIndex].key,
          {
            description: `Se envió a revisión con el comentario: ${description}`,
            user: this.db.getReference(`users/${this._user.user.key}`),
            createdAt: new Date().getTime()
          }
        );

        for (const supervisor of <DocumentReference[]>this.board.supervisors) {
          this._notification.setUser(supervisor.id, {
            createdDate: new Date().getTime(),
            description: `El usuario ${this._user.user.name} ${this._user.user.surnames} ha enviado una tarea a revisión con el comentario: ${description}`,
            type: NotificationType.USER_MESSAGE,
            redirectUrl: `admin/kanban/${this.boardKey}`,
            readed: false,
            trash: false
          });
        }
      } else {
        this._kanban.updateTask(
          {
            overhaul: TaskOverhaul.COMPLETED,
            finishDate: new Date(realFinalDate).getTime()
          } as Task,
          event.previousContainer.data[event.previousIndex].key,
          this.boardKey
        );
        await this.sendCompletedTaskNotificationEmail(
          event.previousContainer.data[event.previousIndex]
        );
      }

      await this._kanban.addComment(
        event.previousContainer.data[event.previousIndex].key,
        {
          user: this.db.getReference(`users/${this._user.user.key}`),
          trash: false,
          createdAt: new Date().getTime(),
          comment: description
        },
        this.boardKey
      );
    }

    if (
      this.userPermission == this.userTypeEnum.USER &&
      event.previousContainer.data[event.previousIndex].overhaul ==
      TaskOverhaul.COMPLETED &&
      event.previousContainer.id == KanbanStatus.DONE &&
      (event.container.id == KanbanStatus.IN_PROGRESS ||
        event.container.id == KanbanStatus.TODO) &&
      this.boardType == this.boardTypeEnum.TEAM
    ) {
      return;
    }

    if (
      event.previousContainer.id == KanbanStatus.DONE &&
      event.container.id == KanbanStatus.IN_PROGRESS &&
      (this.userPermission == this.userTypeEnum.ADMIN ||
        this.userPermission == this.userTypeEnum.SUPERVISOR ||
        this.boardType == this.boardTypeEnum.PERSONAL)
    ) {
      this._kanban.updateTask(
        {
          overhaul: TaskOverhaul.NONE,
          finishDate: null
        } as Task,
        event.previousContainer.data[event.previousIndex].key,
        this.boardKey
      );

      this._kanban.setLog(
        this.boardKey,
        event.previousContainer.data[event.previousIndex].key,
        {
          description: 'Se envió a en proceso',
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        }
      );
    }

    if (
      event.previousContainer.id == KanbanStatus.TODO &&
      event.container.id == KanbanStatus.IN_PROGRESS
    ) {
      this._kanban.setLog(
        this.boardKey,
        event.previousContainer.data[event.previousIndex].key,
        {
          description: 'Se envió a en proceso',
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        }
      );
    }

    if (event.previousContainer == event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this._kanban.updateTask(
        { status: +event.container.id } as Task,
        event.previousContainer.data[event.previousIndex].key,
        this.boardKey
      );
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateTasks(event.previousContainer.data);
    }
    this.updateTasks(event.container.data);

    this.sendTaskStatusChangedEmail(event);
  }

  async sendTaskStatusChangedEmail({
    previousContainer,
    container,
    currentIndex
  }) {
    const previousStatus = +previousContainer.id;
    const currentStatus = +container.id;

    if (previousStatus == currentStatus) return;

    const previousStatusLabel = this.getCapitalizedStatusLabel(previousStatus);
    const currentStatusLabel = this.getCapitalizedStatusLabel(currentStatus);

    const task: Task = container.data[currentIndex];

    const supervisorsEmails = this.filterUsersByBoardSupervisors(
      this.users,
      this.board.supervisors
    ).map((user) => user.email);

    if (!supervisorsEmails.length) return;

    this.spinnerService.show();

    try {
      let usersEmail = (<User[]>task.members).map((user) => user.email);
      await this._notification.sendNotificationEmail(
        `La tarea "${task.title}" del tablero "${this.board.name}" cambió de estado "${previousStatusLabel}" a "${currentStatusLabel}" por el usuario ${this._user.user?.name || ''} ${this._user.user?.surnames || ''}`,
        `Tareas: tarea "${task.title}" cambió de estado por el usuario ${this._user.user?.name || ''} ${this._user.user?.surnames || ''}`,
        usersEmail.concat(supervisorsEmails)
      );

      this._kanban.setLog(this.boardKey, task.key, {
        description: `La tarea cambió de estado "${previousStatusLabel}" a "${currentStatusLabel}" por el usuario ${this._user.user?.name || ''} ${this._user.user?.surnames || ''}`,
        user: this.db.getReference(`users/${this._user.user.key}`),
        createdAt: new Date().getTime()
      });
    } catch (e) {
      console.error(e);
    }

    this.spinnerService.hide();
  }

  filterUsersByBoardSupervisors(
    users: User[],
    boardSupervisors: DocumentReference[] | User[]
  ) {
    return users.filter((user) =>
      boardSupervisors.some(
        (supervisor) => (supervisor.id ?? supervisor.key) == user.key
      )
    );
  }

  getCapitalizedStatusLabel(status: number) {
    const statusLabel = this.getStatusLabel(status);

    let [first, ...rest]: string[] = statusLabel.split(' ');

    first = this.titleCasePipe.transform(first);

    return [first, ...rest].join(' ');
  }

  getStatusLabel(status: number) {
    return KanbanStatusLabel[status];
  }

  async sendCompletedTaskNotificationEmail(task: Task) {
    let usersEmail = [];
    let supervisorsEmail = [];
    if (this.board.supervisors.length > 0) {
      supervisorsEmail = this.users.filter((user) =>
        this.board.supervisors.some((supervisor) => supervisor.id == user.key)
      );
      if (supervisorsEmail.length > 0) {
        supervisorsEmail = supervisorsEmail.map(
          (supervisor) => supervisor.email
        );
      }
    }
    if (task.members.length > 0) {
      usersEmail = (<User[]>task.members).map((user) => user.email);
    }
    await this._notification.sendNotificationEmail(
      `La tarea ${task.title} se ha completado`,
      `Tareas: tarea ${task.title} completada`,
      usersEmail.concat(supervisorsEmail)
    );
  }

  updateTasks(data: Task[]) {
    for (const index in data) {
      this._kanban.updateTask(
        { index: +index } as Task,
        data[index].key,
        this.boardKey
      );
    }
  }

  addTask() {
    this.modal.show(KanbanNewItemComponent, {
      initialState: {
        boardKey: this.boardKey,
        userPermission: this.userPermission,
        boardType: this.boardType
      }
    });
  }

  goToTasksList() {
    this.router.navigate([`admin/kanban/${this.boardKey}/tasks-list`]);
  }

  filterTasks() {
    this.todo = _.orderBy(
      this.tasks
        .filter((item) => item.status == KanbanStatus.TODO)
        .filter(
          (item) =>
            !this.userFilter ||
            item.members.some((member) => !!member && member.key == this.userFilter.key)
        )
        .filter(task => !this.project || (task.project && task.project.id == this.project.key)),
      'index',
      'asc'
    );

    this.inProgress = _.orderBy(
      this.tasks
        .filter((item) => item.status == KanbanStatus.IN_PROGRESS)
        .filter(
          (item) =>
            !this.userFilter ||
            item.members.some((member) => !!member && member.key == this.userFilter.key)
        )
        .filter(task => !this.project || (task.project && task.project.id == this.project.key)),
      'index',
      'asc'
    );

    this._kanban.pendingTasks = this.todo.concat(this.inProgress);

    if (this.boardType == this.boardTypeEnum.PERSONAL) {
      this.done = _.orderBy(
        this.tasks.filter((item) => item.status == KanbanStatus.DONE)
      );
      return;
    }

    this.done = _.orderBy(
      this.tasks
        .filter((item) => item.status == KanbanStatus.DONE)
        .filter(
          (item) =>
            !this.userFilter ||
            item.members.some((member) => !!member && member.key == this.userFilter.key)
        )
        .filter(task => !this.project || (task.project && task.project.id == this.project.key)),
      'index',
      'asc'
    ).filter((item) =>
      moment(item.finishDate).isBetween(
        moment(this.startDate).startOf('day'),
        moment(this.finalDate).endOf('day')
      )
    );

    this.todo = this.getFilterTasksByValidity(this.todo, KanbanStatus.TODO);
    this.inProgress = this.getFilterTasksByValidity(
      this.inProgress,
      KanbanStatus.IN_PROGRESS
    );
    this.done = this.getFilterTasksByValidity(this.done, KanbanStatus.DONE);

    this.filteredTasks$ = of([...this.todo, ...this.inProgress, ...this.done]);
  }

  getFilterTasksByValidity(tasks: Task[], status) {
    return tasks.filter((task) =>
      this.validateFilterTaskByValidity({ ...task, status })
    );
  }

  validateFilterTaskByValidity(task: Task) {
    switch (this.validitySelected) {
      case this.expirationFilterEnum.ALL:
        return true;
      case this.expirationFilterEnum.EXPIRED:
        return moment(task.expiratedDate).isBefore(moment().startOf('day'));
      case this.expirationFilterEnum.NOT_EXPIRED:
        if (task.status == KanbanStatus.DONE) {
          return moment(task.finishDate).isSameOrBefore(
            moment().startOf('day')
          );
        } else {
          return moment(task.expiratedDate).isSameOrAfter(
            moment().startOf('day')
          );
        }
    }
  }

  async handleReports() {
    this.isAllBoardsExported = false;
    let userSelected;
    if (this.userPermission == this.userTypeEnum.USER) {
      userSelected = this._user.user;
    } else {
      userSelected = await this.getKanbanUser();
    }
    if (!userSelected) return;

    if (await AlertService.confirm('¿Qué tareas desea exportar?', '', 'Todos los tableros', 'Solo este tablero')) {
      this.isAllBoardsExported = true;
    }

    this.filterTasksByUser(userSelected);
  }

  async getKanbanUser() {
    let users$ = of(this.team);
    const modalRef = this.modal.show(SearchItemsModalComponent, {
      initialState: {
        items$: users$,
        fields: [
          {
            title: 'Nombre',
            column: 'name'
          }
        ]
      },
      class: 'modal-md shadow-lg mt-5',
      id: 100
    });

    await modalRef.onHide.pipe(first()).toPromise();
    return modalRef.content.itemSelected;
  }

  async filterTasksByUser(userSelected: User) {
    let [startDate, endDate] = await AlertService.withHtml(
      'Seleccionar rango de fechas',
      `
        <label>Fecha desde:</label>
        <input id="swal-input1" class="swal2-input" value="${this.startDate}" type="date">
        <label>Hasta:</label>
        <input id="swal-input2" class="swal2-input" value="${this.finalDate}" type="date">`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
        });
      }
    );

    let allTasks = null;

    if (this.isAllBoardsExported) {
      this.spinnerService.show();

      allTasks = await this._kanban.getAllGroupTasks(userSelected.key);

      this.spinnerService.hide();

      allTasks = allTasks.map((task: Task) => ({
        ...task,
        members: (<DocumentReference[]>task.members).map((member) =>
          this.users.find((user) => user.key == member.id)
        ),
        tags: (<DocumentReference[]>task.tags).map((tag) =>
          this.tags.find((tagItem) => tagItem.key == tag.id)
        ),
        isExpirated:
          task.expiratedDate < new Date().getTime() &&
          task.expiratedDate < task.finishDate
      }));

    }

    if (!startDate || !endDate) return;

    let doneTasks = (allTasks || this.tasks)
      .filter((task) => !!task.members)
      .filter(
        (task) =>
          task.status == KanbanStatus.DONE &&
          ((<any[]>task.members)
            .filter((member) => !!member)
            .some((member) => member.key == userSelected.key)) &&
          moment(task.finishDate)
            .endOf('day')
            .isBetween(moment(startDate).startOf('day'), endDate)
      )
      .map((task) => ({
        ...task,
        totalProgress: 100
      }));

    let pendingTasks = await Promise.all(
      (allTasks || this.tasks)
        .filter(
          (task) =>
            task.status != KanbanStatus.DONE &&
            task.members.some((member) => !!member && (member.key == userSelected.key)) &&
            task.isAvailable &&
            task.expiratedDate <=
            moment(endDate).endOf('day').toDate().getTime()
        )
        .map(async (task) => ({
          ...task,
          totalProgress: await this.getTotalProgress(task)
        }))
    );

    let tasks = doneTasks.concat(pendingTasks);

    tasks = _.orderBy(
      tasks.map((task) => ({
        ...task,
        points: moment(task.expiratedDate)
          .endOf('day')
          .isBetween(moment(startDate).startOf('day'), endDate)
          ? 1
          : moment(endDate)
            .startOf('month')
            .diff(moment(task.expiratedDate).startOf('month'), 'month') *
          this.settings.penaltyPoints
      })),
      'status',
      'desc'
    );

    this.modal.show(KanbanReportComponent, {
      initialState: {
        tasks,
        user: userSelected,
        totalPending: pendingTasks.length,
        totalDone: doneTasks.length,
        totalPercent:
          (doneTasks.length * 100) / (pendingTasks.length + doneTasks.length)
      },
      class: 'modal-xl'
    });
  }

  async getTotalProgress(task) {
    let checklist = await this._checklist
      .getAll(this.boardKey, task.key)
      .pipe(take(1))
      .toPromise();

    if (checklist.length == 0) {
      return task.status == KanbanStatus.DONE ? 100 : 0;
    }

    let checkedTasks = checklist.filter(
      (item) =>
        item.checked ||
        (!!item.task &&
          this.tasks.find((task) => task.key == item.task.id).status ==
          KanbanStatus.DONE)
    ).length;

    if (checkedTasks == 0) return 0;

    return (checkedTasks / checklist.length) * 100;
  }

  async openConfig() {
    let [penaltyPoints] = await AlertService.withHtml(
      'Configuración',
      `
        <label>Puntos de penalización</label>
        <input type="number" id="swal-input1" class="swal2-input" value="${this.settings.penaltyPoints}">`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val()]);
        });
      }
    );
    if (!penaltyPoints) return;
    this.settings.penaltyPoints = +penaltyPoints;
    this._kanban.setSettings({ penaltyPoints: this.settings.penaltyPoints });
    AlertService.toastSuccess('Guardado exitosamente');
  }

  setDateRange() {
    this.startDate = moment(this.monthSelected)
      .startOf('month')
      .format('YYYY-MM-DD');
    this.finalDate = moment(this.monthSelected)
      .endOf('month')
      .format('YYYY-MM-DD');

    this.filterTasks();
  }

  openTask(task: Task) {
    this.modal.show(KanbanModalComponent, {
      initialState: {
        item: { ...task },
        boardKey: this.boardKey,
        userPermission: this.userPermission
      },
      class: 'modal-xlg'
    });
  }

  addZoom() {
    if (!!localStorage.tasksZoom) {
      localStorage.setItem('tasksZoom', (+this.zoom + 0.1).toString());
    } else {
      localStorage.setItem('tasksZoom', (1 + +this.zoom + 0.1).toString());
    }
    this.zoom = localStorage.getItem('tasksZoom');
  }

  substractZoom() {
    if (!!localStorage.tasksZoom) {
      localStorage.setItem('tasksZoom', (+this.zoom - 0.1).toString());
    } else {
      localStorage.setItem('tasksZoom', (1 + +this.zoom - 0.1).toString());
    }
    this.zoom = localStorage.getItem('tasksZoom');
  }

  async handleDeleteRecurrentTask() {
    const recurrentTasks$ = this._kanban
      .getRecurrentTasks(this.boardKey)
      .pipe(
        map((tasks) => tasks.map((task: any) => ({
          ...task,
          frequencyLabel: task.frequencyType == 'monthly' ? 'Mensual' : 'Semanal'
        })))
      );

    const modalRef = this.modal.show(SearchItemsModalComponent, {
      initialState: {
        items$: recurrentTasks$,
        fields: [
          {
            title: 'Nombre',
            column: 'title'
          },
          {
            title: 'Frecuencia',
            column: 'frequencyLabel'
          }
        ],
        config: {
          hasSearch: false,
          notFoundText: 'No hay resultados en la búsqueda',
          title: 'Lista de tareas periódicas'
        }
      },
      class: 'modal-md shadow-lg mt-5',
      id: 100
    });

    await modalRef.onHide.pipe(first()).toPromise();

    if (!modalRef.content.itemSelected) return;

    if (!(await AlertService.confirm(`¿Está seguro de eliminar la tarea periódica "${modalRef.content.itemSelected.title}"?`))) return;

    this._kanban.updateTask({
      isRecurrent: false
    } as any, modalRef.content.itemSelected.key, this.boardKey);

    AlertService.toastSuccess('Tarea periódica eliminada exitosamente');
  }

  private getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project.map((project) => ({
            ...project,
            type:
              project.type == CostCenterType.PROJECT
                ? 'Proyectos'
                : 'Centro de Costos'
          }))
        )
      )
      .subscribe(async (data) => {
        this.selectArray = data;
      });
  }

  convertToTime(date) {
    if (date == null) return null;

    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }


}
