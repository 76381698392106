import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddWarehouseComponent } from './modals/add-warehouse/add-warehouse.component';
import { Observable, of } from 'rxjs';
import { WarehouseService } from '../../../shared/services/warehouse.service';
import { Warehouse } from './interfaces/warehouse';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { UpdateWarehouseComponent } from './modals/update-warehouse/update-warehouse.component';
import { TicketService } from '../../../shared/services/ticket.service';
import { Ticket } from './interfaces/ticket';
import { debounceTime, first, map } from 'rxjs/operators';
import { TicketStatus } from './enums/ticket-status.enum';
import { UserService } from '../../../shared/services/user.service';
import { TicketAuthorizationService } from '../../../shared/services/ticket-authorization.service';
import _ from 'lodash';
import { PermissionService } from './services/permission.service';
import { Permission } from '../../enums/permissions.enum';
import { DocumentReference } from '@angular/fire/firestore';
import { warehouseTypeEnum } from './interfaces/warehouseTypeEnum';

@Component({
  selector: 'app-warehouses',
  templateUrl: './warehouses.component.html',
  styleUrls: ['./warehouses.component.css']
})
export class WarehousesComponent implements OnInit {
  warehouses$: Observable<Warehouse[]>;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron bodegas',
    title: ''
  };
  ticketsAuthorized: Observable<Ticket[]>;
  ticketsGenerated: Observable<Ticket[]>;
  ticketStatus = TicketStatus;
  tabIndex: number = 3;
  permission: Permission;
  permissionEnum = Permission;
  currentUser: any;
  warehouses: Warehouse[] = [];
  filteredWarehouses: Warehouse[] = [];
  typeFilter: warehouseTypeEnum = null;
  typeEnum = warehouseTypeEnum;

  constructor(private modal: BsModalService,
    private _warehouse: WarehouseService,
    private _ticketAuthorized: TicketAuthorizationService,
    private router: Router,
    private _user: UserService,
    private _ticket: TicketService,
    private _permission: PermissionService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._permission.resetUserType();
    const { permission } = this._user.user.permissions.find((permission) => permission.section == 'BODEGAS');

    this._permission.setUserType(permission);
    this.permission = permission;

    this.loadWarehouses();
    this.route.params.subscribe((params) => {
      params.tab === 'users' ? this.tabIndex = 3 : this.tabIndex = 0;
    });
  }

  loadWarehouses(): void {
    this._warehouse.getAll().subscribe((data) => {
      data = this.getWarehousesAssigned(data);

      this.warehouses = data;
      this.warehouses$ = of(data);

      this.ticketsAuthorized = this._ticket.getAllByWarehouses().pipe(
        map((tickets) =>
          tickets.filter(
            (ticket) =>
              this.validateGrocersWarehouses(ticket.warehouse.id) &&
              ticket.status == this.ticketStatus.AUTHORIZED
          )
        ),
        map((tickets) => _.orderBy(tickets, 'lastUpdate', 'desc'))
      );

      this.ticketsGenerated = this._ticket.getAllByWarehouses().pipe(
        map((tickets) =>
          tickets.filter(
            (ticket) =>
              this.validateWarehouseUser(ticket.warehouse.id) &&
              ticket.status == this.ticketStatus.GENERATED
          )
        ),
        map((tickets) => _.orderBy(tickets, 'lastUpdate', 'desc'))
      );
      this.filterWarehouses();
    });
  }

  getWarehousesAssigned(warehouses: Warehouse[]) {
    return warehouses.filter((warehouse) => {

      switch (this.permission) {
        case Permission.ADMIN:
          return true;

        case Permission.GROCER:
          return this.isAssignedToGrocer(warehouse) || this.isAssignedToBuyer(warehouse) || this.isAssignedToUser(warehouse);

        case Permission.SUPERVISOR:
          return this.isAssignedToSupervisor(warehouse) || this.isAssignedToUser(warehouse);

        case Permission.BUYER:
          return this.isAssignedToBuyer(warehouse) || this.isAssignedToGrocer(warehouse) || this.isAssignedToUser(warehouse);

        case Permission.USER:
          return this.isAssignedToUser(warehouse) || this.isAssignedToGrocer(warehouse);

        default:
          return false;
      }
    });
  }

  isAssignedToGrocer(warehouse: Warehouse) {
    const grocer = warehouse.grocer
      ? warehouse?.grocer?.path
        ? warehouse.grocer.id
        : warehouse.grocer.reference.id
      : null;

    return grocer == this._user.user.key;
  }

  isAssignedToSupervisor(warehouse: Warehouse) {
    return warehouse.warehouseManager.some(
      (manager) => manager.reference.id == this._user.user.key
    );
  }

  isAssignedToBuyer(warehouse: Warehouse) {
    return (<DocumentReference>warehouse.buyer)?.id == this._user.user.key;
  }

  isAssignedToUser(warehouse: Warehouse) {
    if (!warehouse.users) {
      return false;
    }

    return warehouse.users.some(
      (user) => user.reference.id == this._user.user.key
    );
  }

  validateWarehouseUser(warehouseKey: string) {
    let warehouse = this.warehouses.find(
      (warehouse) => warehouse.key == warehouseKey
    );
    if (!warehouse) {
      return false;
    }
    return (
      (!!warehouse.warehouseManager &&
        warehouse.warehouseManager.some(
          (manager) => manager.reference.id == this._user.user.key
        )) ||
      this.permission == Permission.ADMIN
    );
  }

  validateGrocersWarehouses(warehouseKey: string) {
    let warehouse = this.warehouses.find(
      (warehouse) => warehouse.key == warehouseKey
    );
    if (!warehouse) {
      return false;
    }
    return (
      (!!warehouse.warehouseManager &&
        warehouse.grocer?.reference?.id == this._user.user.key) ||
      this.permission == Permission.ADMIN
    );
  }

  openAddWarehousesModal() {
    this.modal.show(AddWarehouseComponent);
  }

  gotoWarehouseDetails(warehouseKey?: string) {
    this.router.navigateByUrl(`admin/warehouseDetails/${warehouseKey}`);
  }

  updateWarehouse(warehouse, warehouseKey) {
    this.modal.show(UpdateWarehouseComponent, {
      initialState: {
        warehouse: { ...warehouse },
        warehouseKey: warehouseKey
      },
      backdrop: 'static',
      keyboard: false
    });
  }

  goToUserDetails(userKey: string): void {
    this.router.navigateByUrl(`admin/user-details/${userKey}`);
  }

  handleFilterChange(event: any) {
    this.typeFilter = event;
    this.filterWarehouses();
  }

  async filterWarehouses() {
    const allWarehouses = await this.warehouses$.pipe(debounceTime(300), first()).toPromise();

    this.filteredWarehouses =
      this.typeFilter == null
        ? allWarehouses
        : allWarehouses.filter((warehouse) => warehouse.type == this.typeFilter);
  }
}
