<div class="chat-application chat-window-wrapper">
  <div class="card chat-wrapper shadow-none mb-0 bg-transparent">
    <div class="card-content">
      <div class="card-body chat-container" id="test" fxFlex="auto">
        <div class="chat-content">
          <div class="chat chat-left" #messages
               *ngFor="let comment of comments">
            <div class="chat-avatar">
              <a [routerLink]="" class="avatar m-0">
                <img [src]="(comment.user | doc | async)?.imageUrl"/>
              </a>
            </div>
            <div class="chat-body ml-2">
              <div class="chat-message">
                <p>{{comment.comment}}</p>
                <span class="chat-time">{{comment.date | date : 'dd/MM/yyyy HH:mm'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer chat-footer px-2 py-1 pb-0">
    <form class="d-flex align-items-center"  [formGroup]="commentForm">
      <input type="text"  formControlName="comment" class="form-control chat-message-send mx-1"
             id="iconLeft4"
             placeholder="Escriba un comentario aquí....">
      <button type="submit" class="btn btn-primary glow send d-lg-flex" (click)="submitComment()"><i
        class="ficon feather ft-send"></i>
        <span class="d-none d-lg-block mx-50 ml-1">Enviar</span></button>
    </form>
  </div>
</div>
