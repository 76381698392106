import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-float-button',
  template: `
    <button
      class="btn btn-primary btn-float-right add-btn"
      app-tooltip="Agregar"
      (click)="clickEmmiter.emit()"
      [ngStyle]="setMyStyles()"
    >
      <i class="fa fa-plus"></i>
    </button>
  `,
  styleUrls: ['./add-float-button.component.scss']
})
export class AddFloatButtonComponent implements OnInit {
  @Output() clickEmmiter: EventEmitter<void> = new EventEmitter();
  @Input() bottom: number;
  @Input() right: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  setMyStyles() {
    return {
      ...this.bottom && { 'bottom': this.bottom + 'px' },
      ...this.right && { 'right': this.right + 'px' }
    };
  }
}
