<ng-container *ngIf="!!purchaseOrder">
  <div class="d-flex justify-content-between ml-2 mr-2">
    <app-provider-info-view [providerInfo]="provider"></app-provider-info-view>
    <div
      class="col-md-4 col-12 text-center text-md-right"
      *ngIf="!!purchaseOrder.provider"
    >
      <h2 class="font-weight-bold">Orden de compra</h2>
      <h3 class="font-weight-bold">
        OC: {{ purchaseOrder.purchaseID | slice: 0:-3 }}
      </h3>
      <h3>Fecha de creación: {{ purchaseOrder.date | date: 'dd/MM/yyyy' }}</h3>
      <h3>Proyecto: {{ (this.purchaseOrder.project | doc | async)?.name }}</h3>
      <h3>Proveedor: {{ (purchaseOrder.provider | doc | async)?.name }}</h3>
      <h3>Categoría: {{ (purchaseOrder.budget | doc | async)?.name }}</h3>
      <h3>Descripción</h3>
      <p>{{ description ? description : 'Sin descripción' }}</p>
      <div *ngIf="categoryIsExams">
        <button class="btn btn-info btn-sm mb-1" (click)="openDescriptionForm()">Modificar descripción</button>
      </div>
      <h3>Condiciones comerciales:</h3>
      <p>
        {{
        bussinessConditions
          ? bussinessConditions
          : 'Sin condiciones comerciales'
        }}
      </p>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Artículos">
      <app-articles-purchase-view
        [articlesPurchaseEdit]="articlesPurchase"
        [date]="purchaseOrder.date"
        [deliveryReceiver]="purchaseOrder.deliveryDate"
        [approvalDateReceiver]="purchaseOrder.approvalDate"
        [ticketStatus]="purchaseOrder.status"
      ></app-articles-purchase-view>

      <div class="row" *ngIf="purchaseOrder.status >= purchaseStatus.GENERATED">
        <div class="col-12 d-flex justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <app-upload-file-storage
              *ngIf="!_user.user.signature"
              routeFile="users"
              extension="png"
              fileType="image/*"
              [showPreview]="false"
              errorLabel="Es obligatorio agregar la firma"
              label="Firma"
            ></app-upload-file-storage>

            <div class="text-center" *ngIf="!!purchaseOrder.supervisor">
              <p class="mb-0 font-weight-bold">
                ________________________________________________________
              </p>
              <p class="mb-0 font-weight-bold">
                FIRMA AUTORIZADA POR:
                {{ (purchaseOrder.supervisor | doc | async)?.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Cotización" *ngIf="quotation">
      <div class="col-lg-9 col-md-6 col-sm-12 center-layout center mt-1">
        <ng-container
          *ngIf="
            permission == permissionEnum.SUPERVISOR ||
            permission == permissionEnum.ADMIN
          "
        >
          <app-upload-file-storage
            label="Actualizar cotización"
            extension="pdf"
            errorLabel="La cotización es obligatoria"
            controlName="quotation"
            (isImageLoaded)="quotationLoaded()"
            [showPreview]="false"
          ></app-upload-file-storage>
          <button class="btn btn-success float-right" (click)="updateQuotation()">
            <i class="feather ft-refresh-ccw"></i>
          </button>
        </ng-container>
        <div class="card shadow-none">
          <div class="card-body pt-2">
            <div class="write-post">
              <div class="col-sm-12 px-2">
                <div *ngIf="isPdf" [innerHTML]="url"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Comentarios">
      <app-purchase-comments
        [purchaseOrder]="purchaseOrder"
      ></app-purchase-comments>
    </mat-tab>

    <mat-tab label="Registros">
      <app-logs [logs$]="logs$"></app-logs>
    </mat-tab>
  </mat-tab-group>

  <div class="d-flex justify-content-end mt-2 mr-2 mb-2" id="purchase-buttons-node">
    <button
      *ngIf="purchaseOrder.status != purchaseStatus.GENERATED ||purchaseOrder.status != purchaseStatus.UNDER_APPROVAL"
      class="btn btn-primary mr-2"
      (click)="cancelPurchase()"
    >
      Cancelar compra
    </button>
    <button
      class="btn btn-primary mr-2"
      *ngIf=" (permission != permissionEnum.USER ) "
      (click)="changeToDraft()"
    >
      Pasar a borrador
    </button>
    <button
      class="btn btn-success cursor-pointer mr-2"
      *ngIf="
        permission == permissionEnum.FINANCE  && (purchaseOrder.status == purchaseStatus.UNDER_REVIEW || purchaseOrder.status == purchaseStatus.WAITING_FOR_REVIEW)
      "
      (click)="askForAuthorization()"
    >
      Pedir autorización
    </button>
    <button
      class="btn btn-success cursor-pointer mr-2"
      *ngIf="
        (permission == permissionEnum.SUPERVISOR || permission == permissionEnum.ADMIN) &&
        ( purchaseOrder.status == purchaseStatus.UNDER_APPROVAL || purchaseOrder.status == purchaseStatus.GENERATED)
      "
      (click)="checkBudget()"
    >
      Autorizar
    </button>
    <button class="btn btn-primary mr-2" (click)="close()">Cerrar</button>
  </div>


</ng-container>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">{{ spinnerMessage }}</p></ngx-spinner>
