import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Article } from '../../admin/pages/warehouses/interfaces/article';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, take } from 'rxjs/operators';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  id: string;
  public sizes: any = {
    general: [
      { name: 'XS', value: 'xs' },
      { name: 'S', value: 's' },
      { name: 'M', value: 'm' },
      { name: 'L', value: 'l' },
      { name: 'XL', value: 'xl' },
      { name: 'XXL', value: 'xxl' }
    ],
    shoes: [
      { name: '36', value: '36' },
      { name: '37', value: '37' },
      { name: '38', value: '38' },
      { name: '39', value: '39' },
      { name: '40', value: '40' },
      { name: '41', value: '41' },
      { name: '42', value: '42' },
      { name: '43', value: '43' },
      { name: '44', value: '44' },
      { name: '45', value: '45' }
    ]
  };

  constructor(private db: FirebaseDataService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage) {
  }

  getAll(): Observable<Article[]> {
    return this.afs.collectionGroup<Article>(`articles`, ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a: any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
          warehouseKey: a.payload.doc.ref.parent.parent.id
        };
      })));
  }

  getAllByWarehouse(warehouseKey: string): Observable<Article[]> {
    return this.afs.collection<Article>(`warehouses/${warehouseKey}/articles`, ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a: any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
          warehouseKey: a.payload.doc.ref.parent.parent.id
        };
      })));
  }

  async add(warehouseKey: any, article: any, articleKey?: string): Promise<any> {
    if (articleKey) {
      await this.afs.doc(`warehouses/${warehouseKey}/articles/${articleKey}`).set(article, { merge: true });
    } else {
      await this.afs.collection<Article>(`warehouses/${warehouseKey}/articles`).add(article);
    }
  }

  async delete(warehouseKey: string, articlesKey: string): Promise<void> {
    return await this.afs.doc<Article>(`warehouses/${warehouseKey}/articles/${articlesKey}`).update({ trash: true });
  }

  async updateQuantity(warehouseKey: string, articlesKey: string, quantity: number): Promise<void> {
    return await this.afs.doc<Article>(`warehouses/${warehouseKey}/articles/${articlesKey}`).update({ quantity: quantity >= 0 ? quantity : 0 });
  }

  async incrementQuantity(warehouseKey: string, articlesKey: string, quantity: number): Promise<void> {
    return await this.afs.doc(`warehouses/${warehouseKey}/articles/${articlesKey}`).update({ quantity: firebase.firestore.FieldValue.increment(quantity) });
  }

  update(warehouseKey: any, articleKey: any, article: any) {
    return this.afs.doc(`warehouses/${warehouseKey}/articles/${articleKey}`).update(article);
  }

  async uploadPicture(productPhoto, productKey) {
    if (productKey == null) {
      this.id = this.afs.createId();
    } else {
      this.id = productKey;
    }
    const uploadRef = this.getStorageRef(this.id);
    await uploadRef.put(productPhoto);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(productPhoto, this.id);

    return url;
  }

  uploadImage(data, id) {
    return this.storage.upload(`article/${id}/articlePicture.jpeg`, data);
  }

  getStorageRef(id) {
    return this.storage.ref(`article/${id}/articlePicture.jpeg`);
  }

  get(warehouseKey: string, articleKey: string) {
    return this.afs.doc(`warehouses/${warehouseKey}/articles/${articleKey}`).valueChanges();
  }

  getFromPath(path: string): Observable<Article> {
    return this.afs.doc(path).valueChanges() as Observable<Article>;
  }

  getReference(warehouseKey: string, articleKey: string) {
    return this.afs.doc(`warehouses/${warehouseKey}/articles/${articleKey}`).ref;
  }

  async checkIfExist(warehouseKey: string, articleKey: string) {
    const docRef = this.afs.doc(`warehouses/${warehouseKey}/articles/${articleKey}`);
    const docSnapshot = await docRef.get().toPromise();
    return docSnapshot.exists;
  }
}
