import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArticlesTransferService } from '../../../../services/articles-transfer.service';

@Component({
  selector: 'app-article-quantity-form',
  templateUrl: './article-quantity-form.component.html',
  styleUrls: ['./article-quantity-form.component.css']
})
export class ArticleQuantityFormComponent implements OnInit {
  @Input() originArticle: { articleId: string, warehouseId: string };
  @Input() destinationArticle: { articleId: string, warehouseId: string };
  @Input() isOrigin: boolean = true;

  quantity: string = '';
  modalId = 667;

  constructor(protected modal: BsModalService,
    private _transfer: ArticlesTransferService) {
  }

  ngOnInit(): void {
  }

  hideModal() {
    this.modal.hide(this.modalId);
  }

  handleUpdateItemQuantity() {
    if (!this.quantity || +this.quantity < 0) {
      return this.hideModal();
    }

    if (this.isOrigin) {
      if (this._transfer.moveArticle(this.originArticle, this.destinationArticle, +this.quantity)) {
        this.modal.hide(this.modalId);
      }
    } else {
      this._transfer.updateArticleQuantity(this.destinationArticle.warehouseId, this.destinationArticle.articleId, +this.quantity);
      this.modal.hide(this.modalId);
    }
  }
}
