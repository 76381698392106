export const Sections = [
  'USUARIOS',
  'BODEGAS',
  'PROYECTOS',
  'INICIO',
  'POSTULANTES',
  'CATEGORÍAS',
  'CONFIGURACIÓN',
  'PROVEEDORES',
  'COMPRAS',
  'TESORERÍA',
  'TAREAS',
  'VENTAS',
  'FONDOS POR RENDIR',
  'DESVIACIONES',
  'FLOTA',
  'GESTIÓN DE PERSONAS',
  'BLOG',
  'CAPACITACIÓN',
  'DOCUMENTOS'
];
