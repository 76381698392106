<mat-tab-group>
  <mat-tab label="Compra">
    <div class="p-2">
      <div class="row">
        <div class="col-sm-6">
          <app-upload-file-storage
            label="Cotización"
            extension="pdf"
            errorLabel="La cotización es obligatoria"
            controlName="quotation"
            [showPreview]="false"
            (pathEmitter)="setCurrentFileUrl($event)"
          ></app-upload-file-storage>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Número cotización <span class="required">*</span></label>
            <input
              type="text"
              [(ngModel)]="quotationNumber"
              class="form-control"
            >
          </div>
        </div>
      </div>

      <app-provider-info
        (eventChangeProviderForm)="getProviderForm($event)"
        (budgetsEmitter)="getBudgets($event)"
        [providerInfo]="this.provider"
      ></app-provider-info>

      <div class="col-md-6 pl-0">
        <div class="form-group">
          <label>Descripción*</label>
          <textarea
            rows="3"
            [(ngModel)]="description"
            type="text"
            class="form-control"
          >
          </textarea>
        </div>
      </div>

      <app-articles-purchase
        (totalEmitter)="getTotal($event)"
        (eventChangeArticlesPurchaseForm)="getArticlesPurchaseForm($event)"
        (eventChangeDeliveryDate)="getDeliveryDate($event)"
        [deliveryDate]="deliveryDate"
        [articlesPurchaseEdit]="this.articlesPurchase"
      >
      </app-articles-purchase>


      <div class="row mt-2">
        <div class="col-md-12">
          <div class="form-group">
            <label>Condiciones comerciales</label>
            <textarea
              rows="3"
              [(ngModel)]="bussinessConditions"
              type="text"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2" id="purchase-buttons-node">
        <button
          class="btn btn-primary mr-2"
          (click)="addPurchase(isCreatePurchaseOrder)"
          [disabled]="loading"
          *ngIf="isCreatePurchaseOrder; else isCreated"
        >
          Añadir borrador
        </button>
        <ng-template #isCreated>
          <ng-container *ngIf="purchaseOrderTotal >= financeThreshold; else askForAuthorization">
            <button
              class="btn btn-success mr-2"
              [ngClass]="{ 'btn-success': passBudget, 'btn-dark': !passBudget }"
              (click)="addPurchase(isCreatePurchaseOrder)"
              [disabled]="!passBudget"
              *ngIf="!isCreatePurchaseOrder"
            >
              {{ passBudget ? 'Solicitar revisión' : 'No hay suficiente presupuesto' }}
            </button>
          </ng-container>

          <ng-template #askForAuthorization>
            <button
              class="btn btn-info mr-2"
              (click)="addPurchase(isCreatePurchaseOrder,true)"
            >
              Solicitar autorización
            </button>
          </ng-template>
        </ng-template>

        <button class="btn btn-primary mr-2" (click)="close()">Cerrar</button>
      </div>

      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
        type="square-jelly-box"
      ><p style="color: white"> Subiendo... </p></ngx-spinner>
    </div>

  </mat-tab>

  <mat-tab label="Cotización" *ngIf="quotation&&this.url">
    <div class="col-lg-9 col-md-6 col-sm-12 center-layout center">
      <div class="card shadow-none">
        <div class="card-body pt-2">
          <div class="write-post">
            <div class="col-sm-12 px-2">
              <div *ngIf="isPdf" [innerHTML]="url"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
