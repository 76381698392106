<div class="d-flex flex-column justify-content-center align-items-center " [formGroup]="settingsForm">
  <div class="form-group">
    <label>Ticket ID <span class="required">*</span></label>
    <input
      type="number"
      formControlName="ticketID"
      class="form-control"
      placeholder="Ticket ID"
      [ngClass]="{ 'is-invalid': formSubmitted && formControls.ticketID.errors }"
    >
    <div *ngIf="formSubmitted && formControls.ticketID.errors" class="invalid-feedback">
      Es obligatorio
    </div>
  </div>

  <div class="form-group mt-2">
    <label>Orden de compra ID <span class="required">*</span></label>
    <input
      type="number"
      formControlName="purchaseID"
      class="form-control"
      placeholder="Orden de compra ID"
      [ngClass]="{ 'is-invalid': formSubmitted && formControls.purchaseID.errors }"
    >
    <div *ngIf="formSubmitted && formControls.purchaseID.errors" class="invalid-feedback">
      Es obligatorio
    </div>
  </div>

  <div class="form-group mt-2">
    <label>Proyecto ID <span class="required">*</span></label>
    <input
      type="number"
      formControlName="projectID"
      class="form-control"
      placeholder="Proyecto ID"
      [ngClass]="{ 'is-invalid': formSubmitted && formControls.projectID.errors }"
    >
    <div *ngIf="formSubmitted && formControls.projectID.errors" class="invalid-feedback">
      Es obligatorio
    </div>
  </div>

  <div class="form-group mt-2">
    <label>Solicitar revisión a partir de los: <span class="required">*</span></label>
    <input
      type="number"
      formControlName="financeProfileThreshold"
      class="form-control"
      placeholder="Umbral para perfil de finanzas"
      [ngClass]="{ 'is-invalid': formSubmitted && formControls.financeProfileThreshold.errors }"
    >
    <div *ngIf="formSubmitted && formControls.financeProfileThreshold.errors" class="invalid-feedback">
      Es obligatorio
    </div>
  </div>

  <div class="form-group mt-2">
    <label>Texto por defecto de condiciones comerciales: <span class="required">*</span></label>
    <input
      formControlName="comercialConditions"
      class="form-control"
      placeholder="Condiciones comerciales"
      [ngClass]="{ 'is-invalid': formSubmitted && formControls.financeProfileThreshold.errors }"
    >
    <div *ngIf="formSubmitted && formControls.financeProfileThreshold.errors" class="invalid-feedback">
      Es obligatorio
    </div>
  </div>

  <button class="btn btn-success mt-2" (click)="submit()">Guardar Cambios</button>
</div>
