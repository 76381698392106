import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BillService } from '../../../shared/services/bill.service';
import { Bill } from '../../interfaces/bill';
import { take } from 'rxjs/operators';
import moment from 'moment';
import { CurrencyPipe, formatDate } from '@angular/common';
import { DataTableConfig } from 'src/app/shared/interfaces/data-table-config';
import * as Chart from 'chart.js';
import { BillModalComponent } from '../../modals/bill-modal/bill-modal.component';
import { SwapCommasAndDotsPipe } from '../../pipes/swap-commas-and-dots.pipe';
import _ from 'lodash';
import { ObjectService } from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {
  @ViewChild('billsCanvas') private billsCanvas: ElementRef;

  months = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE'
  ];
  config: DataTableConfig = {
    title: 'Facturas',
    notFoundText: 'No se encontraron facturas en este rango de fechas',
    hasSearch: true
  };
  dateFilterType = {
    BILLS_ISSUED: 0,
    OVERDUE_BILLS: 1,
    BILLS_TO_PAY: 2
  };
  configPurchase: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron facturas',
    title: 'Facturas'
  };
  billsFilter: any [] = [{
    label: 'Facturas emitidas',
    value: this.dateFilterType.BILLS_ISSUED
  },
    {
      label: 'Facturas por pagar',
      value: this.dateFilterType.BILLS_TO_PAY
    },
    {
      label: 'Facturas vencidas',
      value: this.dateFilterType.OVERDUE_BILLS
    }];
  billsChart: any;
  year = new Date().getFullYear();

  bills: any[];
  allBills: Bill[];
  load: boolean = true;

  providers: any[];
  costCenters: any[];
  dateSelected;
  dateRangeTypeSelected;
  dateRangeType = {
    DAILY: 0,
    WEEKLY: 1,
    MONTHLY: 2
  };
  years: string[] = [];
  startDate = null;
  finalDate = null;
  momentUnitOfTime: any[] = ['month', 'month', 'y', 'day'];
  monthSelected;
  finalDayOfMonth;
  filterBills;
  totalDaysBetweenDates;
  dataChart = [];
  labelChart = [];
  dateFilterTypeSelected;
  bills$: Observable<Bill[]>;
  barChartOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return this.swapCommasAndDots.transform(
            this.currency.transform(tooltipItem.yLabel, 'USD')
          );
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: (label: number, index, labels) => {
            return this.swapCommasAndDots.transform(
              this.currency.transform(label, 'USD')
            );
          }
        }
      }]
    }
  };

  billSubscription: Subscription = new Subscription();
  total: number = 0;

  indexedBills: any;
  constructor(
    private modal: BsModalService,
    private _bill: BillService,
    private currency: CurrencyPipe,
    private swapCommasAndDots: SwapCommasAndDotsPipe) {
  }

  async ngOnInit() {
    this.years = this.getYears();
    this.dateRangeTypeSelected = this.dateRangeType.DAILY;
    this.dateFilterTypeSelected = this.dateFilterType.BILLS_ISSUED;
    const date = new Date(
      `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`
    );
    this.startDate = this.formatDate(moment(date));
    this.finalDate = this.formatDate(moment(date).add(1, 'month').toDate());

    await this.getBills();
    await this.handleDateRangeTypeSelected();

    this.validateBillsByDateRange();
  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
  }

  getRut(provider) {
    if (!provider) return '-';

    return provider.trim().split(' ')[0];
  }

  getProvider(provider: any): any {
    if (!provider) return '-';

    return provider.trim().split(' ').slice(1).join(' ');
  }

  lineStackedChartMethod() {
    if (this.billsChart) this.billsChart.destroy();

    if (this.validateDateRangeTypeSelected(this.dateRangeType.MONTHLY)) {
      this.billsChart = new Chart(this.billsCanvas.nativeElement, {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              label: 'Total de las facturas por mes',
              data: this.dataChart,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              borderColor: 'rgb(75, 192, 192)'
            }
          ]
        },
        options: {
          ...this.barChartOptions
        }
      });
    }

    if (this.validateDateRangeTypeSelected(this.dateRangeType.WEEKLY)) {
      this.billsChart = new Chart(this.billsCanvas.nativeElement, {
        type: 'bar',
        data: {
          labels: [
            `1-5  ${this.monthSelected}`,
            `6-12  ${this.monthSelected}`,
            `13-22  ${this.monthSelected}`,
            `21-${this.finalDayOfMonth} ${this.monthSelected}`
          ],
          datasets: [
            {
              label: 'Total de las facturas por Semanas',
              data: this.dataChart,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              borderColor: 'rgb(75, 192, 192)'
            }
          ]
        },
        options: {
          ...this.barChartOptions
        }
      });
    }

    if (this.validateDateRangeTypeSelected(this.dateRangeType.DAILY)) {
      this.billsChart = new Chart(this.billsCanvas.nativeElement, {
        type: 'bar',
        data: {
          labels: this.labelChart,
          datasets: [{
            label: 'Total de las facturas por dia',
            data: this.dataChart,
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            borderColor: 'rgb(75, 192, 192)'
          }]
        },
        options: {
          ...this.barChartOptions
        }
      });
    }
  }

  getYears(): string[] {
    const yearsLength: number = moment(`${moment().format('yyyy')}`).diff(
      moment('2020'),
      'y'
    );
    let years = ['2020'];
    for (let i = 1; i <= yearsLength; i++) {
      years = [moment('2020').add(i, 'y').format('yyyy').toString(), ...years];
    }
    return years;
  }

  async handleDateRangeTypeSelected() {
    if (this.validateDateRangeTypeSelected(this.dateRangeType.WEEKLY)) {
      this.dateSelected = moment().format('yyyy-MM');
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.DAILY)) {
      this.dateSelected = moment().format('yyyy-MM');
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.MONTHLY)) {
      this.dateSelected = this.years[0];
    }
  }

  async handleDateFilterTypeSelected() {
    if (this.validateDateFilterTypeSelected(this.dateFilterType.BILLS_ISSUED)) {
      this.filterBills = this.allBills?.filter((bill) => {
        return moment(bill.startDate).isBetween(
          moment(this.startDate),
          moment(this.finalDate));
      });
    } else if (this.validateDateFilterTypeSelected(this.dateFilterType.BILLS_TO_PAY)) {
      this.filterBills = this.allBills?.filter((bill) => {
        return moment(bill.startDate).isBetween(
          moment(this.startDate),
          moment(this.finalDate)) && bill.isPaid == false;
      });
    } else if (this.validateDateFilterTypeSelected(this.dateFilterType.OVERDUE_BILLS)) {
      this.filterBills = this.allBills?.filter((bill) => {
        return moment(bill.realPaymentDate).isBetween(
          moment(this.startDate),
          moment(this.finalDate)) && bill.isPaid == false;
      });
    }
    await this.assignStartDateAndFinalDate();
  }

  validateDateRangeTypeSelected(expectedDateRangeType: number) {
    return this.dateRangeTypeSelected == expectedDateRangeType;
  }

  validateDateFilterTypeSelected(expectedDateRangeType: number) {
    return this.dateFilterTypeSelected == expectedDateRangeType;
  }

  assignStartDateAndFinalDate() {
    this.startDate = moment(this.dateSelected)
      .startOf(this.momentUnitOfTime[this.dateRangeTypeSelected])
      .toDate();
    this.finalDate = moment(this.dateSelected)
      .endOf(this.momentUnitOfTime[this.dateRangeTypeSelected])
      .toDate();
  }

  async getBills() {
    this.allBills = await this._bill.getAll().pipe(take(1)).toPromise();
    this.indexedBills = ObjectService.indexArray(this.allBills, 'key');
    this.allBills.map(bill => bill.total = Number(String(bill.total).replace(/[.]/g, '')));
  }

  async validateBillsByDateRange() {
    if (this.allBills?.length == 0) {
      return;
    } else {
      await this.assignChartData();
    }
  }

  async assignChartData() {
    this.dataChart = [];
    this.labelChart = [];
    await this.handleDateFilterTypeSelected();

    if (this.validateDateRangeTypeSelected(this.dateRangeType.WEEKLY)) {
      this.monthSelected = this.months[moment(this.dateSelected).month()];

      this.dataChart[0] = this.filterBills?.filter((bill) => {
        return moment(bill.startDate).isBetween(
          moment(this.startDate),
          moment(this.startDate).add(5, 'day')
        );
      }).reduce((acc, el) => acc + el.total, 0);

      this.dataChart[1] = this.filterBills?.filter((bill) => {
        return moment(bill.startDate).isBetween(
          moment(this.startDate).add(6, 'day'),
          moment(this.startDate).add(12, 'day')
        );
      }).reduce((acc, el) => acc + el.total, 0);

      this.dataChart[2] = this.filterBills?.filter((bill) => {
        return moment(bill.startDate).isBetween(
          moment(this.startDate).add(13, 'day'),
          moment(this.startDate).add(20, 'day')
        );
      }).reduce((acc, el) => acc + el.total, 0);

      this.dataChart[3] = this.filterBills?.filter((bill) => {
        return moment(bill.startDate).isBetween(
          moment(this.startDate).add(21, 'day'),
          moment(this.finalDate)
        );
      }).reduce((acc, el) => acc + el.total, 0);

      this.finalDayOfMonth = moment(this.finalDate).format('DD');
    }

    if (this.validateDateRangeTypeSelected(this.dateRangeType.MONTHLY)) {
      const months = 12;
      for (let i = 0; i <= months; i++) {
        this.dataChart[i] = this.filterBills?.filter((bill) => {
          return moment(bill.startDate).isBetween(
            moment(this.startDate).add(i, 'month'),
            moment(this.finalDate).subtract(11 - i, 'month')
          );
        }).reduce((acc, el) => acc + el.total, 0);
      }
    }

    if (this.validateDateRangeTypeSelected(this.dateRangeType.DAILY)) {
      this.monthSelected = this.months[moment(this.startDate).month()];
      this.totalDaysBetweenDates = moment(this.finalDate).diff(this.startDate, 'days') + 1;

      for (let i = 0; i < this.totalDaysBetweenDates; i++) {
        this.dataChart[i] = this.filterBills
          ?.filter((bill) => Number(
            moment(
              this.validateDateFilterTypeSelected(this.dateFilterType.OVERDUE_BILLS)
                ? bill.realPaymentDate
                : bill.startDate
            ).format('DD')) == i + 1)
          .reduce((acc, el) => acc + el.total, 0);
        this.labelChart[i] = i + 1;
      }
    }

    this.filterBills = _.orderBy(this.filterBills, ['startDate'], ['desc']);

    this.total = this.filterBills?.reduce((acc, el) => acc + el.total, 0);

    this.bills$ = of(this.filterBills);
    this.lineStackedChartMethod();
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  gotoOpenEditBill(bill) {
    this.modal.show(BillModalComponent, {
      initialState: {
        bill: { ...bill },
        isUpdate: true
      },
      class: 'modal-xl',
      backdrop: 'static'
    });
  }
}
