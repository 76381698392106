<div *ngIf="article;else newArticle">
  <div
    class="flex-1 card article-card"
    cdkDropList
    [cdkDropListData]="dropData"
    cdkDropListSortingDisabled
    (cdkDropListDropped)="drop($event)"
  >
    <span class="mr-1"><strong>{{ article.name }}</strong></span>
    <div class="d-flex">
      <img class="article-image mr-1" [src]="article?.imageUrl||'assets/images/article.svg'"/>
      <div class="w-100">
        <div class="d-flex flex-column w-100">
          <div class="d-flex align-items-center">
            <span><strong>{{ total }} </strong></span>
            <div *ngIf="addedArticles.length" class="d-flex align-items-center ml-1">
              <span class="text-success mr-1">(+{{ added }})</span>
            </div>
          </div>
        </div>
        <div
          *ngIf="!addedArticles.length; else addedArticlesContainer"
          class="text-center text-light"
        >
          <span>Arrastre aquí los artículos</span>
        </div>
        <ng-template #addedArticlesContainer>
          <div class="movements-container">
            <div class="movement" *ngFor="let articleInfo of addedArticles">
              <i
                (click)="removeArticle(articleInfo.article.key, articleInfo.origin.key)"
                class="fa fa-times cursor-pointer"
              ></i>
              <span class="text-success">
            +{{ articleInfo.quantity }}  {{ articleInfo.article.name }} - {{ articleInfo.origin?.name }}
                ({{ articleInfo?.origin?.code }})
          </span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #newArticle>
  <div
    class="flex-1 card article-card"
    cdkDropList
    [cdkDropListData]="dropData"
    cdkDropListSortingDisabled
    (cdkDropListDropped)="drop($event)"
  >
    <div class="d-flex flex-column align-items-center justify-content-center  text-muted">
      <div>
        <i class="fa fa-plus"></i>
        <span> Nuevo artículo</span>
      </div>
      <span class="text-light">Arrastre aquí los artículos</span>
    </div>
  </div>
</ng-template>
