<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{label}}</mat-label>
  <mat-select [formControl]="$any(control)" [multiple]="multiple">
    <mat-option
      *ngFor="let option of options"
      [value]="bindValue ? option[bindValue] : option">
      {{bindLabel ? option[bindLabel] : option}}
    </mat-option>
  </mat-select>
</mat-form-field>
