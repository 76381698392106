<div class="row" *ngIf="!!user">
  <div class="col-12">
    <div class="card-img-top img-fluid bg-cover height-100">
      <img
        src="https://htmlcolors.com/color-image/f4f5fa.png"
        class="img-fluid rounded background-img"
        alt="timeline image"
      />

      <div class="media profil-cover-details w-100">
        <div class="media-body pt-3 px-2">
          <div class="row">
            <div class="col-12 text-right d-flex justify-content-end">
              <div class="btn-group float-right ml-2">
                <div
                  class="d-inline-block float-md-right"
                  *ngIf="permission >= permissionEnum.SUPERVISOR"
                  ngbDropdown
                >
                  <button
                    class="btn  mr-1"
                    [ngClass]="{'btn-success': isAltProfile, 'btn-warning': !isAltProfile}"
                    [attr.app-tooltip]="isAltProfile ? 'Ver perfil principal' : 'Ver perfil alternativo'"
                    *ngIf="applicantProfile"
                    (click)="handleAltProfile()"
                  >
                    <i class="fa fa-address-card"></i>
                  </button>
                  <button
                    class="btn btn-warning mr-1"
                    (click)="printInfoUser()"
                  >
                    <i class="feather ft-printer"></i>
                  </button>
                  <button
                    class="btn btn-info"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                  >
                    Opciones
                  </button>
                  <div
                    ngbDropdownMenu
                    class="arrow _dropdown_mob dropdown-menu-right"
                    aria-labelledby="dropdownBasic1"
                  >
                    <button
                      class="dropdown-item"
                      (click)="disabledAccount()"
                      *ngIf="!user.isDisable"
                    >
                      Deshabilitar cuenta
                    </button>
                    <button
                      class="dropdown-item"
                      (click)="enableAccount()"
                      *ngIf="user.isDisable"
                    >
                      Habilitar cuenta
                    </button>
                    <button class="dropdown-item" (click)="changePassword()">
                      Cambiar contraseña
                    </button>
                    <button
                      class="dropdown-item"
                      (click)="askToResetPassword()"
                    >
                      Reestablecer contraseña
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="user-data text-center bg-white rounded pb-2 mb-md-2">
      <img
        [src]="!!user.imageUrl ? user.imageUrl : 'assets/images/default.png'"
        class="img-fluid rounded-circle width-150 height-150 profile-image shadow-lg border border-3 cursor-pointer"
        style="object-fit: cover; object-position: top"
        ngxViewer
        alt="timeline image"
      />
      <div class="custom-file d-none">
        <input
          type="file"
          class="custom-file-input"
          (change)="choosePicture($event)"
          #file
          accept="image/*"
        />
      </div>

      <button
        class="btn btn-success btn-sm position-absolute change-image"
        (click)="file.click()"
      >
        Cambiar imagen
      </button>

      <h4 class="mt-1 mb-0">{{ user.name }} {{user.surnames}}</h4>
      <p class="m-0">{{ user.email }}</p>
    </div>

    <mat-tab-group [(selectedIndex)]="tabIndex">
      <mat-tab label="Resumen">
        <div class="col-lg-12 flex-row d-flex justify-content-center">
          <div class="card shadow-none">
            <h5 class="font-weight-bold mb-0 text-center p-1">
              Información general
              <button
                class="btn btn-facebook btn-sm ml-1"
                (click)="openUpdateUserModal()"
              >
                Editar
              </button>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="card shadow-none">
              <div class="card-body">
                <h5 class="card-title mb-0 font-weight-bold text-center">
                  Información personal
                </h5>
                <hr/>

                <div class="mt-1">
                  <h5 class="card-title font-weight-bold mb-0">Nombre</h5>
                  <p>{{ user.name }} {{ user?.surnames }}</p>
                </div>

                <div class="mt-1">
                  <h5 class="card-title font-weight-bold mb-0">Email</h5>
                  <p>{{ user.email }}</p>
                </div>

                <div class="mt-1" *ngIf="user.personalEmail">
                  <h5 class="card-title font-weight-bold mb-0">Email personal</h5>
                  <p>{{ user.personalEmail }}</p>
                </div>

                <div class="mt-1">
                  <h5 class="card-title font-weight-bold mb-0">Teléfono</h5>
                  <p>{{ user.phone }}</p>
                </div>

                <div class="mt-1">
                  <h5 class="card-title font-weight-bold mb-0">Ciudad</h5>
                  <p>{{ user?.city?.name }}</p>
                </div>

                <div class="mt-1">
                  <h5 class="card-title font-weight-bold mb-0">Edad</h5>
                  <p>{{ userAge }} años</p>
                </div>

                <div class="mt-1">
                  <h5 class="card-title font-weight-bold mb-0">
                    Fecha de nacimiento
                  </h5>
                  <p>{{ userBIrthday }}</p>
                </div>

                <div class="mt-1">
                  <h5 class="card-title font-weight-bold mb-0">Nacionalidad</h5>
                  <p>{{ user.nationality }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="card shadow-none">
              <div class="card-body">
                <h5 class="card-title mb-0 font-weight-bold text-center">
                  Información de la empresa
                </h5>
                <hr/>

                <div class="mt-2">
                  <h5 class="card-title font-weight-bold mb-0">
                    Nivel educacional
                  </h5>
                  <p>{{ user.educationLevel }}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title font-weight-bold mb-0">RUT</h5>
                  <p>{{ user.rut }}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title font-weight-bold mb-0">Cargo</h5>
                  <p>{{ user?.position?.name }}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title font-weight-bold mb-0">
                    Experiencia laboral (años)
                  </h5>
                  <p>{{ user.workExperience }} años</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Documentos">
        <app-documents [user]="user"></app-documents>
      </mat-tab>
      <mat-tab label="Comentarios">
        <div class="chat-application chat-window-wrapper">
          <div class="card chat-wrapper shadow-none mb-0 bg-transparent">
            <div class="card-content">
              <div class="card-body chat-container" id="test" fxFlex="auto">
                <div class="chat-content">
                  <div
                    class="chat chat-left"
                    #messages
                    *ngFor="let comment of comments"
                  >
                    <div class="chat-avatar">
                      <a [routerLink]="" class="avatar m-0">
                        <img [src]="(comment.user | doc | async)?.imageUrl"/>
                      </a>
                    </div>
                    <div class="chat-body ml-2">
                      <div class="chat-message">
                        <p>{{ comment.comment }}</p>
                        <span class="chat-time">{{
                          comment.date | date: 'dd/MM/yyyy HH:mm'
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer chat-footer px-2 py-1 pb-0">
            <form class="d-flex align-items-center" [formGroup]="commentForm">
              <input
                type="text"
                formControlName="comment"
                class="form-control chat-message-send mx-1"
                id="iconLeft4"
                placeholder="Escriba un comentario aquí...."
              />
              <button
                type="submit"
                class="btn btn-primary glow send d-lg-flex"
                (click)="submitComment()"
              >
                <i class="ficon feather ft-send"></i>
                <span class="d-none d-lg-block mx-50 ml-1">Enviar</span>
              </button>
            </form>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        label="Permisos"
        *ngIf="
          permission == permissionEnum.SUPERVISOR ||
          permission == permissionEnum.ADMIN
        "
      >
        <div class="row">
          <div class="col pl-3 pt-2">
            <app-permissions
              [user]="user"
              [userKey]="userKey"
            ></app-permissions>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        label="Registros"
        *ngIf="
          permission == permissionEnum.SUPERVISOR ||
          permission == permissionEnum.ADMIN
        "
      >
        <app-logs [logs$]="logs$"></app-logs>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div *ngIf="isPrinting" #componentID>
  <app-recruitment-sheet
    [user]="user"
  ></app-recruitment-sheet>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">Cargando...</p></ngx-spinner
>
