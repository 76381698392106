<app-datatable
  [rows$]="contracts$"
  [config]="config"
>

  <app-datatable-column title="Tipo de contrato">
    <ng-template
      let-row
      #column
    >
      {{row.contractType}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Proyecto">
    <ng-template
      let-row
      #column
    >
      {{(row.project | doc | async)?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha inicio">
    <ng-template
      let-row
      #column
    >
      {{row.startDate | date: 'dd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha término">
    <ng-template
      let-row
      #column
    >
      {{row.endDate | date: 'dd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>
</app-datatable>

<app-add-float-button
  [bottom]="80"
  [right]="15"
  (clickEmmiter)="openAddContractModal()"
></app-add-float-button>
