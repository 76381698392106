import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Warehouse } from '../../../../interfaces/warehouse';
import { Article } from '../../../../interfaces/article';
import { ArticlesTransferService } from '../../../../services/articles-transfer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-warehouse-container',
  templateUrl: './warehouse-container.component.html',
  styleUrls: ['./warehouse-container.component.css']
})
export class WarehouseContainerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() warehouseId: string;

  warehousesSubscription: Subscription = new Subscription();
  warehouses: any;
  warehouse: Warehouse;
  articles: Article[];
  searchedArticles: Article[] = [];
  searchValue = '';

  constructor(private _transfer: ArticlesTransferService) {
  }

  ngOnInit(): void {
    this.warehousesSubscription = this._transfer.warehouses$.subscribe(warehouses => {
      this.warehouses = warehouses;
      this.warehouse = warehouses[this.warehouseId].warehouse;
      this.articles = warehouses[this.warehouseId].articles;
      this.search();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.warehouses) {
      this.warehouse = this.warehouses[this.warehouseId].warehouse;
      this.articles = this.warehouses[this.warehouseId].articles;
      this.searchValue = '';

      this.search();
    }
  }

  search() {
    this.searchedArticles = this.articles.filter(a => a.name.toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  ngOnDestroy(): void {
    this.warehousesSubscription.unsubscribe();
  }
}
