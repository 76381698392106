import { Component, OnInit } from '@angular/core';
import {Provider} from '../../../shared/interfaces/provider';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProviderService} from '../../../shared/services/provider.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ValidationService} from '../../../shared/template-services/validation.service';

@Component({
  selector: 'app-provider-modal',
  templateUrl: './provider-modal.component.html',
  styleUrls: ['./provider-modal.component.css']
})
export class ProviderModalComponent implements OnInit {
  provider: any = {key: ''} as Provider;
  providerForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _provider: ProviderService,
              private _validation: ValidationService) {
    this.createForm();
  }

  ngOnInit(): void {
    if (!!this.provider.name) {
      this.providerForm.patchValue(this.provider);
    }
  }

  submit() {
    this.submitted = true;
    if (this.providerForm.valid) {
      if (!this.provider.name) this.provider.key = this._provider.createId();

      this.providerForm.patchValue({createdAt: new Date().getTime()});

      this._provider.set(this.provider.key, this.providerForm.value);

      AlertService.toastSuccess('Se guardó el proveedor exitosamente');
      this.modal.hide();
    }
  }

  get formControls() {
    return this.providerForm.controls;
  }

  createForm(): void {
    this.providerForm = this.formBuilder.group({
      lastUpdate: [''],
      nameContact: ['', Validators.required],
      addressContact: ['', Validators.required],
      communeContact: ['', Validators.required],
      regionContact: ['', Validators.required],
      phoneContact: ['', Validators.required],
      emailContact: ['', Validators.required],
      cityContact: ['', Validators.required],
      countryContact: ['', Validators.required],
      faxContact: [''],
      // TODO: temporal remove rut validation
      rut: ['', [Validators.required]],
      // rut: ['', [Validators.required, this._validation.validateRut]],
      name: ['', Validators.required],
      lineOfBusiness: ['', Validators.required],
      address: ['', Validators.required],
      commune: ['', Validators.required],
      region: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      trash: [false]
    });
  }
}
