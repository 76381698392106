import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoginLog } from '../../admin/interfaces/login-log';

@Injectable({
  providedIn: 'root'
})
export class LoginLogService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  addLog(log: LoginLog) {
    this.afs.collection('loginLogs').add(log);
  }
}
