import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User as UserInterface, User } from '../../../../../shared/interfaces/user';
import { Warehouse } from '../../interfaces/warehouse';
import { UserType } from '../../../../enums/user-type.enum';
import { Article } from '../../interfaces/article';
import { Subscription } from 'rxjs';
import { TicketEntryStatusLabel } from '../../labels/ticket-entry-status.label';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArticleService } from '../../../../../shared/services/article.service';
import { UserService } from '../../../../../shared/services/user.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { WarehouseService } from '../../../../../shared/services/warehouse.service';
import { NotificationService } from '../../../../services/notification.service';
import { LogsService } from '../../../../services/logs.service';
import { AuthService } from '../../../../../shared/template-services/auth.service';
import { TicketAuthorizationService } from '../../../../../shared/services/ticket-authorization.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { PrintService } from '../../../../../shared/services/print.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { NotificationType } from '../../../../enums/notification-type.enum';
import { TicketEntryStatus } from '../../enums/ticket-entry-status.enum';
import { TicketEntryService } from '../../services/ticket-entry.service';
import { TicketEntry } from '../../interfaces/ticket-entry';
import { SerialsNumbersComponent } from '../serials-numbers/serials-numbers.component';
import { ArticleType } from '../../enums/article-type.enum';
import { PermissionService } from '../../services/permission.service';
import { TicketStatus } from '../../enums/ticket-status.enum';
import moment from 'moment/moment';

type TicketEmail = {
  text: string,
  emails: string[],
  userKeys: string[],
  subject: string
}

@Component({
  selector: 'app-ticket-entry-details',
  templateUrl: './ticket-entry-details.component.html',
  styleUrls: ['./ticket-entry-details.component.css']
})
export class TicketEntryDetailsComponent implements OnInit {
  @ViewChild('componentID') componentID: ElementRef;
  ticket: TicketEntry;
  unknownWarehouse: boolean;
  user: User;
  warehouse: Warehouse;
  userType = UserType;
  articleReferences: Article[];
  EntryTicketStatus = TicketEntryStatus;
  option = [];
  priceArticle: number = 0;
  totalArticle: number = 0;
  articles = [];
  articleSelected: Article;
  total: number = 0;
  quantity: number = 0;
  article: string = '';
  EntryTicketStatusLabel = TicketEntryStatusLabel;
  areAllConfirm: boolean = false;
  articleQuantityEdit = [];
  permission: UserType;
  permissionEnum = UserType;
  currentUser: any;
  submitted: boolean = false;
  comment: string = '';
  users: UserInterface[];
  projectSubscription: Subscription = new Subscription();
  userSubscription: Subscription = new Subscription();
  userSelected: any;
  categories = [];
  allArticles: Article[];
  articleTypeEnum = ArticleType;
  articleForm: FormGroup;

  protected readonly TicketStatus = TicketStatus;

  constructor(public modal: BsModalService,
    private _article: ArticleService,
    private _ticket: TicketEntryService,
    private _user: UserService,
    private _project: ProjectService,
    private _warehouse: WarehouseService,
    private _notification: NotificationService,
    private _log: LogsService,
    private _auth: AuthService,
    private _ticketAuthorized: TicketAuthorizationService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private SpinnerService: NgxSpinnerService,
    private _permission: PermissionService) {
    this.articleForm = formBuilder.group({
      article: ['', Validators.required],
      quantity: ['0', [Validators.required, Validators.min(1)]],
      reason: ['']
    });
  }

  async ngOnInit() {
    this.allArticles = await this._article
      .getAllByWarehouse(this.ticket.warehouse.id)
      .pipe(take(1))
      .toPromise();
    this.getUsers();
    if (this.ticket.status == TicketEntryStatus.DRAFT) {
      this.comment = this.ticket.comments;
    }

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'BODEGAS'
    ).permission;

    if (this.permission != UserType.BUYER && this._permission.hasUserType(UserType.BUYER)) {
      this.permission = UserType.BUYER;
    }

    if (this.unknownWarehouse) {
      await this.loadWarehouse();
    }

    if (this.permission != this.permissionEnum.ADMIN) {
      let supervisor = false;
      let grocer = false;

      if (this.warehouse.grocer.reference.id == this._user.user.key) {
        grocer = true;
      }
      for (let warehouseManager of this.warehouse.warehouseManager) {
        if (warehouseManager.reference.id == this._user.user.key) {
          supervisor = true;
        }
      }

      if (!supervisor && !grocer) {
        this.permission = this.permissionEnum.USER;
      }
    }

    this.user = this._user.user;
    if (this.ticket.status == TicketEntryStatus.AUTHORIZED) {
      this.ticket.articles = this.ticket.articles.map((article) => ({
        ...article,
        isConfirm: false
      }));
    }

    for (let article of this.ticket.articles) {
      let articleQuantity = {
        key: article.key,
        quantity: article.quantity,
        quantityOrdered: article.quantityOrdered
      };
      this.articleQuantityEdit.push(articleQuantity);
    }
  }

  get articleFormControls() {
    return this.articleForm.controls;
  }

  handleAddArticle() {
    if (!this.articleForm.valid) {
      this.articleForm.markAllAsTouched();
      return;
    }

    if (this.articleSelected && this.quantity) {
      this.addArticle(this.quantity);
      this.quantity = 0;
      this.priceArticle = 0;
      this.totalArticle = 0;
    }
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  private getUsers() {
    this.userSubscription = this._user.getUsers().subscribe((data) => {
      this.users = data;
      this.userSelected = this.users.find(
        (user) => user.key == this.ticket.user.id
      );
    });
  }

  async loadWarehouse() {
    this.warehouse = await this._warehouse
      .get(this.ticket.warehouse.id)
      .pipe(take(1))
      .toPromise();
  }

  print(): void {
    PrintService.print(this.componentID.nativeElement);
    this._log.add(this.warehouse.key, {
      description: `Se imprimió el ticket ${this.ticket.ticketID.slice(0, -3)}`
    });
  }

  getFormattedArticleType(type) {
    if (typeof type != 'number') {
      return type.replaceAll('\'', '').replaceAll('"', '');
    } else {
      return type;
    }
  }

  async confirmTicket() {
    // for (let article of this.ticket.articles) {
    //   article.type = this.getFormattedArticleType(article.type);
    //   if (
    //     article.type == ArticleType.STORABLE &&
    //     (!article['serialNumber'] ||
    //       article.quantity > article['serialNumber'].length)
    //   ) {
    //     return AlertService.toastError(
    //       `Es necesario agregar los números de series correspondientes del artículo ${article.name}`
    //     );
    //   }
    // }

    switch (this.ticket.status) {
      case TicketEntryStatus.DRAFT:
        await this.generateDraft();
        break;

      case TicketEntryStatus.GENERATED:
        this._log.add(this.warehouse.key, {
          description: `Ticket ${this.ticket.ticketID.slice(
            0,
            -3
          )} pasó a status AUTORIZADO`
        });
        this.ticket.lastUpdate = new Date().getTime();
        await this.authorizeTicket();

        for (let i = 0; i <= this.ticket.articles.length - 1; i++) {
          let surname = this.user.surnames ? this.user.surnames : '';
          let record = {
            action:
              this.ticket.articles[i].quantity == 1
                ? `Se solicitó un ${this.ticket.articles[i].name} (${this.ticket.articles[i].internalId}) por ${this.user.name} ${surname}`
                : `Se solicitaron ${this.ticket.articles[i].quantity} ${this.ticket.articles[i].name} por ${this.user.name} ${surname}`,
            date: new Date().getTime(),
            trash: false
          };

          await this._log.addRecord(
            this.warehouse.key,
            this.ticket.articles[i].key,
            record
          );
        }
        break;

      case TicketEntryStatus.AUTHORIZED:
        await this.addArticles();
        break;
    }
  }

  async generateDraft() {
    this._log.add(this.warehouse.key, {
      description: `Ticket ${this.ticket.ticketID.slice(
        0,
        -3
      )} pasó a status GENERADO`
    });
    this.ticket.status = TicketEntryStatus.GENERATED;

    this.ticket.lastUpdate = new Date().getTime();
    await this._ticket.update(this.ticket.key, this.ticket);

    for (let warehouseManager of this.warehouse.warehouseManager) {
      let user = await this._user.getSpecificUser(
        warehouseManager.reference.id
      );
      if (!!user) {
        if (warehouseManager.reference.id != this._user.user.key) {
          await this.notificationTicketChanged(
            {
              text: `${
                this._user.user.name
              } ha generado el ticket ${this.ticket.ticketID.slice(0, -3)}`,
              emails: [user.email],
              userKeys: [warehouseManager.reference.id],
              subject: `Bodegas: Ticket ${this.ticket.ticketID.slice(0, -3)} generado`
            }
          );
        }
      }
    }
    AlertService.toastSuccess(`El ticket fue generado`, '');
  }

  async notificationTicketChanged({ text, emails, userKeys, subject }: TicketEmail) {
    for (let key of userKeys) {
      this._notification.setUser(key, {
        createdDate: new Date().getTime(),
        description: text,
        type: NotificationType.USER_MESSAGE,
        redirectUrl: `admin/warehouseDetails/${this.warehouse.key}`,
        readed: false,
        trash: false
      });
    }
    await this._notification.sendNotificationTicketEmail(
      text,
      subject,
      emails,
      `/admin/warehouseDetails/${this.warehouse.key}/tickets-article-entry/${this.ticket.key}`,
      this.ticket.key
    );
  }

  private async authorizeTicket() {
    this.ticket.status = TicketEntryStatus.AUTHORIZED;
    this.ticket.supervisor = await this._user.getReference(this._user.user.key);

    this.ticket['grocer'] = !!this.warehouse.grocer
      ? this.warehouse.grocer
      : '';
    this.ticket.lastUpdate = new Date().getTime();

    await this._ticket.update(this.ticket.key, this.ticket);
    AlertService.toastSuccess(`El ticket fue autorizado`, '');

    let [userKeys, emails] = this.getEmailAndKeyFromUsersTicket();

    if (userKeys.length > 0 && emails.length > 0) {
      await this.notificationTicketChanged(
        {
          text: `${
            this._user.user.name
          } ha autorizado el ticket ${this.ticket.ticketID.slice(0, -3)}`,
          emails,
          userKeys,
          subject: `Bodegas: Ticket ${this.ticket.ticketID.slice(0, -3)} autorizado`
        }
      );
    }
  }

  removeDuplicateUsers(users: any[]): any {
    return users.filter(
      (user, index) => users.findIndex((data) => data.key == user.key) == index
    );
  }

  getEmailAndKeyFromUsersTicket() {
    let users = this.users.filter(
      (user) =>
        (!!this.ticket.grocer && user.key == this.ticket.grocer.reference.id) ||
        (!!this.ticket.supervisor && user.key == this.ticket.supervisor.id) ||
        (!!this.ticket.user && user.key == this.ticket.user.id)
    );
    if (users.length > 0) {
      users = this.removeDuplicateUsers(users);
      let userKeys = users.map((user) => user.key);
      let emails = users.map((user) => user.email);
      return [userKeys, emails];
    } else {
      return [[], []];
    }
  }

  private async sendNotifications() {
    const user = await this._user.getSpecificUser(this.ticket.user.id);
    const warehouse = await this._warehouse
      .get(this.ticket.warehouse.id)
      .pipe(take(1))
      .toPromise();

    this._notification.set({
      redirectUrl: `admin/warehouseDetails/${this.ticket.warehouse.id}`,
      description: `El usuario "${user.name}" ha INGRESADO artículos a la bodega "${warehouse.name}"`,
      createdDate: new Date().getTime(),
      trash: false,
      readed: false,
      type: NotificationType.TICKET_COLLECTED
    });
  }

  private async saveLog() {
    this._log.add(this.warehouse.key, {
      description: `Ticket ${this.ticket.ticketID.slice(
        0,
        -3
      )} pasó a status INGRESADO`
    });
    this.ticket.lastUpdate = new Date().getTime();

    await this._warehouse.update(this.warehouse.key, {
      lastUpdate: new Date().getTime()
    } as Warehouse);

    for (let i = 0; i <= this.ticket.articles.length - 1; i++) {
      let surname = this.user.surnames ? this.user.surnames : '';
      let record = {
        action:
          this.ticket.articles[i].quantity == 1
            ? `Se agregó un ${this.ticket.articles[i].name} (${this.ticket.articles[i].internalId}) por ${this.user.name} ${surname}`
            : `Se agregaron ${this.ticket.articles[i].quantity} ${this.ticket.articles[i].name} por ${this.user.name} ${surname}`,
        date: new Date().getTime(),
        trash: false
      };

      await this._log.addRecord(
        this.warehouse.key,
        this.ticket.articles[i].key,
        record
      );
    }
  }

  async isConfirm(article: Article, articleIndex: number) {
    const indexIsConfirm = this.ticket.articles.findIndex(
      (article) => !article.isConfirm
    );
    if (indexIsConfirm == -1) this.areAllConfirm = true;
    if (!this.ticket.articles[articleIndex].isConfirm) return;
  }

  async addArticles() {
    if (this.ticket.articles.some((article) => !article.isConfirm)) {
      return AlertService.toastError(`Se deben de confirmar todos los artículos`);
    }
    this.SpinnerService.show();

    for (const article of this.ticket.articles) {
      let articleRef: any = this.allArticles.find(
        (articleRef) => articleRef.key == article.key
      );

      if (!articleRef) {
        articleRef = await this._article.getFromPath(article.ref.path).pipe(take(1)).toPromise();

        await this._article.add(article.destinyWarehouse.id, {
          ...articleRef,
          quantity: article.quantity,
          total: 0,
          isComplete: false,
          lost: false,
          message: '',
          returned: 0,
          serialNumberCollected: [],
          warehouseKey: this.warehouse.key,
          ...(article.type == ArticleType.STORABLE && {
            serialNumber: [...article.serialNumber ?? [], ...article.serialNumber ?? []]
          })
        }, article.reference.id);
      } else {
        articleRef = {
          ...articleRef,
          quantity: articleRef.quantity + article.quantity,
          ...(articleRef.type == ArticleType.STORABLE && {
            serialNumber: [...article.serialNumber ?? [], ...articleRef.serialNumber ?? []]
          })
        };

        await this._article.update(this.warehouse.key, article.key, articleRef);
      }
    }

    await this._ticket.update(this.ticket.key, {
      status: TicketEntryStatus.ADDED
    } as TicketEntry);

    await this.sendNotifications();
    await this.saveLog();

    this.SpinnerService.hide();
    this.modal.hide();

    AlertService.toastSuccess(`Se han ingresado los artículos al inventario`);
  }

  setArticleSelected($event: any) {
    this.articleSelected = $event;
    this.priceArticle = this.articleSelected.price;

    if (this.quantity == 0) {
      return;
    }

    this.totalArticle = this.priceArticle * this.quantity;
  }

  totalPriceArticle(quantity) {
    this.quantity = +quantity;

    if (quantity.value <= 0) {
      this.quantity = 0;
      return AlertService.toastError('No se permiten números negativos');
    }

    this.totalArticle = this.priceArticle * this.quantity;
  }

  dropArticle(index: number, articleKey) {
    const indexQuantityEdit = this.articleQuantityEdit.findIndex(
      (article) => article.key == articleKey
    );
    this.articleQuantityEdit.splice(indexQuantityEdit, 1);
    this.ticket.total = this.ticket.total - this.ticket.articles[index].total;
    this.ticket.articles.splice(index, 1);
    this.ticket.lastUpdate = new Date().getTime();
    return this._ticket.update(this.ticket.key, this.ticket);
  }

  async addArticle(quantity) {
    const articleQuantity: number = +quantity;
    const index = this.ticket.articles.findIndex(
      (article) => article.name == this.articleSelected.name
    );

    if (index >= 0) {
      return AlertService.toastError('Este artículo ya está en la lista', '');
    }
    if (articleQuantity <= 0) {
      return AlertService.toastError('No ha seleccionado cantidad', '');
    }

    let total = this.articleSelected.price * articleQuantity;
    this.articles = this.ticket.articles;
    this.articles.push({
      name: this.articleSelected.name,
      type: this.articleSelected.type,
      quantity: articleQuantity,
      price: this.articleSelected.price,
      total: total,
      key: this.articleSelected.key,
      imageUrl: this.articleSelected.imageUrl,
      returned: 0,
      isComplete: false,
      lost: false,
      message: '',
      quantityOrdered: articleQuantity,
      reason: this.articleFormControls.reason.value
    });

    this.ticket.articles = this.articles;
    this.ticket.total = this.ticket.total + total;
    this.ticket.lastUpdate = new Date().getTime();
    this.articleQuantityEdit.push({
      key: this.articleSelected.key,
      quantity: articleQuantity
    });
    await this._ticket.update(this.ticket.key, this.ticket);
    this.calculateTotal();
  }

  async discardTicket() {
    if (
      await AlertService.confirm(
        '¿Estás seguro que quieres descartar este ticket?',
        ''
      )
    ) {
      await this._ticket.delete(this.ticket.key);
      AlertService.toastSuccess('Se descartó correctamente', '');
      this.modal.hide();
    }
  }

  calculateTotal(): void {
    this.ticket.total = 0;
    this.ticket.articles.forEach(
      (article) => (this.ticket.total += article.price * article.quantity)
    );
  }

  getStatusClass(status: number) {
    switch (status) {
      case TicketEntryStatus.DRAFT:
        return 'text-primary';

      case TicketEntryStatus.GENERATED:
        return 'text-primary';

      case TicketEntryStatus.AUTHORIZED:
        return 'text-info';

      case TicketEntryStatus.CANCELLED:
      case TicketEntryStatus.EXPIRED:
        return 'text-dark';

      default:
        return 'text-success';
    }
  }

  async setProjectSelected($event: any) {
    let reference = await this._project.getReference($event.key);
    await this._ticket.update(this.ticket.key, {
      projects: reference
    } as TicketEntry);
    this.ticket.projects = reference;
    this.categories = $event.budgets;
  }

  async setUserSelected($event: any) {
    let reference = await this._user.getReference($event.key);
    await this._ticket.update(this.ticket.key, {
      user: reference
    } as TicketEntry);
    this.ticket.user = reference;
  }

  async changeToDraft() {
    await this._ticket.update(this.ticket.key, {
      status: TicketEntryStatus.DRAFT
    } as TicketEntry);
    this.ticket.status = TicketEntryStatus.DRAFT;

    this._log.add(this.warehouse.key, {
      description: `El ticket ${this.ticket.ticketID.slice(
        0,
        -3
      )} pasó a borrador`
    });

    this.getUsers();
    this.comment = this.ticket.comments;
  }

  async cancelTicket() {
    await this._ticket.update(this.ticket.key, {
      status: TicketEntryStatus.CANCELLED
    } as TicketEntry);
    this.ticket.status = TicketEntryStatus.CANCELLED;
  }

  showSerialsNumbersArticles(article: any) {
    this.modal.show(SerialsNumbersComponent, {
      initialState: {
        article
      }
    });
  }

  hasPermission(permission) {
    switch (permission) {
      case 'confirm':
        return (
            this.permission == this.permissionEnum.GROCER
            || this.permission == this.permissionEnum.ADMIN
            || this._permission.hasUserType(UserType.GROCER)
          )
          && this.ticket.status == this.EntryTicketStatus.AUTHORIZED;
    }
  }

  copyLink() {
    navigator.clipboard.writeText(window.location.href);
    AlertService.toastSuccess('Se ha copiado el enlace');
  }
}
