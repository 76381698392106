import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Warehouse } from '../../admin/pages/warehouses/interfaces/warehouse';
import { Observable } from 'rxjs';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { debounce, debounceTime, first, take } from 'rxjs/operators';
import { TicketStatus } from "../../admin/pages/warehouses/enums/ticket-status.enum";
import { AlertService } from "../template-services/alert.service";

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  id: string;

  constructor(private db: FirebaseDataService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage) {
  }

  getAll(): Observable<Warehouse[]> {
    return this.db.colWithIds$<Warehouse>('warehouses', ref => ref
      .where('trash', '==', false));
  }

  get(warehouseKey: string): Observable<Warehouse> {
    return this.db.docWithId$(`warehouses/${warehouseKey}`);
  }

  add(warehouse: Warehouse) {
    this.afs.collection('warehouses').add(warehouse);
  }

  getReference(warehouseKey: string): DocumentReference {
    return this.afs.doc(`warehouses/${warehouseKey}`).ref;
  }

  update(warehouseKey: string, warehouse: Warehouse): Promise<void> {
    return this.afs.doc<Warehouse>(`warehouses/${warehouseKey}`).update(warehouse);
  }

  delete(warehouseKey: string): Promise<void> {
    return this.afs.doc<Warehouse>(`warehouses/${warehouseKey}`).update({ trash: true });
  }

  async uploadPicture(warehousePhoto, warehouseKey) {
    if (warehouseKey == null) {
      this.id = this.afs.createId();
    } else {
      this.id = warehouseKey;
    }
    const uploadRef = this.getStorageRef(this.id);
    await uploadRef.put(warehousePhoto);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(warehousePhoto, this.id);

    return url;
  }

  getStorageRef(id: string) {
    return this.storage.ref(`warehouse/${id}/warehousePicture.jpeg`);
  }

  uploadImage(data, id) {
    return this.storage.upload(`warehouse/${id}/warehousePicture.jpeg`, data);
  }

  getLogs(warehouseKey: string) {
    return this.db.colWithIds$(`warehouses/${warehouseKey}/logs`, ref => ref
      .orderBy('createdAt', 'desc')
      .limit(200));
  }

  async revokePermissions(userKey: string) {
    return new Promise(async (resolve, reject) => {
      const warehouses = await this.db.colWithIds$<Warehouse>('warehouses', ref => ref.where('trash', '==', false))
        .pipe(debounceTime(300), first()).toPromise();

      const affectedWarehouses = [];
      warehouses.forEach(warehouse => {
          const users = warehouse.users.filter(user => user.reference.id != userKey);
          const warehouseManager = warehouse.warehouseManager.filter(user => user.reference.id != userKey);

          const grocer = warehouse.grocer?.reference
            ? warehouse.grocer.reference?.id == userKey
              ? null
              : warehouse.grocer
            : warehouse.grocer?.id == userKey
              ? null
              : warehouse.grocer;
          // @ts-ignore
          const buyer = warehouse.buyer?.id == userKey ? null : warehouse.buyer;

          if (users.length != warehouse.users.length ||
            warehouseManager.length != warehouse.warehouseManager.length ||
            !grocer && warehouse.grocer ||
            !buyer && warehouse.buyer
          ) {
            affectedWarehouses.push({
              name: warehouse.name,
              code: warehouse.code,
              users: {
                data: users,
                changed: users.length != warehouse.users.length,
                name: 'Lista blanca'
              },
              warehouseManager: {
                data: warehouseManager,
                changed: warehouseManager.length != warehouse.warehouseManager.length,
                name: 'Encargados'
              },
              grocer: {
                data: grocer,
                changed: !grocer && warehouse.grocer,
                name: 'Bodeguero'
              },
              buyer: {
                data: buyer,
                changed: !buyer && warehouse.buyer,
                name: 'Comprador'
              }
            });
          }
        }
      );

      const warehouseList =
        '<ul style="font-size: .85rem;text-align: start;list-style-type: none;text-transform: capitalize;">' +
        affectedWarehouses.map(warehouse =>
          '<li> <strong>' + warehouse.name + ' - ' + warehouse.code + '</strong></li>' +
          '<ul>' +
          Object.values(warehouse).filter((w: any) => w.changed).map((val: any) =>
            '<li>' + val.name + '</li>'
          ).join('') +
          '</ul>'
        ).join('') +
        '</ul>';
      
      if (!(await AlertService.confirm('Bodegas afectadas', '', warehouseList))) return resolve(false)
      
      affectedWarehouses.forEach(warehouse => {
        this.db.update(`warehouses/${warehouse.key}`, {
          users: warehouse.users.data,
          warehouseManager: warehouse.warehouseManager.data,
          grocer: warehouse.grocer.data,
          buyer: warehouse.buyer.data
        });
      });

      resolve(true);
    });
  }
}
