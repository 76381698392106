import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { UserService } from "../../../../../shared/services/user.service";
import { User } from "../../../../../shared/interfaces/user";
import { AlertService } from "../../../../../shared/template-services/alert.service";
import _ from "lodash";

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.css']
})
export class EmailFormComponent implements OnInit {
  emailForm: any;
  user: User;
  users: User[];
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private modal: BsModalService,
    private _user: UserService) {
    this.emailForm = formBuilder.group({
      email: ['', [Validators.required, Validators.email, this.colsanEmailValidator()]]
    });
  }

  get formControls() {
    return this.emailForm.controls;
  }

  colsanEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email: string = control.value as string;

      if (email && !email.endsWith('@colsan.cl')) return { customEmail: true };

      return null;
    };
  }

  ngOnInit(): void {
  }

  async submit() {
    this.submitted = true;

    if (this.emailForm.invalid) return;

    const existingUser = _.find(this.users, { email: this.emailForm.value.email });

    if (existingUser) {
      AlertService.toastError('Ya existe un usuario con este email');
      return;
    }

    if (!await AlertService.confirm('¿Está seguro de actualizar el email?')) return;

    await this._user.update(this.user.key, {
      personalEmail: this.user.email,
      email: this.emailForm.value.email
    } as User);

    this.closeModal();
    AlertService.toastSuccess('Email actualizado');
  }

  closeModal() {
    this.modal.hide();
  }
}
