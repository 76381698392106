<app-datatable-pagination
  [rows$]="logs$"
  [config]="config"
>
  <app-datatable-column title="Descripción">
    <ng-template let-row #column>
      {{ row?.description }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Usuario">
    <ng-template let-row #column>
      {{ row.user ? (row.user | doc | async)?.name : 'No asignado' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{ row.createdAt | date : 'dd/MM/yyyy HH:mm' }}
    </ng-template>
  </app-datatable-column>
</app-datatable-pagination>
