import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Ticket } from '../pages/warehouses/interfaces/ticket';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';
import { UserService } from '../../shared/services/user.service';
import { Observable } from 'rxjs';
import { UserNotification } from '../interfaces/userNotification';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../shared/template-services/auth.service';
import { take } from 'rxjs/operators';
import { TicketEntry } from '../pages/warehouses/interfaces/ticket-entry';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private _user: UserService,
    private _auth: AuthService
  ) {
  }

  async sendTicketCollectedEmail(ticket: Ticket | TicketEntry) {
    delete ticket.withdrawn;
    delete ticket.projects;
    delete ticket.grocer;

    let ticketData = _.cloneDeep(ticket);
    ticketData = {
      ...ticketData,
      //@ts-ignore
      createdBy: ticketData.createdBy.id,
      supervisor: ticket.supervisor.id as any,
      user: ticket.user.id as any,
      warehouse: ticket.warehouse.id as any,
      projectCategory: ticket.projectCategory.id as any
    };

    try {
      const resp = await this.http
        .post(`${environment.apiBaseURL}/sendTicketCollectedEmail`, {
          ticket: JSON.stringify(ticketData)
        })
        .pipe(take(1))
        .toPromise();
    } catch (e) {
      console.log('error', e);
    }
  }

  getAll(): Observable<UserNotification[]> {
    return this.db.colWithIds$<UserNotification>(
      `users/${this._user.user.key}/notifications`,
      (ref) => ref.where('trash', '==', false).orderBy('createdDate', 'desc')
    );
  }

  set(notification: UserNotification): void {
    this.afs
      .collection(`users/${this._auth.user.uid}/notifications`)
      .add(notification);
  }

  setUser(userKey, notification: UserNotification): void {
    this.afs.collection(`users/${userKey}/notifications`).add(notification);
  }

  update(userKey, notification: UserNotification, notificationKey: string) {
    this.afs
      .doc(`users/${userKey}/notifications/${notificationKey}`)
      .update(notification);
  }

  async sendNotificationEmail(
    text: string,
    subject: string,
    emails: string[]
  ): Promise<any> {
    return await this.http
      .post(`${environment.apiBaseURL}/sendNotificationEmail`, {
        text,
        subject,
        emails
      })
      .pipe(take(1))
      .toPromise();
  }

  async sendNotificationTicketEmail(
    text: string,
    subject: string,
    emails: string[],
    path: string,
    ticketId?: string
  ): Promise<any> {
    return await this.http
      .post(`${environment.apiBaseURL}/sendNotificationTicketEmail`, {
        text,
        subject,
        emails,
        path,
        ticketId
      })
      .pipe(take(1))
      .toPromise();
  }
}
