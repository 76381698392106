<ng-select
  class="w-25"
  placeholder="Filtrar por ciudad"
  [items]="cities"
  [(ngModel)]="selectedCity"
  bindValue="value"
  (ngModelChange)="filterProviders()
    "></ng-select>

<app-datatable [rows$]="providersFiltered$"
               [config]="config">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="RUT">
    <ng-template let-row #column>
      {{ row.rut }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Giro">
    <ng-template let-row #column>
      {{ row.lineOfBusiness }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Ciudad">
    <ng-template let-row #column>
      {{ row.city }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="País">
    <ng-template let-row #column>
      {{ row.country }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Sitio web">
    <ng-template let-row #column>
      {{ row.website }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Acciones">
    <ng-template let-row #column>
      <button class="btn btn-info" style="margin-right: 1rem;" (click)="gotoOpenEditProvider(row)">Editar</button>
      <button class="btn btn-primary" (click)="delete(row.key)">Eliminar</button>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<app-add-float-button (clickEmmiter)="openAddProviderModal()"></app-add-float-button>
