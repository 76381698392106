import { Component, OnInit } from '@angular/core';
import { CommentService } from "../../../../../shared/services/comment.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AlertService } from "../../../../../shared/template-services/alert.service";


@Component({
  selector: 'app-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.css']
})
export class EditCommentComponent implements OnInit {
  commentKey: any;
  applicantKey: any;
  comment: string;

  constructor(private _comments: CommentService,
    public modal: BsModalRef) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modal.hide();
  }

  submit() {
    this._comments.update(this.applicantKey, this.commentKey, { comment: this.comment });

    AlertService.toastSuccess('Se actualizó el comentario');
    this.modal.hide();
  }
}
