import { Component, Input, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataTableConfig } from '../../../../../../shared/interfaces/data-table-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TicketService } from '../../../../../../shared/services/ticket.service';
import { Ticket } from '../../../interfaces/ticket';
import { WarehouseService } from '../../../../../../shared/services/warehouse.service';
import { TicketDetailComponent } from '../../../modals/ticket-detail/ticket-detail.component';
import { Warehouse } from '../../../interfaces/warehouse';
import { TicketStatusLabel } from '../../../labels/ticket-status.label';
import { TicketStatus } from '../../../enums/ticket-status.enum';
import { Article } from '../../../interfaces/article';
import { debounceTime, first, map, take } from 'rxjs/operators';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { TicketAuthorization } from '../../../interfaces/ticket-authorization';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../../../shared/services/user.service';
import { UserType } from '../../../../../enums/user-type.enum';
import { WarehouseDetailService } from '../../../services/warehouse-detail.service';
import { ObjectService } from '../../../../../../shared/template-services/object.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TicketAuthorizationService } from '../../../../../../shared/services/ticket-authorization.service';
import { Permission } from '../../../../../enums/permissions.enum';
import { PermissionService } from '../../../services/permission.service';
import moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit, OnDestroy {
  @Input() warehouse: Warehouse;
  @Input() articleReferences: Article[];
  @Input() ticketAuthorizationReferences: TicketAuthorization[] = [];
  @Input() requestsReference: TicketAuthorization[] = [];
  @Input() tickets$: Observable<Ticket[]>;
  @Input() ticketsFiltered$: Observable<Ticket[]> = new Observable<Ticket[]>();
  @Input() isAll: boolean = false;
  @Input() isProject: boolean = false;
  @Input() projectKey: string;
  @Input() statusSelected: any;
  @Input() unknownWarehouse: boolean = false;
  @Input() ticket: any;
  @Input() submitExcel: boolean = false;
  ticketsFiltered: Ticket[] = [];
  ticketStatus = TicketStatus;
  ticketsSubscription: Subscription = new Subscription();
  warehouseReference: any;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron tickets',
    hasSearch: true,
    exportCallback: this.decoratedTicketsToExport.bind(this),
    excelFileName: 'Tickets autorizados',
    pagination: true,
    paginationLimit: 25,
    removeInfiniteScroll: true
  };
  ticketStatusLabel = TicketStatusLabel;
  myRadio: number;
  permission: UserType;
  warehouses: Warehouse[] = [];
  usersIndexed: any = {};
  projectsIndexed: any = {};
  private usersSubscription: Subscription = new Subscription();
  private projectsSubscription: Subscription = new Subscription();
  messageLoad: string;

  constructor(private _ticket: TicketService,
    private _warehouse: WarehouseService,
    private activatedRoute: ActivatedRoute,
    private _project: ProjectService,
    private modal: BsModalService,
    private _user: UserService,
    private _warehouseDetail: WarehouseDetailService,
    private SpinnerService: NgxSpinnerService,
    private _ticketAuthorized: TicketAuthorizationService,
    private _permission: PermissionService,
    private _location: Location) {
  }

  async ngOnInit() {
    this.messageLoad = 'Cargando...';
    this.SpinnerService.show();

    await this.loadUsers();
    await this.loadProjects();

    if (!this.warehouse) {
      this.warehouse = this._warehouseDetail.warehouse;
      this.articleReferences = this._warehouseDetail.articleReferences;
      this.ticketAuthorizationReferences =
        this._warehouseDetail.ticketAuthorizationReferences;
      this.requestsReference = this._warehouseDetail.requestsReference;
      this.ticket = this._warehouseDetail.ticket;
    }

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'BODEGAS'
    ).permission;

    if (this.permission != UserType.GROCER && this._permission.hasUserType(Permission.GROCER as any)) {
      this.permission = UserType.GROCER;
    }

    if (this.isProject) {
      await this.loadTickets();
    }

    if (!this.isAll && !this.isProject) {
      this.getWarehouseReference();
      await this.loadTickets();
    }

    if (this.isAll) {
      this.filterTickets();
    }

    const ticketKey = this.activatedRoute.snapshot.params['ticketKey'];
    if (!!ticketKey) {
      if (!this.requestsReference) {
        await this.setRequestsReference();
      }

      if (!this.ticketAuthorizationReferences) {
        await this.setTicketAuthorizationReferences();
      }
      const ticket = await this._ticket
        .get(ticketKey)
        .pipe(take(1))
        .toPromise();

      this.openTicketDetail(ticket);
    }

    if (this.permission == UserType.GROCER) {
      this.myRadio = TicketStatus.AUTHORIZED;
      this.changeTicketStatus();
    }

    this.SpinnerService.hide();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['ticket'];
    this.ticket = change;
    if (!!change && change.currentValue) {
      this.openTicketDetail(change.currentValue);
    }
  }

  ngOnDestroy() {
    this.ticketsSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
    this.projectsSubscription.unsubscribe();
  }

  async loadTickets() {
    this.ticketsFiltered$ = this.tickets$ = null;

    if (this.isProject) {
      this.ticketsFiltered$ = this.tickets$ = this._ticket
        .getAllByProject(this._project.getReference(this.projectKey))
        .pipe(take(1));
    } else {
      this.ticketsFiltered$ = this.tickets$ = this._ticket
        .getAll(this.warehouseReference)
        .pipe(map((tickets) => tickets.sort(this.compareFn)));
    }
  }

  compareFn(a, b) {
    if (parseInt(a.ticketID) < parseInt(b.ticketID)) {
      return 1;
    }
    if (parseInt(a.ticketID) > parseInt(b.ticketID)) {
      return -1;
    }
    return 0;
  }

  getWarehouseReference() {
    this.warehouseReference = this._warehouse.getReference(this.warehouse.key);
  }

  openTicketDetail(ticket) {
    if(!this?.warehouse?.key) this._location.replaceState(`/admin/warehouseDetails/${ticket.warehouse.id}/tickets/${ticket.key}`);
    else this._location.replaceState(`/admin/warehouseDetails/${this.warehouse.key}/tickets/${ticket.key}`);

    const modalRef = this.modal.show(TicketDetailComponent, {
      initialState: {
        ticket: { ...ticket },
        warehouse: this.warehouse,
        articleReferences: this.articleReferences,
        unknownWarehouse: this.unknownWarehouse,
        requestsReference: this.requestsReference ?? [],
        ticketAuthorizationReferences: this.ticketAuthorizationReferences ?? []
      },
      class: 'modal-xl',
      backdrop: 'static'
    });

    modalRef.onHide.subscribe(() => {
      this._location.replaceState(`/admin/warehouseDetails/${this.warehouse.key}/tickets`);
    });
  }

  async changeTicketStatus() {
    if (this.myRadio == 99) {
      this.ticketsFiltered$ = this.tickets$;
      return;
    }

    switch (this.myRadio) {
      case TicketStatus.DRAFT:
        this.statusSelected = { name: 'BORRADORES', status: TicketStatus.DRAFT };
        break;

      case TicketStatus.GENERATED:
        this.statusSelected = {
          name: 'GENERADOS',
          status: TicketStatus.GENERATED
        };
        break;

      case TicketStatus.AUTHORIZED:
        this.statusSelected = {
          name: 'AUTORIZADOS',
          status: TicketStatus.AUTHORIZED
        };
        break;

      case TicketStatus.COLLECTED:
        this.statusSelected = {
          name: 'RETIRADOS',
          status: TicketStatus.COLLECTED
        };
        break;

      case TicketStatus.INCOMPLETE:
        this.statusSelected = {
          name: 'ENTREGADOS INCOMPLETOS',
          status: TicketStatus.INCOMPLETE
        };
        break;

      case TicketStatus.RETURNED:
        this.statusSelected = {
          name: 'REGRESADOS',
          status: TicketStatus.RETURNED
        };
        break;
    }

    this.filterTickets();
  }

  getStatusClass(status: number) {
    switch (status) {
      case TicketStatus.DRAFT:
        return 'btn-outline-primary';

      case TicketStatus.GENERATED:
        return 'btn-outline-primary';

      case TicketStatus.AUTHORIZED:
        return 'btn-outline-info';

      case TicketStatus.COLLECTED:
        return 'btn-outline-warning';

      case TicketStatus.INCOMPLETE:
        return 'btn-outline-danger';

      case TicketStatus.RETURNED:
        return 'btn-outline-success';

      case TicketStatus.CANCELLED:
      case TicketStatus.EXPIRED:
        return 'btn-outline-dark';

      default:
        return 'btn-outline-success';
    }
  }

  async filterTickets() {
    this.config.notFoundText = `No se encontraron tickets ${this.statusSelected.name}`;
    this.ticketsFiltered$ = this.tickets$.pipe(
      map((tickets) =>
        tickets.filter((ticket) => {
          return ticket.status == this.statusSelected.status;
        })
      )
    );
  }

  private async loadUsers() {
    this.usersSubscription = this._user.getAllByColsan().subscribe((users) => {
      this.usersIndexed = ObjectService.indexArray(users, 'key');
    });
  }

  private async loadProjects() {
    this.projectsSubscription = this._project.getAll().subscribe((projects) => {
      this.projectsIndexed = ObjectService.indexArray(projects, 'key');
    });
  }

  async setRequestsReference() {
    this.requestsReference = await this._ticketAuthorized
      .getAllRequests(this.warehouse.key)
      .pipe(first())
      .toPromise();
  }

  async setTicketAuthorizationReferences() {
    this.ticketAuthorizationReferences = await this._ticketAuthorized
      .getAll()
      .pipe(first())
      .toPromise();
  }

  decoratedTicketsToExport(rows: any[]) {
    return ObjectService.replaceUndefined(rows.map(row => ({
      ticketID: row.ticketID.slice(0, -3),
      total: row.total,
      user: this.usersIndexed[row.user.id].name,
      supervisor: row.supervisor ? this.usersIndexed[row.supervisor.id].name : 'No registrado',
      projects: this.projectsIndexed[row.projects.id].name,
      comments: row.comments ? row.comments : 'No hay comentarios',
      lastUpdate: row?.lastUpdate ? moment(row.lastUpdate).format('DD/MM/YYYY HH:mm') : moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
      status: this.ticketStatusLabel[row.status]
    })));
  }
}
