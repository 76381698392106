import { Component, OnInit } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.css']
})
export class RequestDetailsComponent implements OnInit {
  request: any;
  warehouseKey: string;
  ticketUrl: string;

  constructor(private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.ticketUrl = `/admin/warehouseDetails/${this.warehouseKey}/tickets/${this.request.ticketKey}`;
  }

  close() {
    this.modal.hide();
  }
}
