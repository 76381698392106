import {Component, Input, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, of} from 'rxjs';
import {BillModalComponent} from '../../modals/bill-modal/bill-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BillService} from '../../../shared/services/bill.service';
import {take} from 'rxjs/operators';
import {PurchaseOrderService} from 'src/app/shared/services/purchase-order.service';
import { BillModalV2Component } from '../../modals/bill-modal-v2/bill-modal-v2.component';

@Component({
  selector: 'app-purchase-order-bills',
  templateUrl: './purchase-order-bills.component.html',
  styleUrls: ['./purchase-order-bills.component.css']
})
export class PurchaseOrderBillsComponent implements OnInit {
  bills = [];
  @Input() purchaseOrder;
  bills$: Observable<any>;
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron facturas', title: ''};


  constructor(private modal: BsModalService,
              private _bill: BillService,
              private _purchase: PurchaseOrderService) {
  }

  async ngOnInit(): Promise<void> {
    this._purchase.get(this.purchaseOrder.key).subscribe(async purchase => {
      this.bills = await Promise.all(purchase.invoice.map(async bill => {
        return await this._bill.get(bill.id).pipe(take(1)).toPromise();
      }));

      this.bills$ = of(this.bills);
    });
  }

  goToOpenEditBill(bill) {
    this.modal.show(BillModalV2Component, {
      initialState: {
        bill: {...bill},
        isUpdate: true,
      },
      class: 'modal-xl',
      backdrop: 'static',
      id: 8177
    });
  }
}
