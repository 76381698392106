import { Component, OnInit } from '@angular/core';
import { Warehouse } from '../../interfaces/warehouse';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArticleService } from '../../../../../shared/services/article.service';
import { WarehouseService } from '../../../../../shared/services/warehouse.service';
import { ArticlesTransferService } from '../../services/articles-transfer.service';
import { debounceTime, take } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-articles-transfer',
  templateUrl: './articles-transfer.component.html',
  styleUrls: ['./articles-transfer.component.css']
})
export class ArticlesTransferComponent implements OnInit {
  selectedOrigin: string;
  selectedDestination: string[];
  warehouses: Warehouse[] = null;
  selectOptions: { name: string, value: string }[] = [];
  loading = true;

  constructor(
    private modal: BsModalService,
    private _article: ArticleService,
    private _warehouse: WarehouseService,
    private _transfer: ArticlesTransferService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();

    this.warehouses = await this._warehouse.getAll().pipe(debounceTime(500), take(1)).toPromise();
    this.selectOptions = this.warehouses.map(warehouse => ({
      name: ` ${warehouse.name} (${warehouse.code})`,
      value: warehouse.key
    }));

    /*    if(this.selectedOrigin) this.sele*/

    const allArticles = await this._article.getAll().pipe(debounceTime(500), take(1)).toPromise() as any;
    const articlesByWarehouse = _.mapValues(_.keyBy(this.warehouses, 'key'), (warehouse) => ({
      warehouse: warehouse,
      articles: [],
      removed: {},
      added: {}
    }));

    allArticles.forEach(article => {
      articlesByWarehouse[article.warehouseKey].articles.push(article);
    });

    this._transfer.setWarehouses(articlesByWarehouse);
    this.spinner.hide();
    this.loading = false;
  }

  closeModal() {
    this.modal.hide();
  }

  async submit() {
    if (!this._transfer.validateArticles()) return;

    if (!await AlertService.confirm('¿Está seguro que desea transferir los artículos?')) return;

    await this._transfer.transferArticles();

    AlertService.toastSuccess('Artículos transferidos con éxito');
    this.closeModal();
  }
}
