import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PurchaseOrder } from '../pages/purchase-orders/interfaces/purchase-order';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../shared/template-services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderPrintService {

  constructor(
    private httpClient: HttpClient,
    public db: FirebaseDataService
  ) {
  }

  printPdf(purchaseOrder: PurchaseOrder): Promise<void> {
    return new Promise(resolve => {
      const url = `${environment.apiBaseURLV1}/purchase-order/pdf/${purchaseOrder.key}`;
      const headers = new HttpHeaders({
        Accept: 'application/pdf'
      });

      try {
        this.httpClient.get(url, {
          responseType: 'arraybuffer',
          headers: headers
        })
          .subscribe((response: ArrayBuffer) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = `OC-${purchaseOrder.purchaseID.toString().slice(0, -3)}.pdf`;
            link.click();
            resolve();
          });
      } catch (e) {
        AlertService.error('Error al generar PDF', e);
        resolve();
      }
    });
  }

  async getFile(purchaseOrderKey: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const url = `${environment.apiBaseURLV1}/purchase-order/pdf/${purchaseOrderKey}`;
      const headers = new HttpHeaders({
        Accept: 'application/pdf'
      });

      try {
        this.httpClient.get(url, {
          responseType: 'arraybuffer',
          headers: headers
        })
          .subscribe((response: ArrayBuffer) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            resolve(blob as File);
          });
      } catch (e) {
        AlertService.error('Error al generar PDF', e);
        reject();
      }
    });
  }
}
