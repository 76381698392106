<div class="general-item">
  <label class="font-weight-bold">ID Documento</label>
  <h5 class="mb-0">{{bill?.documentNumber}}</h5>
</div>

<div class="general-item">
  <label class="font-weight-bold">Proveedor</label>
  <h5 class="mb-0">{{bill?.provider}}</h5>
</div>

<div class="general-item" *ngIf="bill?.projectCategory">
  <label class="font-weight-bold">Categoría</label>
  <h5 class="mb-0">{{bill?.projectCategory?.name || 'Categoría no asignada'}}</h5>
</div>

<div class="general-item">
  <label class="font-weight-bold">Fecha de emisión</label>
  <h5 class="mb-0">{{bill?.startDate | date : 'dd/MM/yyyy'}}</h5>
</div>

<div class="general-item" *ngIf="bill?.project">
  <label class="font-weight-bold">Proyecto o Centro de costos</label>
  <h5 class="mb-0">{{bill?.project.name || 'Proyecto no asignado'}}</h5>
</div>

<div class="d-flex my-1">
  <div
    class="badge badge-pill mr-2 {{billClassifyBadge.badgeType}}"
    ngbTooltip="{{billClassifyBadge.tooltip}}">
    <i class="la {{billClassifyBadge.icon}}"></i>
  </div>
  <div
    class="badge badge-pill {{billPaidBadge.badgeType}}"
    ngbTooltip="{{billPaidBadge.tooltip}}">
    <i class="la la-money"></i>
  </div>
</div>
