import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TicketService} from "../../../shared/services/ticket.service";
import {PurchaseOrderService} from "../../../shared/services/purchase-order.service";
import {AlertService} from "../../../shared/template-services/alert.service";
import {ProjectService} from '../../../shared/services/project.service';

@Component({
  selector: 'app-id-settings',
  templateUrl: './id-settings.component.html',
  styleUrls: ['./id-settings.component.css']
})
export class IdSettingsComponent implements OnInit {
  settingsForm: FormGroup;
  formSubmitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private _settings: TicketService,
              private _project: ProjectService,
              private _purchase: PurchaseOrderService) {
    this.settingsForm = formBuilder.group({
      purchaseID: ['', Validators.required],
      ticketID: ['', Validators.required],
      projectID: ['', Validators.required],
      financeProfileThreshold: ['', Validators.required],
      comercialConditions: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.settingsForm.patchValue({
      ticketID: await this._settings.getId(),
      purchaseID: await this._purchase.purchaseId(),
      projectID: (await this._project.getID()).projectID,
      financeProfileThreshold: await this._purchase.getFinanceThreshold(),
      comercialConditions: await this._purchase.getComercialConditions()
    })
  }

  get formControls() {
    return this.settingsForm.controls;
  }

  async submit() {
    this.formSubmitted = true;

    if (this.settingsForm.valid) {
      if (await AlertService.confirm('¿Estás seguro que deseas actualizar la configuración?')) {
        this._settings.updateTicketID(this.settingsForm.value.ticketID);
        this._purchase.updatePurchaseID(this.settingsForm.value.purchaseID);
        this._project.updateProjectID(this.settingsForm.value.projectID);
        this._purchase.updateFinancialThreshold(this.settingsForm.value.financeProfileThreshold)
        this._purchase.updateComercialConditions(this.settingsForm.value.comercialConditions)
        AlertService.toastSuccess('Actualizado correctamente')
      }
    }
  }
}
