<div class="p-2">
  <h3>Actualizar dirección de correo</h3>
  <form [formGroup]="emailForm" (ngSubmit)="submit()" class="mt-3">
    <div>
      <input
        type="text"
        class="form-control"
        formControlName="email"
        placeholder="ejemplo@colsan.cl"
        [ngClass]="{'is-invalid': submitted && formControls.email.errors}"
      />
      <small
        class="form-text text-muted danger invalid-feedback"
        *ngIf=" submitted && formControls.email.errors"
      >
        <div *ngIf="formControls.email.errors.required">
          El campo no puede estar vacío.
        </div>
        <div *ngIf="formControls.email.errors.customEmail || formControls.email.errors.email">
          Debe ingresar un correo válido de COLSAN "ejemplo@colsan.cl".
        </div>
      </small>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-success">Guardar</button>
      <button class="btn btn-danger ml-2" (click)="closeModal()">Cancelar</button>
    </div>
  </form>
</div>
