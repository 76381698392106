import { Component, OnInit } from '@angular/core';
import _ from "lodash";
import { DataTableConfig } from "../../../../../../../../shared/interfaces/data-table-config";
import { TicketStatus } from "../../../../../enums/ticket-status.enum";
import { TicketEntryStatus } from "../../../../../enums/ticket-entry-status.enum";
import { TicketsBadgesService } from "../../services/tickets-badges.service";
import { ArticleType } from "../../../../../enums/article-type.enum";

@Component({
  selector: 'app-article-history',
  templateUrl: './article-history.component.html',
  styleUrls: ['./article-history.component.css']
})
export class ArticleHistoryComponent implements OnInit {
  article: any;
  allTickets: any[];
  allEntryTickets: any[];
  totalWithdrawn: number = 0;
  totalAdded: number = 0;
  history: any[];
  userTicketsTableConfig: DataTableConfig = {
    title: 'Artículos retirados por usuario',
    notFoundText: 'No se encontraron tickets',
    hasSearch: false,
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 25,
    noClickable: true
  };

  constructor(public TicketsBadgesService: TicketsBadgesService) {
  }

  ngOnInit(): void {
    const tickets = _.chain(this.allTickets)
      .filter((ticket => ticket?.articles?.some(a => a.key == this.article.key)))
      .flatMap(
        ticket => ticket.articles
          .filter(a => a.key == this.article.key)
          .map(a => ({
            ...a,
            ticketStatus: ticket.status,
            ticketID: ticket.ticketID,
            createdAt: ticket['createdDate'],
            type: 'ticket'
          }))
      )
      .value();

    const entryTickets = _.chain(this.allEntryTickets)
      .filter((ticket => ticket?.articles?.some(a => a.key == this.article.key)))
      .flatMap(
        ticket =>
          ticket.articles
            .filter(a => a.key == this.article.key)
            .map(a => ({
              ...a,
              ticketStatus: ticket.status,
              ticketID: ticket.ticketID,
              createdAt: ticket['createdDate'],
              type: 'entry'
            }))
      )
      .value();

    this.totalWithdrawn =
      tickets
        .filter(t => t.ticketStatus == TicketStatus.COLLECTED)
        .reduce((acc, t) => acc + t.quantity, 0);

    this.totalAdded =
      entryTickets
        .filter(t => t.ticketStatus == TicketEntryStatus.ADDED)
        .reduce((acc, t) => acc + t.quantity, 0);

    this.history = _.orderBy([...tickets, ...entryTickets], 'createdAt', 'desc');
  }

  protected readonly ArticleType = ArticleType;
}
