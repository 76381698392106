import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { Job } from '../../admin/interfaces/job';
import { Observable } from 'rxjs';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  constructor(private db: FirebaseDataService,
    private afs: AngularFirestore) {
  }

  getAll(): Observable <any>{
    return this.db.colWithIds$('applicantSkills', ref => ref
      .where('trash', '==', false)
      .orderBy('name', 'asc'));
  }

  getReference(skillId: string): DocumentReference {
    return this.afs.doc(`applicantSkills/${skillId}`).ref;
  }
}
