import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Board} from '../../interfaces/board';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UserService} from '../../../shared/services/user.service';
import {User} from 'src/app/shared/interfaces/user';
import {Subscription} from 'rxjs';
import {UserType} from '../../enums/user-type.enum';
import {AngularFirestore} from '@angular/fire/firestore';
import {NgxSpinnerService} from 'ngx-spinner';
import {UploadFileStorageComponent} from '../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {BoardService} from '../../services/board.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {DocumentReference} from '@angular/fire/firestore';
import {NotificationService} from '../../services/notification.service';
import {NotificationType} from '../../enums/notification-type.enum';
import {BoardTypeEnum} from '../../enums/board-type.enum';

@Component({
  selector: 'app-board-modal',
  templateUrl: './board-modal.component.html',
  styleUrls: ['./board-modal.component.css']
})
export class BoardModalComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent)
  filesStorage: UploadFileStorageComponent;
  boardForm: FormGroup;
  board: Board = {key: ''} as Board;
  submitted: boolean = false;
  allUsers: User[];
  users: User[];
  supervisors: User[];
  admins: User[];
  usersSubscription: Subscription;
  userTypeEnum = UserType;
  userType: number;
  isImageLoaded: boolean = false;
  boardTypeEnum = BoardTypeEnum;

  constructor(private formBuilder: FormBuilder,
    public modal: BsModalRef,
    private _user: UserService,
    private _board: BoardService,
    private _notification: NotificationService,
    private afs: AngularFirestore,
    private db: FirebaseDataService,
    private spinner: NgxSpinnerService) {
    this.createBoardForm();
  }

  async ngOnInit() {
    if (!!this.board.key) {
      this.boardForm.patchValue(this.board);
    } else {
      this.board.key = this.afs.createId();
    }
  }

  get formControls() {
    return this.boardForm.controls;
  }

  createBoardForm() {
    this.boardForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      imageUrl: [''],
      createdAt: [],
      admins: [[]],
      supervisors: [[]],
      users: [[]],
      owner: [null],
      type: [this.boardTypeEnum.TEAM],
      trash: [false]
    });
  }

  async submit() {
    this.submitted = true;
    if (this.boardForm.valid) {
      if (
        await AlertService.confirm(
          `¿Estás seguro de que deseas ${
            !!this.board.name ? 'guardar' : 'agregar'
          } este tablero?`
        )
      ) {
        this.spinner.show();
        if (this.isImageLoaded) {
          const url = await this.filesStorage.uploadDocument(
            'boards',
            new Date().getTime()
          );
          if (!!url) {
            this.boardForm.patchValue({[this.filesStorage.controlName]: url});
          }
        }
        if (this.boardForm.value.type == this.boardTypeEnum.TEAM) {
          if (this.boardForm.value.admins.length > 0) {
            this.boardForm.patchValue({
              admins: this.getUsersDocumentReferences(
                this.boardForm.value.admins
              )
            });
          }
          if (this.boardForm.value.users.length > 0) {
            this.boardForm.patchValue({
              users: this.getUsersDocumentReferences(this.boardForm.value.users)
            });
          }
          if (this.boardForm.value.supervisors.length > 0) {
            this.boardForm.patchValue({
              supervisors: this.getUsersDocumentReferences(
                this.boardForm.value.supervisors
              )
            });
          }
        } else if (this.boardForm.value.type == this.boardTypeEnum.PERSONAL) {
          this.boardForm.patchValue({
            owner: this.db.getReference(`users/${this._user.user.key}`)
          });
          this.boardForm.value.users.length = 0;
          this.boardForm.value.admins.length = 0;
          this.boardForm.value.supervisors.length = 0;
        }
        if (!this.board.name) {
          this.boardForm.patchValue({createdAt: new Date().getTime()});
        }

        await this._board.set(this.boardForm.value, this.board.key);

        if (
          !this.board.name &&
          this.boardForm.value.type == this.boardTypeEnum.TEAM
        ) {
          for (const user of <DocumentReference[]>this.boardForm.value.users) {
            this._notification.setUser(user.id, {
              description: `Se te agregó a un tablero`,
              type: NotificationType.USER_MESSAGE,
              redirectUrl: `admin/kanban/${this.board.key}`,
              createdDate: new Date().getTime(),
              readed: false,
              trash: false
            });
          }
        }
        if (this.boardForm.value.users.length > 0 && !this.board.name)
          await this.sendNotificationEmail(this.boardForm.value.users);
        if (this.boardForm.value.supervisors.length > 0 && !this.board.name)
          await this.sendNotificationEmail(this.boardForm.value.supervisors);
        if (this.boardForm.value.admins.length > 0 && !this.board.name)
          await this.sendNotificationEmail(this.boardForm.value.admins);
        this.spinner.hide();
        this.modal.hide();
        AlertService.toastSuccess(
          `El tablero se ha ${
            !!this.board.name ? 'guardado' : 'agregado'
          } correctamente`
        );
      }
    }
  }

  compareUsers(user, currentUser) {
    return user.key == currentUser.key;
  }

  getUsersDocumentReferences(users: User[]) {
    return users.map((user) => this.db.getReference(`users/${user.key}`));
  }

  async sendNotificationEmail(users: any) {
    let usersEmails = users.map(
      (user) => this.allUsers.find((data) => data.key == user.id).email
    );
    await this._notification.sendNotificationEmail(
      `Has sido agregado al tablero ${this.boardForm.value.name}`,
      `Tareas: asignación al tablero tablero ${this.boardForm.value.name}`,
      usersEmails
    );
  }
}
