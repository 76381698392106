import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BillService} from '../../../shared/services/bill.service';
import {Bill} from '../../interfaces/bill';
import {Observable, of, Subscription} from 'rxjs';
import {PurchaseOrderService} from '../../../shared/services/purchase-order.service';
import {take} from 'rxjs/operators';
import {BillModalComponent} from '../../modals/bill-modal/bill-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {PurchaseStatus} from '../../enums/purchase-status.enum';
import {UserType} from '../../enums/user-type.enum';
import {UserService} from '../../../shared/services/user.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ObjectService} from '../../../shared/template-services/object.service';
import {PaymentCategoryService} from '../../services/payment-category.service';
import {ProviderService} from '../../../shared/services/provider.service';
import {ProjectService} from '../../../shared/services/project.service';

@Component({
  selector: 'app-project-bills',
  templateUrl: './project-bills.component.html',
  styleUrls: ['./project-bills.component.css'],
})
export class ProjectBillsComponent implements OnInit, OnDestroy {
  @Input() projectKey: string;
  bills$: Observable<Bill[]>;
  billsSubscription: Subscription = new Subscription();
  purchaseOrders = [];
  purchaseOrdersSubscription: Subscription = new Subscription();
  permission: UserType;
  permissionEnum = UserType;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron facturas registradas',
    title: 'Facturas del proyecto',
  };
  indexedBills = {};
  indexedProviders = {};
  categoriesIndexed = {};
  billSubscription: Subscription = new Subscription();
  paymentCategoriesSubscription: Subscription = new Subscription();
  proofOfPaymentSubscription: Subscription = new Subscription();
  categoriesSubscription: Subscription = new Subscription();
  paymentCategories: any[] = [];
  proofOfPayments: any[] = [];

  constructor(private _bill: BillService,
              private _purchaseOrder: PurchaseOrderService,
              private modal: BsModalService,
              private _user: UserService,
              private _purchase: PurchaseOrderService,
              private _paymentCategory: PaymentCategoryService,
              private _provider: ProviderService,
              private _project: ProjectService,) {
  }

  async ngOnInit() {
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'PROYECTOS'
    ).permission;
    this.purchaseOrders = await this._purchaseOrder
      .getAll()
      .pipe(take(1))
      .toPromise();
    this.purchaseOrdersSubscription = this._purchaseOrder
      .getAll()
      .subscribe((data) => {
        this.purchaseOrders = data.filter(
          (purchaseOrder) => purchaseOrder.status >= PurchaseStatus.AUTHORIZED
        );
      });
    this.billsSubscription = this._bill.getAll().subscribe((bills) => {
      this.indexedBills = ObjectService.indexArray(bills, 'key');

      let billsWithProject = bills.filter(
        (bill) => !!bill.project && bill.project.id == this.projectKey
      );

      let billsWithPurchaseOrder = bills.filter((bill) => {
        if (
          !!bill.purchaseOrder &&
          this.purchaseOrders.some(
            (purchaseOrder) => purchaseOrder.key == bill.purchaseOrder.id
          )
        ) {
          return (
            this.purchaseOrders.find(
              (purchaseOrder) => purchaseOrder.key == bill.purchaseOrder.id
            ).project.id == this.projectKey
          );
        }
      });

      bills = billsWithProject.concat(billsWithPurchaseOrder);
      this.bills$ = of(bills);
    });

    this.listenPaymentCategories();
    this.loadProviders();
    this.listenProOfPayments();
    this.listenCategories();
  }

  ngOnDestroy() {
    this.billsSubscription.unsubscribe();
    this.billSubscription.unsubscribe();
    this.paymentCategoriesSubscription.unsubscribe();
    this.proofOfPaymentSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
  }

  listenPaymentCategories() {
    this.paymentCategoriesSubscription = this._paymentCategory
      .getAllWithoutCostCenter()
      .subscribe((data) => {
        this.paymentCategories = data;
      });
  }

  async loadProviders() {
    this.indexedProviders = ObjectService.indexArray(
      await this._provider.getAll().pipe(take(1)).toPromise(),
      'key'
    );
  }

  listenProOfPayments() {
    this.proofOfPaymentSubscription = this._bill.getAllProofPayments().subscribe((data) => {
      this.proofOfPayments = data.filter((proOfPayment) => proOfPayment.type == 1);
    });
  }

  listenCategories() {
    this.categoriesSubscription = this._project.getAllCategories().subscribe((data) => {
      this.categoriesIndexed = ObjectService.indexArray(data, 'key');
    });
  }

  public getRut(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(0, provider.indexOf(' '));
  }

  public getProvider(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(provider.indexOf(' ') + 1);
  }

  gotoOpenEditBill(bill) {
    this.modal.show(BillModalComponent, {
      initialState: {
        bill: {...bill},
        isUpdate: true,
      },
      class: 'modal-lg',
      backdrop: 'static',
      id: 8177,
    });
  }

  async deleteBill(billKey: string) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar esta factura?')) {
      await this._bill.delete(billKey);
    }
  }
}
