import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../../../../shared/template-services/alert.service';
import { formatDate } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProviderArticleService } from '../../../../../../services/provider-article.service';
import { UnitService } from '../../../../../../services/unit.service';
import { Observable, Subscription } from 'rxjs';
import { FirebaseDataService } from '../../../../../../../shared/template-services/firebase-data.service';
import { UnitModalComponent } from '../../../../../../modals/unit-modal/unit-modal.component';
import { Unit } from '../../../../../../interfaces/unit';
import { ProviderArticle } from '../../../../../../interfaces/provider-article';
import { SearchOrderArticleComponent } from '../search-order-article/search-order-article.component';

@Component({
  selector: 'app-articles-purchase',
  templateUrl: './articles-purchase.component.html',
  styleUrls: ['./articles-purchase.component.css']
})
export class ArticlesPurchaseComponent implements OnInit, OnDestroy {
  @Input() articlesPurchaseEdit: any;
  @Input() deliveryDate: any;
  @Output() eventChangeArticlesPurchaseForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventChangeDeliveryDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() totalEmitter: EventEmitter<number> = new EventEmitter<number>();

  submitted: boolean = false;
  articlesPurchaseForm: FormGroup;
  quantity: number = 0;
  unit: any = '';
  unitKey: string = '';
  description: string = '';
  unitCode: string = null;
  providerCode: string = null;
  firstUnitCode: string = null;
  unitValue: number = 0;
  discount: number = 0;
  total: number = 0;
  exempt: boolean = false;
  articlesPurchase = [];
  units$: Observable<Unit[]>;
  unitsSubscription: Subscription = new Subscription;
  unitsArray = [];
  paymentTypeArray = [
    'Contado',
    'Crédito 30 días',
    'Crédito 45 Días',
    'Crédito 60 Días'
  ];
  currencyArray = [
    'Pesos',
    'Dólares',
    'UF'
  ];
  paymentType: string;
  currency: string;
  subtotal: number = 0;
  discountTotal: number = 0;
  net: number = 0;
  iva: number = 0;
  totalValue: number = 0;
  currentDate = new Date().getTime();

  constructor(private formBuilder: FormBuilder,
    private modal: BsModalService,
    private _providerArticle: ProviderArticleService,
    private _unit: UnitService,
    private db: FirebaseDataService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.units$ = this._unit.getAll();

    this.unitsSubscription = this.units$.subscribe(data => {
      this.unitsArray = data;
    });

    if (this.deliveryDate) this.formatDate();

    if (this.articlesPurchaseEdit) {
      this.articlesPurchase = this.articlesPurchaseEdit.articles;
      this.paymentType = this.articlesPurchaseEdit.paymentType;
      this.currency = this.articlesPurchaseEdit.currency;
      this.articlesPurchaseForm.patchValue({ currency: this.currency, paymentType: this.paymentType, articles: this.articlesPurchase });
      this.getTotals();
    }
  }

  ngOnDestroy() {
    this.unitsSubscription.unsubscribe();
  }

  createForm(): void {
    this.articlesPurchaseForm = this.formBuilder.group({
      articles: [[], Validators.required],
      subtotal: ['', Validators.required],
      discount: [0],
      net: ['', Validators.required],
      iva: ['', Validators.required],
      total: ['', Validators.required],
      paymentType: ['', Validators.required],
      currency: ['', Validators.required]
    });
  }

  changeArticlesPurchaseForm() {
    if (this.articlesPurchaseForm.valid) {
      this.eventChangeArticlesPurchaseForm.emit(this.articlesPurchaseForm.value);
    }
  }

  async addToArticles() {
    if (this.quantity == 0 || this.unit == '' || this.description == '' || this.unitValue == 0 || this.total == 0) {
      return AlertService.toastError('Ningún campo debe de estar vacío');
    }

    if (this.unitCode != this.firstUnitCode && this.unitKey) await this._providerArticle.update(this.unitKey, { code: this.unitCode } as ProviderArticle);

    this.articlesPurchase.push(
      {
        quantity: this.quantity,
        unit: await this.db.getReference(`units/${this.unit.key}`),
        description: this.description,
        code: this.unitCode,
        providerCode: this.providerCode,
        unitValue: this.unitValue,
        discount: this.discount,
        total: this.total,
        exempt: this.exempt
      }
    );

    this.quantity = 0;
    this.unit = '';
    this.unitCode = '';
    this.providerCode = '';
    this.description = '';
    this.unitValue = 0;
    this.discount = 0;
    this.total = 0;
    this.exempt = false;

    await this.updateForm();
  }

  async updateForm() {
    await this.getTotals();
    this.articlesPurchaseForm.patchValue({ articles: this.articlesPurchase });
    this.changeArticlesPurchaseForm();
  }

  getTotals(calculateWithDiscount = true) {
    this.subtotal = 0;
    this.discountTotal = 0;
    this.net = 0;
    this.iva = 0;
    this.totalValue = 0;
    this.exempt = false;

    for (let i = 0; i < this.articlesPurchase?.length; i++) {
      let subTotal: number = +this.articlesPurchase[i].unitValue * +this.articlesPurchase[i].quantity;
      let exempt: boolean = this.articlesPurchase[i].exempt;
      let discount;
      let net;
      let iva = 0;
      let total;

      if (!calculateWithDiscount) subTotal = +this.articlesPurchase[i].total;

      net = subTotal;
      if (this.articlesPurchase[i].discount > 0 && calculateWithDiscount) {
        discount = subTotal * (this.articlesPurchase[i].discount / 100);
        net = subTotal - discount;
      }

      if (!exempt) iva = Math.round(net * (19 / 100));

      total = net + iva;

      if (isNaN(discount)) discount = 0;

      this.subtotal = this.subtotal + subTotal;
      this.discountTotal = this.discountTotal + discount;
      this.net = this.net + net;
      this.iva = this.iva + iva;
      this.totalValue = this.totalValue + total;
    }

    this.articlesPurchaseForm.patchValue({
      subtotal: this.subtotal,
      discount: this.discountTotal,
      net: this.net,
      iva: this.iva,
      total: this.totalValue,
      articles: this.articlesPurchase
    });

    this.emiTotal(this.totalValue);
    this.changeArticlesPurchaseForm();
  }

  async dropArticle(index: number) {
    this.articlesPurchase.splice(index, 1);
    await this.updateForm();
  }

  setPaymentType() {
    this.articlesPurchaseForm.patchValue({ paymentType: this.paymentType });
    this.changeArticlesPurchaseForm();
  }

  setCurrency() {
    this.articlesPurchaseForm.patchValue({ currency: this.currency });
    this.changeArticlesPurchaseForm();
  }

  setTotal() {
    this.total = (this.unitValue * this.quantity) * (1 - (this.discount / 100));
  }

  setTotalInRow(article, index) {
    this.articlesPurchase[index].total = (this.articlesPurchase[index].unitValue * this.articlesPurchase[index].quantity) * (1 - (this.articlesPurchase[index].discount / 100));
    this.getTotals();
  }

  convertToTime(event) {
    let newDeliveryDate = event.value.replaceAll('-', '/');
    let deliveryDateMilliseconds = new Date(newDeliveryDate).getTime();

    this.eventChangeDeliveryDate.emit({
      deliveryDate: deliveryDateMilliseconds,
      approvalDate: this.currentDate
    });
  }

  private formatDate() {
    const deliveryDateDate = new Date(this.deliveryDate);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    this.deliveryDate = formattedDate.replace(/\//g, '-');
    let date = { value: this.deliveryDate };
    this.convertToTime(date);
  }

  openArticleSearcher() {
    const modalRef = this.modal.show(SearchOrderArticleComponent, {
      initialState: {
        articles$: this._providerArticle.getAll(),
        units$: this.units$
      },
      class: 'modal-xl shadow-lg mt-5',
      id: 100
    });

    modalRef.content.selectedArticle.subscribe((article) => {
      this.quantity = article.quantity;
      this.providerCode = article.providerCode;
      this.unit = this.unitsArray.find(unit => unit.id == article.unit.id)
      this.description = article.name;
      this.unitValue = article.unitPrice;
      this.discount = article.discount;
      this.exempt = article.exempt;
      if (!article.code) {
        this.unitCode = null;
        this.firstUnitCode = null;
      } else {
        this.unitCode = article.code;
        this.firstUnitCode = article.code;
      }
      this.unitKey = article.key;
      this.setTotal();
    });
  }

  addUnit() {
    this.modal.show(UnitModalComponent, {
      id: 50
    });
  }

  isReference(unit) {
    return !!unit.path;
  }

  setProviderCode() {
    // this.providerCode = this.unit.code;
  }

  emiTotal(total: number) {
    this.totalEmitter.emit(total);
  }
}
