import { Injectable } from '@angular/core';
import * as XLSX from '../../../../node_modules/xlsx';
import * as FileSaver from '../../../../node_modules/file-saver';
import moment from 'moment';
import { ExcelHeader } from '../../admin/interfaces/excel-header';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { first } from 'rxjs/operators';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {
  constructor(private db: FirebaseDataService,) {
  }

  public async exportAsExcelFile(headers: ExcelHeader[], data: any[], excelFileName: string) {
    const populatedData = await Promise.all(data.map(async item => {
      return await this.populateRow(item, headers);
    }));

    const filteredData = populatedData.map(item => {
      const filteredItem: any = {};
      headers.forEach(header => {
        filteredItem[header.name] = this.getValue(item, header) || '';
      });
      return filteredItem;
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData, {
      header: headers.map(header => header.name)
    });

    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private async populateRow(row, headers) {
    for (const header of headers) {
      const attrs = header.att.split('.');
      for (const att of attrs) {
        if (row[att] && row[att].path) {
          row[att] = await this.db.docWithId$(row[att].path).pipe(first()).toPromise();
        }
      }
    }
    return row;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private getValue(row, headerItem) {
    const attrs = headerItem.att.split('.');
    let value = '';

    if (attrs.length > 1) value = attrs.reduce((acc, att) => att[acc], row);
    else value = row[headerItem.att];

    if (!value) return '';
    if (headerItem.type == 'date') value = moment(value).format('DD/MM/YYYY');
    if (headerItem.type == 'currency') value = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(+value);

    return value;
  }
}
