import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { JobService } from '../../../shared/services/job.service';
import { take } from 'rxjs/operators';
import { Applicant } from 'src/app/admin/interfaces/applicant';
import { WizardComponent } from 'angular-archwizard';
import { GeneralRegisterComponent } from '../../components/general-register/general-register.component';
import { AppConstants } from '../../../shared/helpers/app.constants';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild(WizardComponent) public wizard: WizardComponent;
  @ViewChild(GeneralRegisterComponent) public generalStep: GeneralRegisterComponent;
  formApplicantSubmitted: boolean = false;
  formCurriculumSubmitted: boolean = false;
  quizPosition: string;
  applicants: Applicant[];
  applicant;
  quiz: any;
  documents: any;
  profilePhoto: any;
  complete: boolean = false;
  messageLoading: string = 'Registrando...';
  completeMessage: string = 'Gracias por ingresar tus datos, han sido guardados exitosamente.';

  constructor(private _applicant: ApplicantService,
              private SpinnerService: NgxSpinnerService,
              private _job: JobService) {
  }

  async ngOnInit(): Promise <void> {
    this.applicants = await this._applicant.getAll().pipe(take(1)).toPromise();
  }

  changeQuiz(quizPosition: string): void {
    this.quizPosition = quizPosition;
  }

  getQuiz(quiz: any) {
    this.quiz = quiz;
  }

  getDocuments(documents: any) {
    this.documents = documents;
  }

  getProfilePhoto(profilePhoto: string) {
    this.profilePhoto = profilePhoto;
  }

  async submitApplicant() {
    this.SpinnerService.show();
    let applicant = {
      rut: this.generalStep.applicantForm.value.rut.trim(),
      name: this.generalStep.applicantForm.value.name,
      surnames: this.generalStep.applicantForm.value.surnames,
      region: this.generalStep.applicantForm.value.region,
      city: this.generalStep.applicantForm.value.city,
      position: { reference: this._job.getReference(this.generalStep.applicantForm.value.job.key) },
      status: this.generalStep.applicantForm.value.status,
      birthday: new Date(this.generalStep.applicantForm.value.birthday).getTime(),
      age: this.getAge(new Date(this.generalStep.applicantForm.value.birthday).getTime()),
      workExperience: this.generalStep.applicantForm.value.workExperience,
      educationLevel: this.generalStep.applicantForm.value.educationLevel,
      email: this.generalStep.applicantForm.value.email,
      phone: this.generalStep.applicantForm.value.phone,
      nationality: this.generalStep.applicantForm.value.nationality,
      experience: this.generalStep.applicantForm.value.experience,
      expectation: this.generalStep.applicantForm.value.expectation,
      maritalStatus: this.generalStep.applicantForm.value.maritalStatus,
      quiz: !!this.quiz ? this.quiz : null,
      titleApplicant: null,
      curriculum: null,
      trash: false,
      createdAt: new Date().getTime()
    };

    this.messageLoading = 'Subiendo título… 33%';

    applicant.titleApplicant = this.documents.title ? await this._applicant.uploadTitle(this.documents.title, null) : null;

    this.messageLoading = 'Subiendo curriculum… 66%';

    applicant.curriculum = await this._applicant.uploadCurriculum(this.documents.curriculum, null);

    this.messageLoading = 'Cargando… 99%';

    this.applicant = this.findApplicant(applicant.rut);

    if (!!this.applicant) {
      delete applicant.rut;
      await this._applicant.update(this.applicant.key, applicant);
    } else {
      await this._applicant.add(applicant.rut.trim(), applicant);
    }

    this.SpinnerService.hide();
    AlertService.toastSuccess('Se agregó correctamente');
    this.complete = true;
  }

  findApplicant(rut) {
    return this.applicants.find(applicantUnique => this.normalizeRut(applicantUnique.rut) == this.normalizeRut(rut));
  }

  normalizeRut(rut: string) {
    return rut.replaceAll('.', '').replaceAll('-', '');
  }

  getAge(date) {
    this.messageLoading = 'Obteniendo edad… 0%';
    let timeDiff = Math.abs(Date.now() - date);
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
  }

  async formApplicantSubmittedToTrue() {
    this.formApplicantSubmitted = true;

    if (this.generalStep.applicantForm.invalid) return;

    if (!!this.findApplicant(this.generalStep.applicantForm.value.rut)) {
      if (await AlertService.confirm('Ya existe una solicitud con tus datos, ¿Deseas continuar para actualizar tus datos?')) {
        this.wizard.navigation.goToNextStep();
        return;
      } else {
        this.completeMessage = 'Tus datos ya están siendo procesados por nuestro equipo.';
        this.complete = true;
        return;
      }
    }

    this.wizard.navigation.goToNextStep();
  }

  formCurriculumSubmittedToTrue() {
    this.formCurriculumSubmitted = true;
  }

  public readonly AppConstants = AppConstants;
}
