<div class="p-4">
  <div class="form-group">
    <div class="text-bold-600 font-medium-2">
      Destinatarios
    </div>
    <ng-select
      [items]="[]"
      [addTag]="true"
      [(ngModel)]="recipients"
      [multiple]="true"
      placeholder="ejemplo: usuario@dominio.com"
    >
    </ng-select>
  </div>

  <div class="form-group">
    <label>Comentario <span class="required">*</span></label>
    <textarea cols="30" rows="10" class="form-control" [(ngModel)]="comment"></textarea>
  </div>
  <div class="d-flex justify-content-end">
    <button class=" btn btn-success" (click)="submit()">Enviar</button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">Enviando PDF...</p></ngx-spinner>
