import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';

import {ApplicantRoutingModule} from './applicant-routing.module';
import {ApplicantComponent} from './applicant.component';
import {RegisterComponent} from './pages/register/register.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {ChartistModule} from 'ng-chartist';
import {CardModule} from '../shared/template-components/partials/general/card/card.module';
import {ArchwizardModule} from 'angular-archwizard';
import {GeneralRegisterComponent} from './components/general-register/general-register.component';
import {QuizComponent} from './components/quiz/quiz.component';
import {CurriculumComponent} from './components/curriculum/curriculum.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { UpdateCvComponent } from './pages/update-cv/update-cv.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PreselectComponent } from './pages/preselect/preselect.component';


@NgModule({
  declarations: [
    ApplicantComponent,
    RegisterComponent,
    GeneralRegisterComponent,
    QuizComponent,
    CurriculumComponent,
    UpdateCvComponent,
    PreselectComponent
  ],
  exports: [
    RegisterComponent
  ],
  imports: [
    CommonModule,
    ApplicantRoutingModule,
    NgSelectModule,
    FormsModule,
    ChartistModule,
    SharedModule,
    CardModule,
    ArchwizardModule,
    NgxSpinnerModule,
    MatInputModule,
    MatSelectModule
  ],
  providers: [CurrencyPipe]
})
export class ApplicantModule {
}
