<div (window:resize)="onResize($event)"></div>
<div class="d-flex flex-column h-100" style=" min-width: 0;">
  <app-navigation></app-navigation>
  <div class="app-content content flex-1 d-flex flex-column flex-grow-1" style="min-height: 0">
    <app-embedded-layout></app-embedded-layout>
    <div class="content-wrapper flex-1 overflow-auto" *ngIf="hidePrivateLayout">
      <div class="content-body" id="content-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="sidenav-overlay d-none" id="sidenav-overlay" (click)="rightbar($event)"></div>
  <app-footer class="mt-1"></app-footer>
</div>
