<div #tableContainer>
  <ng-container *ngIf="rows$ | async as rows; else notObserbable">
    <ng-container *ngIf="rows && rows.length > 0; else notFound">
      <div
        class="card"
        *ngIf="rows | filter: search : (!!config.filtersFields ? config.filtersFields : []) | sort : sortByFieldSelected : orderType as filterRows"
      >
        <div class="card-header" *ngIf="!!config.title || config.hasSearch">
          <h4 class="card-title">{{ config.title }}</h4>

          <div class="d-flex flex-row justify-content-between">
            <h4 class="card-title mt-2 mr-2">Se encontraron {{ rows.length }} resultados</h4>
            <h4 class="card-title mt-2" *ngIf="!!config.propTotal">
              {{
                !!config.propLabelTotal
                  ? config.propLabelTotal : 'Total'
              }}
              : {{ calculateTotal(rows) | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency }}</h4>
            <div class="d-flex justify-content-around">
            </div>
            <h4 class="card-title mt-2" *ngIf="!!config.propTotal2">
              {{ config.propLabelTotal2 }}
              : {{ calculateTotal2(rows) | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency }}</h4>
            <h4 class="card-title mt-2" *ngIf="!!config.propTotal3">
              {{ config.propLabelTotal3 }}
              : {{ calculateTotal3(rows) | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency }}</h4>
          </div>

          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li class="mr-1">
                <button
                  class="btn btn-warning"
                  (click)="printList()"
                  app-tooltip="Imprimir lista"
                >
                  <i class="la la-print"></i>
                </button>
              </li>
              <li class="mr-1" *ngIf="!!config.exportCallback">
                <button
                  class="btn btn-success"
                  (click)="exportToExcel(rows)"
                  app-tooltip="Exportar lista a csv"
                >
                  <i class="fa fa-file-text-o"></i>
                </button>
              </li>
              <li class="mr-1" *ngIf="!!config.exportToExcel">
                <button
                  class="btn btn-success"
                  (click)="exportToExcel(rows)"
                  app-tooltip="Exportar lista a excel"
                >
                  <i class="fa fa-file-excel-o"></i>
                </button>
              </li>
              <li *ngIf="config.hasSearch">
                <div class="form-group position-relative has-icon-left">
                  <input
                    type="text"
                    class="form-control"
                    id="iconLeft4"
                    placeholder="Buscar"
                    [(ngModel)]="search"
                  >
                  <div class="form-control-position">
                    <i class="feather ft-search"></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="card-content mt-3"
          #componentID
        >
          <div (scroll)="handleVerticalScroll($event)" class="table-responsive" #tableResponsive>
            <table class="table border_bottom table-hover">
              <thead #tableHeader>
              <tr>
                <th
                  scope="col"
                  class="border-top-0"
                  [style.top]="inverseOfTranslation"
                  [ngClass]="{'d-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                  *ngFor="let column of columns"
                  (click)="selectSortByField(column.field)"
                >
                <span
                  *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)"
                >{{ column.title }}</span>
                </th>
              </tr>
              </thead>
              <tbody #tableBody>
              <tr
                [ngClass]="{'cursor-pointer':!config.noClickable}"
                *ngFor="let row of filterRows | pagination: page : config.paginationLimit; index as index"
              >
                <td
                  class="text-truncate text-column"
                  [ngClass]="{'text-small' : printSmall && isPrinting, 'd-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                  *ngFor="let column of columns"
                  (click)="selectRow(column.isClickable, row)"
                >
                  <ng-container
                    [ngTemplateOutlet]="column.template"
                    [ngTemplateOutletContext]="{ $implicit: row, index: index }"
                    *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)"
                  >
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>

            <app-pages
              [rowsPerPage]="config.paginationLimit"
              [totalRows]="filterRows.length"
              (pageChange)="page = $event"
            ></app-pages>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #notFound>
      <app-not-found
        size="medium"
        [title]="config.notFoundText"
      ></app-not-found>
    </ng-template>
  </ng-container>

  <ng-template #notObserbable>
    <ng-container *ngIf="rows && rows.length > 0; else notFound">
      <div
        class="card"
        *ngIf="rows | filter: search : (!!config.filtersFields ? config.filtersFields : []) | sort : sortByFieldSelected : orderType as filterRows"
      >
        <div class="card-header" *ngIf="!!config.title || config.hasSearch">
          <h4 class="card-title">{{ config.title }}</h4>

          <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-column">
              <h4 class="card-title mt-2 mr-2">Se encontraron {{ rows.length }} resultados</h4>
              <h4
                class="card-title mt-2" *ngIf="!!config.propTotal"
              >
                {{
                  !!config.propLabelTotal
                    ? config.propLabelTotal : 'Total'
                }}
                : {{ calculateTotal(rows) | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency }}
              </h4>
            </div>
            <div class="d-flex justify-content-around">
            </div>
            <h4
              class="card-title mt-2"
              *ngIf="!!config.propTotal2"
            >
              {{ config.propLabelTotal2 }}
              : {{ calculateTotal2(rows) | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency }}</h4>
            <h4
              class="card-title mt-2"
              *ngIf="!!config.propTotal3"
            >
              {{ config.propLabelTotal3 }}
              : {{ calculateTotal3(rows) | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency }}</h4>
          </div>

          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li class="mr-1">
                <button
                  class="btn btn-warning"
                  (click)="printList()"
                  app-tooltip="Imprimir lista"
                >
                  <i class="la la-print"></i>
                </button>
              </li>
              <li
                class="mr-1"
                *ngIf="!!config.exportCallback"
              >
                <button
                  class="btn btn-success"
                  (click)="exportToExcel(rows)"
                  app-tooltip="Exportar lista"
                >
                  <i class="fa fa-file-excel-o"></i>
                </button>
              </li>
              <li *ngIf="config.hasSearch">
                <div class="form-group position-relative has-icon-left">
                  <input
                    type="text"
                    class="form-control"
                    id="iconLeft4"
                    placeholder="Buscar"
                    [(ngModel)]="search"
                  >
                  <div class="form-control-position">
                    <i class="feather ft-search"></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="card-content mt-3"
          #componentID
        >
          <div (scroll)="handleVerticalScroll($event)" class="table-responsive" #tableResponsive>
            <table class="table border_bottom table-hover">
              <thead #tableHeader>
              <tr>
                <th
                  scope="col"
                  class="border-top-0"
                  [style.top]="inverseOfTranslation"
                  [ngClass]="{'d-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                  *ngFor="let column of columns"
                  (click)="selectSortByField(column.field)"
                >
                <span
                  *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)"
                >{{ column.title }}</span>
                </th>
              </tr>
              </thead>
              <tbody #tableBody>
              <tr
                [ngClass]="{'cursor-pointer':!config.noClickable}"
                *ngFor="let row of filterRows | pagination: page : config.paginationLimit; index as index"
              >
                <td
                  class="text-truncate text-column"
                  [ngClass]="{'text-small' : printSmall && isPrinting, 'd-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                  *ngFor="let column of columns"
                  (click)="selectRow(column.isClickable, row)"
                >
                  <ng-container
                    [ngTemplateOutlet]="column.template"
                    [ngTemplateOutletContext]="{ $implicit: row, index: index }"
                    *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)"
                  >
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>

            <app-pages
              [rowsPerPage]="config.paginationLimit"
              [totalRows]="filterRows.length"
              (pageChange)="page = $event"
            ></app-pages>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #notFound>
      <app-not-found
        size="medium"
        [title]="config.notFoundText"
      ></app-not-found>
    </ng-template>
  </ng-template>
</div>
