<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
>
  <p style="color: white">Cargando artículos de bodegas...</p>
</ngx-spinner>

<div class="bulk-transfer" *ngIf="!loading">
  <div class="warehouses-wrapper" cdkDropListGroup>

    <div class="column">
      <ng-select
        [clearable]="true"
        [items]="selectOptions"
        bindLabel="name"
        bindValue="value"
        placeholder="Bodega origen"
        [(ngModel)]="selectedOrigin"
      ></ng-select>
      <div
        *ngIf="!selectedOrigin?.length; else originWarehouseTemplate"
        class="w-100 h-100 d-flex align-items-center justify-content-center"
      >
        <span class="text-light">No se ha seleccionado la bodega de origen</span>
      </div>
      <ng-template #originWarehouseTemplate>
        <div class="panel-container">
          <app-warehouse-container
            *ngIf="selectedOrigin"
            [warehouseId]="selectedOrigin"
          ></app-warehouse-container>
        </div>
      </ng-template>
    </div>
    <div class="column">
      <ng-select
        [multiple]="true"
        [clearable]="true"
        [items]="selectOptions"
        bindLabel="name"
        bindValue="value"
        placeholder="Bodegas destino"
        [(ngModel)]="selectedDestination"
      ></ng-select>
      <div class="h-100 w-100 overflow-auto">
        <div
          *ngIf="!selectedDestination?.length ; else destinationWarehousesTemplate"
          class="w-100 h-100 d-flex align-items-center justify-content-center"
        >
          <span class="text-light">No se han seleccionado las bodegas destino</span>
        </div>
        <ng-template #destinationWarehousesTemplate>
          <div class="panel-container">
            <app-destination-warehouse
              *ngFor="let warehouse of selectedDestination"
              [warehouseId]="warehouse"
            ></app-destination-warehouse>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end w-100">
    <button class="btn btn-danger mr-2" (click)="closeModal()">Cancelar</button>
    <button class="btn btn-primary" (click)="submit()">Terminar</button>
  </div>
</div>
