import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PurchaseOrderService } from '../../../../../../../shared/services/purchase-order.service';
import { AlertService } from '../../../../../../../shared/template-services/alert.service';

@Component({
  selector: 'app-description-form',
  templateUrl: './description-form.component.html',
  styleUrls: ['./description-form.component.scss']
})
export class DescriptionFormComponent implements OnInit {
  description: string;
  purchaseOrderId: string;

  constructor(private modal: BsModalService,
    private _purchase: PurchaseOrderService) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modal.hide(666);
  }

  async updateDescription() {
    if (!this.description) {
      AlertService.toastError('Debe ingresar una descripción');
      return;
    }

    if (!await AlertService.confirm('¿Está seguro de actualizar la descripción?')) return;

    await this._purchase.updateDescription(this.purchaseOrderId, this.description);

    this.closeModal();
    AlertService.toastSuccess('La descripción se actualizó correctamente');
  }
}
