import { Routes } from '@angular/router';
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { FirstLoginModule } from './first-login/first-login.module';
import { ApplicantModule } from './applicant/applicant.module';
import { ExternalModule } from './external/external.module';

const externalRoutes: Routes = [
  {
    path: 'external',
    loadChildren: () => ExternalModule,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'external'
  }
];

const adminRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => AdminModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'first-login',
    loadChildren: () => FirstLoginModule,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'admin/blog'
  }
];

const applicantRoutes: Routes = [
  {
    path: 'aplicante',
    loadChildren: () => ApplicantModule
  },
  {
    path: '**',
    redirectTo: 'aplicante'
  }
];

const getRoutes = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return [
        ...applicantRoutes.slice(0, -1),
        ...externalRoutes.slice(0, -1),
        ...adminRoutes
      ];

    case 'plataforma.colsan.cl':
      return adminRoutes;

    case 'postular.colsan.cl':
      return applicantRoutes;

    case 'colsan-dev.firebaseapp.com':
      return [
        ...applicantRoutes.slice(0, -1),
        ...externalRoutes.slice(0, -1),
        ...adminRoutes
      ];

    case 'colsan-dev.web.app':
      return [
        ...applicantRoutes.slice(0, -1),
        ...externalRoutes.slice(0, -1),
        ...adminRoutes
      ];
  }
};

export const appRoutes: Routes = getRoutes();
