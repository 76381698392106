import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../template-services/auth.service';
import { AlertService } from '../template-services/alert.service';
import { take } from 'rxjs/operators';
import { ApplicantService } from '../services/applicant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private _auth: AuthService,
              private _user: UserService,
              private _applicant: ApplicantService) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const authUser = await this._auth.loadFirebaseUser();

    if (!authUser) {
      if (state.url == '/auth/login') return true;
      return this.router.navigateByUrl('auth');
    }

    const user = await this._user.loadUser(authUser.uid);

    if (!user) await this._auth.signOut();

    let applicant = null;

    if (user.isApplicant) {
      applicant = await this._applicant.get(user.rut).pipe(take(1)).toPromise();

      if (!applicant) {
        const resp = await this._applicant.getByRut(user.rut).pipe(take(1)).toPromise();
        applicant = resp ? resp[0] : null;
      }

      if (applicant && applicant.initialAccessDate && applicant.finalAccessDate) {
        const currentDate = new Date().getTime();

        if (currentDate < applicant.initialAccessDate) {
          await AlertService.error('Aún no puedes acceder a la plataforma', 'Comunícate con administración');
          await this._auth.signOut();
        } else if (currentDate > applicant.finalAccessDate) {
          await AlertService.error('Tu acceso a la plataforma ha expirado', 'Comunícate con administración');
          await this._applicant.update(applicant.key, { status: 'calificado' } as any);
          await this._auth.signOut();
        }
      }
    }

    const whitelist = await this._auth.getWhiteList().pipe(take(1)).toPromise();

    if (!whitelist.find((item: any) => item.email == this._user.user.email)) {
      await AlertService.error(`El usuario ${this._user.user.email} no tiene acceso`);
      await this._auth.signOut();
    } else if (this._user.user.isDisable) {
      await AlertService.error('Tu cuenta ha sido deshabilitada', 'Comuníquese con administración');
      await this._auth.signOut();
    } else if (this._user.user.firstLogin && this._user.user.isApplicant) {
      await AlertService.error('Debes subir tus documentos para poder acceder a la plataforma', 'Lo redirigiremos a la sección de documentos');
      await this._auth.signOut();

      window.location.href = `https://postular.colsan.cl/aplicante/documentos/${applicant.rut}`;
      return;
    }

    if (state.url == '/auth/login') return this.router.navigateByUrl('admin/blog');
    return true;
  }
}
