import {Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ProviderArticle } from '../../interfaces/provider-article';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PurchaseOrder } from '../../pages/purchase-orders/interfaces/purchase-order';
import { PurchaseOrderModalComponent } from '../../pages/purchase-orders/modals/purchase-order-modal/purchase-order-modal.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-provider-article',
  templateUrl: './provider-article.component.html',
  styleUrls: ['./provider-article.component.css']
})
export class ProviderArticleComponent implements OnInit {
  article: any;

  constructor(
    private modal: BsModalService
  ) {
  }

  ngOnInit(): void {
  }

  openPurchaseOrder(purchaseOrder: PurchaseOrder) {
    const modalRef = this.modal.show(PurchaseOrderModalComponent, {
      initialState: {
        purchaseOrder: { ...purchaseOrder }
      },
      class: 'modal-xxl90',
      backdrop: 'static',
      id: 31
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      const newUrl = window.location.href.replace(`/modal/${purchaseOrder.key}`, '');
      window.history.pushState({}, '', newUrl);
    });
  }
}
