import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, take } from 'rxjs/operators';
import { Setting } from '../interfaces/setting';
import { Observable } from 'rxjs';
import { ApplicantSettings } from '../interfaces/applicant-settings';

@Injectable({
  providedIn: 'root'
})
export class SettingService extends FirebaseDataService {

  constructor(private afs: AngularFirestore) {
    super(afs, null);
  }

  async getTravelAdvanceID() {
    return ((await this.docWithId$('settings/setting').pipe(first()).toPromise()) as Setting).travelAdvanceID;
  }

  async updateTravelAdvanceID(travelAdvanceID: number) {
    this.update('settings/setting', { travelAdvanceID });
  }

  async createApplicantsSettings() {
    await this.afs.doc(`settings/applicants`).set({ createdAt: Date.now() });
  }

  getApplicantsSettings(): Observable<ApplicantSettings> {
    return this.docWithId$(`settings/applicants`);
  }

  getPeopleManagementSettings(): Observable<any> {
    return this.docWithId$(`settings/peopleManagement`);
  }

  async updateApplicantsSettings(applicantSettings: Partial<ApplicantSettings>) {
    return this.update(`settings/applicants`, { ...applicantSettings, updatedAt: Date.now() });
  }

  async getSetting() {
    return await this.docWithId$(`settings/setting`).pipe(take(1)).toPromise()
  }
}
