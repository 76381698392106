<div class="card mb-0">
  <div class="position-relative d-flex p-2">
    <div app-tooltip="Eliminar" class="remove-icon">
      <i (click)="removeUser()" class="fa fa-times"></i>
    </div>
    <img [src]="user.imageUrl" alt="Icono del perfil de usuario" class="user-icon"/>
    <div class="d-flex flex-column">
      <span> <strong>{{ user.name }}</strong></span>
      <div *ngIf="mapTypeToLabel[permission]; else noPermissions" class="text-success" style="font-size: 0.75rem">
        <i class="fa fa-circle  permission-indicator"></i>
        {{ mapTypeToLabel[permission] }}
      </div>
      <ng-template #noPermissions>
        <div class="text-muted" style="font-size: 0.75rem">
          <i class="fa fa-question-circle permission-indicator"></i>
          Sin permisos
        </div>
      </ng-template>
    </div>
  </div>
</div>

