<div class="floating-menu-wrapper" #menu>
  <div *ngIf="!isAddingShortcut">
    <div class="floating-menu" *ngIf="showMenuItems">
      <div
        class="menu-item show"
        [style.top]="menuItems[0].top"
        [style.left]="menuItems[0].left"
      >
        <span class="menu-item-label" [ngClass]="{'visible':isMobile}"> Reportar un problema</span>
        <button class="btn btn-danger" (click)="toggleBugRecorder();toggleMenu()">
          <i class="fa fa-bug"></i>
        </button>
      </div>
      <div
        class="menu-item show"
        [style.top]="menuItems[1].top"
        [style.left]="menuItems[1].left"
        *ngIf="showAddButton"
      >
        <span
          class="menu-item-label align-self-stretch"
          [ngClass]="{'visible':isMobile}"
        > Agregar nuevo acceso directo</span>
        <button class="btn btn-success" (click)="toggleNewShortcutMenu()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <div
        *ngFor="let item of menuItems.slice(showAddButton ? 2 : 1); let i = index"
        class='menu-item'
        [style.top]="item.top"
        [style.left]="item.left"
        [style.animation-delay]="calculateDelay(i)"
      >
        <span class="menu-item-label" [ngClass]="{'visible':isMobile}">{{ item.label || ' ' }}</span>
        <a
          [class]="'btn btn-' + (item.color ? item.color : 'info')"
          (click)="toggleMenu()"
          href="{{item.to}}"
        >
          <i class="fa fa-{{ item.icon }}"></i>
        </a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isAddingShortcut && !showMenuItems">
    <button
      class="btn btn-primary floating-menu-button"
      (click)="toggleMenu();$event.stopPropagation();"
      app-tooltip="Menu de acceso rápido"
    >
      <i class="fa fa-bars"></i>
    </button>
  </ng-container>
  <ng-container *ngIf="isAddingShortcut">
    <button
      class="btn btn-info floating-menu-button floating-menu-button--action"
      (click)="handleNewShortcut()"
      app-tooltip="Agregar al menú"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      class="btn btn-danger floating-menu-button floating-menu-button--close"
      (click)="toggleNewShortcutMenu()"
      app-tooltip="Cancelar"
    >
      <i class="fa fa-times"></i>
    </button>
  </ng-container>
  <ng-container *ngIf="showMenuItems">
    <button
      class="btn btn-primary floating-menu-button active floating-menu-button--action"
      (click)="toggleMenu(); $event.stopPropagation();"
      app-tooltip="Menu de acceso rápido"
    >
      <i class="fa fa-bars"></i>
    </button>
    <button
      class="btn btn-info floating-menu-button floating-menu-button--close"
      [ngClass]="{'floating-menu-button__active': showMenuItems}"
      (click)="handleEditShortcuts();toggleMenu();$event.stopPropagation();"
      app-tooltip="Configurar accesos directos"
    >
      <i class="fa fa-cog"></i>
    </button>
  </ng-container>
</div>
<app-report-button *ngIf="showRecordPanel" (closeReportPanel)="toggleBugRecorder()"></app-report-button>
