import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArticleService } from '../../../../../shared/services/article.service';
import { Article } from '../../interfaces/article';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryService } from '../../../../../shared/services/category.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { UnitsMeasureService } from '../../../../../shared/services/units-measure.service';
import { ArticleTypeLabel } from '../../labels/article-type.label';
import { ArticleType } from '../../enums/article-type.enum';
import { ProviderService } from '../../../../../shared/services/provider.service';
import { LogsService } from '../../../../services/logs.service';
import { WarehouseService } from '../../../../../shared/services/warehouse.service';
import { Warehouse } from '../../interfaces/warehouse';
import { ProviderModalComponent } from '../../../../modals/provider-modal/provider-modal.component';
import { ValidationService } from '../../../../../shared/template-services/validation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent implements OnInit {
  articleForm: FormGroup;
  submitted: boolean = false;
  categorySubscription: Subscription = new Subscription();
  providerSubscription: Subscription = new Subscription();
  unitMeasureSubscription: Subscription = new Subscription();
  @ViewChild('labelImport', { static: true }) labelImport: ElementRef;
  imageToUpload: File = null;
  path: any;
  profilePhoto: any;
  photoValidation: boolean = true;
  categoriesValidation: boolean = true;
  unitsMeasureValidation: boolean = true;
  multipleSelectArray: any = [];
  selectArrayProviders: any = [];
  selectArray: any = [];
  warehouseKey: string;
  articleType = ArticleTypeLabel;
  articleTypeEnum = ArticleType;
  serialNumberTemp = [];
  serialNumberSelect: any = [];
  sizes: any = {};
  sizeType: string;
  sizesTypes = [
    {
      label: 'General',
      value: 'general'
    },
    {
      label: 'Zapatos',
      value: 'shoes'
    }
  ];

  quantityTemp;

  constructor(private formBuilder: FormBuilder,
    private _article: ArticleService,
    private _category: CategoryService,
    private _provider: ProviderService,
    public modal: BsModalRef,
    private modalService: BsModalService,
    private _log: LogsService,
    private _unitMeasure: UnitsMeasureService,
    private _warehouse: WarehouseService,
    private SpinnerService: NgxSpinnerService) {
    this.articleForm = formBuilder.group({
      type: ['', Validators.required],
      name: ['', Validators.required],
      internalId: ['', Validators.required],
      quantity: [this.quantityTemp, Validators.required],
      price: ['', Validators.required],
      categories: [[], Validators.required],
      minStock: [0],
      location: ['', Validators.required],
      imageUrl: [''],
      totalQuantity: ['', Validators.required],
      provider: [''],
      maxStock: [''],
      trash: [false],
      serialNumber: [this.serialNumberTemp],
      unitMeasure: [[], Validators.required],
      size: ['']
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getCategories();
    await this.getProviders();
    await this.getUnitsMeasure();
    this.sizes = this._article.sizes;
  }

  get formControls() {
    return this.articleForm.controls;
  }

  isValidPath(): boolean {
    return !(this.path == '' || this.path == null);
  }

  onFileChange(event) {
    if (!ValidationService.validateFileSize(event, 10000)) return AlertService.toastError('El archivo debe ser menor a 10MB');

    this.labelImport.nativeElement.innerText = Array.from(event.target.files as FileList)
      .map(f => f.name)
      .join(', ');
    this.imageToUpload = event.target.files.item(0);
    this.choosePicture(event);
  }

  async submit() {
    this.submitted = true;
    this.articleForm.patchValue({ totalQuantity: this.articleForm.value.quantity });
    if (this.articleForm.valid) {
      this.SpinnerService.show();
      if (!!this.profilePhoto) {
        this.articleForm.patchValue({
          imageUrl: await this._article.uploadPicture(this.profilePhoto, null)
        });
      }
      if (this.articleForm.get('serialNumber').value.length > this.quantityTemp) return AlertService.toastError('Tiene un número serial extra');

      await this._article.add(this.warehouseKey, this.articleForm.value as Article);

      this._warehouse.update(this.warehouseKey, { lastUpdate: new Date().getTime() } as Warehouse);

      this._log.add(this.warehouseKey, {
        description: `Artículo ${this.articleForm.get('name').value}(${this.articleForm.get('internalId').value}) fue agregado al almacén`
      });

      this.SpinnerService.hide();
      AlertService.toastSuccess('Artículo agregado');
      this.modal.hide();
    }
  }

  getCategories() {
    this.categorySubscription = this._category.getAll()
      .pipe(
        map(categories => categories.map(category => ({
            name: category.name,
            key: category.key
          }
        )))
      )
      .subscribe(data => {
        this.multipleSelectArray = data;
      });
  }

  getProviders() {
    this.providerSubscription = this._provider.getAll()
      .pipe(
        map(categories => categories.map(provider => ({
            name: provider.name,
            key: provider.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayProviders = data;
      });
  }

  getUnitsMeasure() {
    this.unitMeasureSubscription = this._unitMeasure.getAll()
      .pipe(
        map(unitsMeasure => unitsMeasure.map(unitMeasure => ({
            name: unitMeasure.name,
            key: unitMeasure.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArray = data;
      });
  }

  choosePicture(event) {
    this.profilePhoto = event.target.files[0];
    if (event.target.files && this.profilePhoto) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.profilePhoto);
      this.articleForm.patchValue({ imageUrl: 'url' });
    }
  }

  async addCategory(): Promise<void> {
    const category = await AlertService.input('Nombre de la categoría');
    if (!!category) {
      this._category.add({ name: category, trash: false });
    }
  }

  async addProvider(): Promise<void> {
    this.modalService.show(ProviderModalComponent, {
      class: 'modal-xl',
      backdrop: 'static'
    });
  }

  async addUnitsMeasure() {
    const unitMeasure = await AlertService.input('Nombre de la unidad de medida');
    if (!!unitMeasure) {
      this._unitMeasure.add({ name: unitMeasure, trash: false });
    }
  }

  async addSerialNumber() {
    if (this.articleForm.get('serialNumber').value.length == this.quantityTemp) return AlertService.toastError('Ya ingresaste el máximo de números de serie');
    this.serialNumberTemp = this.articleForm.get('serialNumber').value;
    for (let i = this.articleForm.get('serialNumber').value.length; i < this.quantityTemp; i++) {
      const serialNumber = await AlertService.input(`Número de serie ${i + 1} de ${this.quantityTemp}`);
      if (!!serialNumber) {
        this.serialNumberTemp.push(serialNumber);
      }
    }
    this.articleForm.patchValue({
      serialNumber: this.serialNumberTemp
    });
  }

  async addQuantity(event) {
    this.quantityTemp = +this.articleForm.get('quantity').value;
  }

  resetSize() {
    this.articleForm.patchValue({ size: '' });
  }
}
