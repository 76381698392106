<div class="warehouse-container">
  <h2>Actualizar bodega</h2>
  <form [formGroup]="editWarehouseForm" (ngSubmit)="submit()" class="warehouse-content">
    <mat-tab-group>
      <mat-tab label="General" class="h-100">
        <div class="container pt-2">
          <div class="row">
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Nombre *</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  placeholder=""
                  [ngClass]="{'is-invalid': submitted && formControls.name.errors}"
                />
                <small
                  class="form-text text-muted danger invalid-feedback"
                  *ngIf="submitted && formControls.name.errors"
                >
                  <div *ngIf="formControls.name.errors.required">
                    El nombre es obligatorio
                  </div>
                </small>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Codigo *</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="code"
                  placeholder=""
                  [ngClass]="{'is-invalid': submitted && formControls.code.errors}"
                />
                <small
                  class="form-text text-muted danger invalid-feedback"
                  *ngIf="submitted && formControls.code.errors"
                >
                  <div *ngIf="formControls.code.errors.required">
                    El codigo es obligatorio
                  </div>
                </small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Descripción *</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="description"
                  placeholder=""
                  [ngClass]="{
                'is-invalid': submitted && formControls.description.errors
              }"
                />
                <small
                  class="form-text text-muted danger invalid-feedback"
                  *ngIf="submitted && formControls.description.errors"
                >
                  <div *ngIf="formControls.description.errors.required">
                    La descripción es obligatorio
                  </div>
                </small>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Ubicación *</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="location"
                  placeholder=""
                  [ngClass]="{
                'is-invalid': submitted && formControls.location.errors
              }"
                />
                <small
                  class="form-text text-muted danger invalid-feedback"
                  *ngIf="submitted && formControls.location.errors"
                >
                  <div *ngIf="formControls.location.errors.required">
                    La ubicación es obligatoria
                  </div>
                </small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <div class="custom-file">
                <ng-select
                  formControlName="icon"
                  [items]="icons"
                  placeholder="Seleccione un icono *"
                >
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="icon-select">
                      <i class="{{item}} font-medium-5"></i>
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <i class="{{item}} font-medium-5"></i>
                  </ng-template>
                </ng-select>
                <small
                  class="text-danger"
                  *ngIf="submitted && formControls.icon.errors"
                >
                  El campo es obligatorio
                </small>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-file">
                  <ng-select
                    formControlName="bgColor"
                    [items]="bgColors"
                    placeholder="Seleccione color de bodega *"
                  >
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <div [style.background-color]="item" class="bg-color-select"></div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div [style.background-color]="item" class="bg-color-select"></div>
                    </ng-template>
                  </ng-select>
                  <small
                    class="text-danger"
                    *ngIf="submitted && formControls.bgColor.errors"
                  >
                    El campo es obligatorio
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-file">
                  <ng-select
                    *ngIf="!!grocersAndBuyers"
                    formControlName="grocer"
                    [items]="grocersAndBuyers"
                    bindLabel="name"
                    placeholder="Seleccione al bodeguero *"
                    appendTo="body"
                  >
                  </ng-select>
                  <small
                    class="text-danger"
                    *ngIf="submitted && formControls.grocer.errors"
                  >
                    El campo es obligatorio
                  </small>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-file">
                  <ng-select
                    formControlName="buyer"
                    [items]="grocersAndBuyers"
                    bindLabel="name"
                    placeholder="Seleccione al comprador *"
                    appendTo="body"
                    required
                  >
                  </ng-select>
                  <small
                    class="text-danger"
                    *ngIf="submitted && formControls.buyer.errors"
                  >
                    El campo es obligatorio
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="custom-file">
                  <ng-select
                    formControlName="type"
                    [items]="warehouseType"
                    bindLabel="name"
                    bindValue="value"
                    placeholder="Seleccione el tipo de bodega"
                    appendTo="body"
                    required
                  >
                  </ng-select>
                  <small
                    class="text-danger"
                    *ngIf="submitted && formControls.buyer.errors"
                  >
                    El campo es obligatorio
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Encargados" class="h-100">
        <div class="users-container">
          <div class="p-1">
            <mat-form-field class="w-100">
              <mat-label>Seleccionar al encargado</mat-label>
              <mat-select
                formControlName="warehouseManager"
                [disableOptionCentering]="true"
                (selectionChange)="handleManagers($event.value)"
              >
                <input
                  class="form-control"
                  placeholder="Buscar entre usuarios asignados"
                  (input)="handleSearchManager($event.target.value)"
                >
                <mat-option
                  *ngFor="let user of managersRemaining"
                  [value]="user?.reference?.id"
                >
                  {{ user?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="users-panel">
            <input
              class="form-control  mb-2"
              placeholder="Buscar entre usuarios asignados"
              (input)="handleSearchManager($event.target.value,false)"
            >
            <div>
              <app-user-card
                *ngFor="let user of filteredManagersSelected"
                [user]="user"
                (removeFromList)="handleManagers($event,true)"
              ></app-user-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Usuarios">
        <div class="users-container">
          <div class="p-1">
            <mat-form-field class="w-100">
              <mat-label>Lista blanca de usuarios</mat-label>
              <mat-select
                formControlName="warehouseManager"
                [disableOptionCentering]="true"
                (selectionChange)="handleWhitelist($event.value)"
              >
                <input
                  class="form-control"
                  placeholder="Buscar entre usuarios asignados"
                  (input)="handleSearchWhitelist($event.target.value)"
                >
                <mat-option
                  *ngFor="let user of filteredWhitelistRemaining"
                  [value]="user.reference.id"
                >
                  {{ user.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="users-panel">
            <input
              class="form-control  mb-2"
              placeholder="Buscar entre usuarios asignados"
              (input)="handleSearchWhitelist($event.target.value,false)"
            >
            <div>
              <app-user-card
                *ngFor="let user of filteredWhitelistSelected"
                [user]="user"
                (removeFromList)="handleWhitelist($event,true)"
              ></app-user-card>
            </div>
          </div>
        </div>

        <ng-template ng-option-tmp let-item="item">
          {{ item.name }}
        </ng-template>
        <small
          class="text-danger"
          *ngIf="submitted && formControls.buyer.errors"
        >
          El campo es obligatorio
        </small>

      </mat-tab>
    </mat-tab-group>
    <div class="modal-footer border-0">
      <button class="btn btn-primary add-btn">Guardar</button>
      <div
        class="btn btn-danger cancel-btn cursor-pointer"
        (click)="close()"
      >
        Cancelar
      </div>
    </div>
  </form>
</div>
