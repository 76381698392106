import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArticlesComponent} from './components/warehouse-details/articles/articles.component';
import {AddArticleComponent} from './modals/add-article/add-article.component';
import {UpdateArticleComponent} from './modals/update-article/update-article.component';
import {AddWarehouseComponent} from './modals/add-warehouse/add-warehouse.component';
import {WarehouseDetailsComponent} from './components/warehouse-details/warehouse-details.component';
import {AddTicketComponent} from './modals/add-ticket/add-ticket.component';
import {TicketsComponent} from './components/warehouse-details/tickets/tickets.component';
import {TicketDetailComponent} from './modals/ticket-detail/ticket-detail.component';
import {UpdateWarehouseComponent} from './modals/update-warehouse/update-warehouse.component';
import {WarehouseSummaryComponent} from './components/warehouse-details/warehouse-summary/warehouse-summary.component';
import {ArticlesReturnedComponent} from './components/warehouse-details/articles-returned/articles-returned.component';
import {PrintArticlesComponent} from './modals/print-articles/print-articles.component';
import {AddArticlesComponent} from './modals/add-articles/add-articles.component';
import {TicketAuthorizationComponent} from './components/warehouse-details/ticket-authorization/ticket-authorization.component';
import {ArticleReturnerQuantityComponent} from './modals/article-returner-quantity/article-returner-quantity.component';
import {SerialsNumberComponent} from './modals/serials-number/serials-number.component';
import {TicketsArticleEntryComponent} from './components/warehouse-details/tickets-article-entry/tickets-article-entry.component';
import {AddTicketEntryArticlesComponent} from './modals/add-ticket-entry-articles/add-ticket-entry-articles.component';
import {TicketEntryDetailsComponent} from './modals/ticket-entry-details/ticket-entry-details.component';
import {SerialsNumbersComponent} from './modals/serials-numbers/serials-numbers.component';
import {MinimumStockComponent} from './components/warehouse-details/minimum-stock/minimum-stock.component';
import {AddSerialNumberComponent} from './modals/add-serial-number/add-serial-number.component';
import {RouterModule} from '@angular/router';
import {WarehousesRoutes} from './warehouses-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {CategoryNamePipe} from './pipes/category-name.pipe';
import { PermissionPipe } from './pipes/permission.pipe';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {ChartistModule} from 'ng-chartist';
import {ChartsModule} from 'ng2-charts';
import {SwapCommasAndDotsPipe} from '../../pipes/swap-commas-and-dots.pipe';
import { UserCardComponent } from './modals/update-warehouse/components/user-card/user-card.component';
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { LogsComponent } from './components/warehouse-details/logs/logs.component';
import { RequestDetailsComponent } from './components/warehouse-details/request-details/request-details.component';
import { UserTicketsComponent } from './components/warehouse-details/user-tickets/user-tickets.component';
import { UserTicketsDetailsComponent } from './components/warehouse-details/user-tickets/components/user-tickets-details/user-tickets-details.component';
import { ArticleHistoryComponent } from './components/warehouse-details/user-tickets/components/article-history/article-history.component';
import { UserListComponent } from './components/warehouse-details/user-tickets/components/user-list/user-list.component';
import { ArticlesTransferComponent } from './components/articles-transfer/articles-transfer.component';
import { WarehouseContainerComponent } from './components/articles-transfer/components/warehouse-container/warehouse-container.component';
import { WarehouseArticleComponent } from './components/articles-transfer/components/warehouse-article/warehouse-article.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DestinationWarehouseComponent } from './components/articles-transfer/components/destination-warehouse/destination-warehouse.component';
import { DestinationWarehouseArticleComponent } from './components/articles-transfer/components/destination-warehouse-article/destination-warehouse-article.component';
import { ArticleQuantityFormComponent } from './components/articles-transfer/components/article-quantity-form/article-quantity-form.component';

@NgModule({
  declarations: [
    ArticlesComponent,
    AddArticleComponent,
    UpdateArticleComponent,
    AddWarehouseComponent,
    WarehouseDetailsComponent,
    AddTicketComponent,
    TicketsComponent,
    TicketDetailComponent,
    UpdateWarehouseComponent,
    WarehouseSummaryComponent,
    ArticlesReturnedComponent,
    PrintArticlesComponent,
    AddArticlesComponent,
    TicketAuthorizationComponent,
    ArticleReturnerQuantityComponent,
    SerialsNumberComponent,
    TicketsArticleEntryComponent,
    AddTicketEntryArticlesComponent,
    TicketEntryDetailsComponent,
    SerialsNumbersComponent,
    MinimumStockComponent,
    AddSerialNumberComponent,
    CategoryNamePipe,
    PermissionPipe,
    UserCardComponent,
    PermissionPipe,
    LogsComponent,
    LogsComponent,
    RequestDetailsComponent,
    UserTicketsComponent,
    UserTicketsDetailsComponent,
    ArticleHistoryComponent,
    UserListComponent,
    ArticlesTransferComponent,
    WarehouseContainerComponent,
    WarehouseArticleComponent,
    DestinationWarehouseComponent,
    DestinationWarehouseArticleComponent,
    ArticleQuantityFormComponent
  ],
  exports: [
    TicketsComponent,
    UserTicketsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(WarehousesRoutes),
    SharedModule,
    NgxQRCodeModule,
    ChartistModule,
    ChartsModule,
    MatInputModule,
    MatSelectModule,
    DragDropModule
  ]
})
export class WarehousesModule { }
