import { Component, OnInit } from '@angular/core';
import { BillDataService } from '../../services/bill-data.service';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'app-bill-classification',
  templateUrl: './bill-classification.component.html',
  styleUrls: ['./bill-classification.component.scss']
})
export class BillClassificationComponent implements OnInit {
  hasPurchaseOrder: boolean = true;
  updatingSubscription = new Subscription();

  constructor(public _billData: BillDataService) {
  }

  ngOnInit(): void {
    this.hasPurchaseOrder = !!this._billData.bill.purchaseOrder;

    this.updatingSubscription = this._billData.updatingEmitter.subscribe((updating) => {
      if (updating && this._billData.billToEdit.project) this.onProjectChange();
    });
  }

  toggleHasPurchaseOrder() {
    this.hasPurchaseOrder = !this.hasPurchaseOrder;

    if (!this.hasPurchaseOrder) {
      this._billData.billToEdit.purchaseOrder = null;
      this._billData.billToEdit.project = null;
      this._billData.billToEdit.projectCategory = null;
    }
  }

  onPurchaseOrderChange(purchaseOrder) {
    this._billData.billToEdit.purchaseOrder = purchaseOrder;
    this._billData.billToEdit.project = purchaseOrder?.project?.id ? _.find(this._billData.projects, (project) => project.key === purchaseOrder.project.id) : null;
    this._billData.billToEdit.projectCategory = purchaseOrder?.budget?.id ? _.find(this._billData.projectCategories, (projectCat) => projectCat.key === purchaseOrder.budget.id) : null;
  }

  onProjectChange() {
    const categoryIndex = _.keyBy(this._billData.projectCategories, 'key');
    this._billData.filteredCategories = this._billData.billToEdit.project.budgets.filter((budget) => categoryIndex[budget.reference.id]);
  }
}
