import { Component, Input, OnInit } from '@angular/core';
import { WarehouseService } from "../../../../../../shared/services/warehouse.service";
import { Warehouse } from "../../../interfaces/warehouse";
import { delay } from "rxjs/operators";
import { WarehouseDetailService } from "../../../services/warehouse-detail.service";
import { DataTableConfig } from "../../../../../../shared/interfaces/data-table-config";

@Component({
  selector: 'app-warehouse-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  @Input() warehouse: Warehouse;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron registros',
    hasSearch: false,
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 25
  };
  logs$: any;

  constructor(private _warehouse: WarehouseService,
    private _warehouseDetail: WarehouseDetailService) {
  }

  async ngOnInit() {
    if (!this.warehouse) {
      setTimeout(() => {
        this.logs$ = this._warehouse.getLogs(this._warehouseDetail.warehouseKey);
      }, 1000);
    }
  }
}
