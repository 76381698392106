<div class="row mt-2">
  <div class="col-xl-12 col-lg-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div (change)="changeStatusFilter()">
              <div class="d-inline-block custom-control custom-radio mr-1">
                <input
                  type="radio" class="custom-control-input bg-success" [(ngModel)]="status"
                  [value]="purchaseStatus.ACTIVE" name="colorRadio" id="colorRadio1"
                >
                <label class="custom-control-label" for="colorRadio1">{{ statusLabel[purchaseStatus.ACTIVE] }}</label>
              </div>
              <div class="d-inline-block custom-control custom-radio mr-1">
                <input
                  type="radio" class="custom-control-input bg-dark" [(ngModel)]="status"
                  [value]="purchaseStatus.INACTIVE" name="colorRadio" id="colorRadio2"
                >
                <label class="custom-control-label" for="colorRadio2">{{ statusLabel[purchaseStatus.INACTIVE] }}</label>
              </div>
              <div class="d-inline-block custom-control custom-radio mr-1">
                <input
                  type="radio" class="custom-control-input bg-danger" [(ngModel)]="status"
                  [value]="purchaseStatus.EXPIRATED" name="colorRadio" id="colorRadio3"
                >
                <label
                  class="custom-control-label"
                  for="colorRadio3"
                >{{ statusLabel[purchaseStatus.EXPIRATED] }}</label>
              </div>
              <div class="d-inline-block custom-control custom-radio mr-1">
                <input
                  type="radio" class="custom-control-input bg-warning" [(ngModel)]="status"
                  [value]="99" name="colorRadio" id="all"
                >
                <label class="custom-control-label" for="all">Todos</label>
              </div>
            </div>
            <div class="mr-1">
              <div *ngIf="userPermission == userTypeEnum.ADMIN || userPermission == userTypeEnum.SUPERVISOR">
                <label>Valor de venta total</label>
                <h5>
                  {{ totalSaleValue| clearCurrency | swapCommasAndDots }}
                </h5>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-datatable
  [rows$]="projectsFiltered$"
  [config]="config"
  (rowSelected)="gotoProjectDetails($event.key)"
>

  <app-datatable-column title="Orden de servicio">
    <ng-template let-row #column>
      {{ row?.code }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{ row?.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha inicio">
    <ng-template let-row #column>
      {{ row?.startDate }}
    </ng-template>
  </app-datatable-column>


  <app-datatable-column title="Fecha finalización">
    <ng-template let-row #column>
      {{ validateEndDate(row) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Días transcurridos">
    <ng-template let-row #column>
      <p>Días de ejecución: {{
          !!calculateElapsedDays(row, true) ? calculateElapsedDays(row, true) : '----'
        }}
      </p>
      <p>Días de retraso: {{
          !!calculateElapsedDays(row, false) ? calculateElapsedDays(row, false) : '----'
        }}
      </p>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estado">
    <ng-template let-row #column>
      <div
        class="badge"
        [ngClass]="{
              'badge-success': row.status == purchaseStatus.ACTIVE,
              'badge-dark' : row.status == purchaseStatus.INACTIVE,
              'badge-danger' : row.status == purchaseStatus.EXPIRATED}"
      >
        {{ statusLabel[row?.status] | titlecase }}{{ !!row.activeLateEndDate ? ' retrasado' : '' }}
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Creador" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <ul class="list-unstyled users-list m-0">
        <li class="avatar avatar-sm pull-up">
          <img
            class="media-object rounded-circle"
            container="body"
            [ngbTooltip]="(row?.createdBy | doc | async)?.name"
            [src]="!!(row?.createdBy | doc | async)?.imageUrl ? (row?.createdBy | doc | async)?.imageUrl : '/assets/images/default.png'"
            alt="Supervisor"
          >
        </li>
      </ul>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Valor de venta">
    <ng-template let-row #column>
      {{ row?.saleValue | clearCurrency | swapCommasAndDots }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column [isClickable]="false">
    <ng-template let-row #column>
      <div
        class="d-flex justify-content-end"
        *ngIf="userPermission == userTypeEnum.ADMIN || userPermission == userTypeEnum.SUPERVISOR"
      >
        <button
          class="btn btn-circle"
          [ngClass]="{
                  'btn-success': row.status == projectStatusEnum.ACTIVE,
                  'btn-dark': row.status == projectStatusEnum.INACTIVE}"
          ngbTooltip="Pasar a {{row.status == projectStatusEnum.ACTIVE ? 'inactivo' : 'activo'}}"
          (click)="changeProjectStatus(row)"
          *ngIf="row.status != projectStatusEnum.EXPIRATED"
        >
          <i class="la la-power-off"></i>
        </button>

        <button
          class="btn btn-danger btn-circle ml-1"
          ngbTooltip="Eliminar"
          (click)="delete(row)"
        >
          <i class="la la-trash"></i>
        </button>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddProjectModal()"></app-add-float-button>

