<div *ngIf="!multiline">
  <div *ngIf="mode == 'bootstrap'">
    <div *ngIf="!control;else withControl">
      <label *ngIf="!!label" class="mb-0 font-weight-bold">{{ label }}</label>
      <input
        class="form-control"
        [type]="type"
        [placeholder]="placeholder"
        (ngModelChange)="onChange($event)"
        [(ngModel)]="model"
      />
    </div>
    <ng-template #withControl>
      <label *ngIf="!!label" class="mb-0 font-weight-bold">{{ label }}</label>
      <input
        class="form-control"
        [type]="type"
        [placeholder]="placeholder"
        [formControl]="control"
      />
      <div class="text-danger error-message">
        <div *ngIf="control.touched && control.errors">
          <div *ngIf="control.errors?.required; else otherError">
            Este campo es obligatorio
          </div>
          <ng-template #otherError>
            <div>
              Valor incorrecto
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>

  <mat-form-field
    class="w-100"
    appearance="outline"
    *ngIf="mode == 'material'"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      *ngIf="!control"
      [type]="type"
      [placeholder]="placeholder"
      (ngModelChange)="onChange($event)"
      [(ngModel)]="model"
    >
    <ng-container *ngIf="control">
      <input
        matInput
        [type]="type"
        [placeholder]="placeholder"
        [formControl]="$any(control)"
        [prefix]="prefix"
        [suffix]="suffix"
        [mask]="mask"
        thousandSeparator="."
        [max]="max"
        (ngModelChange)="onChange($event)"
      >
    </ng-container>
  </mat-form-field>
</div>

<div *ngIf="multiline">
  <ng-container *ngIf="mode == 'bootstrap'">
    <textarea
      class="form-control"
      [placeholder]="placeholder"
      (ngModelChange)="onChange($event)"
      [(ngModel)]="model"
      [rows]="rows"
    ></textarea>
  </ng-container>

  <mat-form-field
    *ngIf="mode == 'material'"
    class="w-100"
    appearance="outline"
  >
    <mat-label>{{ label }}</mat-label>

    <ng-container *ngIf="!control">
      <textarea
        matInput
        [placeholder]="placeholder"
        (ngModelChange)="onChange($event)"
        [(ngModel)]="model"
        [rows]="rows"
      ></textarea>
    </ng-container>

    <ng-container *ngIf="control">
      <textarea
        matInput
        [type]="type"
        [placeholder]="placeholder"
        [formControl]="$any(control)"
        [prefix]="prefix"
        [suffix]="suffix"
        [mask]="mask"
        [rows]="rows"
        thousandSeparator="."
      ></textarea>
    </ng-container>
  </mat-form-field>

</div>
