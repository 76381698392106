<div class="overflow-y-scroll" style="max-height: calc(100vh - 100px)" *ngIf="purchaseOrder">
  <div class="my-2 mx-2">
    <button
      class="btn btn-info cursor-pointer mr-2"
      (click)="copyUrl()"
    >
      Compartir enlace
    </button>
  </div>

  <app-purchase-order-wrapper
    (closeModal)="closeModal()"
    [initialPurchaseOrder]="purchaseOrder"
  ></app-purchase-order-wrapper>
</div>
