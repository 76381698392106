import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray, CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';
import { ShortcutsService } from '../../services/shortcuts.service';
import { UserService } from '../../services/user.service';
import { IShortcut } from '../../interfaces/shortcut';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from '../../template-services/alert.service';
import { AddShortcutComponent } from '../add-shortcut/add-shortcut.component';

@Component({
  selector: 'app-shortcuts-config',
  templateUrl: './shortcuts-config.component.html',
  styleUrls: ['./shortcuts-config.component.scss']
})
export class ShortcutsConfigComponent implements OnInit {
  shortcuts: IShortcut[] = [];

  constructor(private _shortcuts: ShortcutsService,
    private _user: UserService,
    private modal: BsModalService) {
  }

  ngOnInit(): void {
    this._shortcuts.get(this._user.user.shortcuts.path).subscribe(shortcuts => {
      this.shortcuts = [...shortcuts].reverse();
    });
  }

  async drop(event: CdkDragDrop<IShortcut[]>) {
    moveItemInArray(this.shortcuts, event.previousIndex, event.currentIndex);
    await this._shortcuts.updatePosition(this.shortcuts.reverse());
  }

  async handleRemoveShortcut(shortcut: IShortcut) {
    if (!await AlertService.confirm('¿Estás seguro que quieres eliminar este acceso directo?')) return;

    await this._shortcuts.delete(shortcut);
    AlertService.toastSuccess('Acceso directo eliminado');
  }

  async handleEditShortcut(shortcut: IShortcut) {
    this.modal.show(AddShortcutComponent, {
      initialState: {
        currentUrl: shortcut.to,
        shortcutLabel: shortcut.label,
        selectedIcon: shortcut.icon,
        isEdit: true,
        id: shortcut.id,
        modalId: 5003
      },
      id: 5003
    });
  }

  closeModal() {
    this.modal.hide(5002);
  }
}
