import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreselectionStatusEnum } from '../../enums/PreselectionStatusEnum';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { Applicant } from '../../../admin/interfaces/applicant';
import { take } from 'rxjs/operators';
import { AppConstants } from '../../../shared/helpers/app.constants';

@Component({
  selector: 'app-preselect',
  templateUrl: './preselect.component.html',
  styleUrls: ['./preselect.component.css']
})
export class PreselectComponent implements OnInit {
  message: string;
  status: PreselectionStatusEnum;
  applicant: Applicant | null = null;
  applicantNotFound = false;
  loading = true;
  messageLoading = 'Cargando...';
  public PreselectionStatusEnum = PreselectionStatusEnum;
  public readonly AppConstants = AppConstants;
  
  constructor(private activatedRoute: ActivatedRoute,
              private _applicant: ApplicantService) {
              }

  async ngOnInit() {
    const id = this.activatedRoute.snapshot.params.id;
    this.status = this.activatedRoute.snapshot.params.status as PreselectionStatusEnum;

    try {
      this.applicant = await this._applicant.get(id).pipe(take(1)).toPromise();

      if (!this.applicant || this.applicant?.recruitmentStatus?.emailApproved || this.applicant?.recruitmentStatus?.emailRejected) {
        this.applicantNotFound = true;
      } else {
        await this.updateRecruitmentStatus(id, this.status);
      }
    } catch (error) {
      this.applicantNotFound = true;
    } finally {
      this.loading = false;
    }
  }

  async updateRecruitmentStatus(id: string, status: PreselectionStatusEnum) {
    if (this.applicant) {
      const updatedRecruitmentStatus = { ...this.applicant.recruitmentStatus };
      if (status === PreselectionStatusEnum.ACCEPTED) {
        updatedRecruitmentStatus.emailApproved = true;
      } else if (status === PreselectionStatusEnum.REJECTED) {
        updatedRecruitmentStatus.emailApproved = false;
        updatedRecruitmentStatus.emailRejected = true;
      }
      try {
        await this._applicant.updateRecruimentStatus(id, updatedRecruitmentStatus);
      } catch (error) {
        console.error('Error updating recruitment status:', error);
      }
    }
  }
}
