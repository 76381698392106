<form class="p-3 shadow" (ngSubmit)="handleUpdateItemQuantity()"  >
  <div class="d-flex flex-column mb-2">
    <label for="quantity"><strong>Cantidad</strong></label>
    <input [(ngModel)]="quantity" class="form-control" type="number" name="quantity" id="quantity" min="0">
  </div>
  <div class="d-flex align-items-center button-container">
    <button type="button" (click)="hideModal()" class="btn btn-danger">Cancelar</button>
    <button type="submit" class="btn btn-primary">Aceptar</button>
  </div>
</form>
