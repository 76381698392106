import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../../../../shared/template-services/alert.service';
import { take } from 'rxjs/operators';
import { UserService } from '../../../../shared/services/user.service';
import { KanbanService } from '../kanban.service';
import { UploadFileStorageComponent } from '../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Task } from '../interfaces/task';
import { FirebaseDataService } from '../../../../shared/template-services/firebase-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../../../../shared/interfaces/user';
import { Tag } from '../interfaces/tag';
import { TaskOverhaul } from '../enums/task-overhaul';
import { UserType } from '../../../enums/user-type.enum';
import { ChecklistComponent } from '../components/checklist/checklist.component';
import { Observable, of, Subscription } from 'rxjs';
import { NotificationType } from '../../../enums/notification-type.enum';
import { NotificationService } from '../../../services/notification.service';
import { AgreementType } from '../enums/agreement-type.enum';
import moment from 'moment';
import { TaskAgreementTypeLabel } from '../labels/task-agreement-type.label';
import { Board } from 'src/app/admin/interfaces/board';
import { BoardService } from 'src/app/admin/services/board.service';
import { KanbanStatus } from '../enums/kanban-status.enum';
import { BoardTypeEnum } from '../../../enums/board-type.enum';
import { DocumentReference } from '@angular/fire/firestore';
import { map } from 'rxjs/internal/operators/map';
import { CostCenterType } from '../../../enums/cost-center-type.enum';
import { ProjectService } from '../../../../shared/services/project.service';
import _ from 'lodash';
import { ProjectStatus } from '../../../enums/project-status.enum';

declare const $;

@Component({
  selector: 'app-kanban-modal',
  templateUrl: './kanban-modal.component.html',
  styleUrls: ['./kanban-modal.component.css']
})
export class KanbanModalComponent implements OnInit, OnDestroy {
  @ViewChild(UploadFileStorageComponent)
  filesStorage: UploadFileStorageComponent;
  @ViewChild(ChecklistComponent) checklist: ChecklistComponent;
  item: Task;
  usersAssignedSnapshot = [];
  team = [];
  tags = [];
  files: any[] = [];
  comment: string = '';
  comments: any[] = [];
  boardKey: string;
  board: Board;
  boardTypeEnum = BoardTypeEnum;
  userPermission: any;
  userTypeEnum = UserType;
  allUsers: User[];
  userFinishDate: any;
  supervisorFinishDate: any;
  expiratedDate: any = this.formatDate(new Date());
  isdaysLimitValid: boolean = true;
  agreementTypeEnum = AgreementType;
  taskAgreementTypeLabel = TaskAgreementTypeLabel;
  kanbanStatusEnum = KanbanStatus;
  logs$: Observable<any>;
  createdLog$: Observable<any>;
  editingTitle: boolean = false;
  isRegexMatch: boolean;
  users: User[];
  private usersSubscription: Subscription = new Subscription();
  selectArray = [];
  private projectSubscription: any;

  constructor(
    private _user: UserService,
    private _kanban: KanbanService,
    private db: FirebaseDataService,
    public modal: BsModalRef,
    private urlModal: BsModalService,
    private spinner: NgxSpinnerService,
    private _notification: NotificationService,
    private _board: BoardService,
    private _project: ProjectService
  ) {
  }

  async ngOnInit() {
    // TODO: Refactorizar la obtención de los usuarios aquí y en el kanban new item, porque es código duplicado
    if (!('links' in this.item)) this.item['links'] = [];
    this.usersAssignedSnapshot = this.item.members;

    const logs = await this._kanban.getLogs(this.boardKey, this.item.key).pipe(take(1)).toPromise();

    this.logs$ = of(_.orderBy(
      [
        ...logs,
        {
          description: 'Tarea creada',
          createdAt: this.item.createdAt,
          user: null
        }
      ],
      ['createdAt'],
      ['desc']
    ));
    this.board = await this._board.get(this.boardKey).pipe(take(1)).toPromise();
    this.usersSubscription = this._user
      .getAllUndeleted()
      .subscribe(async (users) => {
        this.team = users.filter((user) => {
          if (!user.permissions) return false;
          let section = user.permissions.find((per) => per.section == 'TAREAS');
          return (
            !!section && this.board.users.some((data) => data.id == user.key)
          );
        });
        this.allUsers = users.filter((user) => {
          if (!user.permissions) return false;

          let section = user.permissions.find((per) => per.section == 'TAREAS');
          return !!section;
        });

        this.tags = await this._kanban.getAllTags().pipe(take(1)).toPromise();

        this._kanban
          .getAllComments(this.item.key, this.boardKey)
          .subscribe((data) => {
            data = data.filter((comment: any) =>
              this.allUsers.some((user) => user.key == comment.user.id)
            );
            this.comments = data.map((item: any) => ({
              ...item,
              user: this.allUsers.find((user) => user.key == item.user.id)
            }));
          });
        this.files.push(this.generateRouteFile());
        if (!!this.item.expiratedDate) {
          this.item.expiratedDate = this.formatDate(this.item.expiratedDate);
        }
      });
    if (!!this.item.deal.supervisorFinishDate) {
      this.supervisorFinishDate = this.formatDate(
        this.item.deal.supervisorFinishDate
      );
    } else {
      this.supervisorFinishDate = this.formatDate(this.item.deal.userFinishDate);
    }
    if (!!this.item.deal.userFinishDate) {
      this.userFinishDate = this.formatDate(this.item.deal.userFinishDate);
    } else {
      this.userFinishDate = this.formatDate(new Date().getTime());
    }
    if (!!this.item.expiratedDate) {
      this.expiratedDate = this.formatDate(this.item.expiratedDate);
    } else {
      this.expiratedDate = this.formatDate(this.item.deal.userFinishDate);
    }
    if (moment(new Date()).diff(this.item.createdAt, 'd') >= 2) {
      this.isdaysLimitValid = false;
    }

    this.usersSubscription = await this._user
      .getAllUndeleted()
      .subscribe((users) => (this.users = users));

    this.getProjects();
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
    this.projectSubscription.unsubscribe();
  }

  async deleteAttachment(index: any) {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas eliminar este adjunto?'
      )
    ) {
      this.item.attachments.splice(index, 1);

      this._kanban.updateTask(
        { attachments: this.item.attachments } as Task,
        this.item.key,
        this.boardKey
      );
      this._kanban.setLog(this.boardKey, this.item.key, {
        description: 'Se eliminó un archivo adjunto',
        user: this.db.getReference(`users/${this._user.user.key}`),
        createdAt: new Date().getTime()
      });

      this.files = [this.generateRouteFile()];
      AlertService.toastSuccess('Eliminado correctamente');
    }
  }

  async updateAttachments() {
    let url: string;

    this.spinner.show();

    if (this.filesStorage.isInputValid) {
      url = await this.filesStorage.uploadDocument(
        'task',
        new Date().getTime()
      );
    }

    this.item.attachments = [
      ...this.item.attachments,
      {
        fileType: this.filesStorage.fileToUpload.type,
        url,
        fileName: this.filesStorage.fileToUpload.name
      }
    ];

    this._kanban.updateTask(
      { attachments: this.item.attachments } as Task,
      this.item.key,
      this.boardKey
    );

    this._kanban.setLog(this.boardKey, this.item.key, {
      description: 'Se adjuntó un archivo',
      user: this.db.getReference(`users/${this._user.user.key}`),
      createdAt: new Date().getTime()
    });
    this.spinner.hide();
    this.files = [this.generateRouteFile()];
    AlertService.toastSuccess('Imagen adjuntada correctamente');
  }

  public compareMembers(member, currentMember) {
    return member.key == currentMember.key;
  }

  async sendComment() {
    if (!this.comment) return;

    let regex = new RegExp(
      /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi
    );

    if (regex.test(this.comment)) {
      if (this.comment.includes(' ')) {
        AlertService.toastError(
          'Asegurese de introducir unicamente el link en el comentario (sin espacios)'
        );
        return;
      }
      this.isRegexMatch = true;
      if (!(this.comment.includes('/') || this.comment.includes('http'))) {
        this.comment = 'https://' + this.comment;
      }
      this._kanban.addLink(
        this.item.key,
        {
          url: this.comment
        },
        this.boardKey
      );
      let link = this.comment;
      let url = this.comment.replace(/.+\/\/|www.|\..+/g, '');
      this.item.links = [
        ...this.item.links,
        {
          title: url,
          link
        }
      ];
      this.update('links', this.item.links);
    } else {
      this.isRegexMatch = false;
    }

    this._kanban.addComment(
      this.item.key,
      {
        user: this.db.getReference(`users/${this._user.user.key}`),
        trash: false,
        createdAt: new Date().getTime(),
        comment: this.comment
      },
      this.boardKey
    );

    for (const member of <User[]>this.item.members) {
      if (!!member) {
        this._notification.setUser(member.key, {
          createdDate: new Date().getTime(),
          description: `${this._user.user.name} ha comentado la tarea ${this.item.title}`,
          type: NotificationType.USER_MESSAGE,
          redirectUrl: `admin/kanban/${this.boardKey}`,
          readed: false,
          trash: false
        });
      }
    }

    this._kanban.setLog(this.boardKey, this.item.key, {
      description: 'Se agregó un comentario',
      user: this.db.getReference(`users/${this._user.user.key}`),
      createdAt: new Date().getTime()
    });

    if (this.item.members.length > 0) {
      let emails = (<User[]>this.item.members)
        .filter((user) => !!user)
        .map((member) => member.email);

      const supervisorsEmails = this.filterUsersByBoardSupervisors(
        this.users,
        this.board.supervisors
      ).map((user) => user.email);
      let userAndSupervisor = supervisorsEmails.concat(emails);
      await this._notification.sendNotificationEmail(
        `Se agregó un comentario en la tarea ${this.item.title}`,
        'Comentario',
        userAndSupervisor
      );
    }

    this.comment = '';
    AlertService.toastSuccess('Comentario agregado');
  }

  filterUsersByBoardSupervisors(
    users: User[],
    boardSupervisors: DocumentReference[] | User[]
  ) {
    return users.filter((user) =>
      boardSupervisors.some(
        (supervisor) => (supervisor.id ?? supervisor.key) == user.key
      )
    );
  }

  generateRouteFile() {
    return `kanban/${new Date().getTime()}`;
  }

  async deleteTask() {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas eliminar esta tarea?'
      )
    ) {
      this._kanban.deleteTask(this.item.key, this.boardKey);
      AlertService.toastSuccess('Se eliminó correctamente');
      this._kanban.setLog(this.boardKey, this.item.key, {
        description: 'Se eliminó la tarea',
        user: this.db.getReference(`users/${this._user.user.key}`),
        createdAt: new Date().getTime()
      });
      this.modal.hide();
    }
  }

  private formatDate(date) {
    if (!date) return null;

    return moment(date).format('YYYY-MM-DD');
  }

  async update(field: string, value) {
    switch (field) {
      case 'members':
        if (value.length > 0) {
          let emails = value.map((user) => user.email);
          this._notification.sendNotificationEmail(
            `Se modificaron los responsables de la tarea ${this.item.title}`,
            `Tareas: tarea ${this.item.title} modificada`,
            emails
          );

          this.sendUserAssignedToTaskEmail(value);
        }

        value = (<User[]>this.item.members).map((member) =>
          this.db.getReference(`users/${member.key}`)
        );
        this._kanban.setLog(this.boardKey, this.item.key, {
          description: 'Se modificaron los miembros',
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        });
        break;

      case 'tags':
        value = (<Tag[]>this.item.tags).map((tag) =>
          this.db.getReference(`tags/${tag.key}`)
        );
        this._kanban.setLog(this.boardKey, this.item.key, {
          description: 'Se modificó la prioridad',
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        });
        break;

      case 'expiratedDate':
        value = value.replaceAll('-', '/');
        value = new Date(value).getTime();
        this._kanban.setLog(this.boardKey, this.item.key, {
          description: 'Se actualizó la fecha de finalización',
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        });
        let usersEmails = (<User[]>this.item.members)
          .filter((user) => !!user)
          .map((user) => user.email);
        await this._notification.sendNotificationEmail(
          `Se actualizó la fecha de la tarea ${this.item.title}`,
          `Tareas: tarea ${this.item.title} modificada`,
          usersEmails
        );
        break;

      case 'supervisorFinishDate':
        if (
          await AlertService.confirm(
            '¿Estás seguro de que deseas agregar esta fecha de finalización?'
          )
        ) {
          field = 'deal';
          value = value.replaceAll('-', '/');
          this.item.deal['supervisorFinishDate'] = new Date(value).getTime();
          if (!!this.item.deal.userFinishDate) {
            value = {
              userFinishDate: this.item.deal.userFinishDate,
              supervisorFinishDate: new Date(value).getTime()
            };
          } else {
            value = { supervisorFinishDate: new Date(value).getTime() };
          }
          this._kanban.setLog(this.boardKey, this.item.key, {
            description: 'Supervisor sugirió una fecha de finalización',
            user: this.db.getReference(`users/${this._user.user.key}`),
            createdAt: new Date().getTime()
          });
          let usersEmails = (<User[]>this.item.members)
            .filter((user) => !!user)
            .map((user) => user.email);
          await this._notification.sendNotificationEmail(
            `Supervisor sugirió nueva fecha de finalización para la tarea ${this.item.title}`,
            `Tareas: tarea ${this.item.title} sugerencia de finalización agregada`,
            usersEmails
          );
        } else {
          if (!!this.item.deal.supervisorFinishDate) {
            this.supervisorFinishDate = this.formatDate(
              this.item.deal.supervisorFinishDate
            );
          } else {
            this.supervisorFinishDate = null;
          }
          return;
        }
        break;

      case 'userFinishDate':
        if (
          await AlertService.confirm(
            '¿Estás seguro de que deseas agregar esta fecha de finalización?'
          )
        ) {
          field = 'deal';
          value = value.replaceAll('-', '/');
          this.item.deal['userFinishDate'] = new Date(value).getTime();
          if (!!this.item.deal.supervisorFinishDate) {
            value = {
              userFinishDate: new Date(value).getTime(),
              supervisorFinishDate: this.item.deal.supervisorFinishDate
            };
          } else {
            value = { userFinishDate: new Date(value).getTime() };
          }
          this._kanban.setLog(this.boardKey, this.item.key, {
            description: 'Usuario sugirió una fecha de finalización',
            user: this.db.getReference(`users/${this._user.user.key}`),
            createdAt: new Date().getTime()
          });
          let usersEmails = (<User[]>this.item.members)
            .filter((user) => !!user)
            .map((user) => user.email);
          await this._notification.sendNotificationEmail(
            `Usuario sugirió nueva fecha de finalización para la tarea ${this.item.title}`,
            `Tareas: tarea ${this.item.title} sugerencia de finalización agregada`,
            usersEmails
          );
        } else {
          if (!!this.item.deal.userFinishDate) {
            this.userFinishDate = this.formatDate(
              this.item.deal.userFinishDate
            );
          } else {
            this.userFinishDate = null;
          }
          return;
        }
        break;

      case 'title':
        this.editingTitle = false;
        break;
    }

    this._kanban.updateTask(
      { [field]: value } as Task,
      this.item.key,
      this.boardKey
    );
  }

  async sendUserAssignedToTaskEmail(currentUsers) {
    if (currentUsers.length <= this.usersAssignedSnapshot.length) return;
    const usersEmails = currentUsers
      .filter(
        (user) =>
          !this.usersAssignedSnapshot.some((data) => data.key == user.key)
      )
      .map((user) => user.email);

    if (!usersEmails.length) return;
    await this._notification.sendNotificationEmail(
      `Has sido asignado a la tarea: ${this.item.title}`,
      `Tareas: tarea ${this.item.title} asignación`,
      usersEmails
    );
  }

  async completeTask() {
    if ((await this.item.members.length) > 0) {
      if (
        await AlertService.confirm(
          '¿Estás seguro de que deseas completar esta tarea?'
        )
      ) {
        this._kanban.updateTask(
          {
            overhaul: TaskOverhaul.COMPLETED,
            status: this.kanbanStatusEnum.DONE,
            finishDate: new Date().getTime()
          } as Task,
          this.item.key,
          this.boardKey
        );
        this._kanban.setLog(this.boardKey, this.item.key, {
          description: 'La tarea fue aceptada como finalizada',
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        });
        if (this.item.members.length > 0) {
          let emails = (<User[]>this.item.members)
            .filter((user) => !!user)
            .map((member) => member.email);
          await this._notification.sendNotificationEmail(
            `La tarea ${this.item.title} se ha completado`,
            `Tareas: tarea ${this.item.title} completada`,
            emails
          );
        }
        this.modal.hide();
      }
    } else {
      return AlertService.toastError('Asigne a las personas correspondientes');
    }
  }

  notifyCheckedTask() {
    for (const member of <User[]>this.item.members) {
      this._notification.setUser(member.key, {
        createdDate: new Date().getTime(),
        description: `${this._user.user.name} ha marcado como completa una subtarea dentro de la tarea ${this.item.title}`,
        type: NotificationType.USER_MESSAGE,
        redirectUrl: `admin/kanban/${this.boardKey}`,
        readed: false,
        trash: false
      });
    }
  }

  async acceptExpiratedDate() {
    let selectedDate;
    let selectedDateType;

    if (this.userPermission == this.userTypeEnum.ADMIN || this.userPermission == this.userTypeEnum.SUPERVISOR) {
      selectedDateType = await AlertService.menu(
        '¿Que fecha desea aceptar?',
        [
          { label: 'Usuario', value: UserType.USER, color: 'success' },
          { label: 'Supervisor', value: UserType.SUPERVISOR, color: 'info' },
          { label: 'Cancelar', value: null, color: 'primary' }
        ]);

      if (selectedDateType === null) return;
      if (!(await AlertService.confirm('¿Aceptas esta fecha de finalización de tarea?'))) return;

      if ((this.userPermission == this.userTypeEnum.ADMIN || this.userPermission == this.userTypeEnum.SUPERVISOR)) {
        selectedDate = selectedDateType == UserType.USER
          ? new Date(this.userFinishDate.replaceAll('-', '/')).getTime()
          : new Date(this.supervisorFinishDate.replaceAll('-', '/')).getTime();
      } else {
        selectedDate = new Date(this.supervisorFinishDate.replaceAll('-', '/')).getTime();
      }
    }

    this._kanban.updateTask({
        expiratedDate: selectedDate,
        agreementType: AgreementType.DEAL,
        isAvailable: true
      } as Task,

      this.item.key,
      this.boardKey
    );

    this._kanban.setLog(this.boardKey, this.item.key, {
      description: 'Se aceptó la tarea',
      user: this.db.getReference(`users/${this._user.user.key}`),
      createdAt: new Date().getTime()
    });

    this.modal.hide();
  }

  async addminAssignExpiratedDate(date) {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas asignar esta fecha de finalización?'
      ))
    ) {
      if (!!this.item.expiratedDate) {
        this.expiratedDate = this.item.expiratedDate;
      } else {
        this.expiratedDate = null;
      }
    }
    if (this.isdaysLimitValid) {
      this.update('agreementType', AgreementType.ADMIN);
    } else {
      this.update('agreementType', AgreementType.TIME);
    }

    this.update('expiratedDate', date);
    this.update('isAvailable', true);
    this.item['expiratedDate'] = date;
  }

  async startsTask() {
    if (this.item.members.length > 0) {
      await AlertService.confirm(
        '¿Estás seguro de que deseas comenzar esta tarea?'
      );
      this._kanban.updateTask(
        {
          status: this.kanbanStatusEnum.IN_PROGRESS
        } as Task,
        this.item.key,
        this.boardKey
      );
      this._kanban.setLog(this.boardKey, this.item.key, {
        description: 'Se comenzó',
        user: this.db.getReference(`users/${this._user.user.key}`),
        createdAt: new Date().getTime()
      });
      if (this.item.members.length > 0) {
        let emails = (<User[]>this.item.members)
          .filter((user) => !!user)
          .map((member) => member.email);
        await this._notification.sendNotificationEmail(
          `La tarea ${this.item.title} ha comenzado`,
          `Tareas: tarea ${this.item.title} inició`,
          emails
        );
      }
      this.modal.hide();
    } else {
      return AlertService.toastError('Asigne a las personas correspondientes');
    }
  }

  changed() {
    if (this.item.tags.length > 1) {
      let tags: any[] = this.item.tags;
      tags.pop();
      this.item.tags = [tags[0]];
    }
    let value = (<Tag[]>this.item.tags).map((tag) =>
      this.db.getReference(`tags/${tag.key}`)
    );

    this._kanban.setLog(this.boardKey, this.item.key, {
      description: 'Se modificó la prioridad',
      user: this.db.getReference(`users/${this._user.user.key}`),
      createdAt: new Date().getTime()
    });

    this._kanban.updateTask(
      { tags: value } as Task,
      this.item.key,
      this.boardKey
    );
  }

  async changeFinalDate() {
    let date = await AlertService.inputDate('Cambiar fecha de finalización');

    if (!!date) {
      this.item.finishDate = new Date(date[0].replaceAll('-', '/')).getTime();
      this._kanban.updateTask(
        { finishDate: this.item.finishDate } as Task,
        this.item.key,
        this.boardKey
      );
    }
  }

  async addLink() {
    let [title, link] = await AlertService.withHtml(
      'Nuevo enlace',
      `
        <label>Título</label>
        <input type="text" id="swal-input1" class="swal2-input form-control">
        <label>Enlace</label>
        <input type="text" id="swal-input2" class="swal2-input form-control">`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
        });
      }
    );
    if (!title.trim() || !link.trim()) return;
    this.item.links = [
      ...this.item.links,
      {
        title,
        link
      }
    ];

    this.update('links', this.item.links);
  }

  deleteLink(index: number) {
    let links = this.item.links.filter((link, i) => i != index);
    this.item.links = links;
    this.update('links', links);
  }

  isImage(url: any) {
    return (
      url.fileType == 'image/png' ||
      url.fileType == 'image/jpg' ||
      url.fileType == 'image/jpeg'
    );
  }

  isVideo(url: any) {
    return url.fileType == 'video/mp4';
  }

  isPdf(url) {
    return url.fileType == 'application/pdf';
  }

  private getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project
            .filter(
              (project) => project.status == ProjectStatus.ACTIVE ||
                project.status == ProjectStatus.EXPIRATED
            )
            .map((project) => ({
              ...project,
              type:
                project.type == CostCenterType.PROJECT
                  ? 'Proyectos'
                  : 'Centro de Costos'
            }))
        )
      )
      .subscribe(async (data) => {
        this.selectArray = data;

        if (this.item.project) {
          let indexProject = this.selectArray.findIndex(
            (project) => project.key == this.item.project.id
          );

          if (!this.selectArray[indexProject]) return;

          this.item.project = this.selectArray[indexProject];
        }
      });
  }

  convertToTime(date) {
    if (date == null) return null;

    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }

  updateProject() {
    this.update('project', this._project.getReference(
      this.item.project.key
    ));
  }
}
