<div class="w-100 h-100" *ngIf="purchaseOrder; else addPurchaseOrder">
  <div class="modal-header border-0 d-flex align-items-center justify-content-center ">
    <div class="w-100 d-flex justify-content-center ">
      <h2
        class="text-center mb-0"
        [ngClass]="getStatusClass(purchaseOrder.status)"
      >{{ purchaseStatusLabel[purchaseOrder.status] }}</h2>
    </div>

    <button class="close pull-right" (click)="handleClose()">
      <span class="">&times;</span>
    </button>
  </div>
  <div id="purchase-order-details-node" class="p-1">
    <app-draft-purchase-order
      *ngIf="purchaseOrder.status == purchaseStatus.DRAFT || !purchaseOrder.status"
      [purchaseOrder]="purchaseOrder"
      (purchaseOrderCreated)="changePurchaseOrder($event)"
      (closeModal)="handleClose()"
    ></app-draft-purchase-order>

    <app-generated-purchase-order
      *ngIf="
      purchaseOrder.status == purchaseStatus.GENERATED
      || purchaseOrder.status == purchaseStatus.UNDER_REVIEW
      || purchaseOrder.status == purchaseStatus.UNDER_APPROVAL
      || purchaseOrder.status == purchaseStatus.WAITING_FOR_REVIEW
    "
      [purchaseOrderId]="purchaseOrder.key"
      (purchaseOrderChanged)="changePurchaseOrder($event)"
      (closeModal)="handleClose()"
      [supervisorUsers]="supervisorUsers"
      [financeUsers]="financeUsers"
      [financeThreshold]="financeThreshold"
      [updatedFromDraft]="updatedFromDraft"
    ></app-generated-purchase-order>

    <app-incomplete-purchase-order
      *ngIf="purchaseOrder.status == purchaseStatus.INCOMPLETE"
      [purchaseOrderId]="purchaseOrder.key"
      (purchaseOrderChanged)="changePurchaseOrder($event)"
      (closeModal)="handleClose()"
    ></app-incomplete-purchase-order>

    <app-authorized-purchase-order
      *ngIf="purchaseOrder.status == purchaseStatus.AUTHORIZED || purchaseOrder.status == purchaseStatus.CANCELLED"
      [purchaseOrderId]="purchaseOrder.key"
      (purchaseOrderChanged)="changePurchaseOrder($event)"
      (closeModal)="handleClose()"
      [updatedFromDraft]="updatedFromDraft"
    ></app-authorized-purchase-order>
  </div>
</div>

<ng-template #addPurchaseOrder>
  <app-draft-purchase-order
    [purchaseOrder]="purchaseOrder"
    (purchaseOrderCreated)="changePurchaseOrder($event)"
    (closeModal)="handleClose()"
  ></app-draft-purchase-order>
</ng-template>
