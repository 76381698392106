import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-qrmodal',
  templateUrl: './qrmodal.component.html',
  styleUrls: ['./qrmodal.component.css']
})
export class QRModalComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value: string;
  applicantKey: string;

  constructor(private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.value = 'https://plataforma.colsan.cl/admin/applicant-details/' + this.applicantKey;
  }

  closeModal() {
    this.modal.hide();
  }
}
