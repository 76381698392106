import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NormalizeService {

  constructor() {
  }

  number(value: string | number) {
    return Number(value.toString().replaceAll(/\D/g, ''));
  }
}
