<div class="p-1">
  <div class="card" *ngIf="article">
    <div class="card-body">
      <h2 class="text-capitalize mb-1"> {{ article.name }} ({{ article.internalId }}) </h2>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <img
            class="mr-1"
            src="{{!article.imageUrl ? 'assets/images/img_default.png' : article.imageUrl}}"
            style="width: 100px; height: 100px"
          />
          <div class="d-flex flex-column">
            <div class="d-flex">
              <span class="mr-1 text-light">Tipo:</span>
              <span>{{ article.type == ArticleType.STORABLE ? 'Almacenable' : 'Consumible' }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-1 text-light">Bodega:</span>
              <span>{{ article?.warehouse?.name }} ({{ article?.warehouse?.code }})</span>
            </div>
            <div class="d-flex">
              <span class="mr-1 text-light">Ubicación:</span>
              <span>{{ article?.location }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-1 text-light">Precio por unidad:</span>
              <span>{{ article.price | currencyFormat }}</span>
            </div>
            <div *ngIf="article.size" class="d-flex">
              <span class="mr-1 text-light">Precio por unidad:</span>
              <span>{{ article.size ? 'Talla: ' + article.size : '' }}</span>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column text-right">
            <div class="d-flex flex-column">
              <span class="">Total retirado:</span>
              <span>
              <strong>{{ (totalWithdrawn * (+article.price)) | currencyFormat }} </strong>
                <span class="text-light">({{ totalWithdrawn }})</span>
            </span>
            </div>
            <div class="d-flex flex-column">
              <span class="text-muted">Total ingresado:</span>
              <span>
              <strong>{{ (totalAdded * (+article.price)) | currencyFormat }} </strong>
              <span class="text-light">({{ totalAdded }})</span>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <app-datatable-pagination
      [rows]="history"
      [config]="userTicketsTableConfig"
    >
      <app-datatable-column title="Ticket">
        <ng-template let-row #column>
          <div>
            {{ row.type == 'ticket' ? 'Salida' : 'Entrada' }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Ticket ID">
        <ng-template let-row #column>
          <div class="text-center">
            {{ row.ticketID }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Estado">
        <ng-template let-row #column>
          <div
            class="badge badge-pill"
            [ngClass]="row.type=='ticket'
            ? TicketsBadgesService.getTicketStatusBadge(row.ticketStatus)
            : TicketsBadgesService.getEntryTicketStatusBadge(row.ticketStatus)"
          >
            {{
              row.type == 'ticket'
                ? TicketsBadgesService.getTicketStatusLabel(row.ticketStatus)
                : TicketsBadgesService.getEntryTicketStatusLabel(row.ticketStatus)
            }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Cantidad retirada">
        <ng-template let-row #column>
          <div class="text-center">
            {{ row.type == 'ticket' ? row.quantity : '-' }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Cantidad ingresada">
        <ng-template let-row #column>
          <div class="text-center">
            {{ row.type == 'entry' ? row.quantity : '-' }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Total">
        <ng-template let-row #column>
          <div>
            {{ (row.quantity * row.price) | currencyFormat }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fecha">
        <ng-template let-row #column>
          <div>
            {{ row.createdAt | date: 'dd/MM/yyyy - hh:mm' }}
          </div>
        </ng-template>
      </app-datatable-column>
    </app-datatable-pagination>
  </div>
</div>
