import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Applicant } from '../../interfaces/applicant';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../shared/template-services/alert.service';
import { Observable, of, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChangeStatusComponent } from '../../modals/change-status/change-status.component';
import { StatusService } from '../../../shared/services/status.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/template-services/auth.service';
import { UserService } from '../../../shared/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first, map, take } from 'rxjs/operators';
import { UpdateApplicantComponent } from '../../modals/update-applicant/update-applicant.component';
import { PrintService } from '../../../shared/services/print.service';
import { Location } from '@angular/common';
import { LogsService } from '../../services/logs.service';
import { AssignProjectComponent } from '../../modals/assign-project/assign-project.component';
import { UserType } from '../../enums/user-type.enum';
import _ from 'lodash';
import { CopyApplicantDocsUrlComponent } from './modals/copy-applicant-docs-url/copy-applicant-docs-url.component';
import { AppConstants } from '../../../shared/helpers/app.constants';
import { CurriculumModalComponent } from './components/curriculum-modal/curriculum-modal.component';
import { CommentsModalComponent } from './components/comments-modal/comments-modal.component';
import { QRModalComponent } from './components/qrmodal/qrmodal.component';

@Component({
  selector: 'app-applicant-detail',
  templateUrl: './applicant-detail.component.html',
  styleUrls: ['./applicant-detail.component.css']
})
export class ApplicantDetailComponent implements OnInit, OnDestroy {
  commentForm: FormGroup;
  submitted: boolean = false;
  applicants: any = [];
  comments: any = [];
  applicantKey: string;
  applicant: Applicant;
  indexApplicant: number;
  status;
  position: string = '';
  city: string = '';
  pdf =
    'https://firebasestorage.googleapis.com/v0/b/colsan-dev.appspot.com/o/applicant%2Fcurriculum%2Fyf5G6BLejoNBunvUJl9t%2FapplicantCurriculum.pdf?alt=media&token=9f9e9893-756d-4467-bd23-463629caf611';
  currentUser: any;
  arrayStatus: any = [];
  statusSubscription: Subscription;
  quizRatingPercentage: number;
  quizRatingCorrect: number;
  isPrinting: boolean = false;
  @ViewChild('componentID') componentID: ElementRef;
  logs$: Observable<any>;
  logs: any[] = [];
  file: any;
  path: any;
  uploadMessage: string = '';
  permission: any;
  permissionEnum = UserType;
  usersSubscription: Subscription = new Subscription();
  users = [];
  usersNotification = [];
  user: any;
  isMobile: boolean = false;
  isMenuOpen: boolean = false;
  isOpenModal: boolean = false;

  getStatusClass(name) {
    switch (name) {
      case 'apto':
        return 'badge-info';
      case 'incompleto':
        return 'badge-danger';
      case 'calificado':
        return 'badge-success';
      case 'postulante':
        return 'badge-primary';
      case 'no apto':
        return 'badge-danger';
      case 'bloqueado':
        return 'badge-dark';
      case 'revisión':
        return 'badge-warning';
      case 'inactivo':
        return 'badge-light';
      case 'contratado':
        return 'badge-success';
      default:
        return 'warning';
    }
  }

  constructor(
    private _applicant: ApplicantService,
    private activatedRoute: ActivatedRoute,
    private modal: BsModalService,
    private _status: StatusService,
    private formBuilder: FormBuilder,
    private _auth: AuthService,
    private _user: UserService,
    private SpinnerService: NgxSpinnerService,
    private _location: Location,
    private _log: LogsService
  ) {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required],
      user: ['', Validators.required],
      date: [new Date().getTime()],
      usersNotification: [''],
      trash: [false]
    });
  }

  async ngOnInit(): Promise<void> {
    this.isMobile = window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH;

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'POSTULANTES'
    ).permission;

    this.applicantKey = this.activatedRoute.snapshot.params['applicantKey'];

    this.applicants = this._applicant.applicantsQueue;

    const applicant = await this._applicant
      .get(this.applicantKey)
      .pipe(take(1))
      .toPromise();

    if (!this.applicants || !this.applicants.length) {
      this.applicants = [];
      this.applicants.push(applicant);
      this._applicant.applicantsQueue = this.applicants;
    }

    this.indexApplicant = this.applicants.findIndex((applicant) => applicant.key == this.applicantKey);

    this.applicants[this.indexApplicant] = applicant;
    this.applicant = this.applicants[this.indexApplicant];

    const resp = await this._log.getAllApplicant(this.applicantKey).pipe(take(1)).toPromise();
    this.logs = _.orderBy([
      {
        description: 'Registro del postulante',
        user: null,
        createdAt: this.applicant?.createdAt
      },
      ...resp
    ], 'createdAt', 'desc');
    this.logs$ = null;
    this.logs$ = of(this.logs);

    await this.getStatus();
    await this.getQuizRating();
    await this.getUsers();
    this.user = await this._user.getByEmail(this.applicant.email);
  }

  getQuizRating() {
    if (!this.applicants[this.indexApplicant].quiz) {
      return;
    }
    this.quizRatingPercentage = 0;
    this.quizRatingCorrect = 0;

    for (let i = 0; i < this.applicants[this.indexApplicant].quiz.length; i++) {
      if (
        this.applicants[this.indexApplicant].quiz[i].selected ==
        this.applicants[this.indexApplicant].quiz[i].correct
      ) {
        this.quizRatingCorrect++;
      }
    }

    this.quizRatingPercentage =
      (this.quizRatingCorrect /
        this.applicants[this.indexApplicant].quiz.length) *
      100;
  }

  getUsers() {
    this.usersSubscription = this._user.getAll()
      .pipe(
        map((users) => users.filter((user) => user.type != UserType.ADMIN)),
        map((users) => users.filter((user) => !user.isApplicant)),
        map((users) => users.filter((user) => !user.trash))
      )
      .subscribe((data) => {
        this.users = data;
      });
  }

  get formControls() {
    return this.commentForm.controls;
  }

  ngOnDestroy(): void {
    this.usersSubscription.unsubscribe();
  }

  async previousApplicant() {
    this.quizRatingPercentage = null;
    this.indexApplicant--;

    this.applicant = null;
    this.user = null;
    await this.delay(100);

    this.applicant = await this._applicant
      .get(this.applicants[this.indexApplicant].key)
      .pipe(take(1))
      .toPromise();

    this.user = await this._user.getByEmail(this.applicant.email);
    this._location.replaceState(`/admin/applicant-details/${this.applicant.key}`);

    this.getQuizRating();
    this.loadLogs();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async nextApplicant() {
    this.quizRatingPercentage = null;
    this.indexApplicant++;

    this.applicant = null;
    this.user = null;

    await this.delay(100);

    this.applicant = await this._applicant
      .get(this.applicants[this.indexApplicant].key)
      .pipe(take(1))
      .toPromise();

    this.user = await this._user.getByEmail(this.applicant.email);
    this._location.replaceState(`/admin/applicant-details/${this.applicant.key}`);

    this.getQuizRating();
    this.loadLogs();
  }

  async loadLogs() {
    const resp = await this._log.getAllApplicant(this.applicant.key).pipe(take(1)).toPromise();

    this.logs = _.orderBy([
      {
        description: 'Registro del postulante',
        user: null,
        createdAt: this.applicant?.createdAt
      },
      ...resp
    ], 'createdAt', 'desc');

    this.logs$ = null;
    this.logs$ = of(this.logs);
  }

  changeStatusModal() {
    const modalRef = this.modal.show(ChangeStatusComponent, {
      initialState: {
        applicantKey: this.applicant.key,
        applicantStatus: this.applicant.status
      }
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      this.applicant = await this._applicant
        .get(this.applicants[this.indexApplicant].key)
        .pipe(take(1))
        .toPromise();

      if (this.applicant && !this.applicant.project && (this.applicant.status.name == 'contratado' || this.applicant.status.name == 'calificado')) {
        this.assignProject();
      }
    });

    this._log.addApplicant(this.applicantKey, {
      description: `Se cambió el status ${this.applicant.status.name}`
    });
  }

  async sendAccountModal() {
    if (this.applicants[this.indexApplicant].status.name == 'contratado' && await AlertService.confirm('¿El usuario no se ha creado en la plataforma?', '', 'Solo enviar correo', 'Crear usuario y enviar correo')) {
      AlertService.toastSuccess('Se ha enviado el correo exitosamente');
      return this._applicant.sendAccountEmail(
        this.applicants[this.indexApplicant].email,
        this.applicants[this.indexApplicant].rut
          .replaceAll('.', '')
          .replaceAll('-', '')
          .slice(0, -1),
        this.applicants[this.indexApplicant].email
      );
    }

    if (await AlertService.confirm('¿Estás seguro que deseas enviarle el acceso a la Plataforma Colsan?')) {
      this.uploadMessage = 'Registrando...';
      this.SpinnerService.show();

      this._auth.addToWhitelist(this.applicants[this.indexApplicant].email);

      await this._applicant.sendAccountEmail(
        this.applicants[this.indexApplicant].email,
        this.applicants[this.indexApplicant].rut
          .replaceAll('.', '')
          .replaceAll('-', '')
          .slice(0, -1),
        this.applicants[this.indexApplicant].email
      );

      let user = {
        rut: this.applicants[this.indexApplicant].rut,
        name: this.applicants[this.indexApplicant].name,
        surnames: this.applicants[this.indexApplicant].surnames,
        region: this.applicants[this.indexApplicant].region,
        city: this.applicants[this.indexApplicant].city,
        position: this.applicants[this.indexApplicant].position,
        status: this.applicants[this.indexApplicant].status,
        birthday: this.applicants[this.indexApplicant].birthday,
        workExperience: this.applicants[this.indexApplicant].workExperience,
        educationLevel: this.applicants[this.indexApplicant].educationLevel,
        email: this.applicants[this.indexApplicant].email,
        phone: this.applicants[this.indexApplicant].phone,
        nationality: this.applicants[this.indexApplicant].nationality,
        experience: this.applicants[this.indexApplicant].experience,
        expectation: this.applicants[this.indexApplicant].expectation,
        quiz: this.applicants[this.indexApplicant].quiz,
        curriculum: this.applicants[this.indexApplicant].curriculum,
        isApplicant: true,
        firstLogin: true,
        trash: false,
        isDisable: false,
        permissions: []
      };

      const resp: any = await this._user.createAccount(
        this.applicants[this.indexApplicant].email,
        this.applicants[this.indexApplicant].rut
          .replaceAll('.', '')
          .replaceAll('-', '')
          .slice(0, -1)
      );
      await this._applicant.updateStatus(this.applicantKey, {
        name: 'contratado'
      });

      if (!resp.ok) {
        return AlertService.toastSuccess(
          'Se ha enviado el acceso'
        );
      }

      this._user.addUserByKey(resp.uid, user);

      this.SpinnerService.hide();
      AlertService.toastSuccess(
        'Se ha registrado con éxito y se ha enviado el acceso'
      );
    }
  }

  async changeStatus(applicantKey: string, applicantStatus: string, indexApplicant) {
    this.status = this.arrayStatus.find(
      (status) => status.name == applicantStatus
    );

    await this._applicant.updateStatus(applicantKey, this.status);

    this.applicant = await this._applicant
      .get(this.applicants[this.indexApplicant].key)
      .pipe(take(1))
      .toPromise();

    this._log.addApplicant(this.applicantKey, {
      description: `Se cambió el status ha ${this.applicant.status.name}`
    });
  }

  getStatus() {
    this.statusSubscription = this._status
      .getAll()
      .pipe(
        map((status) =>
          status.map((status) => ({
            name: status.name,
            key: status.key
          }))
        )
      )
      .subscribe((data) => {
        this.arrayStatus = data;
      });
  }

  openModalUpdateApplicant() {
    const modalRef = this.modal.show(UpdateApplicantComponent, {
      initialState: {
        applicantKey: this.applicant.key,
        applicant: this.applicant
      },
      class: 'modal-lg'
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {

      this.applicant = await this._applicant
        .get(this.applicantKey)
        .pipe(take(1))
        .toPromise();

      this._log.addApplicant(this.applicantKey, {
        description: `Se modificó la información del postulante`
      });
    });
  }

  printInfoUser() {
    this.isPrinting = true;
    setTimeout(() => {
      PrintService.print(this.componentID.nativeElement);
      this.isPrinting = false;
    }, 500);
  }

  goToApplicantList() {
    this._location.back();
  }

  assignProject() {
    const modalRef: BsModalRef = this.modal.show(AssignProjectComponent, {
      initialState: {
        applicantKey: this.applicant.key,
        applicant: this.applicant
      }
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      this.applicant = await this._applicant
        .get(this.applicants[this.indexApplicant].key)
        .pipe(take(1))
        .toPromise();

      if (!this.applicant.project && (this.applicant.status.name == 'contratado' || this.applicant.status.name == 'calificado')) {
        await AlertService.error('Debes asignar un proyecto al postulante');
        this.assignProject();
      }
    });
  }

  copyLink() {
    if (!this.applicant.initialAccessDate) {
      return AlertService.error('Aún no puedes copiar el link', 'Asigna de nuevo un proyecto o genera un contrato para este postulante');
    }

    this.modal.show(CopyApplicantDocsUrlComponent, {
      initialState: {
        applicant: this.applicant
      }
    });
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  async handleOpenModal(type: 'curriculum' | 'comments') {
    this.isOpenModal = true;
    let modalRef: BsModalRef;

    if (type == 'curriculum') {
      modalRef = this.modal.show(CurriculumModalComponent, {
        initialState: {
          permission: this.permission,
          submitted: this.submitted,
          file: this.file,
          applicant: this.applicant,
          isMobile: this.isMobile,
          path: this.path,
          uploadMessage: this.uploadMessage,
          applicants: this.applicants,
          indexApplicant: this.indexApplicant,
          applicantKey: this.applicantKey
        },
        class: 'modal-xl',
        backdrop: 'static'
      });
    } else {
      modalRef = this.modal.show(CommentsModalComponent, {
        initialState: {
          comments: this.comments,
          users: this.users,
          usersNotification: this.usersNotification,
          commentForm: this.commentForm,
          applicant: this.applicant,
          applicants: this.applicants,
          currentUser: this.currentUser,
          indexApplicant: this.indexApplicant,
          applicantKey: this.applicantKey
        },
        class: 'modal-xl',
        backdrop: 'static'
      });
    }

    await modalRef.onHidden.pipe(first()).toPromise();
    this.isOpenModal = false;
  }

  async openQRModal() {
    this.isOpenModal = true;
    let modalRef: BsModalRef;

    modalRef = this.modal.show(QRModalComponent, {
      initialState: {
        applicantKey: this.applicantKey
      },
      class: 'modal-xl',
      backdrop: 'static'
    });

    await modalRef.onHidden.pipe(first()).toPromise();
    this.isOpenModal = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = event.target.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH;
  }

  protected readonly AppConstants = AppConstants;
}
