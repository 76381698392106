<div class="modal-wrapper">
  <div class="modal-header mobile-modal-header">
    <h1>Comentarios</h1>
    <i (click)="closeModal()" class="la la-close"></i>
  </div>
  <app-applicant-comments
    [comments]="this.comments"
    [users]="this.users"
    [usersNotification]="this.usersNotification"
    [applicant]="this.applicant"
    [currentUser]="this.currentUser"
    [submitted]="this.submitted"
    [indexApplicant]="this.indexApplicant"
    [applicants]="this.applicants"
    [applicantKey]="this.applicantKey"
    [commentForm]="this.commentForm"
  >
  </app-applicant-comments>
  <div class="mb-2"></div>
</div>
