<app-datatable-pagination
  [rows]="users"
  [config]="userTicketsTableConfig"
  [printSmall]="true"
  (rowSelected)="openUserTicketsDetails($event)"
>
  <app-datatable-column title="RUT">
    <ng-template let-row #column>
      <div>
        {{ row?.rut }}
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      <div>
        {{ row?.name }}
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Tipo">
    <ng-template let-row #column>
      <div>
        {{ row?.userType }}
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Total retirado">
    <ng-template let-row #column>
      <div>
        {{ (row?.total | currencyFormat) || '-' }}
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Total ingresado">
    <ng-template let-row #column>
      <div>
        {{ (row?.totalEntry | currencyFormat) || '-' }}
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Última solicitud">
    <ng-template let-row #column>
      <div>
        {{ row?.lastUpdate | date: 'dd/MM/yyyy - HH:mm' }}
      </div>
    </ng-template>
  </app-datatable-column>
</app-datatable-pagination>
