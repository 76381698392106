export enum recruitmentStatusEnum {
  EMAIL_SENT,
  EMAIL_APPROVED,
  IN_EXAMS,
  EXAMS_APPROVED,
  IN_CUSTOMER_INDUCTION,
  IN_COLSAN_INDUCTION,
  IN_HIRING,
  APPROVED_CUSTOMER_INDUCTION,
  APPROVED_COLSAN_INDUCTION,
  EMAIL_REJECTED,
}

export const mapApplicantPathToFilter: Record<string, Record<string, any>> = {
  'cv-valido': { value: 'apto', label: 'cv válido' },
  'calificado': { value: 'calificado', label: 'calificado' },
  'postulante': { value: 'postulante', label: 'postulante' },
  'entrevistado': { value: 'entrevistado', label: 'entrevistado' }
};

export const mapRecruitmentPathToFilter = {
  'correo-enviado': { value: recruitmentStatusEnum.EMAIL_SENT, label: 'Preselección' },
  'correo-aceptado': { value: recruitmentStatusEnum.EMAIL_APPROVED, label: 'Preselección aceptada' },
  'en-examenes': { value: recruitmentStatusEnum.IN_EXAMS, label: 'En exámenes' },
  'examenes-aprobados': { value: recruitmentStatusEnum.EXAMS_APPROVED, label: 'Exámenes aprobados' },
  'induccion-cliente': { value: recruitmentStatusEnum.IN_CUSTOMER_INDUCTION, label: 'En inducción cliente' },
  'induccion-colsan': { value: recruitmentStatusEnum.IN_COLSAN_INDUCTION, label: 'En inducción colsan' },
  'en-contratacion': { value: recruitmentStatusEnum.IN_HIRING, label: 'En contratación' }
};
