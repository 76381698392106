import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BoardModalComponent} from '../../modals/board-modal/board-modal.component';
import {Board} from '../../interfaces/board';
import {BoardService} from '../../services/board.service';
import {Observable, of, Subscription} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {User} from '../../../shared/interfaces/user';
import {UserService} from '../../../shared/services/user.service';
import {DocumentReference} from '@angular/fire/firestore';
import {AlertService} from '../../../shared/template-services/alert.service';
import {UserType} from '../../enums/user-type.enum';
import {Router} from '@angular/router';
import {BoardTypeEnum} from '../../enums/board-type.enum';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {ObjectService} from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-boards',
  templateUrl: './boards.component.html',
  styleUrls: ['./boards.component.css'],
})
export class BoardsComponent implements OnInit, OnDestroy {
  boards$: Observable<Board[]>;
  boardSubscription: Subscription = new Subscription();
  allUsers: User[];
  users: User[];
  supervisors: User[];
  admins: User[];
  usersSubscription: Subscription = new Subscription();
  userTypeEnum = UserType;
  permission: number;
  boardTypeSelected: number;
  boardTypeEnum = BoardTypeEnum;
  personalBoards: Board[];
  teamBoards: Board[];
  config: DataTableConfig = {
    notFoundText: 'No se encontraron tableros',
    title: 'Lista de tableros',
    hasSearch: true,
    exportCallback: this.decoratedBoardsToExport.bind(this),
    excelFileName: 'Tableros',
  };

  constructor(
    private modal: BsModalService,
    private _board: BoardService,
    private _user: UserService,
    private router: Router,
    private db: FirebaseDataService
  ) {
  }

  async ngOnInit() {
    this.boardTypeSelected = this.boardTypeEnum.TEAM;
    if (
      !this._user.user.permissions.find(
        (permission) => permission.section == 'TAREAS'
      )
    )
      return this.router.navigate(['admin/default']);
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'TAREAS'
    ).permission;

    this.listenForUsers();
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
    this.boardSubscription.unsubscribe();
  }

  listenForUsers() {
    this.usersSubscription = this._user.getAll().subscribe((users) => {
      this.allUsers = users;
      this.setUsers();
      this.setSupervisors();
      this.setAdmins();
      this.boardSubscription.unsubscribe();
      this.listenForBoards();
    });
  }

  listenForBoards() {
    this.boardSubscription = this._board.getAll().subscribe((boards) => {
      boards = boards.map((board) => {
        if (!('type' in board)) {
          this.db.update(`boards/${board.key}`, {
            type: BoardTypeEnum.TEAM,
          } as Board);
          return {
            ...board,
            type: this.boardTypeEnum.TEAM,
          };
        } else return board;
      });
      this.personalBoards = this.filterPersonalBoards(boards);
      boards = this.filterTeamBoards(boards);
      if (this.permission == this.userTypeEnum.USER) {
        boards = boards.filter((board) =>
          board.users.some((user) => !!user && user.key == this._user.user.key)
        );
      }
      if (this.permission == this.userTypeEnum.SUPERVISOR) {
        let filteredBoardsAdmins = boards.filter((board) =>
          board.admins.some(
            (admin) => !!admin && admin.key == this._user.user.key
          )
        );
        let filteredBoardsUsers = boards.filter((board) =>
          board.users.some((user) => !!user && user.key == this._user.user.key)
        );
        let filteredBoardsSupervisors = boards.filter((board) =>
          board.supervisors.some(
            (supervisor) =>
              !!supervisor && supervisor.key == this._user.user.key
          )
        );
        boards = filteredBoardsUsers.concat(
          filteredBoardsSupervisors.concat(filteredBoardsAdmins)
        );
      }
      boards = this.removeDuplicateBoards(boards);
      this.teamBoards = boards;
      this.changeBoardsFilter();
    });
  }

  setAdmins() {
    this.admins = this.allUsers.filter((user) => {
      if (!user.permissions) return false;
      let section = user.permissions.find((per) => per.section == 'TAREAS');
      return !!section && (section.permission == this.userTypeEnum.SUPERVISOR ||
        section.permission == this.userTypeEnum.ADMIN)
    });
  }

  setUsers() {
    this.users = this.allUsers.filter((user) => {
      if (!user.permissions) return false;

      return !!user.permissions.find((per) => per.section == 'TAREAS');
    });
  }

  setSupervisors() {
    this.supervisors = this.allUsers.filter((user) => {
      if (!user.permissions) return false;
      let section = user.permissions.find((per) => per.section == 'TAREAS');
      return (
        !!section &&
        (section.permission == this.userTypeEnum.SUPERVISOR ||
          section.permission == this.userTypeEnum.ADMIN)
      );
    });
  }

  showAddBoardModal() {
    this.modal.show(BoardModalComponent, {
      initialState: {
        userType: this.permission,
        allUsers: [...this.allUsers],
        users: [...this.users],
        supervisors: [...this.supervisors],
        admins: [...this.admins],
      },
    });
  }

  showEditBoardModal(board: Board) {
    this.modal.show(BoardModalComponent, {
      initialState: {
        board: {...board},
        userType: this.permission,
        allUsers: [...this.allUsers],
        users: [...this.users],
        supervisors: [...this.supervisors],
        admins: [...this.admins],
      },
    });
  }

  async deleteBoard(boardKey: string) {
    if (
      await AlertService.confirm(
        'Estás seguro de que deseas eliminar este tablero'
      )
    ) {
      await this._board.delete(boardKey);
      AlertService.toastSuccess('El tablero se ha eliminado correctamente');
    }
  }

  goToSelectedBoard(board: Board) {
    this.router.navigate(['admin/kanban', board.key]);
  }

  changeBoardsFilter() {
    if (this.boardTypeSelected == this.boardTypeEnum.TEAM)
      this.boards$ = of(this.teamBoards);
    else this.boards$ = of(this.personalBoards);
  }

  filterPersonalBoards(boards: Board[]): Board[] {
    if (boards.length > 0) {
      let personalBoards: Board[] = boards.filter(
        (board) =>
          'type' in board &&
          board.type == this.boardTypeEnum.PERSONAL &&
          (<DocumentReference>board.owner).id == this._user.user.key
      );
      if (personalBoards.length > 0) {
        personalBoards = this.formatBoardsOwner(personalBoards);
      }
      return personalBoards;
    } else {
      return [];
    }
  }

  filterTeamBoards(boards: Board[]) {
    if (boards.length > 0) {
      let teamBoards = boards.filter(
        (board) =>
          !('type' in board) ||
          ('type' in board && board.type == this.boardTypeEnum.TEAM)
      );
      if (teamBoards.length > 0)
        teamBoards = this.formatTeamBoardsUsers(teamBoards);
      return teamBoards;
    } else {
      return [];
    }
  }

  formatTeamBoardsUsers(boards: Board[]): Board[] {
    return boards.map((board: Board) => ({
      ...board,
      users: !!board.users
        ? (<DocumentReference[]>board.users).map((user) =>
          this.allUsers.find((data) => data.key == user.id)
        )
        : [],
      supervisors: !!board.supervisors
        ? (<DocumentReference[]>board.supervisors).map((supervisor) =>
          this.allUsers.find((data) => data.key == supervisor.id)
        )
        : [],
      admins: !!board.admins
        ? (<DocumentReference[]>board.admins).map((admin) =>
          this.allUsers.find((data) => data.key == admin.id)
        )
        : [],
    }));
  }

  formatBoardsOwner(boards: Board[]): Board[] {
    return boards.map((board) => ({
      ...board,
      owner: !!board.owner
        ? this.allUsers.find(
          (user) => user.key == (<DocumentReference>board.owner).id
        )
        : null,
    }));
  }

  removeDuplicateBoards(boards: Board[]) {
    return boards.filter(
      (board, index) =>
        boards.findIndex((data) => data.key == board.key) == index
    );
  }

  decoratedBoardsToExport(boards: Board[]) {
    return ObjectService.replaceUndefined(boards.map((board: any) => {
      board['admins'] = board.admins.map((admin) => admin.name);
      board['supervisors'] = board.supervisors.map((supervisor) => supervisor.name);
      board['users'] = board.users.map((user) => user.name);

      return this.boardTypeSelected == this.boardTypeEnum.TEAM
        ? {
          name: board.name,
          admins: board.admins.join(),
          supervisors: board.supervisors.join(),
          users: board.users.join(),
        }
        : {
          name: board.name,
          owner: board.owner.name,
        };
    }));
  }

  redirectToMyTasksKanban() {
    this.router.navigateByUrl(`admin/my-tasks-kanban`)
  }

  protected readonly UserType = UserType;
}
