import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KanbanService } from '../kanban.service';
import { UserService } from '../../../../shared/services/user.service';
import { Subscription } from 'rxjs';
import {
  UploadFileStorageComponent
} from 'src/app/shared/template-components/upload-file-storage/upload-file-storage.component';
import { AlertService } from '../../../../shared/template-services/alert.service';
import { FirebaseDataService } from '../../../../shared/template-services/firebase-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { KanbanStatus } from '../enums/kanban-status.enum';
import { TaskOverhaul } from '../enums/task-overhaul';
import { DocumentReference } from '@angular/fire/firestore';
import { NotificationType } from '../../../enums/notification-type.enum';
import { NotificationService } from '../../../services/notification.service';
import { UserType } from '../../../enums/user-type.enum';
import { BoardService } from '../../../services/board.service';
import { Board } from '../../../interfaces/board';
import { first, map, take } from 'rxjs/operators';
import { Frequency } from '../enums/frequency.enum';
import { BoardTypeEnum } from '../../../enums/board-type.enum';
import { formatDate } from '@angular/common';
import * as _ from 'lodash';
import { CostCenterType } from '../../../enums/cost-center-type.enum';
import { ProjectService } from '../../../../shared/services/project.service';
import moment from 'moment';
import { ProjectStatus } from '../../../enums/project-status.enum';

declare const $;

@Component({
  selector: 'app-kanban-new-item',
  templateUrl: './kanban-new-item.component.html',
  styleUrls: ['./kanban-new-item.component.css']
})
export class KanbanNewItemComponent implements OnInit, OnDestroy {
  @ViewChildren(UploadFileStorageComponent)
  filesStorage: QueryList<UploadFileStorageComponent>;
  kanbanForm: FormGroup;
  submitted: boolean;
  team: any[] = [];
  tags: any[] = [];
  files: any[] = [];
  boardKey: string;
  private usersSubscription: Subscription = new Subscription();
  private tagsSubscription: Subscription = new Subscription();
  projectSubscription: Subscription = new Subscription();
  userTypeEnum = UserType;
  userPermission: any;
  supervisorFinishDate: string;
  userFinishDate: string;
  expiratedDate: string;
  board: Board;
  boardsSubscription: Subscription = new Subscription();
  boards: Board[];
  frequencyType = Frequency;
  boardTypeEnum = BoardTypeEnum;
  boardType;
  weekDays = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado'
  ];
  monthDays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28
  ];
  startDate = null;
  finalDate = null;
  boardSelected: Board;
  capture: string;
  selectArray = [];

  constructor(
    private formBuilder: FormBuilder,
    private _user: UserService,
    private db: FirebaseDataService,
    private spinner: NgxSpinnerService,
    private _notification: NotificationService,
    private _kanban: KanbanService,
    private _board: BoardService,
    public modal: BsModalRef,
    private _project: ProjectService
  ) {
    this.createKanbanForm();
    this.files.push(this.generateRouteFile());
  }

  async ngOnInit() {
    this.userPermission = this._user.getPermissionBySection('TAREAS');
    this.loadBoards();
    if (!this.validateIsTeamBoard()) {
      this.kanbanForm.patchValue({ expiratedDate: this.formatDate(new Date()) });
      this.kanbanForm.get('members').clearValidators();
      this.kanbanForm.get('members').updateValueAndValidity();
    } else {
      if (
        this.userPermission == this.userTypeEnum.ADMIN ||
        this.userPermission == this.userTypeEnum.SUPERVISOR
      ) {
        this.supervisorFinishDate = this.formatDate(new Date());
      } else if (this.userPermission == this.userTypeEnum.USER) {
        this.userFinishDate = this.formatDate(new Date());
      }
    }
    await this.loadUsers();
    this.loadTags();
    this.getProjects();
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
    this.tagsSubscription.unsubscribe();
    this.boardsSubscription.unsubscribe();
  }

  get formControls() {
    return this.kanbanForm.controls;
  }

  createKanbanForm() {
    this.kanbanForm = this.formBuilder.group({
      title: ['', Validators.required],
      tags: [[]],
      expiratedDate: [null],
      members: [[], Validators.required],
      description: ['', Validators.required],
      status: [KanbanStatus.TODO],
      attachments: [[]],
      overhaul: [TaskOverhaul.NONE],
      finishDate: [null],
      deal: [{}],
      createdAt: [new Date().getTime()],
      isAvailable: [false],
      isRecurrent: [false],
      frequencyType: [this.frequencyType.MONTHLY],
      creationDay: [null],
      links: [[]],
      trash: [false],
      project: [null]
    });
  }

  loadBoards() {
    this.boardsSubscription = this._board
      .getAll()
      .pipe(
        map((boards) => {
          return boards.filter((board) => {
            return this.validateBoardsFilter(board);
          });
        })
      )
      .subscribe((boards: any) => {
        if (boards.length > 0) {
          this.boardSelected = boards.find(
            (board) => board.key == this.boardKey
          );
          this.boards = boards;
          this.boards = _.sortBy(this.boards, 'name', 'asc');
        }
      });
  }

  validateBoardsFilter(board: Board) {
    if (!this.validateIsTeamBoard()) return false;
    if (this.validateUserPermission(this.userTypeEnum.USER)) {
      return board.users.some((user) => this._user.user.key == user.id);
    } else if (this.validateUserPermission(this.userTypeEnum.SUPERVISOR)) {
      return board.supervisors.some(
        (supervisor) => this._user.user.key == supervisor.id
      );
    } else if (this.validateUserPermission(this.userTypeEnum.ADMIN)) {
      return true;
    }
  }

  validateUserPermission(permissionExpected) {
    return this.userPermission == permissionExpected;
  }

  validateIsTeamBoard() {
    return this.boardType == this.boardTypeEnum.TEAM;
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  async submit() {
    this.submitted = true;
    if (this.validateIsTeamBoard() && this.kanbanForm.value.members.length == 0) {
      return AlertService.toastError(
        'Por favor seleccione una persona responsable'
      );
    }
    if (!this.kanbanForm.valid) {
      return AlertService.toastError('Verifique todos los campos');
    }
    if (this.kanbanForm.value.isRecurrent && !this.kanbanForm.value.creationDay) {
      return AlertService.toastError('Debe seleccionar el día de creación');
    }

    if (
      this.kanbanForm.valid &&
      (await AlertService.confirm(
        '¿Estás seguro que deseas agregar esta tarea?'
      ))
    ) {
      this.spinner.show();
      if (this.boardType == this.boardTypeEnum.TEAM) {
        if (
          (this.userPermission == this.userTypeEnum.ADMIN ||
            this.userPermission == this.userTypeEnum.SUPERVISOR) &&
          !!this.supervisorFinishDate
        ) {
          this.kanbanForm.patchValue({
            deal: {
              ...this.kanbanForm.value.deal,
              supervisorFinishDate: moment(this.supervisorFinishDate).set({
                hour: 12,
                minute: 0,
                second: 0,
                millisecond: 0
              }).valueOf()
            }
          });
        }
        if (
          this.userPermission == this.userTypeEnum.USER &&
          !!this.userFinishDate
        ) {
          this.kanbanForm.patchValue({
            deal: {
              ...this.kanbanForm.value.deal,
              userFinishDate: moment(this.userFinishDate).set({
                hour: 12,
                minute: 0,
                second: 0,
                millisecond: 0
              }).valueOf()
            }
          });
        }
        if (!this.supervisorFinishDate && !this.userFinishDate) {
          this.kanbanForm.patchValue({
            expiratedDate: moment().set({
              hour: 12,
              minute: 0,
              second: 0,
              millisecond: 0
            }).valueOf()
          });
        }
        this.kanbanForm.patchValue({
          members: this.kanbanForm.value.members.map((member) => this.db.getReference(`users/${member.key}`))
        });
      } else {
        if (!!this.expiratedDate) {
          this.kanbanForm.patchValue({
            expiratedDate: moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).valueOf()
          });
        }
        this.kanbanForm.patchValue({ isAvailable: true });
      }

      this.kanbanForm.patchValue({
        tags: this.kanbanForm.value.tags.map((tag) => this.db.getReference(`tags/${tag.key}`))
      });

      this.kanbanForm;

      if (!!this.kanbanForm.value.project) {
        this.kanbanForm.patchValue({
          project: this._project.getReference(
            this.kanbanForm.value.project.key
          )
        });
      }

      let doc = await this._kanban.addTask(
        {
          ...this.kanbanForm.value,
          createdBy: this.db.getReference(`users/${this._user.user.key}`)

        },
        this.getBoardKey()
      );
      await this._kanban.setLog(this.getBoardKey(), doc.id, {
        description: 'Se creó la tarea',
        user: this.db.getReference(`users/${this._user.user.key}`),
        createdAt: new Date().getTime()
      });

      for (const member of <DocumentReference[]>this.kanbanForm.value.members) {
        this._notification.setUser(member.id, {
          createdDate: new Date().getTime(),
          description: `Se te ha agregado a una tarea`,
          type: NotificationType.USER_MESSAGE,
          redirectUrl: `admin/kanban/${this.getBoardKey()}`,
          readed: false,
          trash: false
        });
      }
      if (this.kanbanForm.value.members.length > 0) this.sendNotificationEmail(this.kanbanForm.value.members);
      this.spinner.hide();
      this.modal.hide();
      AlertService.toastSuccess('Tarea agregada con éxito');
    }
  }

  public compareMembers(member, currentMember) {
    if (!currentMember) return false;
    return member.key == currentMember.key;
  }

  private async loadUsers() {
    this.board = await this._board.get(this.boardKey).pipe(take(1)).toPromise();
    this.usersSubscription = this._user.getAllUndeleted().subscribe((users) => {
      this.team = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find((per) => per.section == 'TAREAS');
        return (
          !!section && this.board.users.some((data) => data.id == user.key)
        );
      });
    });
  }

  getBoardKey() {
    return !!this.boardSelected ? this.boardSelected.key : this.boardKey;
  }

  private loadTags() {
    this.tagsSubscription = this._kanban
      .getAllTags()
      .subscribe((data) => (this.tags = data));
  }

  generateRouteFile() {
    return `kanban/${new Date().getTime()}`;
  }

  async sendNotificationEmail(users: any) {
    let usersEmails = users
      .map((user) => {
        const member = this.team.find((data) => data.key == user.id);
        if (!member) return null;

        return member.email;
      })
      .filter((email) => !!email);

    if (usersEmails.length == 0) return;

    this._notification.sendNotificationEmail(
      `Has sido agregado a la tarea ${this.kanbanForm.value.title}`,
      `Tareas: tarea ${this.kanbanForm.value.title} asignación`,
      usersEmails
    );
  }

  changed() {
    if (this.kanbanForm.value.tags.length > 1) {
      this.kanbanForm.value.tags.pop();
      this.kanbanForm.patchValue({
        tags: this.kanbanForm.value.tags
      });
    }
  }

  async boardSelectionChanged() {
    this.board = this.boardSelected;
    this.kanbanForm.patchValue({ members: [] });
    this.assignTeam();
  }

  async assignTeam() {
    this.team = await this._user.getAllUndeleted().pipe(first()).toPromise();
    this.team = this.team.filter((user) => {
      if (!user.permissions) return false;
      let section = user.permissions.find((per) => per.section == 'TAREAS');
      return !!section && this.board.users.some((data) => data.id == user.key);
    });
  }

  async addLink() {
    let [title, link] = await AlertService.withHtml(
      'Nuevo enlace',
      `
        <label>Título</label>
        <input type="text" id="swal-input1" class="swal2-input form-control">
        <label>Enlace</label>
        <input type="text" id="swal-input2" class="swal2-input form-control">`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
        });
      }
    );
    if (!title.trim() || !link.trim()) return;
    this.kanbanForm.patchValue({
      links: [
        ...this.kanbanForm.value.links,
        {
          title,
          link
        }
      ]
    });
  }

  deleteLink(index: number) {
    let links = this.kanbanForm.value.links.filter((link, i) => i != index);
    this.kanbanForm.patchValue({ links: links });
  }

  async uploadFile() {
    let filesUrl = [];
    let url: string;
    let type: string;
    let name: string;

    for (const component of this.filesStorage.toArray()) {
      if (component.isInputValid) {
        url = await component.uploadDocument('task', new Date().getTime());
      }
      type = component.fileToUpload.type;
      name = component.fileToUpload.name;
    }
    filesUrl.push({ fileType: type, url: url, fileName: name });
    this.kanbanForm.value.attachments = [
      ...this.kanbanForm.value.attachments,
      ...filesUrl
    ];
  }

  private getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project
            .filter(
              (project) => project.status == ProjectStatus.ACTIVE ||
                project.status == ProjectStatus.EXPIRATED
            )
            .map((project) => ({
              ...project,
              type:
                project.type == CostCenterType.PROJECT
                  ? 'Proyectos'
                  : 'Centro de Costos'
            }))
        )
      )
      .subscribe(async (data) => {
        this.selectArray = data;
      });
  }

  convertToTime(date) {
    if (date == null) return null;

    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }
}
