import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Warehouse } from '../../../../interfaces/warehouse';
import { Article } from '../../../../interfaces/article';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArticlesTransferService } from '../../../../services/articles-transfer.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AlertService } from '../../../../../../../shared/template-services/alert.service';
import { ArticleQuantityFormComponent } from '../article-quantity-form/article-quantity-form.component';

@Component({
  selector: 'app-destination-warehouse-article',
  templateUrl: './destination-warehouse-article.component.html',
  styleUrls: ['./destination-warehouse-article.component.css']
})
export class DestinationWarehouseArticleComponent implements OnInit {
  @Input() article: Article;
  @Input() warehouseId: string;

  addedArticles: { article: Article, quantity: number, origin: Warehouse }[] = [];
  warehouses: any;
  warehousesSubscription = new Subscription();
  total: number = 0;
  added: number = 0;
  dropData: any;

  constructor(private _transfer: ArticlesTransferService,
    private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.warehousesSubscription = this._transfer.warehouses$.subscribe(warehouses => {
      this.warehouses = warehouses;

      if (this.article) {
        this.total = warehouses[this.warehouseId]?.articles?.find(a => a.key == this.article.key)?.quantity || 0;
        this.added = warehouses[this.warehouseId]?.added[this.article.key]?.quantity || 0;
        this.addedArticles = warehouses[this.warehouseId]?.added[this.article.key]?.articles || [];
      }
    });

    this.dropData = {
      warehouseId: this.warehouseId,
      articleId: this.article?.key || null
    };
  }

  removeArticle(articleId: string, articleWarehouseId: string) {
    this._transfer.removeArticle(
      {
        articleId: articleId,
        warehouseId: articleWarehouseId
      },
      {
        articleId: this.article.key,
        warehouseId: this.warehouseId
      });
  }

  drop(event: CdkDragDrop<any>) {
    const destination = event.container.data;
    const origin = event.item.data;

    if (!!destination.articleId && origin.articleId != destination.articleId) {
      AlertService.toastError('Los artículos son diferentes, no se puede realizar el traspaso');
      return false;
    }

    if (
      !destination.articleId &&
      (
        this.warehouses[this.warehouseId].articles.find(article => article.key == origin.articleId) ||
        this.warehouses[this.warehouseId].added[origin.articleId]
      )
    ) {
      AlertService.toastError('El artículo ya se encuentra en la lista');
      return false;
    }

    if (event.container.data.warehouseId == event.item.data.warehouseId) {
      AlertService.toastError('No se puede transferir un artículo a la misma bodega');
      return;
    }

    this.modal.show(ArticleQuantityFormComponent, {
      initialState: {
        originArticle: event.item.data,
        destinationArticle: event.container.data
      },
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
      id: 667
    });
  }

  ngOnDestroy() {
    this.warehousesSubscription.unsubscribe();
  }
}
