<div class="card">
  <div class="card-body d-flex align-items-center">
    <div class="d-flex flex-column">
      <label>Filtrar por tipo de tablero</label>
      <mat-radio-group [(ngModel)]="boardTypeSelected" (change)="changeBoardsFilter()" [color]="">
        <mat-radio-button class="mr-1" [value]="boardTypeEnum.TEAM">Equipo</mat-radio-button>
        <mat-radio-button [value]="boardTypeEnum.PERSONAL" (click)="redirectToMyTasksKanban()">Personal</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<app-datatable [rows$]="boards$" [config]="config" (rowSelected)="goToSelectedBoard($event)">

  <app-datatable-column title="Nombre" field="name">
    <ng-template let-row #column>
      <h4>{{ row.name }}</h4>
      <p>{{row.description}}</p>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Admins" field="admins" *ngIf="boardTypeSelected == boardTypeEnum.TEAM">
    <ng-template let-row #column>
      <div *ngIf="row?.admins.length > 0; else userDefault">
        <ul class="list-unstyled users-list m-0">
          <li class="avatar avatar-sm pull-up" *ngFor="let admin of row?.admins">
            <img class="media-object rounded-circle avatar-img"
                 container="body"
                 ngbTooltip="{{admin?.name}}"
                 src="{{!!admin?.imageUrl ? admin?.imageUrl : '/assets/images/default.png'}}" alt="Administrador">
          </li>
        </ul>
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Supervisores" field="supervisors" *ngIf="boardTypeSelected == boardTypeEnum.TEAM">
    <ng-template let-row #column>
      <div *ngIf="row?.supervisors.length > 0; else userDefault">
        <ul class="list-unstyled users-list m-0">
          <li class="avatar avatar-sm pull-up" *ngFor="let supervisor of row?.supervisors">
            <img class="media-object rounded-circle avatar-img"
                 container="body"
                 ngbTooltip="{{supervisor?.name}}"
                 src="{{!!supervisor?.imageUrl ? supervisor?.imageUrl : '/assets/images/default.png'}}"
                 alt="Supervisor">
          </li>
        </ul>
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Usuarios" field="users" *ngIf="boardTypeSelected == boardTypeEnum.TEAM">
    <ng-template let-row #column>
      <div *ngIf="row?.users.length > 0; else userDefault">
        <ul class="list-unstyled users-list m-0">
          <li class="avatar avatar-sm pull-up" *ngFor="let user of row?.users">
            <img class="media-object rounded-circle avatar-img"
                 container="body"
                 ngbTooltip="{{user?.name}}"
                 src="{{!!user?.imageUrl ? user?.imageUrl : '/assets/images/default.png'}}" alt="Usuario">
          </li>
        </ul>
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Usuario" field="owner" *ngIf="boardTypeSelected == boardTypeEnum.PERSONAL">
    <ng-template let-row #column>
      <div *ngIf="!!row?.owner; else userDefault">
        <ul class="list-unstyled users-list m-0">
          <li class="avatar avatar-sm pull-up">
            <img class="media-object rounded-circle avatar-img"
                 container="body"
                 ngbTooltip="{{row.owner?.name}}"
                 src="{{!!row.owner?.imageUrl ? row.owner?.imageUrl : '/assets/images/default.png'}}" alt="Usuario">
          </li>
        </ul>
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex justify-content-end">
        <button ngbTooltip="{{
                  permission == userTypeEnum.ADMIN
                  || permission == userTypeEnum.SUPERVISOR
                  || boardTypeSelected == boardTypeEnum.PERSONAL ? 'Editar' : 'Ver'}}"
                class="btn btn-info mr-1" (click)="showEditBoardModal(row)">
          <i
            *ngIf="permission == userTypeEnum.ADMIN || permission == userTypeEnum.SUPERVISOR || boardTypeSelected == boardTypeEnum.PERSONAL"
            class="la la-edit"></i>
          <i
            *ngIf="(permission != userTypeEnum.ADMIN && permission != userTypeEnum.SUPERVISOR) && boardTypeSelected == boardTypeEnum.TEAM"
            class="la la-eye"></i>
        </button>
        <button ngbTooltip="Eliminar" class="btn btn-danger" (click)="deleteBoard(row.key)"
                *ngIf="permission == userTypeEnum.ADMIN || boardTypeSelected == boardTypeEnum.PERSONAL">
          <i class="la la-trash"></i>
        </button>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<ng-template #userDefault>
  <ul class="list-unstyled users-list m-0">
    <li class="avatar avatar-sm pull-up">
      <img class="media-object rounded-circle avatar-img"
           container="body"
           ngbTooltip="Sin asignar"
           src="/assets/images/default.png" alt="Sin asignar">
    </li>
  </ul>
</ng-template>

<app-add-float-button *ngIf="permission == userTypeEnum.ADMIN" (clickEmmiter)="showAddBoardModal()"></app-add-float-button>
