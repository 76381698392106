import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTableConfig } from '../../../../../../../../shared/interfaces/data-table-config';
import _ from 'lodash';
import { Permission } from '../../../../../../../enums/permissions.enum';
import { TicketStatus } from '../../../../../enums/ticket-status.enum';
import { Ticket } from '../../../../../interfaces/ticket';
import { TicketEntry } from '../../../../../interfaces/ticket-entry';
import { TicketEntryStatus } from '../../../../../enums/ticket-entry-status.enum';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArticleHistoryComponent } from '../article-history/article-history.component';
import { TicketsBadgesService } from '../../services/tickets-badges.service';
import { ArticleType } from '../../../../../enums/article-type.enum';
import { NormalizeService } from '../../../../../../../../shared/services/normalize.service';
import { Warehouse } from '../../../../../interfaces/warehouse';
import moment from 'moment';

@Component({
  selector: 'app-user-tickets-details',
  templateUrl: './user-tickets-details.component.html',
  styleUrls: ['./user-tickets-details.component.css']
})
export class UserTicketsDetailsComponent implements OnInit {
  public ArticleType = ArticleType;
  public TicketStatus = TicketStatus;

  @Input() user: any;
  @Input() tickets: Ticket[];
  @Input() entryTickets: TicketEntry[];
  @Input() warehouses: { [key: string]: Warehouse };
  @Output() resetSelectedUser: EventEmitter<any> = new EventEmitter<any>();

  warehouseOptions: Warehouse[];
  articles: any[];
  filteredArticles: any[];
  entryArticles: any[];
  filteredEntryArticles: any[];
  tabIndex = 0;
  total = 0;
  totalConsumables = 0;
  totalStorable = 0;
  totalEntry = 0;
  totalEntryConsumables = 0;
  totalEntryStorable = 0;
  allArticles: any[];
  filteredAllArticles: any[];
  typeFilter = ArticleType.STORABLE;
  returnedArticles: any = [];
  filteredReturnedArticles: any = [];
  warehouseFilter = '';
  minDate;
  maxDate;

  articlesResumeTableConfig: DataTableConfig = {
    title: 'Artículos retirados por usuario',
    notFoundText: 'No se encontraron tickets',
    hasSearch: true,
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 25,
    filtersFields: ['internalId', 'name', 'type']
  };
  articlesTableConfig: DataTableConfig = {
    ...this.articlesResumeTableConfig,
    noClickable: true,
    filtersFields: ['internalId', 'ticketId', 'name', 'type']
  };

  constructor(private modal: BsModalService,
    public ticketsBadgesService: TicketsBadgesService,
    private normalize: NormalizeService) {
  }

  ngOnInit(): void {
    this.warehouseOptions = Object.values(this.warehouses);
    this.articles =
      _.chain(this.tickets)
        .filter(ticket =>
          !ticket?.isTransfer &&
          (ticket.status == TicketStatus.COLLECTED ||
            ticket.status == TicketStatus.INCOMPLETE)
        )
        .flatMap(ticket => ticket.articles.map(a => ({
          ...a,
          createdAt: ticket['createdDate'],
          ticketID: ticket.ticketID,
          ticketStatus: ticket.status,
          warehouse: ticket.warehouse
        })))
        .filter(article => article.quantityOrdered != article.returned)
        .orderBy('createdAt', 'desc')
        .value();

    this.returnedArticles =
      _.chain(this.tickets)
        .filter(ticket =>
          !ticket.isTransfer &&
          (ticket.status == TicketStatus.RETURNED || ticket.status == TicketStatus.INCOMPLETE))
        .flatMap(ticket => ticket.articles.map(a => ({
          ...a,
          createdAt: ticket?.['updatedDate'] ? ticket?.['updatedDate'] : ticket['createdDate'],
          ticketID: ticket.ticketID,
          ticketStatus: ticket.status,
          warehouse: ticket.warehouse
        })))
        .filter(article => article.quantityOrdered != article.returned)
        .orderBy('createdAt', 'desc')
        .value();
    this.filteredReturnedArticles = this.returnedArticles.filter(article => article.returned > 0);

    this.entryArticles =
      _.chain(this.entryTickets)
        .filter(ticket =>
          !ticket?.isTransfer &&
          ticket.status == TicketEntryStatus.ADDED
        )
        .flatMap(ticket => ticket.articles.map(a => ({
          ...a,
          createdAt: ticket?.['updatedDate'] ? ticket?.['updatedDate'] : ticket['createdDate'],
          ticketID: ticket.ticketID,
          warehouse: ticket.warehouse,
          ticketStatus: ticket.status
        })))
        .value();

    let mappedArticles = {};
    this.articles.forEach(article => {
      const quantityOrdered = article.ticketStatus == TicketStatus.INCOMPLETE
        ? article.quantityOrdered - article.returned
        : article.quantityOrdered;

      if (!mappedArticles[article.key]) {
        mappedArticles[article.key] = {
          ...article,
          entryQuantity: 0,
          entryTotal: 0,
          quantityOrdered: quantityOrdered,
          total: quantityOrdered * article.price
        };
      } else {
        mappedArticles[article.key].quantityOrdered += quantityOrdered;
        mappedArticles[article.key].total = mappedArticles[article.key].quantityOrdered * article.price;
      }
    });

    this.entryArticles.forEach(article => {
      if (!mappedArticles[article.key]) {
        mappedArticles[article.key] = {
          ...article,
          quantityOrdered: 0,
          total: 0,
          entryQuantity: article.quantityOrdered,
          entryTotal: article.quantityOrdered * article.price
        };
      } else {
        mappedArticles[article.key].entryQuantity += article.quantityOrdered;
        mappedArticles[article.key].entryTotal = mappedArticles[article.key].entryQuantity * article.price;
      }
    });

    this.total = this.user.total;
    this.totalEntry = this.user.totalEntry;

    this.totalConsumables = this.articles
      .filter(a => this.normalize.number(a.type) == ArticleType.CONSUMABLE)
      .reduce((acc, a) => {
        if (a.ticketStatus == TicketStatus.INCOMPLETE) {
          return acc + ((a.quantityOrdered - a.returned) * a.price);
        } else {
          return acc + (a.quantityOrdered * a.price);
        }
      }, 0);

    this.totalEntryConsumables = this.entryArticles
      .filter(a => this.normalize.number(a.type) == ArticleType.CONSUMABLE)
      .reduce((acc, a) => acc + (a.quantityOrdered * a.price), 0);

    this.totalStorable = this.articles
      .filter(a => this.normalize.number(a.type) == ArticleType.STORABLE)
      .reduce((acc, a) => {
        if (a.ticketStatus == TicketStatus.INCOMPLETE) {
          return acc + ((a.quantityOrdered - a.returned) * a.price);
        } else {
          return acc + (a.quantityOrdered * a.price);
        }
      }, 0);

    this.totalEntryStorable = this.entryArticles
      .filter(a => this.normalize.number(a.type) == ArticleType.STORABLE)
      .reduce((acc, a) => acc + (a.quantityOrdered * a.price), 0);

    this.allArticles = Object.values(mappedArticles);
    this.handleFilter();
  }

  openArticleHistory(article: any) {
    this.modal.show(ArticleHistoryComponent, {
      initialState: {
        article,
        allTickets: this.tickets,
        allEntryTickets: this.entryTickets
      },
      class: 'modal-lg'
    });
  }

  handleResetSelectedUser() {
    this.resetSelectedUser.emit(null);
  }

  getTotalOwned(withdraw = 0, added = 0) {
    return added - withdraw;
  }

  getTotalClass(total: number, type: 'in' | 'out') {
    if (type == 'in') {
      return total > 0 ? 'text-success' : total < 0 ? 'text-danger' : 'text-light';
    } else {
      return total > 0 ? 'text-danger' : total < 0 ? 'text-success' : 'text-light';
    }
  }

  filterArticles(articles: any[]) {
    const min = this.minDate && moment(this.minDate).valueOf();
    const max = this.maxDate && moment(this.maxDate).valueOf();
    return articles
      .filter(article => article.type == this.typeFilter)
      .filter(article => !this.warehouseFilter || this.warehouseFilter == '' || article.warehouse.id == this.warehouseFilter)
      .filter(article => !this.minDate || article.createdAt >= min)
      .filter(article => !this.maxDate || article.createdAt <= max);
  }

  handleFilter() {
    this.filteredAllArticles = this.allArticles;
    this.filteredArticles = this.articles;
    this.filteredEntryArticles = this.entryArticles;

    if (this.typeFilter == null) {
      return;
    }

    this.filteredAllArticles = this.filterArticles(this.allArticles);
    this.filteredArticles = this.filterArticles(this.articles);
    this.filteredEntryArticles = this.filterArticles(this.entryArticles);
  }
}
