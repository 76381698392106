import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number | string): unknown {
    const numberValue = Number(value.toString().replace(/[.$,]/g, '')) || 0;
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(numberValue);
  }
}
