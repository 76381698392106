<div
  class="flex-1 card article-card"
  cdkDrag
  [cdkDragData]="dragData"
>
  <span class="mr-1"><strong>{{ article.name }}</strong></span>
  <div class="d-flex">
    <img class="article-image mr-1" [src]="article?.imageUrl||'assets/images/article.svg'"/>
    <div>
      <div class="d-flex flex-column">
        <div>
          <span><strong>{{ total }} </strong></span>
          <span *ngIf="removedArticles.length" class="text-danger">(-{{ getTotalRemoved() }})</span>
        </div>
      </div>
      <div
        *ngIf="removedArticles.length"
        class="movements-container"
      >
        <div *ngFor="let articleInfo of removedArticles" class="movement">
          <i
            (click)="removeArticle(articleInfo.article.key,articleInfo.destination.key)"
            class="fa fa-times cursor-pointer"
          ></i>
          <span class="text-danger">
        (-{{ articleInfo.quantity }}) - {{ articleInfo.destination.name }} ({{ articleInfo.destination.code }})
      </span>
        </div>
      </div>
    </div>
  </div>
</div>
