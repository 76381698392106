import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-icon-group',
  templateUrl: './icon-group.component.html',
  styleUrls: ['./icon-group.component.scss']
})
export class IconGroupComponent implements OnInit {
  @Input() title: string;
  @Input() icons: string[];
  @Input()  selectedIcon: string = '';
  @Output() handleSelectedIcon = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleIconSelection(icon: string) {
    this.selectedIcon = icon;
    this.handleSelectedIcon.emit(icon);
  }
}
