<div class="close-icon" (click)="closeModal()">
  <i class="fa fa-times"></i>
</div>
<form (ngSubmit)="updateDescription()">
  <div>
    <label>Ingrese nueva descripción</label>
    <textarea class="form-control description-input" rows="3" [(ngModel)]="description" name="description"></textarea>
  </div>
  <div class="button-container">
    <button class="btn btn-success" type="submit">Actualizar</button>
    <button class="btn btn-primary" (click)="closeModal()" type="button">Cancelar</button>
  </div>
</form>
