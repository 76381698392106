import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Article } from '../../../../interfaces/article';
import { Warehouse } from '../../../../interfaces/warehouse';
import { ArticlesTransferService } from '../../../../services/articles-transfer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-warehouse-article',
  templateUrl: './warehouse-article.component.html',
  styleUrls: ['./warehouse-article.component.css']
})
export class WarehouseArticleComponent implements OnInit, OnDestroy {
  @Input() article: Article;
  @Input() warehouseId: string;

  dragData: any;
  removedArticles: { article: Article, quantity: number, destination: Warehouse }[] = [];
  warehouses: Warehouse[] = [];
  warehousesSubscription = new Subscription();
  total: number = 0;

  constructor(private _transfer: ArticlesTransferService) {
  }

  ngOnInit(): void {
    this.warehousesSubscription = this._transfer.warehouses$.subscribe(warehouses => {
      this.total = warehouses[this.warehouseId]?.articles?.find(a => a.key == this.article.key)?.quantity || 0;
      this.removedArticles = warehouses[this.warehouseId]?.removed?.[this.article.key] || [];
    });

    this.dragData = {
      warehouseId: this.warehouseId,
      articleId: this.article.key
    };
  }

  getTotalRemoved() {
    return this.removedArticles.reduce((acc, data) => acc + data.quantity, 0);
  }

  removeArticle(articleId: string, warehouseId: string) {
    this._transfer.removeArticle(
      {
        articleId: this.article.key,
        warehouseId: this.warehouseId
      },
      {
        articleId: articleId,
        warehouseId: warehouseId
      }
    );
  }

  ngOnDestroy() {
    this.warehousesSubscription.unsubscribe();
  }
}
