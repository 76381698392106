import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFullName'
})
export class GetFullNamePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    return (value?.surnames ? `${value?.name} ${value?.surnames || ''}` : value?.name).trim();
  }

}
