<div class="col-xl-12 col-lg-12 mt-2">
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="d-flex flex-wrap justify-content-between">
          <div class="d-flex flex-wrap">
            <ng-select
              class="mr-md-1 mb-sm-1"
              placeholder="Filtrar por categorías"
              [(ngModel)]="categoriesSelected"
              (ngModelChange)="filterArticles()"
              [clearable]="true"
              [multiple]="true"
              [searchable]="true"
            >
              <ng-option [value]="category" *ngFor="let category of categories">
                {{ category.name | categoryName }}
              </ng-option>
            </ng-select>
            <ng-select
              class="mr-md-1 mb-sm-1"
              [items]="sizes"
              placeholder="Talla"
              bindValue="value"
              bindLabel="name"
              [(ngModel)]="sizesSelected"
              (ngModelChange)="filterArticles()"
            >
            </ng-select>
            <ng-select
              class="mr-md-1 mb-sm-1"
              [items]="locations"
              placeholder="Filtrar por ubicación"
              [(ngModel)]="locationSelected"
              (ngModelChange)="filterArticles()"
            >
            </ng-select>
            <div class="pt-2">
              <button class="btn btn-danger btn-sm" (click)="clearFilter()"><i
                class="la la-times"
                style="font-size: 14px"
              ></i>Limpiar
              </button>
            </div>
          </div>
          <div *ngIf="permission == userType.SUPERVISOR || permission == userType.ADMIN">
            <button class="btn btn-light mr-1" (click)="selectAll()">
              Seleccionar todos
            </button>
            <button class="btn btn-info mr-1" (click)="openTransferModal()">
              <i class="fa fa-exchange"></i>
              <span> Transferir artículos</span>
            </button>
            <button
              class="btn btn-danger"
              (click)="deleteSelecteds()"
              [disabled]="articlesSelected.length == 0"
              ngbTooltip="Eliminar seleccionados"
            >
              <i class="la la-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-datatable-pagination
  [rows$]="articlesFiltered$"
  [config]="config"
  (rowSelected)="openUpdateArticleModal($event, $event.key)"
  *ngIf="!submitExcel"
  [stickyToClassName]="'.warehouse-content'"
>
  <app-datatable-column [isClickable]="false">
    <ng-template let-row #column>
      <div class="checkboxsas">
        <label>
          <input
            type="checkbox"
            [checked]="validateArticleSelected(row)"
            (click)="selectArticle(row)"
          />
        </label>
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Código">
    <ng-template let-row #column>
      {{ row?.internalId }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Artículo">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Categoría">
    <ng-template let-row #column>
      {{ row.categories[0].name | categoryName }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Imagen" [isClickable]="false">
    <ng-template let-row #column>
      <img
        (click)="
          openModalImage(
            !!row.imageUrl ? row?.imageUrl : 'assets/images/article.svg'
          )
        "
        [src]="!!row.imageUrl ? row?.imageUrl : 'assets/images/article.svg'"
        class="imageSize"
      />
    </ng-template>
  </app-datatable-column>

  <ng-template #title></ng-template>

  <app-datatable-column title="Precio">
    <ng-template let-row #column>
      {{ row.price | clearCurrency | swapCommasAndDots }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Locación">
    <ng-template let-row #column>
      {{ row?.location }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Tipo">
    <ng-template let-row #column>
      <div
        class="badge"
        [ngClass]="{
          'badge-info': row.type == 0,
          'badge-warning': row.type == 1
        }"
      >
        {{ articleTypeLabel[row?.type] }}
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Total">
    <ng-template let-row #column>
      {{ row?.type == ArticleType.CONSUMABLE ? '' : row?.quantity + (articlesInUse[row.key] || 0) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="En almacén">
    <ng-template let-row #column>
      {{ row?.quantity }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Retirados">
    <ng-template let-row #column>
      {{ row?.type == ArticleType.CONSUMABLE ? '' : (articlesInUse[row.key] || 0) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column
    title=""
    [isClickable]="false"
    [isVisiblePrint]="false"
    *ngIf="
    permission == permissionEnum.ADMIN ||
    permission == permissionEnum.SUPERVISOR ||
    permission == permissionEnum.BUYER
"
  >
    <ng-template let-row #column>
      <button
        class="btn btn-danger btn-sm ml-2"
        (click)="dropInventory(row)"
        ngbTooltip="Eliminar inventario"
      >
        <i class="feather ft-arrow-down"></i>
      </button>

      <button
        class="btn btn-info btn-sm ml-2"
        *ngIf="
          (!!row.serialNumber && row.serialNumber.length == 0) ||
          (!row.serialNumber && row.type == 0)
        "
        (click)="addSerials(row)"
        ngbTooltip="Habilitar números de serie"
      >
        <i class="feather ft-hash"></i>
      </button>

      <button
        class="btn btn-warning btn-sm ml-2"
        *ngIf="!!row.serialNumber && row.serialNumber.length > 0"
        (click)="deleteSerials(row)"
        ngbTooltip="Eliminar números seriales"
      >
        <i class="feather ft-hash"></i>
      </button>

      <button
        class="btn btn-facebook btn-sm ml-2"
        *ngIf="!!row.serialNumber && row.serialNumber.length > 0"
        (click)="editSerials(row)"
        ngbTooltip="Editar números seriales"
      >
        <i class="feather ft-edit-2"></i>
      </button>

      <button
        class="btn btn-danger btn-sm ml-2"
        (click)="deleteArticle(row.key)"
        ngbTooltip="Eliminar artículo"
        *ngIf="permission == permissionEnum.ADMIN || permission == permissionEnum.BUYER"
      >
        <i class="feather ft-trash"></i>
      </button>
    </ng-template>
  </app-datatable-column>
</app-datatable-pagination>
