<div class="shortcuts-config">
  <h2>Configuración de atajos</h2>
  <div cdkDropList class="shortcuts-container" (cdkDropListDropped)="drop($event)">
    <span>Arrastra los atajos para cambiar su orden</span>
    <div
      class="shortcut" *ngFor="let shortcut of shortcuts"
      cdkDrag
    >
      <div class="d-flex align-items-center justify-content-center">
        <i [ngClass]="'text-dark-emphasis fa fa-' + shortcut.icon"></i>
        <span class="ml-2 text-light">{{shortcut.label}}</span></div>
      <div class="shortcut-button-container ">
        <i class="fa fa-pencil text-info" (click)="handleEditShortcut(shortcut)"></i>
        <i class="fa fa-trash text-danger" (click)="handleRemoveShortcut(shortcut)"></i>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center my-2">
    <button class="btn btn-info" (click)="closeModal()">Terminar</button>
  </div>
</div>
