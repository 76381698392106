import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Article} from '../../interfaces/article';

@Component({
  selector: 'app-article-returner-quantity',
  templateUrl: './article-returner-quantity.component.html',
  styleUrls: ['./article-returner-quantity.component.css'],
})
export class ArticleReturnerQuantityComponent implements OnInit {
  serialNumberCollected: any[] = [];
  quantity: number;
  comments: string = 'Artículo en buen estado';
  serialNumberReturned: any[] = [];
  article: Article;
  selectedSerialNumbers;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {}

  finish() {
    if (!this.quantity)
      return AlertService.error('Se debe agregar una cantidad');
    if (!this.serialNumberCollected && this.selectedSerialNumbers)
      return AlertService.error('Se debe agregar un número de serie');
    if (
      this.article.serialNumber.length > 0
      && !!this.serialNumberCollected
      && this.quantity != this.serialNumberReturned.length
    )
      return AlertService.toastError(
        'Tiene que seleccionar el mismo número de seriales que la cantidad a devolver'
      );

    this.modalRef.hide();
  }
}
