<div class="card-body p-2">
  <div class="d-flex align-items-center justify-content-between">
    <div class="mb-2">
      <span><strong>Ticket:</strong></span>
      <a class="a cursor-pointer text-info ml-1" [href]="ticketUrl" target="_blank">
        {{ request.ticketID | slice:0 : -3 }}
        <span class="text-muted font-size-xsmall">#{{ request?.ticketID | slice:-3 }}</span>
      </a>
    </div>
    <span class="text-muted">{{ request.date | date }}</span>
  </div>
  <div>
    <span>El usuario <strong>{{ request.user }}</strong> ha solicitado una rectificación
    para el ticket
    <strong>
      {{ request.ticketID | slice:0 : -3 }}
      <span class="text-muted font-size-xsmall">#{{ request?.ticketID | slice:-3 }}</span>
    </strong> el día <strong>{{ request.date|date }}</strong>.
    </span>
  </div>
  <div class="w-100 text-center p-2">
    <span class="text-muted">"{{ request.comments }}"</span>
  </div>
  <div class="d-flex align-items-center justify-content-end mt-2">
    <button class="btn btn-primary" (click)="close()">Cerrar</button>
    <a class="ml-2 btn btn-info" [href]="ticketUrl" target="_blank">Ir al ticket</a>
  </div>
</div>
<div class="close-icon" (click)="close()">
  <i class="fa fa-times "></i>
</div>
