<block-ui>
  <div class="d-flex mt-2 mb-2 flex-wrap">
    <div class="ml-2 col-md-4 p-0">
      <ng-select [items]="articleStatus"
                 [multiple]="false"
                 [(ngModel)]="statusSelected"
                 class="select-status select-width w-100"
                 placeholder="Seleccione un estatus"
                 bindLabel="name">
      </ng-select>
    </div>

    <div class="ml-2">
      <ng-select [items]="selectArrayProjects"
                 [(ngModel)]="projectSelected"
                 bindLabel="name"
                 placeholder="Seleccione un proyecto"
                 class="select-status select-width">
      </ng-select>
    </div>

    <div class="ml-2">
      <ng-select [items]="selectArrayUsers"
                 [(ngModel)]="userSelected"
                 bindLabel="name"
                 placeholder="Seleccione un usuario"
                 class="select-status select-width">
      </ng-select>
    </div>

    <div class="ml-2 d-flex align-items-center">
      <button class="btn btn-info export-btn"
              app-tooltip="Exportar"
              (click)="exportArticles()">
        <i class="la la-download"></i>
      </button>

    </div>
  </div>

  <ng-container *ngIf="tickets$ | async as tickets">
    <ng-container *ngIf="tickets.length > 0; else notFound">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{config.title}}</h4>
          <div class="heading-elements" *ngIf="config.hasSearch">
            <ul class="list-inline mb-0">
              <li>
                <div class="form-group position-relative has-icon-left">
                  <input type="text" class="form-control" id="iconLeft4" placeholder="Buscar" [(ngModel)]="search">
                  <div class="form-control-position">
                    <i class="feather ft-search"></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-content mt-3">
          <div class="table-responsive" fxFlex="auto" perfectScrollbar>
            <table class="table border_bottom table-hover">
              <thead>
              <tr>
                <th class="pro-thumbnail">Ticket</th>
                <th class="pro-thumbnail">Proyecto</th>
                <th class="pro-thumbnail">Solicitante</th>
                <th class="pro-thumbnail">Artículo</th>
                <th class="pro-thumbnail">Imagen</th>
                <th class="pro-thumbnail">Cantidad</th>
                <th class="pro-thumbnail">Fecha</th>
                <th class="pro-thumbnail">Acción</th>
              </tr>
              </thead>
              <tbody>
              <ng-container
                *ngFor="let ticket of tickets | filter : !!userSelected ? userSelected.name : null  : ['userName'] | filter :!!projectSelected ? projectSelected.name : null : ['projectName']; let index = index">
                <ng-container *ngFor="let article of ticket.articles | filter : search : ['name'] let index = index">
                  <ng-container
                    *ngIf="(article.lost == statusSelected.lost && !!article.damaged == statusSelected.damaged && !article.isComplete && !statusSelected.consumable && article.type == articleTypeEnum.STORABLE)">
                    <tr>
                      <td>{{ticket?.ticketID | slice:0 : -3}}<span
                        class="text-muted font-size-xsmall">#{{ticket?.ticketID | slice:-3 }}</span></td>
                      <td>{{ticket?.projectName}}</td>
                      <td>{{ticket?.userName}}</td>
                      <td>{{article.name}} ({{articleTypeLabel[article.type]}})</td>
                      <td>
                        <img
                          (click)="openModalImage(article?.imageUrl || 'assets/images/article.svg')"
                          [src]="article?.imageUrl || 'assets/images/article.svg'"
                          class="imageSize"
                        >
                      </td>
                      <td>{{article.quantity - article.returned}}</td>
                      <td>{{ticket?.createdDate | date: 'dd/MM/yyyy HH:mm'}}</td>
                      <td>
                        <div class="d-flex flex-row">
                          <div class="btn btn-success btn-sm mr-sm" ngbTooltip="Cantidad a devolver"
                               (click)="addArticleQuantity(article, ticket.key, tickets)"><i
                            class="feather ft-plus"></i></div>
                          <div class="btn btn-danger btn-sm mr-sm" ngbTooltip="Reportar perdido"
                               *ngIf="!statusSelected.lost && !statusSelected.damaged && article.type == articleTypeEnum.STORABLE"
                               (click)="lostArticle(article, ticket.key, tickets)"><i
                            class="feather ft-x"></i></div>
                          <div class="btn btn-danger btn-sm mr-sm" ngbTooltip="Reportar como dañado"
                               *ngIf="!statusSelected.damaged && article.type == articleTypeEnum.STORABLE"
                               (click)="damageArticle(article, ticket.key, tickets)"><i
                            class="feather ft-alert-triangle"></i></div>
                          <div class="btn btn-info btn-sm" ngbTooltip="No se regresó nada"
                               *ngIf="!statusSelected.lost && article.type == articleTypeEnum.CONSUMABLE"
                               (click)="addArticleQuantity(article, ticket.key, tickets, true)"><i
                            class="feather ft-check"></i></div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container
                    *ngIf="(article.lost == statusSelected.lost && !!article.damaged == statusSelected.damaged && !article.isComplete && !!statusSelected.consumable && article.type == articleTypeEnum.CONSUMABLE)">
                    <tr>
                      <td>{{ticket?.ticketID | slice:0 : -3}}<span
                        class="text-muted font-size-xsmall">#{{ticket?.ticketID | slice:-3 }}</span></td>
                      <td>{{(ticket?.projects | doc | async)?.name}}</td>
                      <td>{{(ticket?.user | doc | async)?.name}}</td>
                      <td>{{article.name}} ({{articleTypeLabel[article.type]}})</td>
                      <td>
                        <img
                          (click)="openModalImage(article?.imageUrl || 'assets/images/article.svg')"
                          [src]="article?.imageUrl || 'assets/images/article.svg'"
                          class="imageSize">
                      </td>
                      <td>{{article.quantity - article.returned}}</td>
                      <td>{{ticket?.createdDate | date: 'dd/MM/yyyy HH:mm'}}</td>
                      <td>
                        <div class="btn btn-success btn-sm mr-1" ngbTooltip="Cantidad a devolver"
                             (click)="addArticleQuantity(article, ticket.key, tickets)"><i
                          class="feather ft-plus"></i></div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #notFound>
      <app-not-found size="medium" [title]="config.notFoundText"></app-not-found>
    </ng-template>

  </ng-container>

  <ng-template #loaded>
    <div class="loader-wrapper">
      <div class="loader-container">
        <div class="ball-clip-rotate loader-primary">
          <div></div>
        </div>
      </div>
    </div>
  </ng-template>
</block-ui>
