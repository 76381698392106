import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProviderArticle } from '../interfaces/provider-article';
import { ReplaySubject } from 'rxjs';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProviderArticleService extends FirebaseDataService {
  public providersArticles: any[];
  providersArticles$: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  constructor(private _db: AngularFirestore,
    private http: HttpClient) {
    super(_db, null);
  }

  set(providerArticleKey: string, providerArticle: ProviderArticle): void {
    this.doc(`providersArticles/${providerArticleKey}`).set(providerArticle, { merge: true });
  }

  getAll() {
    if (!this.providersArticles) {
      this.providersArticles = [];
      this.colWithIds$('providersArticles', ref => ref
        .where('trash', '==', false)
        .orderBy('createdAt', 'desc'))
        .subscribe(data => {
          this.providersArticles$.next(data);
          this.providersArticles = data;
        });
    }

    return this.providersArticles$;
  }

  getArticlesDataFromAPI(fromDate?: number) {
    return this.http.get<{
      articles: ProviderArticle[],
      providers: any,
      purchaseOrders: any
    }>(`${environment.apiBaseURLV1}/purchase-order/articles${fromDate ? '/' + fromDate : ''}`);
  }

  async update(providerArticleKey: string, providerArticle: ProviderArticle): Promise<void> {
    return await this.doc<ProviderArticle>(`providersArticles/${providerArticleKey}`).update(providerArticle);
  }
}
