import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Applicant } from '../../admin/interfaces/applicant';
import { take } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {
  id: string;
  applicants: any[];
  applicantsQueue: Applicant[] = [];

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private http: HttpClient,
              private _user: UserService) {
  }

  getAll(): Observable<Applicant[]> {
    return this.db.colWithIds$<Applicant>('applicants', ref => ref
      .where('trash', '==', false)
      .orderBy('createdAt', 'desc'));
  }

  get(applicantKey: string): Observable<Applicant> {
    return this.db.docWithId$(`applicants/${applicantKey}`);
  }

  getByRut(rut: string): Observable<Applicant[]> {
    return this.db.colWithIds$<Applicant>('applicants', ref => ref
      .where('rut', '==', rut));
  }

  getSpecifyUser(name, surnames): Observable<Applicant[]> {
    return this.db.colWithIds$<Applicant>('applicants', ref => ref
      .where('name', '==', name)
      .where('surnames', '==', surnames));
  }

  update(applicantKey: string, applicant: Applicant): Promise<void> {
    return this.afs.doc<Applicant>(`applicants/${applicantKey}`).update(applicant);
  }

  updateQuiz(applicantKey: string, quiz: any) {
    this.afs.doc<Applicant>(`applicants/${applicantKey}`).update(quiz);
  }

  updateStatus(applicantKey: string, status: any): Promise<void> {
    return this.afs.doc<Applicant>(`applicants/${applicantKey}`).update({ status, updatedAt: Date.now() });
  }

  updateRecruimentStatus(applicantKey: string, recruitmentStatus: any): Promise<void> {
    return this.afs.doc<Applicant>(`applicants/${applicantKey}`).update({ recruitmentStatus });
  }

  updateProject(applicantKey: string, project: any, applicant?: Applicant): Promise<void> {
    if (applicant) {
      return this.afs.doc<Applicant>(`applicants/${applicantKey}`).update({
        project,
        initialAccessDate: applicant.initialAccessDate ? applicant.initialAccessDate : moment().add(1, 'year').toDate().getTime(),
        finalAccessDate: applicant.finalAccessDate ? applicant.finalAccessDate : moment().add(1, 'year').add(1, 'day').toDate().getTime()
      });
    } else {
      return this.afs.doc<Applicant>(`applicants/${applicantKey}`).update({ project });
    }
  }

  delete(applicantKey: string): Promise<void> {
    return this.afs.doc<Applicant>(`applicants/${applicantKey}`).update({ trash: true });
  }

  add(rut: string, applicant: Applicant) {
    return this.afs.collection<Applicant>('applicants').doc(rut).set(applicant, { merge: true });
  }

  async getSpecificApplicant(applicantKey: string): Promise<Applicant> {
    return await this.db.docWithId$<Applicant>(`applicants/${applicantKey}`)
      .pipe(take(1))
      .toPromise();
  }

  async uploadCurriculum(curriculum, applicantKey) {
    if (applicantKey == null) {
      this.id = this.afs.createId();
    } else {
      this.id = applicantKey;
    }
    const uploadRef = this.getStorageRefCurriculum(this.id);
    await uploadRef.put(curriculum);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadCurriculumStorage(curriculum, this.id);

    return url;
  }

  uploadCurriculumStorage(data, id) {
    return this.storage.upload(`applicant/curriculum/${id}/applicantCurriculum.pdf`, data);
  }

  getStorageRefCurriculum(id) {
    return this.storage.ref(`applicant/curriculum/${id}/applicantCurriculum.pdf`);
  }

  async uploadTitle(title, applicantKey) {
    if (applicantKey == null) {
      this.id = this.afs.createId();
    } else {
      this.id = applicantKey;
    }
    const uploadRef = this.getStorageRefTitle(this.id);
    await uploadRef.put(title);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadTitleStorage(title, this.id);

    return url;
  }

  uploadTitleStorage(data, id) {
    return this.storage.upload(`applicant/title/${id}/title.jpeg`, data);
  }

  getStorageRefTitle(id) {
    return this.storage.ref(`applicant/title/${id}/title.jpeg`);
  }

  async sendAccountEmail(email: string, password: string, emailUser: string) {
    return this.http.post(`${environment.apiBaseURL}/sendAccountEmail`, {
      email,
      password,
      emailUser,
      senderEmail: this._user.user.email
    }).pipe(take(1)).toPromise();
  }

  async sendUpdateCvEmail(applicantKey: string, email: string) {
    return this.http.post(`${environment.apiBaseURL}/sendUpdateCvEmail`, {
      applicantKey,
      email
    }).pipe(take(1)).toPromise();
  }

  getComments(applicantKey: any): Observable<any[]> {
    return this.db.colWithIds$(`applicants/${applicantKey}/comments`, ref => ref
      .orderBy('date', 'desc'));
  }
}
