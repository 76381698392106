<div class="p-2">
  <div class="d-flex py-1">
    <span
      (click)="handleResetSelectedUser()"
      class="badge badge-pill badge-primary cursor-pointer"
    >
      <i class="fa fa-arrow-left mr-1"></i>Regresar
    </span>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-around">
        <div class="d-flex">
          <img
            src="{{!user?.photoUrl  ? 'assets/images/default.png' : user.photoUrl}}"
            alt="user photo"
            class="rounded-circle mr-2"
            width="100"
            height="100"
          />
          <div>
            <h3 class="mb-0">{{ user | getFullName }}</h3>
            <span class="text-light">{{ user.rut }}</span>
          </div>
        </div>
      </div>
      <div class="mt-2 d-flex text-center justify-content-center">
        <div>
          <span>Artículos retirados</span>
          <div class="totals-container">
            <div class="d-flex flex-column">
              <span class="total-subtitle text-light">Consumibles:</span>
              <span><strong>{{ totalConsumables | currencyFormat }}</strong></span>
            </div>
            <div class="d-flex flex-column">
              <span class="total-subtitle text-light">Almacenables:</span>
              <span><strong>{{ totalStorable | currencyFormat }}</strong></span>
            </div>
            <div class="d-flex flex-column">
              <span class="total-subtitle text-light">Total:</span>
              <span><strong>{{ total | currencyFormat }}</strong></span>
            </div>
          </div>
        </div>
        <div>
          <span>Artículos ingresados</span>
          <div class="totals-container">
            <div class="d-flex flex-column">
              <span class="total-subtitle text-light">Consumibles:</span>
              <span><strong>{{ totalEntryConsumables | currencyFormat }}</strong></span>
            </div>
            <div class="d-flex flex-column">
              <span class="total-subtitle text-light">Almacenables:</span>
              <span><strong>{{ totalEntryStorable | currencyFormat }}</strong></span>
            </div>
            <div class="d-flex flex-column">
              <span class="total-subtitle text-light">Total:</span>
              <span><strong>{{ totalEntry | currencyFormat }}</strong></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <ng-select
          [items]="warehouseOptions"
          class="flex-1"
          bindLabel="name"
          bindValue="id"
          placeholder="Bodega"
          [(ngModel)]="warehouseFilter"
          (change)="handleFilter()"
          style="flex: 1;"
        ></ng-select>

        <div class="d-flex justify-content-evenly ml-2">
          <div class="d-flex flex-column mr-1 ml-1">
            <label [htmlFor]="minDate">Desde</label>
            <input
              id="minDate"
              class="form-control date-input"
              type="date"
              [(ngModel)]="minDate"
              (change)="handleFilter()"
            />
          </div>
          <div class="d-flex flex-column">
            <label [htmlFor]="maxDate">Hasta</label>
            <input
              id="maxDate"
              class="form-control date-input"
              type="date"
              [(ngModel)]="maxDate"
              (change)="handleFilter()"
            />
          </div>
        </div>
      </div>
      <div class="d-flex" (change)="handleFilter()">
        <div class="d-inline-block custom-control custom-radio mr-1">
          <input
            type="radio" class="custom-control-input bg-warning" [(ngModel)]="typeFilter"
            [value]="null" name="colorRadio" id="colorRadio1"
          >
          <label class="custom-control-label" for="colorRadio1">Todos</label>
        </div>
        <div class="d-inline-block custom-control custom-radio mr-1">
          <input
            type="radio" class="custom-control-input bg-primary" [(ngModel)]="typeFilter"
            [value]="ArticleType.CONSUMABLE" name="colorRadio" id="colorRadio2"
          >
          <label class="custom-control-label" for="colorRadio2">Consumibles</label>
        </div>
        <div class="d-inline-block custom-control custom-radio mr-1">
          <input
            type="radio" class="custom-control-input bg-info" [(ngModel)]="typeFilter"
            [value]="ArticleType.STORABLE" name="colorRadio" id="colorRadio3"
          >
          <label class="custom-control-label" for="colorRadio3">Almacenables</label>
        </div>
      </div>
    </div>
  </div>

  <mat-tab-group [(selectedIndex)]="tabIndex">
    <mat-tab label="Resumen de artículos">
      <app-datatable-pagination
        [rows]="filteredAllArticles"
        [config]="articlesResumeTableConfig"
        (rowSelected)="openArticleHistory($event)"
      >
        <app-datatable-column title="ID">
          <ng-template let-row #column>
            <div class="width-fit">
              {{ row?.internalId }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Nombre">
          <ng-template let-row #column>
            <div>
              {{ row?.name }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Tipo">
          <ng-template let-row #column>
            <div>
              {{ +row?.type == ArticleType.STORABLE ? 'Almacenable' : 'Consumible' }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Bodega">
          <ng-template let-row #column>
            <div>
              {{ row?.warehouse?.name }} ({{ row?.warehouse?.code }})
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Precio por unidad">
          <ng-template let-row #column>
            <div>
              {{ row?.price | currencyFormat }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Total retirado">
          <ng-template let-row #column>
            <div *ngIf="!!row.quantityOrdered;else noQuantity">
              <span [class]="getTotalClass(row.total,'out')">- {{ row.total|currencyFormat }}</span>
              <span class="text-light"> ({{ row.quantityOrdered }})</span>
            </div>
            <ng-template #noQuantity>
              <span class="text-center">-</span>
            </ng-template>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Total ingresado">
          <ng-template let-row #column>
            <div *ngIf="!!row.entryQuantity;else noEntryQuantity">
              <span [class]="getTotalClass(row.entryTotal,'in')">{{ row.entryTotal|currencyFormat }}</span>
              <span class="text-light"> ({{ row.entryQuantity }})</span>
            </div>
            <ng-template #noEntryQuantity>
              <span>-</span>
            </ng-template>
          </ng-template>
        </app-datatable-column>
      </app-datatable-pagination>
    </mat-tab>
    <mat-tab label="Artículos retirados">
      <app-datatable-pagination
        [rows]="filteredArticles"
        [config]="articlesTableConfig"
        [printSmall]="true"
      >
        <app-datatable-column title="ID">
          <ng-template let-row #column>
            <div>
              {{ row?.internalId }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Nombre">
          <ng-template let-row #column>
            <div>
              {{ row?.name }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Bodega">
          <ng-template let-row #column>
            <div>
              {{ row?.warehouse?.name }} ({{ row?.warehouse?.code }})
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Ticket">
          <ng-template let-row #column>
            <div>
              {{ row?.ticketID | slice:0 : -3 }}
              <span class="text-muted font-size-xsmall">#{{ row?.ticketID | slice:-3 }}</span>
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Estado">
          <ng-template let-row #column>
            <div
              class="badge badge-pill"
              [ngClass]="ticketsBadgesService.getTicketStatusBadge(row?.ticketStatus) "
            >
              {{ ticketsBadgesService.getTicketStatusLabel(row?.ticketStatus) }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Cantidad">
          <ng-template let-row #column>
            <div>
              {{ row.ticketStatus == TicketStatus.INCOMPLETE ? (row.quantityOrdered - row.returned) : row.quantityOrdered }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Precio por unidad">
          <ng-template let-row #column>
            <div>
              {{ row.price | currency }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Total">
          <ng-template let-row #column>
            <div>
              {{ (row.price * (row.ticketStatus == TicketStatus.INCOMPLETE ? (row.quantityOrdered - row.returned) : row.quantityOrdered)) | currency }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Fecha">
          <ng-template let-row #column>
            <div>
              {{ row.createdAt | date: 'dd/MM/yyyy - HH:mm' }}
            </div>
          </ng-template>
        </app-datatable-column>
      </app-datatable-pagination>
    </mat-tab>
    <mat-tab label="Artículos ingresados">
      <app-datatable-pagination
        [rows]="filteredEntryArticles"
        [config]="articlesTableConfig"
        [printSmall]="true"
      >
        <app-datatable-column title="ID">
          <ng-template let-row #column>
            <div>
              {{ row?.internalId }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Nombre">
          <ng-template let-row #column>
            <div>
              {{ row?.name }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Bodega">
          <ng-template let-row #column>
            <div>
              {{ row?.warehouse?.name }} ({{ row?.warehouse?.code }})
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Ticket de entrada">
          <ng-template let-row #column>
            <div>
              {{ row?.ticketID | slice:0 : -3 }}
              <span class="text-muted font-size-xsmall">#{{ row?.ticketID | slice:-3 }}</span>
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Estado">
          <ng-template let-row #column>
            <div
              class="badge badge-pill"
              [ngClass]="ticketsBadgesService.getEntryTicketStatusBadge(row?.ticketStatus) "
            >
              {{ ticketsBadgesService.getEntryTicketStatusLabel(row?.ticketStatus) }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Cantidad">
          <ng-template let-row #column>
            <div>
              {{ row.quantityOrdered }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Precio por unidad">
          <ng-template let-row #column>
            <div>
              {{ row.price | currency }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Total">
          <ng-template let-row #column>
            <div>
              {{ (row.price * row.quantityOrdered) | currency }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Fecha">
          <ng-template let-row #column>
            <div>
              {{ row.createdAt | date: 'dd/MM/yyyy' }}
            </div>
          </ng-template>
        </app-datatable-column>
      </app-datatable-pagination>
    </mat-tab>
    <mat-tab label="Artículos devueltos">
      <app-datatable-pagination
        [rows]="filteredReturnedArticles"
        [config]="articlesTableConfig"
        [printSmall]="true"
      >
        <app-datatable-column title="ID">
          <ng-template let-row #column>
            <div>
              {{ row?.internalId }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Nombre">
          <ng-template let-row #column>
            <div>
              {{ row | getFullName }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Bodega">
          <ng-template let-row #column>
            <div>
              {{ row?.warehouse?.name }} ({{ row?.warehouse?.code }})
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Ticket de entrada">
          <ng-template let-row #column>
            <div>
              {{ row?.ticketID | slice:0 : -3 }}
              <span class="text-muted font-size-xsmall">#{{ row?.ticketID | slice:-3 }}</span>
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Estado">
          <ng-template let-row #column>
            <div
              class="badge badge-pill"
              [ngClass]="ticketsBadgesService.getTicketStatusBadge(row?.ticketStatus) "
            >
              {{ ticketsBadgesService.getTicketStatusLabel(row?.ticketStatus) }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Cantidad">
          <ng-template let-row #column>
            <div>
              {{ row.returned }} / {{ row.quantityOrdered }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Precio por unidad">
          <ng-template let-row #column>
            <div>
              {{ row.price | currency }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Total devuelto">
          <ng-template let-row #column>
            <div>
              {{ (row.price * row.returned) | currency }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Total posesión">
          <ng-template let-row #column>
            <div>
              {{ (row.price * (row.quantityOrdered - row.returned)) | currency }}
            </div>
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Fecha">
          <ng-template let-row #column>
            <div>
              {{ row.createdAt | date: 'dd/MM/yyyy' }}
            </div>
          </ng-template>
        </app-datatable-column>
      </app-datatable-pagination>
    </mat-tab>
  </mat-tab-group>
</div>
