import { EventEmitter, Injectable } from '@angular/core';
import { Bill } from '../../../interfaces/bill';
import { ProofOfPayment } from '../../../interfaces/proof-of-payment';

@Injectable()
export class BillDataService {
  bill: Bill;
  updating: boolean;
  billToEdit: Bill;
  purchaseOrders: any[] = [];
  projects: any[] = [];
  projectCategories: any[] = [];
  updatingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  filteredCategories: any[] = [];
  constructor() { }
}
