import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../shared/template-services/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Router} from '@angular/router';

import {UserType} from '../../admin/enums/user-type.enum';
import {AlertService} from '../../shared/template-services/alert.service';
import {User as UserData} from '../../shared/interfaces/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, first} from 'rxjs/operators';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from '../../shared/helpers/app.constants';
import { SettingService } from '../../admin/services/setting.service';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: any;
  loginForm: FormGroup;
  submitted: boolean = false;
  whitelist = [];
  bannerUrl: string;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private _auth: AuthService,
    private _user: UserService,
    private _setting: SettingService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    this.loginForm = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this._auth.getWhiteList().subscribe((data) => (this.whitelist = data));
    this._setting.getPeopleManagementSettings().subscribe(data => this.bannerUrl = data.loginBanner);
  }

  get formControls() {
    return this.loginForm.controls;
  }

  async login(): Promise<void> {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.spinner.show();
      this._auth
        .reAuth(this.loginForm.value.email, this.loginForm.value.password)
        .then(async (data) => {
          delete this.loginForm.value['password'];
          this._user.getSpecificUser3(data.user.uid)
            .pipe(
              debounceTime(5000),
              first()
            )
            .subscribe(dataUser => {
              this.spinner.hide();
              this.router.navigateByUrl('admin/blog');
            });
        })
        .catch((err) => {
          this.spinner.hide();
          AlertService.toastError(
            'Error',
            AuthService.getMessageError(err.code)
          );
        });
    }
  }

  async onGoogleLogin() {
    try {
      this.blockUI.start('Iniciando sesión, podría tardar unos segundos...');

      this.user = (await this._auth.loginGoogle()).user;
      const userEmailparts: string[] = this.user.email.split('@');
      const userEmailDomain = userEmailparts[1];

      if (userEmailDomain != 'colsan.cl') {
        this.blockUI.stop();
        AlertService.error(`Para iniciar con Google necesitas seleccionar un correo @colsan.cl`);

        return this._auth.signOut();
      } else {
        const userFinded: any = this.whitelist.find(
          (item: any) => item.email == this.user.email
        );

        if (!userFinded) {
          AlertService.error(`El usuario ${this.user.email} no tiene acceso`);
          this.blockUI.stop();

          return this._auth.signOut();
        }

        this._user.getSpecificUser3(this.user.uid)
          .pipe(
            debounceTime(10000),
            first()
          )
          .subscribe(async data => {
            if (!data) {
              await this._user.addUserByKey(this.user.uid, {
                email: this.user.email,
                name: this.user.displayName,
                phone: null,
                type: UserType.USER,
                imageUrl: this.user.photoURL,
                isDisable: false,
                firstLogin: true,
                permissions: [
                  {
                    permission: 0,
                    section: 'BODEGAS'
                  }
                ]
              });
            }

            if (!!data && (data.isDisable || ('trash' in data && data.trash))) {
              AlertService.error(
                'Tu cuenta ha sido deshabilitada',
                'Comuníquese con el administrador'
              );

              this.blockUI.stop();

              return this._auth.signOut();
            }

            this._user.update(this.user.uid, {
              imageUrl: this.user.photoURL
            } as UserData);

            this._auth.updateWhitelistItem(userFinded.key, {isRegistered: true});

            if (!data || data.firstLogin == true) {
              this.blockUI.stop();

              return this.router.navigateByUrl('first-login');
            }

            this.blockUI.stop();
            this.router.navigateByUrl('admin/blog');
          });
      }
    } catch (err) {
      this.blockUI.stop();
    }
  }

  public readonly AppConstants = AppConstants;
}
