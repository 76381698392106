<div class="p-2 classification-container">
  <div class="classification-item">
    <label>Orden de compra</label>
    <h5 *ngIf="!_billData.updating">{{
        _billData.bill?.purchaseOrder?.purchaseID
          ? 'OC - ' + (_billData.bill?.purchaseOrder?.purchaseID | slice: 0:-3)
          : _billData.projectCategories
            ? 'Sin OC'
            : 'Seleccione una OC'
      }}</h5>

    <div *ngIf="_billData.updating">
      <button
        class="btn btn-info btn-sm"
        *ngIf="!hasPurchaseOrder"
        (click)="toggleHasPurchaseOrder()"
      >
        {{ hasPurchaseOrder ? 'Sin' : 'Con' }} OC
      </button>

      <ng-select
        *ngIf="hasPurchaseOrder"
        [items]="_billData.purchaseOrders"
        [searchable]="true"
        [(ngModel)]="_billData.billToEdit.purchaseOrder"
        (change)="onPurchaseOrderChange($event)"
        bindLabel="purchaseID"
        appendTo="body"
        placeholder="Seleccione una OC"
      >
        <ng-template ng-header-tmp>
          <div
            class="create-new"
            (click)="toggleHasPurchaseOrder()"
          >
            {{ hasPurchaseOrder ? 'Sin' : 'Con' }} OC
          </div>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
        >
          OC - {{ item.purchaseID | slice: 0:-3 }}
        </ng-template>

        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
        >
          <span class="ng-value-label" *ngIf="!!item">OC -{{ item.purchaseID | slice: 0:-3 }}</span>
          <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="classification-item">
    <label>Fecha prevista a pago</label>
    <h5 *ngIf="!_billData.updating">{{ _billData.bill.expectedPaymentDate | date : 'yyyy-MM-dd' }}</h5>

    <app-bill-date-picker
      *ngIf="_billData.updating"
      [(date)]="_billData.billToEdit.expectedPaymentDate"
    ></app-bill-date-picker>
  </div>

  <div class="classification-item">
    <label>Proyecto o Centro de costos</label>
    <h5 *ngIf="!_billData.updating">
      <span *ngIf="_billData.bill.purchaseOrder">{{ _billData.bill?.purchaseOrder?.project?.name }}</span>
      <span *ngIf="!_billData.bill.purchaseOrder && _billData.bill.project">{{ _billData.bill?.project?.name }}</span>
      <span *ngIf="!_billData.bill.purchaseOrder && !_billData.bill.project">{{ 'Seleccione una OC' }}</span>
    </h5>

    <h5 *ngIf="_billData.updating && hasPurchaseOrder">
      {{ _billData.billToEdit?.project?.name || 'Seleccione una OC' }}
      <span *ngIf="_billData.billToEdit.purchaseOrder">{{ _billData.billToEdit?.purchaseOrder?.project?.name }}</span>
      <span *ngIf="!_billData.billToEdit.purchaseOrder && _billData.billToEdit.project">{{ _billData.billToEdit?.project?.name }}</span>
      <span *ngIf="!_billData.billToEdit.purchaseOrder && !_billData.billToEdit.project">{{ 'Seleccione una OC' }}</span>
    </h5>

    <ng-select
      [items]="_billData.projects"
      *ngIf="!hasPurchaseOrder && _billData.updating"
      [searchable]="true"
      [(ngModel)]="_billData.billToEdit.project"
      (ngModelChange)="onProjectChange()"
      bindLabel="name"
      appendTo="body"
      placeholder="Selecciona un proyecto"
      groupBy="type"
    >
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }}
      </ng-template>
    </ng-select>
  </div>

  <div class="classification-item">
    <label>Categoría</label>
    <h5 *ngIf="!_billData.updating">
      <span *ngIf="_billData.bill.purchaseOrder">{{ _billData.bill?.purchaseOrder?.budget?.name }}</span>
      <span *ngIf="!_billData.bill.purchaseOrder && _billData.bill.project">{{ _billData.bill?.projectCategory?.name }}</span>
      <span *ngIf="!_billData.bill.purchaseOrder && !_billData.bill.project">{{ 'Seleccione una OC' }}</span>
    </h5>

    <h5 *ngIf="_billData.updating && hasPurchaseOrder">
      <span *ngIf="_billData.billToEdit.purchaseOrder">{{ _billData.billToEdit?.purchaseOrder?.budget?.name }}</span>
      <span *ngIf="!_billData.billToEdit.purchaseOrder && _billData.billToEdit.project">{{ _billData.billToEdit?.projectCategory?.name }}</span>
      <span *ngIf="!_billData.billToEdit.purchaseOrder && !_billData.billToEdit.project">{{ 'Seleccione una OC' }}</span>
      <span></span>
    </h5>

    <ng-select
      [items]="_billData.filteredCategories"
      *ngIf="!hasPurchaseOrder && _billData.updating"
      [searchable]="true"
      [(ngModel)]="_billData.billToEdit.projectCategory"
      class="w-40"
      bindLabel="name"
      appendTo="body"
      placeholder="Selecciona una categoría"
      groupBy="type"
    >
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }}
      </ng-template>
    </ng-select>
  </div>
</div>
