import { Component, OnInit } from '@angular/core';
import { EmbeddedLayoutService } from '../../shared/services/embedded-layout.service';
import { AuthService } from '../../shared/template-services/auth.service';
import { combineLatest } from 'rxjs';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-embedded-layout',
  templateUrl: './embedded-layout.component.html',
  styleUrls: ['./embedded-layout.component.css'],
  host:{
    '[class.hidden]': 'hidden'
  }
})
export class EmbeddedLayoutComponent implements OnInit {
  embeddedUrl = '';
  hidden = true;
  uid: string;
  section: string;

  constructor(
    private _embedded: EmbeddedLayoutService,
    private _auth: AuthService,
    public _user: UserService
  ) {
  }

  ngOnInit(): void {
    this.getInitialData();
    this.listenForChanges();
  }

  listenForChanges() {
    combineLatest([
      this._embedded.embeddedUrl$,
      this._embedded.hidden$,
      this._embedded.section$
    ]).subscribe(([
        embeddedUrl,
        hidden,
        section
      ]) => {
        this.hidden = hidden;
        this.embeddedUrl = embeddedUrl;
        this.section = section;
      }
    );
  }

  async getInitialData() {
    this._auth.$user.subscribe(async (user) => {
      this.uid = user?.uid;

      let token = await this._user.getValidationToken();
      token = btoa(token);

      this._embedded.updateLayoutData({
        uid: this.uid,
        validationToken: token
      });
    });
  }

  ngAfterViewInit(): void {
    window.addEventListener('message', this._embedded.getMessageData, false);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this._embedded.getMessageData, false);
  }
}
