import { Component, OnDestroy, OnInit } from '@angular/core';
import { TicketService } from '../../../../../../shared/services/ticket.service';
import { FirebaseDataService } from '../../../../../../shared/template-services/firebase-data.service';
import { TicketStatus } from '../../../enums/ticket-status.enum';
import { Ticket } from '../../../interfaces/ticket';
import { combineLatest, Subscription } from 'rxjs';
import _ from 'lodash';
import { first } from 'rxjs/operators';
import { TicketEntry } from '../../../interfaces/ticket-entry';
import { ViewportScroller } from '@angular/common';
import { TicketEntryStatus } from '../../../enums/ticket-entry-status.enum';
import { UserType } from '../../../../../enums/user-type.enum';
import { TicketEntryService } from '../../../services/ticket-entry.service';
import { WarehouseService } from '../../../../../../shared/services/warehouse.service';

@Component({
  selector: 'app-user-tickets',
  templateUrl: './user-tickets.component.html',
  styleUrls: ['./user-tickets.component.css']
})
export class UserTicketsComponent implements OnInit, OnDestroy {
  ticketsSubscription: Subscription = new Subscription();
  entryTicketsSubscription: Subscription = new Subscription();
  warehouses = {};
  tickets: Ticket[];
  entryTickets: TicketEntry[];
  users: any = [];
  ticketsByUser: {};
  entryTicketsByUser: {};
  selectedUser;
  articles: any = [];
  COLSAN_EMAIL = '@colsan.cl';
  userEntryTickets;
  userTickets;

  constructor(private _tickets: TicketService,
    private _entryTickets: TicketEntryService,
    private db: FirebaseDataService,
    private viewportScroller: ViewportScroller,
    private _warehouse: WarehouseService) {
  }

  ngOnInit(): void {
    this.getTickets();
  }

  getTickets() {
    combineLatest([
      this._tickets.getAllFromEveryWarehouse(),
      this._entryTickets.getAllFromEveryWarehouse(),
      this._warehouse.getAll()
    ]).subscribe(async ([
      tickets,
      entryTickets,
      warehouses]
    ) => {
      this.warehouses = _.keyBy(warehouses, 'id');

      this.tickets = tickets
        .filter(cTicket => !cTicket.isTransfer)
        .map(cTicket => ({ ...cTicket, warehouse: this.warehouses[cTicket.warehouse.id] }));
      this.ticketsByUser = _.groupBy(this.tickets, 'user.id');

      this.entryTickets = entryTickets
        .filter(cTicket => !cTicket.isTransfer)
        .map(cTicket => ({ ...cTicket, warehouse: this.warehouses[cTicket.warehouse.id] }));
      this.entryTicketsByUser = _.groupBy(this.entryTickets, 'user.id');

      const listedUsers = await this.getUsers(_.uniq([..._.keys(this.ticketsByUser), ..._.keys(this.entryTicketsByUser)]));

      const usersWithTotals = listedUsers?.map((user: any) => ({
        ...user,
        total: this.getUserTotal(user, this.ticketsByUser),
        totalEntry: this.getUserEntryTotal(user, this.entryTicketsByUser),
        lastUpdate: this.getLastUpdate(user, this.ticketsByUser, this.entryTicketsByUser),
        userType: this.getUserType(user)
      }));

      this.users = _.chain(usersWithTotals)
        .groupBy((data) => {
          const rut = data.rut || '';
          return rut.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        })
        .mapValues((data) => {
          const users = [];
          let topUser = data.find(user => user.warehouse?.email.includes?.(this.COLSAN_EMAIL));
          if (!topUser) topUser = data[0];

          if (data.length > 1) users.push(...data.map(user => user.id));
          else users.push(topUser.id);

          return {
            ...topUser,
            profiles: users,
            total: _.sumBy(data, 'total'),
            totalEntry: _.sumBy(data, 'totalEntry')
          };
        })
        .values()
        .value();
    });
  }

  getUserTotal(user: any, ticketsByUser: any) {
    const userTickets = ticketsByUser[user.id];
    if (!userTickets?.length) return 0;
    return _.chain(userTickets)
      .filter(ticket => ticket.status == TicketStatus.COLLECTED)
      .flatMap(ticket => ticket.articles)
      .reduce((acc, article) => acc + (article.price * article.quantity), 0)
      .value();
  }

  getUserEntryTotal(user: any, entryTicketsByUser: any) {
    const userTickets = entryTicketsByUser[user.id];
    if (!userTickets?.length) return 0;

    return _.chain(userTickets)
      .filter(ticket => ticket.status == TicketEntryStatus.ADDED)
      .flatMap(ticket => ticket.articles)
      .reduce((acc, article) => acc + (article.quantityOrdered * article.price), 0)
      .value();
  }

  getLastUpdate(user: any, ticketsByUser: any, entryTicketsByUser: any) {
    const userTickets = ticketsByUser[user.id];
    const userEntryTickets = entryTicketsByUser[user.id];

    return _.maxBy([...userTickets || [], ...userEntryTickets || []], 'createdDate')?.createdDate;
  }

  getUserType(user: any) {
    if (!user.permissions) return 'Sin permisos';

    const userType = user?.permissions.find(p => p.section == 'BODEGAS')?.permission;

    switch (userType) {
      case UserType.ADMIN:
        return 'Administrador';
      case UserType.GROCER:
        return 'Bodeguero';
      case UserType.SUPERVISOR:
        return 'Supervisor';
      case UserType.USER:
        return 'Usuario';
      case UserType.BUYER:
        return 'Comprador';
      default:
        return 'Sin permisos';
    }
  }

  async getUsers(userArray: string[]) {
    return await Promise.all(userArray.map(async (user) =>
      await this.db.docWithId$(`users/${user}`).pipe(first()).toPromise()
    ));
  }

  resetSelectedUser(user) {
    this.selectedUser = null;
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  handleUserSelected(user) {
    this.selectedUser = user;
    this.viewportScroller.scrollToPosition([0, 0]);

    this.userTickets = user.profiles.flatMap(profile => {
      return this.ticketsByUser[profile] || [];
    });

    this.userEntryTickets = user.profiles.flatMap(profile => {
      return this.entryTicketsByUser[profile] || [];
    });
  }

  ngOnDestroy() {
    this.ticketsSubscription.unsubscribe();
    this.entryTicketsSubscription.unsubscribe();
  }
}
