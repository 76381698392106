import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PurchaseStatus} from '../../../../../../enums/purchase-status.enum';

@Component({
  selector: 'app-articles-purchase-view',
  templateUrl: './articles-purchase-view.component.html',
  styleUrls: ['./articles-purchase-view.component.css']
})
export class ArticlesPurchaseViewComponent implements OnInit {
  @Input() articlesPurchaseEdit: any;
  @Input() date: any;
  @Input() deliveryReceiver: any;
  @Input() approvalDateReceiver: any;
  @Input() ticketStatus: any;
  currentDate = new Date().getTime();
  deliveryDate: any;
  @Output() eventChangeDeliveryDate: EventEmitter<any> = new EventEmitter<any>();
  purchaseStatus = PurchaseStatus;

  constructor() {
  }

  ngOnInit(): void {
  }

  convertToTime(event) {
    let deliveryDate = new Date(event.value).getTime();
    this.eventChangeDeliveryDate.emit({
      deliveryDate,
      approvalDate: this.currentDate
    });
  }

  isReference(unit) {
    if (typeof(unit) == 'object') return true;
    return false;
  }
}

