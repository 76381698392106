import { PurchaseStatus } from '../enums/purchase-status.enum';

export const PurchaseStatusLabel = {
  [PurchaseStatus.DRAFT]: 'Borrador',
  [PurchaseStatus.GENERATED]: 'Esperando autorización',
  [PurchaseStatus.AUTHORIZED]: 'Autorizado',
  [PurchaseStatus.NOT_AUTHORIZED]: 'No Autorizado',
  [PurchaseStatus.FINALIZED]: 'Finalizado',
  [PurchaseStatus.INCOMPLETE]: 'Incompleto',
  [PurchaseStatus.CANCELLED]: 'Cancelado',
  [PurchaseStatus.SHIPPED]: 'Enviado',
  [PurchaseStatus.UNDER_APPROVAL]: 'En autorización',
  [PurchaseStatus.UNDER_REVIEW]: 'En revisión',
  [PurchaseStatus.WAITING_FOR_REVIEW]: 'Esperando revisión',
};
