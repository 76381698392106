import { Injectable } from '@angular/core';
import { Warehouse } from '../interfaces/warehouse';
import { Article } from '../interfaces/article';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertService } from '../../../../shared/template-services/alert.service';
import { TicketStatus } from '../enums/ticket-status.enum';
import { TicketService } from '../../../../shared/services/ticket.service';
import { UserService } from '../../../../shared/services/user.service';
import { WarehouseService } from '../../../../shared/services/warehouse.service';
import { ArticleService } from '../../../../shared/services/article.service';
import { TicketEntryStatus } from '../enums/ticket-entry-status.enum';
import { TicketEntryService } from './ticket-entry.service';
import { NgxSpinnerService } from 'ngx-spinner';
import _ from 'lodash';
import { Ticket } from '../interfaces/ticket';

interface WarehouseArticles {
  [key: string]: {
    warehouse: Warehouse,
    articles: Article[],
    added: {
      [key: string]: {
        quantity: number,
        isNew: boolean,
        articles: {
          article: Article,
          quantity: number,
          origin: Warehouse,
        }[]
      }
    },
    removed: {
      [key: string]: {
        article: Article,
        quantity: number,
        destination: Warehouse
      }[]
    }
  };
}

@Injectable({
  providedIn: 'root'
})
export class ArticlesTransferService {
  private warehousesSubject: BehaviorSubject<WarehouseArticles>;
  public warehouses$: Observable<WarehouseArticles>;

  constructor(private _ticket: TicketService,
    private spinner: NgxSpinnerService,
    private _user: UserService,
    private _warehouse: WarehouseService,
    private _article: ArticleService,
    private _entryTicket: TicketEntryService) {
    this.warehousesSubject = new BehaviorSubject<WarehouseArticles>({});
    this.warehouses$ = this.warehousesSubject.asObservable();
  }

  get warehouses(): WarehouseArticles {
    return this.warehousesSubject.value;
  }

  updateArticleQuantity(warehouseId: string, articleId: string, quantity: number) {
    const updatedWarehouses = this.warehouses;
    const currentWarehouse = updatedWarehouses[warehouseId];
    currentWarehouse.added[articleId].quantity += quantity;

    this.setWarehouses(updatedWarehouses);
  }

  async handleRemovedItems(removedArticles, warehouse: Warehouse, currentArticles: Article[]) {
    const removedArray = _.map(removedArticles, (value, key) => ({ articleId: key, articles: value }));

    const RemovedItems = [];
    for (let i = 0; i < removedArray.length; i++) {
      const movedArticles = removedArray[i].articles;
      const currentArticle = currentArticles.find(a => a.key === removedArray[i].articleId);

      for (let j = 0; j < movedArticles.length; j++) {
        const movedArticle = movedArticles[j].article;
        const quantity = movedArticles[j].quantity;
        const destination = movedArticles[j].destination;

        RemovedItems.push({
          name: movedArticle.name,
          internalId: movedArticle.internalId,
          type: movedArticle.type,
          location: !!movedArticle.location ? movedArticle.location : '',
          quantity: +quantity,
          price: movedArticle.price,
          total: movedArticle.price * +quantity,
          key: movedArticle.key,
          isComplete: false,
          lost: false,
          message: '',
          imageUrl: !!movedArticle.imageUrl ? movedArticle.imageUrl : null,
          returned: 0,
          quantityOrdered: +quantity,
          reference: this._article.getReference(warehouse.key, currentArticle.key),
          destinyWarehouse: this._warehouse.getReference(destination.key)
        });
      }
    }

    if (RemovedItems.length === 0) return;

    const ticket = {
      articles: RemovedItems,
      comments: `Traspaso de artículos`,
      createdDate: Date.now(),
      endComments: '',
      endDate: 0,
      isAuthorized: false,
      projectCategory: null,
      projects: null,
      status: TicketStatus.GENERATED,
      supervisor: this._user.getReference(this._user.user.key),
      ticketID: await this._ticket.createId(),
      total: RemovedItems.reduce((acc, item) => acc + (item.price * +item.quantity), 0),
      trash: false,
      user: this._user.getReference(this._user.user.key),
      warehouse: this._warehouse.getReference(warehouse.key),
      isTransfer: true
    } as Ticket;

    await this._ticket.add(ticket, ticket.ticketID);
  }

  validateArticles(): boolean {
    const warehouseArray = _.chain(this.warehouses)
      .pickBy((data) => !_.isEmpty(data.added) || !_.isEmpty(data.removed))
      .map((data, key) => data)
      .value();

    for (const warehouse of warehouseArray) {
      for (const [key, data] of Object.entries(warehouse.added)) {
        if (data.quantity < 1) {
          AlertService.toastError(`La cantidad del artículo "${warehouse.articles.find(a => a.key === key).name}" de la bodega "${warehouse.warehouse.name}" debe ser mayor a 0`);
          return false;
        }
      }
    }

    return true;
  }

  async transferArticles() {
    this.spinner.show();

    const warehouseArray = _.chain(this.warehouses)
      .pickBy((data) => !_.isEmpty(data.added) || !_.isEmpty(data.removed))
      .map((data, key) => data)
      .value();

    for (let i = 0; i < warehouseArray.length; i++) {
      const removedArticles = warehouseArray[i].removed;
      const currentWarehouse = warehouseArray[i].warehouse;
      const currentArticles = warehouseArray[i].articles;

      await this.handleRemovedItems(removedArticles, currentWarehouse, currentArticles);
    }
  }

  setWarehouses(warehouses: WarehouseArticles) {
    this.warehousesSubject.next(warehouses);
  }

  removeArticle(
    origin: { articleId: string, warehouseId: string },
    destination: { articleId: string, warehouseId: string }
  ) {
    const updatedWarehouses = { ...this.warehouses };
    const originArticle = origin.articleId;
    const destinationArticle = destination.articleId;

    const originWarehouse = updatedWarehouses[origin.warehouseId];
    const destinationWarehouse = updatedWarehouses[destination.warehouseId];

    originWarehouse.removed[originArticle] = originWarehouse.removed[originArticle].filter(a => a.article.key !== destinationArticle);
    destinationWarehouse.added[destinationArticle].articles = destinationWarehouse.added[destinationArticle].articles.filter(a => a.origin.key !== origin.warehouseId);

    if (destinationWarehouse.added[destinationArticle].articles.length === 0) {
      delete destinationWarehouse.added[destinationArticle];
    }

    this.setWarehouses(updatedWarehouses);
  }

  addArticle = (origin, destination, quantity: number) => {
    const updatedWarehouses = this.warehouses;
    const originWarehouse = updatedWarehouses[origin.warehouseId];
    const originArticle = originWarehouse.articles.find(article => article.key === origin.articleId);

    const destinationWarehouse = updatedWarehouses[destination.warehouseId];
    const destinationArticle = originArticle;

    const remaining = originArticle.quantity - (originWarehouse.removed[origin.articleId]?.reduce((acc, data) => acc + data.quantity, 0) || 0);

    if (quantity > remaining) {
      AlertService.toastError('La cantidad ingresada es mayor a la cantidad disponible');
      return false;
    }

    const currentRemoved = originWarehouse.removed[origin.articleId] || [];
    const currentAdded = destinationWarehouse.added[destination.articleId]?.articles || [];
    const currentAddedArticle = currentAdded.find(a => a.article.key === originArticle.key);
    const currentRemovedArticle = currentRemoved.find(a => a.article.key === destinationArticle.key);

    if (currentAddedArticle) {
      currentAddedArticle.quantity += quantity;
      if (currentRemovedArticle) currentRemovedArticle.quantity += quantity;
    } else {
      currentRemoved.push({
        article: destinationArticle,
        destination: destinationWarehouse.warehouse,
        quantity: quantity
      });
      currentAdded.push({
        article: originArticle,
        origin: originWarehouse.warehouse,
        quantity: quantity
      });
    }

    originWarehouse.removed[origin.articleId] = currentRemoved;
    destinationWarehouse.added[destinationArticle.key] = {
      quantity: destinationWarehouse.added[destination.articleId]?.quantity || 0,
      articles: currentAdded,
      isNew: !destination.articleId
    };

    this.updateArticleQuantity(destination.warehouseId, destinationArticle.key, +quantity);
    this.setWarehouses(updatedWarehouses);
    return true;
  };

  updateArticle = (origin, destination, quantity: number) => {
    const updatedWarehouses = this.warehouses;
    const originWarehouse = updatedWarehouses[origin.warehouseId];
    const originArticle = originWarehouse.articles.find(article => article.key === origin.articleId);

    const destinationWarehouse = updatedWarehouses[destination.warehouseId];

    let destinationArticle = destinationWarehouse.articles.find(article => article.key === destination.articleId);
    if (!destinationArticle) {
      destinationArticle = destinationWarehouse.added[destination.articleId].articles[0].article;
    }
    const remaining = originArticle.quantity - (originWarehouse.removed[origin.articleId]?.reduce((acc, data) => acc + data.quantity, 0) || 0);

    if (quantity > remaining) {
      AlertService.toastError('La cantidad ingresada es mayor a la cantidad disponible');
      return false;
    }

    const currentRemoved = originWarehouse.removed[origin.articleId] || [];
    const currentAdded =
      destinationWarehouse.added[destination.articleId] || {
        quantity: 0,
        isNew: false,
        articles: []
      };

    currentAdded.articles.push({
      article: originArticle,
      origin: originWarehouse.warehouse,
      quantity: quantity
    });

    currentRemoved.push({
      article: destinationArticle,
      destination: destinationWarehouse.warehouse,
      quantity: quantity
    });

    originWarehouse.removed[origin.articleId] = currentRemoved;
    destinationWarehouse.added[origin.articleId] = currentAdded;

    this.updateArticleQuantity(destination.warehouseId, destinationArticle.key, +quantity);
    this.setWarehouses(updatedWarehouses);
    return true;
  };

  moveArticle(origin, destination, quantity: number): boolean {
    const isNew = !destination.articleId;

    if (isNew) return this.addArticle(origin, destination, quantity);
    else return this.updateArticle(origin, destination, quantity);
  }
}
