import { Component, OnInit } from '@angular/core';
import { Warehouse } from '../../interfaces/warehouse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WarehouseService } from '../../../../../shared/services/warehouse.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { UserService } from '../../../../../shared/services/user.service';
import { LogsService } from '../../../../services/logs.service';
import { UserType } from '../../../../enums/user-type.enum';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { PermissionService } from '../../services/permission.service';
import { simpleLineIcons } from '../../data/simple-line';
import { bgColors } from '../../data/bg-colors';
import _ from 'lodash';
import { warehouseTypeEnum } from '../../interfaces/warehouseTypeEnum';

@Component({
  selector: 'app-update-warehouse',
  templateUrl: './update-warehouse.component.html',
  styleUrls: ['./update-warehouse.component.css']
})
export class UpdateWarehouseComponent implements OnInit {
  warehouseKey: string;
  warehouse: Warehouse;
  editWarehouseForm: FormGroup;
  submitted: boolean = false;
  public supervisors = [];
  grocersAndBuyers: any = [];
  users: any = [];
  permissionEnum = UserType;
  photo: any;
  path: any;
  permission;
  icons: string[] = [...simpleLineIcons];
  bgColors: string[] = [...bgColors];
  whitelistSelected: any[] = [];
  filteredWhitelistSelected: any[] = [];
  whitelistRemaining: any[] = [];
  filteredWhitelistRemaining: any[] = [];
  managersSelected: any[] = [];
  filteredManagersSelected: any[] = [];
  managersRemaining: any[] = [];
  filteredManagersRemaining: any[] = [];
  warehouseType = [
    { name: 'Fija', value: warehouseTypeEnum.FIXED },
    { name: 'Transitoria', value: warehouseTypeEnum.TRANSITORY }
  ];

  constructor(private formBuilder: FormBuilder,
    private _warehouse: WarehouseService,
    public modal: BsModalRef,
    private _log: LogsService,
    private _user: UserService,
    private _permission: PermissionService) {
    this.editWarehouseForm = formBuilder.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      location: ['', Validators.required],
      warehouseManager: [''],
      grocer: ['', Validators.required],
      buyer: ['', Validators.required],
      users: [[]],
      imageUrl: [''],
      icon: [''],
      bgColor: [''],
      trash: [false],
      type: [null]
    });
  }

  ngOnInit(): void {
    this.permission = this._user.getPermission('BODEGAS');
    this.editWarehouseForm.patchValue(this.warehouse);

    this.filteredWhitelistSelected = this.whitelistSelected = this.warehouse.users;
    this.filteredManagersSelected = this.managersSelected = this.warehouse.warehouseManager;

    if (this.permission != this.permissionEnum.ADMIN) {
      this.editWarehouseForm.disable();
    }

    this._user
      .getUsers()
      .pipe(
        map((users) =>
          users.map((user) => ({
            imageUrl: !!user.imageUrl ? user.imageUrl : null,
            name: user.name,
            permissions: user.permissions,
            reference: this._user.getReference(user.key)
          }))
        )
      )
      .subscribe((data) => {
        let supervisors = [];
        let grocers = [];
        let users = [];

        this.setBuyer(data);

        for (let i = 0; i < data.length; i++) {
          if (!!data[i].permissions) {
            let permissionsLength = data[i].permissions.length;
            for (let iPermission = 0; iPermission < permissionsLength; iPermission++) {
              if (this._permission.isSupervisor(data[i].permissions[iPermission], 'BODEGAS')) {
                supervisors.push(data[i]);
                users.push(data[i]);
              }

              if (this._permission.isGrocer(data[i].permissions[iPermission], 'BODEGAS') ||
                this._permission.isBuyer(data[i].permissions[iPermission], 'BODEGAS')) {
                grocers.push(data[i]);
                users.push(data[i]);
              }

              if (this._permission.isUser(data[i].permissions[iPermission], 'BODEGAS')) {
                users.push(data[i]);
              }
            }
          }
        }

        this.supervisors = supervisors;
        this.grocersAndBuyers = grocers;
        this.users = data;

        this.filteredManagersRemaining = this.managersRemaining = _.differenceBy(supervisors, this.managersSelected, 'reference.id');
        this.filteredWhitelistRemaining = this.whitelistRemaining = _.differenceBy(users, this.whitelistSelected, 'reference.id');
      });
  }

  isValidPath(): boolean {
    return !(this.path == '' || this.path == null);
  }

  get formControls() {
    return this.editWarehouseForm.controls;
  }

  async submit() {
    if (this.managersSelected.length == 0) {
      return AlertService.toastError('Debe seleccionar al menos un encargado');
    }

    if (this.editWarehouseForm.value?.type == undefined) return AlertService.toastError('Debe seleccionar un tipo de bodega');

    this.submitted = true;
    if (!this.editWarehouseForm.valid) {
      return;
    }
    if (!(await AlertService.confirm('¿Está seguro de que desea guardar los datos?'))) {
      return;
    }

    this.editWarehouseForm.patchValue({
      buyer: this.editWarehouseForm.value.buyer.reference
    });

    if (!!this.path) {
      this.editWarehouseForm.patchValue({
        imageUrl: await this._warehouse.uploadPicture(
          this.photo,
          this.warehouseKey
        )
      });
    }

    this._warehouse.update(this.warehouseKey, {
      ...this.editWarehouseForm.value,
      warehouseManager: this.managersSelected,
      users: this.whitelistSelected
    } as Warehouse);

    this._log.add(this.warehouseKey, {
      description: `Se actualizó la bodega ${
        this.editWarehouseForm.get('name').value
      }`
    });

    AlertService.toastSuccess('Datos guardados correctamente');
    this.modal.hide();
  }

  setBuyer(users: any[]) {
    if (!('buyer' in this.warehouse)) {
      return;
    }
    if (!users.some((user) => user.reference.id == (<any>this.warehouse.buyer).id)) {
      return;
    }

    this.editWarehouseForm.patchValue({
      buyer: users.find(
        (user) => user.reference.id == (<any>this.warehouse.buyer).id
      )
    });
  }

  async handleWhitelist(userId: string, removeFromList = false) {
    const message = removeFromList ? '¿Está seguro de quitar al usuario?' : '¿Está seguro de agregar al usuario?';
    if (!await AlertService.confirm(message)) return this.editWarehouseForm.get('warehouseManager').reset();
    if (removeFromList) {
      const currentSelection = this.users.find(u => u.reference.id == userId);
      if (currentSelection) this.whitelistRemaining = [currentSelection, ...this.whitelistRemaining];
      this.whitelistSelected = this.whitelistSelected.filter(u => u.reference.id != userId);
    } else {
      const currentSelection = this.users.find(u => u.reference.id == userId);
      if (currentSelection) this.whitelistSelected = [currentSelection, ...this.whitelistSelected];
      this.whitelistRemaining = this.whitelistRemaining.filter(u => u.reference.id != userId);
    }

    this.editWarehouseForm.get('warehouseManager').reset();
    this.filteredWhitelistRemaining = this.whitelistRemaining;
    this.filteredWhitelistSelected = this.whitelistSelected;
  }

  async handleManagers(userId: string, removeFromList = false) {
    const message = removeFromList ? '¿Está seguro de quitar al usuario?' : '¿Está seguro de agregar al usuario?';
    if (!await AlertService.confirm(message)) return this.editWarehouseForm.get('warehouseManager').reset();

    this.editWarehouseForm.controls['warehouseManager'].setValue(null);
    if (removeFromList) {
      const currentSelection = this.supervisors.find(u => u.reference.id == userId);
      if (currentSelection) this.managersRemaining = [currentSelection, ...this.managersRemaining];
      this.managersSelected = this.managersSelected.filter(u => u.reference.id != userId);
    } else {
      const currentSelection = this.supervisors.find(u => u.reference.id == userId);
      if (currentSelection) this.managersSelected = [currentSelection, ...this.managersSelected];
      this.managersRemaining = this.managersRemaining.filter(u => u.reference.id != userId);
    }

    this.editWarehouseForm.get('warehouseManager').reset();
    this.filteredManagersRemaining = this.managersRemaining;
    this.filteredManagersSelected = this.managersSelected;
  }

  handleSearchWhitelist(e, remaining = true) {
    if (remaining) {
      this.filteredWhitelistRemaining = this.whitelistRemaining.filter(u => u.name.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.filteredWhitelistSelected = this.whitelistSelected.filter(u => u.name.toLowerCase().includes(e.toLowerCase()));
    }
  }

  handleSearchManager(e, remaining = true) {
    if (remaining) {
      this.filteredManagersRemaining = this.managersRemaining.filter(u => u.name.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.filteredManagersSelected = this.managersSelected.filter(u => u.name.toLowerCase().includes(e.toLowerCase()));
    }
  }

  close() {
    this.modal.hide();
  }
}
