import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../../../../shared/interfaces/user';
import { UserType } from "../../../../../../enums/user-type.enum";

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class UserCardComponent implements OnInit {
  @Input() user: any;
  @Output() removeFromList = new EventEmitter<string>();

  permission: UserType;

  mapTypeToLabel = {
    [UserType.USER]: 'Usuario',
    [UserType.ADMIN]: 'Administrador',
    [UserType.SUPERVISOR]: 'Supervisor',
    [UserType.GROCER]: 'Bodeguero',
    [UserType.BUYER]: 'Comprador'
  };

  constructor() {
  }

  ngOnInit(): void {
    this.permission = this.user.permissions.find(p => p.section === 'BODEGAS')?.permission;
  }

  removeUser() {
    this.removeFromList.emit(this.user.reference.id);
  }
}
