<div class="card shadow-none">
  <div>
    <div class="write-post">
      <div *ngIf="!isMobile; else mobilePDFViewer" class="col-sm-12 px-0">
        <div *ngIf="isPdf" [innerHTML]="url"></div>
      </div>

      <ng-template #mobilePDFViewer>
        <div class="mobile-pdf-container">
          <pdf-viewer
            [src]="this.applicants[this.indexApplicant]?.curriculum"
            [render-text]="true"
            [zoom]="zoom"
            [autoresize]="true"
            [original-size]="false"
            [show-all]="true"
            [zoom-scale]="'page-width'"
            [show-borders]="false"
            style="width: 100%; height: 100%"
          ></pdf-viewer>

          <div class="zoom-controls-container">
            <button class="btn btn-warning" (click)="handleZoom(-0.1)"><i class="fa fa-search-minus"></i></button>
            <button class="btn btn-primary" (click)="resetZoom()"><i class="fa fa-undo"></i></button>
            <button class="btn btn-info" (click)="handleZoom(0.1)"><i class="fa fa-search-plus"></i></button>
          </div>
        </div>
      </ng-template>

      <div *ngIf="isDocx" class="col-sm-12 p-1">
        <h4>No es posible visualizar este documento, si desea verlo en mas detalle favor de descargarlo</h4>
      </div>

      <div class="p-1">
        <label>Actualizar curriculum</label>
        <div
          [ngClass]="{'d-inline-flex align-items-center': !isMobile, 'd-flex flex-column align-items-center justify-content-center ':isMobile}"
        >
          <div [ngClass]="{'mb-1':isMobile}" *ngIf="permission == permissionEnum.ADMIN">
            <div class="form-group mb-0 input-group">
              <div class="custom-file">
                <input
                  type="file" class="custom-file-input"
                  (change)="uploadCurriculum($event)"
                  accept="application/pdf"
                  [ngClass]="{ 'is-invalid': submitted && formControls.comment.errors }"
                >
                <label class="custom-file-label">{{file ? this.file.name : 'Solo archivo pdf'}}</label>
              </div>
              <button
                class="btn btn-success" *ngIf="!!file" [disabled]="!file"
                (click)="updateCurriculum()"
              >
                <i class="feather ft-refresh-ccw"></i>
              </button>
            </div>
          </div>
          <div class="d-flex flex-row">
            <button
              class="btn btn-success padding-sm ml-1"
              (click)="sendUpdateCV()"
            >Solicitar curriculum
            </button>

            <button *ngIf="!!applicant.curriculum" class="btn btn-info padding-sm ml-1">
              <a [href]="url" *ngIf="isDocx" class="text-white" target="_self">Descargar documento</a>
              <a [href]="applicant.curriculum" *ngIf="isPdf" class="text-white" target="_blank">Descargar
                documento</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
