import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Article } from '../../interfaces/article';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArticleService } from '../../../../../shared/services/article.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CategoryService } from '../../../../../shared/services/category.service';
import { UnitsMeasureService } from '../../../../../shared/services/units-measure.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { ArticleType } from '../../enums/article-type.enum';
import { ArticleTypeLabel } from '../../labels/article-type.label';
import { ProviderService } from '../../../../../shared/services/provider.service';
import { LogsService } from '../../../../services/logs.service';
import { DataTableConfig } from '../../../../../shared/interfaces/data-table-config';
import { ValidationService } from '../../../../../shared/template-services/validation.service';
import { UserType } from '../../../../enums/user-type.enum';

@Component({
  selector: 'app-update-article',
  templateUrl: './update-article.component.html',
  styleUrls: ['./update-article.component.css']
})
export class UpdateArticleComponent implements OnInit {
  public UserType = UserType;
  inputsDisabled: boolean = false;
  article: Article;
  articleKey: string;
  editArticleForm: FormGroup;
  submitted: boolean = false;
  categorySubscription: Subscription = new Subscription();
  @ViewChild('labelImport', { static: true }) labelImport: ElementRef;
  imageToUpload: File = null;
  path: any;
  profilePhoto: any;
  photoValidation: boolean = true;
  categoriesValidation: boolean = true;
  multipleSelectArray: any = [];
  warehouseKey: string;
  selectArray: any = [];
  articleType = ArticleTypeLabel;
  selectArrayProviders: any = [];
  unitsMeasureValidation: boolean = true;
  unitMeasureSubscription: Subscription = new Subscription();
  providerSubscription: Subscription = new Subscription();
  serialNumberSelect: any;
  serialNumberTemp = [];
  quantityTemp;
  articleTypeEnum = ArticleType;
  recordsSubscription: Subscription = new Subscription();
  records: any = [];
  permission: UserType;
  config: DataTableConfig = {
    title: 'Registros',
    notFoundText: 'No se encontraron registros en este articulo',
    hasSearch: true
  };

  constructor(private formBuilder: FormBuilder,
    private _article: ArticleService,
    private _category: CategoryService,
    private _provider: ProviderService,
    public modal: BsModalRef,
    private _log: LogsService,
    private _unitMeasure: UnitsMeasureService) {
    this.editArticleForm = formBuilder.group({
      type: ['', Validators.required],
      name: ['', Validators.required],
      internalId: ['', Validators.required],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      categories: ['', Validators.required],
      minStock: [''],
      location: ['', Validators.required],
      imageUrl: ['', Validators.required],
      provider: [''],
      maxStock: [''],
      serialNumber: [[]],
      trash: [false],
      unitMeasure: [[], Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getCategories();
    this.editArticleForm.patchValue(this.article);
    this.getProviders();
    this.getUnitsMeasure();

    this.recordsSubscription = this._log.getAllRecords().subscribe(data => {
      this.records = data;
      this.records = this.records.filter(record => record.articleKey == this.articleKey);
    });

    this.inputsDisabled = this.permission == UserType.GROCER || this.permission == UserType.USER;
  }

  get formControls() {
    return this.editArticleForm.controls;
  }

  getProviders() {
    this.providerSubscription = this._provider.getAll()
      .pipe(
        map(categories => categories.map(provider => ({
            name: provider.name,
            key: provider.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayProviders = data;
      });
  }

  isValidPath(): boolean {
    return !(this.path == '' || this.path == null);
  }

  async submit() {
    this.submitted = true;
    if (!this.editArticleForm.valid) {
      return AlertService.toastError('Verifique todos los campos');
    }

    if (this.path != undefined) {
      this.editArticleForm.patchValue({ imageUrl: await this._article.uploadPicture(this.profilePhoto, this.articleKey) });
    }
    if (this.editArticleForm.get('serialNumber').value.length > this.quantityTemp) {
      return AlertService.toastError('Tiene un número serial extra');
    }
    await this._article.update(this.warehouseKey, this.articleKey, this.editArticleForm.value as Article);

    this._log.add(this.warehouseKey, {
      description: `Artículo ${this.editArticleForm.get('name').value}(${this.editArticleForm.get('internalId').value}) fue actualizado`
    });

    this.modal.hide();
  }

  getCategories() {
    this.categorySubscription = this._category.getAll()
      .pipe(
        map(categories => categories.map(category => ({
            name: category.name,
            key: category.key
          }
        )))
      )
      .subscribe(data => {
        this.multipleSelectArray = data;
      });
  }

  getUnitsMeasure() {
    this.unitMeasureSubscription = this._unitMeasure.getAll()
      .pipe(
        map(unitsMeasure => unitsMeasure.map(unitMeasure => ({
            name: unitMeasure.name,
            key: unitMeasure.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArray = data;
      });
  }

  choosePicture(event) {
    if (!ValidationService.validateFileSize(event, 10000)) {
      return AlertService.toastError('El archivo debe ser menor a 10MB');
    }

    this.profilePhoto = event.target.files[0];
    if (event.target.files && this.profilePhoto) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.profilePhoto);
      this.editArticleForm.patchValue({ imageUrl: 'url' });
    }
  }

  async addSerialNumber() {
    if (this.editArticleForm.get('serialNumber').value.length == this.quantityTemp) {
      return AlertService.toastError('Ya ingresaste el máximo de números de serie');
    }
    this.serialNumberTemp = this.editArticleForm.get('serialNumber').value;
    for (let i = this.editArticleForm.get('serialNumber').value.length; i < this.quantityTemp; i++) {
      const serialNumber = await AlertService.input('Número de serie');
      if (!!serialNumber) {
        this.serialNumberTemp.push({ serialNumber: serialNumber });
      }
    }
    this.editArticleForm.patchValue({
      serialNumber: this.serialNumberTemp
    });
  }

  async addQuantity(event) {
    this.quantityTemp = +this.editArticleForm.get('quantity').value;
  }

  async addCategory() {
    const category = await AlertService.input('Nombre de la categoría');
    if (!!category) {
      this._category.add({ name: category, trash: false });
    }
  }

  onFileChange(event) {
    if (!ValidationService.validateFileSize(event, 10000)) return AlertService.toastError('El archivo debe ser menor a 10MB');

    this.imageToUpload = event.target.files.item(0);
    this.choosePicture(event);
  }
}
