import { Injectable } from '@angular/core';
import { TicketStatus } from "../../../../enums/ticket-status.enum";
import { TicketEntryStatus } from "../../../../enums/ticket-entry-status.enum";

@Injectable({
  providedIn: 'root'
})
export class TicketsBadgesService {

  constructor() { }

  getTicketStatusBadge(status: TicketStatus): string {
    switch (status) {
      case TicketStatus.DRAFT:
      case TicketStatus.GENERATED:
        return 'badge-primary';
      case TicketStatus.AUTHORIZED:
        return 'badge-info';
      case TicketStatus.COLLECTED:
        return 'badge-warning';
      case TicketStatus.INCOMPLETE:
        return 'badge-danger';
      case TicketStatus.RETURNED:
        return 'badge-success';
      case TicketStatus.CANCELLED:
      case TicketStatus.EXPIRED:
        return 'badge-dark';
      default:
        return 'badge-success';
    }
  }

  getEntryTicketStatusBadge(status: TicketEntryStatus): string {
    switch (status) {
      case TicketEntryStatus.DRAFT:
        return 'badge-primary';
      case TicketEntryStatus.GENERATED:
        return 'badge-primary';
      case TicketEntryStatus.AUTHORIZED:
        return 'badge-info';
      case TicketEntryStatus.CANCELLED:
        return 'badge-dark';
      case TicketEntryStatus.ADDED:
        return 'badge-info';
      default:
        return 'badge-success';
    }
  }

  getTicketStatusLabel(status: TicketStatus): string {
    switch (status) {
      case TicketStatus.DRAFT:
        return 'Borrador';
      case TicketStatus.GENERATED:
        return 'Generado';
      case TicketStatus.AUTHORIZED:
        return 'Autorizado';
      case TicketStatus.COLLECTED:
        return 'Retirado';
      case TicketStatus.INCOMPLETE:
        return 'Incompleto';
      case TicketStatus.RETURNED:
        return 'Devuelto';
      case TicketStatus.CANCELLED:
        return 'Cancelado';
      case TicketStatus.EXPIRED:
        return 'Expirado';
      default:
        return '';
    }
  }

  getEntryTicketStatusLabel(status: TicketEntryStatus): string {
    switch (status) {
      case TicketEntryStatus.DRAFT:
        return 'Borrador';
      case TicketEntryStatus.GENERATED:
        return 'Generado';
      case TicketEntryStatus.AUTHORIZED:
        return 'Autorizado';
      case TicketEntryStatus.CANCELLED:
        return 'Cancelado';
      case TicketEntryStatus.ADDED:
        return 'Agregado';
      default:
        return '';
    }
  }
}
