import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Warehouse } from '../../../../interfaces/warehouse';
import { Article } from '../../../../interfaces/article';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArticlesTransferService } from '../../../../services/articles-transfer.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AlertService } from '../../../../../../../shared/template-services/alert.service';
import { ArticleQuantityFormComponent } from '../article-quantity-form/article-quantity-form.component';
import _ from 'lodash';

@Component({
  selector: 'app-destination-warehouse',
  templateUrl: './destination-warehouse.component.html',
  styleUrls: ['./destination-warehouse.component.css']
})
export class DestinationWarehouseComponent implements OnInit {
  @Input() warehouseId: string;

  warehousesSubscription: Subscription = new Subscription();
  warehouses: any;
  warehouse: Warehouse;
  addedArticles: { [key: string]: any } = {};
  articles: Article[];
  searchedArticles: Article[] = [];
  searchValue = '';

  constructor(private modal: BsModalService,
    private _transfer: ArticlesTransferService) {
  }

  ngOnInit(): void {
    this.warehousesSubscription = this._transfer.warehouses$.subscribe(warehouses => {
      const newArticles = _.values( warehouses[this.warehouseId].added).filter(article => article.isNew);
      const addedArticles = newArticles.map(data => {
        return data.articles[0].article
      });

      this.warehouses = warehouses;
      this.warehouse = warehouses[this.warehouseId].warehouse;
      this.articles = [...addedArticles,...warehouses[this.warehouseId].articles];
      this.addedArticles = warehouses[this.warehouseId].added;

      this.search();
    });
  }

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer.data == event.container.data) {
      AlertService.toastError('No se puede transferir un artículo a la misma bodega');
      return;
    }

    this.modal.show(ArticleQuantityFormComponent, {
      initialState: {
        originArticle: event.item.data,
        destinationArticle: event.container.data
      },
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
      id: 667
    });
  }

  search() {
    this.searchedArticles = this.articles.filter(a => a.name.toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  ngOnDestroy(): void {
    this.warehousesSubscription.unsubscribe();
  }
}
