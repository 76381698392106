const app = {
  vehicles: '/admin/vehicles',
  fundToRender: '/admin/fund-to-render',
  peopleManagement: '/admin/people-management',
  blog: '/admin/blog',
  projects: '/admin/projects',
  salesV2: '/admin/sales-v2',
  boardsV2: '/admin/boards-v2',
  induction: '/aplicante/induccion',
  deviations: '/admin/deviations',
  requisitions: '/admin/requisitions',
  providerArticles: '/admin/providerArticles',
  training: '/admin/training',
  documents: '/admin/documents'
};

const embedded = {
  vehicles: 'fleet',
  fundToRender: 'funds-to-render',
  peopleManagement: 'people-management',
  blog: 'blog',
  projects: 'projects',
  salesV2: 'sales',
  boardsV2: 'tasks',
  induction: 'training',
  deviations: 'deviations',
  requisitions: 'purchase-orders/requisitions',
  providerArticles: 'purchase-orders/articles',
  training: 'training',
  documents: 'documents'
};

export const Sections = {
  app,
  embedded
};
