import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PurchaseOrder } from '../interfaces/purchase-order';
import { PurchaseOrderService } from '../../../../shared/services/purchase-order.service';
import { FirebaseDataService } from '../../../../shared/template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersDataService {
  private dataSubject = new BehaviorSubject<PurchaseOrder[]>([]);
  public data$: Observable<PurchaseOrder[]> = this.dataSubject.asObservable();

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private db: FirebaseDataService
  ) {
    this.initData();
  }

  private initData(): void {
    this.purchaseOrderService.getAll().subscribe(data => {
      this.dataSubject.next(data);
    });
  }

  getPurchaseOrder(id: string): Observable<PurchaseOrder> {
    return this.db.docWithId$<PurchaseOrder>(`purchaseOrders/${id}`);
  }
}
