<div class="row p-md-4 pl-0 overflow-x-scroll-hidden">
  <div class="col-12" *blockUI="'numberTabs'; message: 'Loading'">
    <div class="w-100 d-flex flex-column align-items-center">
      <div class="col-12 text-center">
        <img [src]="AppConstants.COLSAN_LOGO_PRIMARY" alt="Logo COLSAN" style="object-fit: contain;" height="80px">
      </div>
      <div class="col-12 text-center">
        <div class="background-colsan row justify-content-center">
          <div class="col-md-8 font text-center">
            <label class="font-size-title sidebar-label mt-2 line-height-1 text-primary">
              Trabaja con nosotros
            </label><br>
            <h1>
              Gracias por tu interés en trabajar en Ingeniería y servicios COLSAN LTDA.
            </h1>
          </div>
        </div>
      </div>
    </div>

    <ng-container>
      <div *ngIf="loading" class="text-center my-4">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <ng-container *ngIf="!loading && applicantNotFound; else content">
        <div class="card-block text-center mt-3">
          <h3 class="text-danger">
            Lo sentimos, no se encontró tu solicitud.
          </h3>
          <p>Por favor, verifica tu información e inténtalo de nuevo. Si el problema persiste, contacta con nuestro equipo de soporte.</p>
        </div>
      </ng-container>

      <ng-template #content>
        <ng-container *ngIf="!loading">
          <div class="card bg-white mt-3 mx-auto" style="max-width: 50rem;">
            <div class="card-header text-center mb-0 pb-0">
              <h2 class="text-primary">
                <i class="feather {{ status === PreselectionStatusEnum.ACCEPTED ? 'ft-check-circle' : 'ft-x-circle' }}"></i>
                {{ status === PreselectionStatusEnum.ACCEPTED ? 'Has aceptado la preselección' : 'Has rechazado la preselección' }}
              </h2>
            </div>
            <div class="card-body text-center">
              <p class="card-text m-0">
                {{ status === PreselectionStatusEnum.ACCEPTED ?
                '¡Gracias por aceptar la preselección! Pronto nos pondremos en contacto contigo para los siguientes pasos.' :
                'Has rechazado la preselección. Agradecemos tu sinceridad y esperamos poder colaborar en el futuro.' }}
              </p>
              <div *ngIf="status === PreselectionStatusEnum.ACCEPTED" class="my-1">
                <i class="feather ft-thumbs-up fa-4x text-success"></i>
              </div>
              <div *ngIf="status === PreselectionStatusEnum.REJECTED" class="my-1">
                <i class="feather ft-thumbs-down fa-4x text-danger"></i>
              </div>
            </div>
            <div class="card-footer text-muted text-center">
              Ingeniería y Servicios COLSAN LTDA.
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> {{messageLoading}} </p></ngx-spinner>
