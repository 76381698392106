export const FileTypeIcons = {
  'application/pdf': 'assets/icons/pdf.png',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'assets/icons/doc.png',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'assets/icons/xls.png',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'assets/icons/file.png',
  'image/jpeg': 'assets/icons/jpg.png',
  'image/png': 'assets/icons/png.png',
  'image/gif': 'assets/icons/picture.png',
  'image/bmp': 'assets/icons/picture.png',
  'image/webp': 'assets/icons/picture.png',
  'image/svg+xml': 'assets/icons/picture.png',
  'image/tiff': 'assets/icons/picture.png',
  'image/x-icon': 'assets/icons/picture.png',
  'image/vnd.microsoft.icon': 'assets/icons/picture.png',
  'image/vnd.adobe.photoshop': 'assets/icons/picture.png',
  'image/vnd.dwg': 'assets/icons/picture.png',
  'image/vnd.dxf': 'assets/icons/picture.png',
  'image/vnd.djvu': 'assets/icons/picture.png',
  'application/vnd.ms-excel': 'assets/icons/xls.png',
  'application/vnd.ms-powerpoint': 'assets/icons/file.png',
  'application/vnd.ms-word': 'assets/icons/doc.png',
  'application/vnd.oasis.opendocument.presentation': 'assets/icons/file.png',
  'application/vnd.oasis.opendocument.spreadsheet': 'assets/icons/xls.png',
  'application/vnd.oasis.opendocument.text': 'assets/icons/doc.png',
  'audio/mp3': 'assets/icons/mp3.png',
  'text/plain': 'assets/icons/txt.png',
  'video/mp4': 'assets/icons/file.png',
  'video/x-msvideo': 'assets/icons/file.png',
  'video/x-ms-wmv': 'assets/icons/file.png',
  'video/x-flv': 'assets/icons/file.png',
  'video/webm': 'assets/icons/file.png',
  'video/ogg': 'assets/icons/file.png',
  'video/quicktime': 'assets/icons/file.png'
};

export const getIcon = (fileType: string) => {
  if (FileTypeIcons[fileType]) return FileTypeIcons[fileType];

  if (fileType.includes('image')) {
    return FileTypeIcons['image/png'];
  }
  if (fileType.includes('video')) {
    return FileTypeIcons['video/mp4'];
  }
  if (fileType.includes('audio')) {
    return FileTypeIcons['audio/mp3'];
  }
  if (fileType.includes('text')) {
    return FileTypeIcons['text/plain'];
  }
  if (fileType.includes('word')) {
    return FileTypeIcons['application/vnd.ms-word'];
  }
  if (fileType.includes('excel')) {
    return FileTypeIcons['application/vnd.ms-excel'];
  }
  if (fileType.includes('pdf') || fileType.includes('powerpoint') || fileType.includes('presentation') || fileType.includes('spreadsheet')) {
    return FileTypeIcons['application/vnd.oasis.opendocument.presentation'];
  }

  return 'assets/icons/file.png';
};
