import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PurchaseStatusLabel } from '../../../../labels/purchase-status.label';
import { PurchaseStatus } from '../../../../enums/purchase-status.enum';
import { PurchaseOrder } from '../../interfaces/purchase-order';
import { UserType } from '../../../../enums/user-type.enum';
import { PurchaseOrderService } from '../../../../../shared/services/purchase-order.service';
import { ProviderService } from '../../../../../shared/services/provider.service';
import { UserService } from '../../../../../shared/services/user.service';
import { Observable, Subscription } from 'rxjs';
import { UploadFileStorageComponent } from '../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { User } from '../../../../../shared/interfaces/user';
import { LogsService } from '../../../../services/logs.service';
import { NotificationService } from '../../../../services/notification.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-purchase-order-wrapper',
  templateUrl: './purchase-order-wrapper.component.html',
  styleUrls: ['./purchase-order-wrapper.component.css']
})
export class PurchaseOrderWrapperComponent implements OnInit {
  @Input() initialPurchaseOrder: PurchaseOrder;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @ViewChild(UploadFileStorageComponent) filesStorage;
  submitted: boolean = false;
  purchaseOrder: PurchaseOrder;
  currentDate = new Date().getTime();
  providerForm: any;
  articlesPurchaseForm: any;
  deliveryDate: any;
  purchaseStatus = PurchaseStatus;
  permission: UserType;
  permissionEnum = UserType;
  logs$: Observable<any>;
  purchaseStatusLabel = PurchaseStatusLabel;
  isPrint: boolean = false;
  quotation = null;
  usersSubscription: Subscription = new Subscription();
  userTypeEnum = UserType;
  supervisorUsers: User[] = [];
  financeUsers: User[] = [];
  financeThreshold: number;
  updatedFromDraft: boolean = false;

  constructor(
    public modal: BsModalService,
    public _purchase: PurchaseOrderService,
    private _provider: ProviderService,
    public _user: UserService,
    private _log: LogsService,
    private _notification: NotificationService,
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const purchaseOrderKey = params.purchaseOrderKey || this.initialPurchaseOrder?.key;
      if (purchaseOrderKey) {
        this._purchase.get(purchaseOrderKey).subscribe((purchaseOrder) => {
          this.purchaseOrder = purchaseOrder;
          this.logs$ = this._log.getAllPurchaseOrder(purchaseOrderKey);
        });
      }
    });

    this.usersSubscription = this._user.getUsers().subscribe((users) => {
      this.supervisorUsers = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find((per) => per.section == 'COMPRAS');

        return !!section && (section.permission == this.userTypeEnum.SUPERVISOR || section.permission == this.userTypeEnum.ADMIN);
      });

      this.financeUsers = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find((per) => per.section == 'COMPRAS');

        return !!section && section.permission == this.userTypeEnum.FINANCE;
      });
    });

    this._purchase.getFinanceThreshold().then((threshold) => {
      this.financeThreshold = threshold;
    });

  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  print() {
    this.isPrint = true;
  }

  getStatusClass(status: number) {
    switch (status) {
      case PurchaseStatus.DRAFT:
        return 'text-dark';

      case PurchaseStatus.GENERATED:
        return 'text-primary';

      case PurchaseStatus.AUTHORIZED:
        return 'text-info';

      case PurchaseStatus.NOT_AUTHORIZED:
        return 'text-warning';

      case PurchaseStatus.INCOMPLETE:
        return 'text-warning';

      case PurchaseStatus.WAITING_FOR_REVIEW:
        return 'waiting-for-review-status-color';

      case PurchaseStatus.UNDER_REVIEW:
        return 'under-review-status-color';

      case PurchaseStatus.UNDER_APPROVAL:
        return 'under-approved-status-color';

      default:
        return 'text-success';
    }
  }

  currencyFormat(value: number | string): string {
    const numberValue = Number(value.toString().replace(/[.$,]/g, '')) || 0;
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(numberValue);
  }

  async changePurchaseOrder(purchaseOrder) {
    this.purchaseOrder = purchaseOrder;
    this.updatedFromDraft = true;
  }

  handleClose() {
    if (this.closeModal.observers.length > 0) {
      this.closeModal.emit();
    } else {
      this.route.navigate(['/admin/purchase-orders']);
    }
  }
}
